import * as React from 'react';
import ReactJson from 'react-json-view-ts';
import { withRouter } from 'react-router-dom';
import config from 'src/config';
import { get } from 'src/shared/ajaxFetch/ajaxFetch';
import { getTranslator } from '../../shared/language';
import { AppSessionContext } from '../app/AppComponent';

const words = getTranslator({
  en: {
    search: 'Search'
  },
  no: {
    search: 'Søk'
  }
});

const SimpleNumisDataViewer = withRouter(({ match, history }) => {
  const appSession = React.useContext(AppSessionContext);
  const [newData, setNewData] = React.useState({});
  const [legacyData, setLegacyData] = React.useState({ attributes: { legacyData: '' } });
  const id = match.params.id;
  const fetchData = async () => {
    const result: Response = await get(
      config.api.objects.getMuseumObjectUrl(id),
      appSession.accessToken
    );
    const data = await result.json();

    const events2Filter = data['events'].filter((ev: any) => {
      if (ev.eventTypeId === 22) {
        let legDta = JSON.parse(ev.attributes.legacyData);
        setLegacyData(legDta);
      }
      return ev.eventTypeId !== 22;
    });
    const filteredData = { ...data, events: events2Filter };
    setNewData(filteredData);
  };

  React.useEffect(() => {
    // tslint:disable-next-line: no-floating-promises
    fetchData();
  }, []);

  return (
    <div className="container-fluid" style={{ marginTop: 100 }}>
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <h1 className="text-center">Numismatikk</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <h2>Legacy data</h2>
          <ReactJson
            src={legacyData['gjenstand'] || { noData: 'No data to show' }}
            collapsed={false}
            name={false}
            displayDataTypes={false}
          />
        </div>
        <div className="col-md-6">
          <h2>Migrerte data</h2>
          <ReactJson
            src={newData || { noData: 'No data to show' }}
            collapsed={false}
            name={false}
            displayDataTypes={false}
          />
        </div>
      </div>
    </div>
  );
});

export default SimpleNumisDataViewer;
