import * as React from 'react';

/*eslint-disable */
export default () => (
  <div>
    <h2>MUSIT - The University museums IT-organisation</h2>
    <br />
    <br />
    <p>
      The University museums IT-organisation (MUSIT) is a national collaboration between
      the University of Bergen, the University of Oslo, University of Stavanger, Norwegian
      University of Science and Technology in Trondheim , and the University of Tromsø
      -Norway's Arctic University.
    </p>
    <p>
      The collaboration is between the University Museum of Bergen, Archeological Museum
      in Stavanger, Museum of Cultural History in Oslo, Natural History Museum in Oslo,
      NTNU University museum in Trondheim, and the Tromsø University museum.
    </p>
    <p>
      These university museums are custodians of Norway’s largest natural and cultural
      history collections encompassing a number of disciplines such as archeology, botany,
      ethnography, geology, numismatic and zoology. MUSITs core purpose is to ensure the
      operation, maintenance and development of the university museums joint collection
      databases in order to enable the dissemination of these data-rich collections for
      use in research, conservation policy and management, and education.
    </p>
    <br />
    <br />
  </div>
);
