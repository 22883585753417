import * as React from 'react';
import SimplePersonsComponent from '../person/SimplePersonsComponent';
import {
  MusitCombinedDatePicker,
  DateFormat,
  DateRangeWithVerbatim
} from '../../../components/DatePicker';

import DetTypeComponent from './DetTypeComp';

import {
  TaxonEvent,
  TaxonTypeEdit,
  TaxonTypeSave,
  TaxonTypeMini,
  formatTaxonStrings,
  PrecisionCode
} from './taxonLib';
import { TaxonPrecisionSensu } from './TaxonPrecisionSensu';
import { uniqueId } from 'lodash';
import { ActorAndRelation } from '../types/event/event';
import { AppSession } from 'src/types/appSession';
import { getTranslator } from '../../../shared/language';
import { RoleTypeId } from '../types/event/eventTypes_auto';
import './Taxon.css';

const words = getTranslator({
  en: {
    determinator: 'Determinator',
    editExpand: 'Edit/Expand',
    note: 'Note',
    searching: 'Searching',
    expandEdit: '+ Edit',
    collapseEdit: '- Collapse edit'
  },
  no: {
    determinator: 'Determinator',
    editExpand: 'Rediger/Ekspander',
    note: 'Notat',
    searching: 'Søker',
    expandEdit: '+ Rediger',
    collapseEdit: '- Minimer redigering'
  }
});

const TaxonComponent = ({
  taxonEvent,
  defaultDateFormat,
  changeTaxonEvent,
  detTypeWords,
  appSession
}: {
  taxonEvent: TaxonEvent;
  defaultDateFormat: string | undefined;
  appSession: AppSession;
  detTypeWords: any;
  changeTaxonEvent: (event: TaxonEvent) => void;
}) => {
  // AUTHENTICATION
  const selectedTaxonEdit = taxonEvent.attributes.taxons
    ? taxonEvent.attributes.taxons[0]
    : undefined;
  const [isSearching, updateIsSearching] = React.useState(
    taxonEvent.attributes.taxons && taxonEvent.attributes.taxons.length > 0 ? false : true
  );
  const [taxonDateId] = React.useState(uniqueId('taxonDate'));

  const [showEdit, changeshowEdit] = React.useState(false);

  const validateChangesAndUpdate = (editTaxon: TaxonTypeEdit) => {
    updateIsSearching(false);
    changeTaxonEvent({
      ...taxonEvent,
      attributes: { ...taxonEvent.attributes, taxons: [editTaxon as TaxonTypeSave] }
    });
  };

  const taxonCardTitle = (
    taxonItem?: TaxonTypeMini,
    precision?: PrecisionCode,
    precisionRank?: string,
    sensu?: string
  ) =>
    taxonItem
      ? formatTaxonStrings(taxonItem, precision, precisionRank, sensu).join(' ')
      : 'Søker opp taxon';

  const onChangeTaxonType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    changeTaxonEvent({
      ...taxonEvent,
      attributes: {
        ...taxonEvent.attributes,
        taxonTypeId: Number.parseInt(e.target.value)
      }
    });
  };

  const taxonAddedForValidation =
    taxonEvent.attributes.taxons.length > 0 &&
    taxonEvent.attributes.taxons[0].selectedName
      ? false
      : true;
  return (
    <div className="container pt-3">
      {!selectedTaxonEdit && !isSearching && (
        <button
          className="btn btn-secondary"
          type="button"
          onClick={() => updateIsSearching(true)}
        >
          + Legg til taxon
        </button>
      )}

      <div className="row">
        <div className="col-md-12">
          <p className="font-italic text-center">
            {selectedTaxonEdit && isSearching === false
              ? taxonCardTitle(
                  selectedTaxonEdit,
                  selectedTaxonEdit.precisionCode,
                  selectedTaxonEdit.precisionRank,
                  selectedTaxonEdit.sensu
                )
              : ''}
          </p>
        </div>
      </div>

      <TaxonPrecisionSensu
        selectedTaxon={selectedTaxonEdit}
        appSession={appSession}
        updateSelectedTaxonEdit={validateChangesAndUpdate}
        taxonData={taxonEvent.attributes.taxons}
        isSearching={isSearching}
        updateIsSearching={updateIsSearching}
      />

      <SimplePersonsComponent
        id={'taxonOrig'}
        extraClass={'border-primary'}
        personData={taxonEvent.relatedActors ? taxonEvent.relatedActors : []}
        disabled={taxonAddedForValidation}
        changePersonDataState={(personData: ActorAndRelation[]) => {
          changeTaxonEvent({ ...taxonEvent, relatedActors: personData });
        }}
        eventMetadata={{ name: '' }}
        changeEventMetadataState={undefined}
        displayNameOnLabel={false}
        personLabel={words.determinator}
        personRole={RoleTypeId.classifiedBy}
      />

      <MusitCombinedDatePicker
        id={taxonDateId}
        disabled={taxonAddedForValidation}
        dateRange={
          taxonEvent.date ? taxonEvent.date : { lengthOfFormat: DateFormat.YEAR }
        }
        className="form-control border-primary"
        onChange={(date: DateRangeWithVerbatim) => {
          changeTaxonEvent({ ...taxonEvent, date: date });
        }}
      />
      <div className="form-row">
        <div
          className="offset-md-9 col-md-3 createLink text-right"
          onClick={() => changeshowEdit(!showEdit)}
        >
          {showEdit ? words.collapseEdit : words.expandEdit}
        </div>
      </div>
      <div>
        <div
          className={
            showEdit ? `show form-row backgroundGrey mt-4 p-3 pb-4 rounded` : `hide`
          }
        >
          <div className="col-md-8">
            <DetTypeComponent
              disabled={taxonAddedForValidation}
              detType={taxonEvent.attributes.taxonTypeId}
              onChange={onChangeTaxonType}
              detTypeWords={detTypeWords}
            />
          </div>
          <div className="col-md-8 form-group">
            <label htmlFor="taxonNote">{words.note}</label>
            <textarea
              className="form-control border-primary"
              disabled={taxonAddedForValidation}
              id="taxonNote"
              rows={4}
              value={taxonEvent.attributes.note}
              onChange={t =>
                changeTaxonEvent({
                  ...taxonEvent,
                  attributes: { ...taxonEvent.attributes, note: t.target.value }
                })
              }
            />
          </div>
        </div>

        <button
          className="btn btn-outline-primary mt-2 mr-n1 float-right bg-white text-dark"
          onClick={e => {
            e.preventDefault();
            /* TODO */
          }}
        >
          {words.resetButton}
        </button>
      </div>
    </div>
  );
};

export default TaxonComponent;
