/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { EventMetadataProps } from './CollectingEventComponent';
import {
  MusitCombinedDatePicker,
  DateFormat,
  DateRangeWithVerbatim
} from '../../../components/DatePicker';
import { getTranslator } from '../../../shared/language';
import { MuseumObjectSearchResult } from '../types/museumObject/museumObject';
import { compareMusNo } from '../utils/compareFunctions';
import { fromMuseumObjectUuidLocalStorageKey } from '../../museumObject/components/CollectingEventRelations/CollectingEventRelationComponent';

const words = getTranslator({
  en: {
    objects: 'Objects',
    name: 'Name',
    from: 'From',
    to: 'To',
    verbatimDate: 'Verbatim date',
    eventDate: 'Event date',
    note: 'Note',
    newEventForObject: 'Create new event for object'
  },
  no: {
    objects: 'Objekter',
    name: 'Navn',
    from: 'Fra',
    to: 'Til',
    verbatimDate: 'Verbatim dato',
    eventDate: 'Event dato',
    note: 'Notat',
    newEventForObject: 'Lag ny event for objekt'
  }
});

const EventMetadata = (props: EventMetadataProps) => {
  const fromObjectUuid = localStorage.getItem(fromMuseumObjectUuidLocalStorageKey);
  const onChange = (fieldName: string, value: string | number | boolean) => {
    const dataToChange = props.dataState || { name: '' };
    props.changeData({ ...dataToChange, [fieldName]: value });
  };
  const onChangeDate = (date: DateRangeWithVerbatim) => {
    props.changeDate({
      ...props.dateState,
      eventDateFrom: date.eventDateFrom ? date.eventDateFrom : undefined,
      eventDateTo: date.eventDateTo ? date.eventDateTo : undefined,
      eventDateVerbatim: date.eventDateVerbatim,
      lengthOfFormat: date.lengthOfFormat
    });
  };

  const renderDatePicker = (
    picker: JSX.Element,
    dateFormatSelect: JSX.Element,
    verbatimInput: JSX.Element
  ) => (
    <React.Fragment>
      <div className="row">
        <label className="col-form-label col-md-2 d-flex justify-content-end" />
        {picker}
        <div className="col-md-2 form-group">
          <label htmlFor="eventDatePickerformat">{words.dateFormat}</label>
          {dateFormatSelect}
        </div>
      </div>
      <div className="form-row">
        <label className="col-form-label col-md-2 d-flex justify-content-end">
          {words.verbatimDate}
        </label>
        <div className="col-md-4 form-group">{verbatimInput}</div>
      </div>
    </React.Fragment>
  );
  return (
    <div className="py-3">
      <form className="form-horizontal">
        {props.relatedObjects && (
          <div className="form-group row">
            <label className="col-form-label col-md-2 d-flex justify-content-end">
              <b>{words.objects}</b>
            </label>
            <div className="col-md-4 col-form-label">
              {props.relatedObjects
                .sort((m1, m2) =>
                  compareMusNo(
                    m1.mnr + '/' + m1.subNo || '1000000000',
                    m2.mnr + '/' + m2.subNo || '1000000000'
                  )
                )
                .map((o: MuseumObjectSearchResult, i) => (
                  <div
                    key={`key-relatedObjects-${i}`}
                    className="form-row"
                    style={{ fontSize: '0.7em' }}
                  >
                    <div className="col-md-7">
                      <a
                        style={{
                          fontWeight:
                            o.museumObjectUuid === fromObjectUuid ? 'bold' : 'normal'
                        }}
                        href="#"
                        key={'museumSearchResult' + i}
                        onClick={a => {
                          a.preventDefault();
                          if (props.gotoUrl && props.getUrl && o.museumObjectUuid) {
                            props.gotoUrl(props.getUrl(o.museumObjectUuid));
                          }
                        }}
                      >
                        {o.mnr}
                        {o.subNo ? '/' + o.subNo : null}
                      </a>
                    </div>
                    <div className="col-md-5">
                      {' '}
                      <a
                        href="#"
                        key={'museumSearchResultButtonNew' + i}
                        onClick={async e => {
                          e.preventDefault();
                          if (
                            props.saveCollectingEventCopyAndCreateNew &&
                            o.museumObjectUuid
                          ) {
                            await props.saveCollectingEventCopyAndCreateNew(
                              o.museumObjectUuid
                            );
                            localStorage.removeItem(fromMuseumObjectUuidLocalStorageKey);
                          }
                        }}
                      >
                        {words.newEventForObject}
                      </a>
                    </div>
                  </div>
                ))}{' '}
            </div>
          </div>
        )}

        <div className="form-row">
          <label className="col-form-label col-md-2 d-flex justify-content-end">
            <b>{words.name}</b>
          </label>
          <div className="col-md-4">
            <input
              type="text"
              //autoFocus  //Taking focus for the whole page, not only component
              className={
                props.validationFileds && props.validationFileds.touchedName
                  ? 'form-control border border-danger'
                  : 'form-control'
              }
              id="nameInput"
              placeholder={words.name}
              value={(props.dataState && props.dataState.name) || ''}
              disabled={props.funcState.readOnly || props.disabled}
              onChange={e => onChange('name', e.target.value)}
              onBlur={() => {
                const status = props.dataState && props.dataState.name ? false : true;
                props.changeValidationStateField &&
                  props.changeValidationStateField('touchedName')(status);
              }}
            />
          </div>
        </div>
        <MusitCombinedDatePicker
          id="eventDatePicker"
          className="form-control"
          onChange={(date: DateRangeWithVerbatim) => onChangeDate(date)}
          renderFunction={renderDatePicker}
          dateRange={{
            ...props.dateState,
            lengthOfFormat:
              props.dateState && props.dateState.lengthOfFormat
                ? props.dateState.lengthOfFormat
                : DateFormat.DATE
          }}
          disabled={props.funcState.readOnly || props.disabled}
        />
        <div className="form-row">
          <label
            className="col-form-label d-flex justify-content-end col-md-2"
            htmlFor="eventNote"
          >
            <b>{words.note}</b>
          </label>

          <div className="col-md-4">
            <textarea
              rows={4}
              className="form-control"
              id="eventNote"
              value={(props.dataState && props.dataState.note) || ''}
              disabled={props.funcState.readOnly || props.disabled}
              onChange={(v: React.ChangeEvent<HTMLTextAreaElement>) =>
                onChange('note', v.target.value)
              }
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default EventMetadata;
