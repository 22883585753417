import {
  CollectingEvent,
  ValidationFields,
  CollectingEventHeader
} from '../types/frontend/collectingEventForm';
import {
  ActorAndRelation,
  MuseumObjectAndRelation,
  DateAttributes as EventDate,
  EventUuid,
  DateFormat
} from '../types/event/event';
import { InputPlace as Place } from '../types/place/place';
import {
  CollectingEventEventAttributes as CollectingEventAttributes,
  EventTypeId
} from '../types/event/eventTypes_auto';

import { AppSession } from 'src/types/appSession';

export type UpdateIsChangedFunc = React.Dispatch<React.SetStateAction<IsChangedType>>;
export type UpdateEventDateFunc = (eventDate: EventDate) => void;
export type UpdateEventFunc = (eventData: CollectingEventAttributes) => void;
export type UpdatePersonsFunc = (personData: ActorAndRelation[]) => void;
export type UpdatePlaceFunc = (placeData: Place) => void;
export type UpdateTouchFunc = (fieldName: string) => (value: boolean) => void;
export type SetValidateStateFunc = (validationState: ValidationFields) => void;

export type IsChangedType = {
  date: { isChanged: boolean };
  metaData: { isChanged: boolean };
  persons: { isChanged: boolean };
  place: { isChanged: boolean };
  objects: { isChanged: boolean };
};
export const initialChangeState: IsChangedType = {
  date: { isChanged: false },
  metaData: { isChanged: false },
  persons: { isChanged: false },
  place: { isChanged: false },
  objects: { isChanged: false }
};

export const initialTouchedState: ValidationFields = {
  touchedName: false
};

export const canSave = (changed: IsChangedType, name?: string) =>
  name !== '' && name !== undefined && hasAnyChanges(changed);

export const hasAnyChanges = (changed: IsChangedType): boolean =>
  changed.date.isChanged ||
  changed.metaData.isChanged ||
  changed.persons.isChanged ||
  changed.place.isChanged ||
  changed.objects.isChanged;

export const createEventHeaderData = (appSession: AppSession): CollectingEventHeader => {
  return {
    eventTypeId: EventTypeId.CollectingEvent,
    museumId: appSession.museumId,
    collectionUuid: appSession.collectionId
  };
};

export const removeEmptyStringAttributes = (
  place: Place,
  attrKey: string = 'attributes'
): Place => {
  const attributes = place && place[attrKey] ? { [attrKey]: place[attrKey] } : null;
  if (attributes) {
    Object.keys(attributes[attrKey]).forEach(
      key => attributes[attrKey][key] === '' && delete attributes[attrKey][key]
    );
  }
  return { ...place, ...attributes };
};

export const createJson = (
  headerJson: CollectingEventHeader,
  dateData?: EventDate,
  metaData?: CollectingEventAttributes,
  personData?: ActorAndRelation[],
  placeData?: Place,
  isChangedData?: IsChangedType,
  eventUuidIn?: EventUuid,
  revisionOfIn?: EventUuid,
  relatedObjects?: MuseumObjectAndRelation[],
  isFromEffectHook?: boolean
): CollectingEvent => {
  const eventUuid = eventUuidIn ? { eventUuid: eventUuidIn } : null;

  const revisionOf = revisionOfIn ? { revisionOf: eventUuidIn } : null;
  const date =
    isFromEffectHook || (isChangedData && isChangedData.date.isChanged)
      ? {
          date: dateData
            ? {
                ...dateData,
                lengthOfFormat: dateData.lengthOfFormat
                  ? dateData.lengthOfFormat
                  : DateFormat.Date,
                eventDateFrom: dateData.eventDateFrom ? dateData.eventDateFrom : '',
                eventDateTo: dateData.eventDateTo ? dateData.eventDateTo : ''
              }
            : undefined
        }
      : null;
  const attributes =
    isFromEffectHook || (isChangedData && isChangedData.metaData.isChanged)
      ? { attributes: metaData }
      : null;
  const relatedActors =
    isFromEffectHook || (isChangedData && isChangedData.persons.isChanged)
      ? { relatedActors: personData }
      : null;
  const place =
    isFromEffectHook || (isChangedData && isChangedData.place.isChanged)
      ? {
          place: placeData
            ? removeEmptyStringAttributes(
                removeEmptyStringAttributes(placeData, 'attributes'),
                'coordinateAttributes'
              )
            : undefined
        }
      : null;
  const returnValue: CollectingEvent = {
    ...eventUuid,
    ...headerJson,
    ...date,
    ...attributes,
    ...relatedActors,
    ...place,
    ...revisionOf,
    relatedMuseumObjects: relatedObjects
  };
  return returnValue;
};
