import * as React from 'react';
import * as H from 'history';
import { InputOutputPerson, PersonAttribute, Synonym } from '../types/person/personName';
import { MuseumAndCollection } from '../types/common';
import PersonComponent from './PersonComponent';
import { Uuid } from 'src/types/common';

type Props = {
  id: string;
  fromModal: boolean;
  initPerson: InputOutputPerson;
  history: H.History<any>;
  savePerson: (data: InputOutputPerson) => Promise<string | undefined>;
  mergePersons?: (
    token: string,
    actorUuidA: Uuid,
    actorUuidB: Uuid
  ) => Promise<string | undefined>;
  closeModal?: () => void;
  saveEnabled: boolean;
  onCancel: () => void;
  hasChanges: boolean;
  personState: InputOutputPerson;
  updatePersonState: (p: InputOutputPerson) => void;
  updatePersonAttributes: (a: PersonAttribute) => void;
  onChangeTextField: (fieldName: string) => (value: string) => void;
  onChangeSynonyms: (
    displaySynonyms: Synonym[],
    addedOrChangedSynonyms: Synonym[],
    removedActorNames: Uuid[]
  ) => void;
  onChangeMuseumAndCollection: (museumAndCollection: MuseumAndCollection[]) => void;
};

export const createPersonForPost = (
  person: InputOutputPerson,
  synonymsChanged: boolean
): InputOutputPerson => {
  const onlyIfchangedSynonyms = person.synonyms ? person.synonyms : [];
  return { ...person, synonyms: synonymsChanged ? onlyIfchangedSynonyms : undefined };
};

export const PersonContainer = (p: Props) => {
  const changePersonStateWithName = (fieldName: string) => (
    value: string,
    nameValue: string
  ) => {
    p.updatePersonState({ ...p.personState, [fieldName]: value, name: nameValue });
  };

  return (
    <PersonComponent
      id={p.id}
      fromModal={p.fromModal}
      onCancel={p.onCancel}
      saveEnabled={p.saveEnabled}
      hasChanges={p.hasChanges}
      history={p.history}
      onChangeMuseumAndCollection={p.onChangeMuseumAndCollection}
      onChangePersonAttributes={p.updatePersonAttributes}
      onChangePersonStateWithName={changePersonStateWithName}
      personState={p.personState}
      savePerson={p.savePerson}
      mergePersons={p.mergePersons}
      onChangeText={p.onChangeTextField}
      onChangeSynonyms={p.onChangeSynonyms}
      closeModal={p.closeModal}
    />
  );
};

export default PersonContainer;
