// @flow
import * as React from 'react';
import { Field } from '../form';
import { Star, TODO } from '../../types/common';

type FieldInputProps = {
  field: Field<Star>;
  title: string;
  onChange: Function;
  inputProps?: {
    className?: string;
    style?: {};
  };
  readOnly?: boolean;
  labelWidth?: number;
  controlWidth?: number;
};

export default function FieldInput({
  field,
  title,
  onChange,
  inputProps,
  readOnly = false,
  labelWidth = 2,
  controlWidth = 12
}: FieldInputProps) {
  return (
    <div className={`form group col-md-${controlWidth}`}>
      {title !== '' && (
        <label className={`col-form-label`} htmlFor={field.name}>
          <b>{title}</b>
        </label>
      )}
      {readOnly ? (
        <p className="form-control-static">
          {field.rawValue && field.rawValue.toString()}
        </p>
      ) : (
        <>
          <input
            {...inputProps}
            className={`form-control ${inputProps ? inputProps.className || '' : ''} ${
              field.status ? (field.status.valid ? '' : 'is-invalid') : ''
            }`}
            id={field.name}
            value={(field.rawValue as TODO) || ''}
            onChange={e => {
              onChange({ name: field.name, rawValue: e.target.value });
            }}
          />
          <div className="invalid-feedback">{field.status?.error}</div>
        </>
      )}
    </div>
  );
}
