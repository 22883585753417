import * as React from 'react';
import { I18n } from 'react-i18nify';
import { withRouter } from 'react-router-dom';

import CollectingEventComponent from './CollectingEventComponent';
import { post } from '../../../shared/ajaxFetch/ajaxFetch';
import config from '../../../config';
import { AppSessionContext } from '../../app/AppComponent';
import { RoleTypeId } from '../types/event/eventTypes_auto';
import { uniqBy } from 'lodash';
import { MuseumObjectUuid } from '../types/museumObject/museumObject old';

import { CollectingEvent, ValidationFields } from '../types/frontend/collectingEventForm';
import {
  ActorAndRelation,
  MuseumObjectAndRelation,
  DateAttributes as EventDate
} from '../types/event/event';
import { OutPlace as Place } from '../types/place/place';
import { CollectingEventEventAttributes as CollectingEventAttributes } from '../types/event/eventTypes_auto';

import {
  UpdateEventDateFunc,
  UpdateEventFunc,
  UpdatePersonsFunc,
  IsChangedType,
  createJson,
  initialChangeState,
  UpdateIsChangedFunc,
  canSave,
  createEventHeaderData,
  initialTouchedState,
  SetValidateStateFunc
} from './CollectingEventContainer';
import { emitSuccess, emitError } from '../../../shared/errors';
import { MuseumObjectSearchResult } from '../types/museumObject/museumObject';

const AddNewCollectingEventContainer = withRouter(({ history }) => {
  const [dateData, changeDateState]: [EventDate, UpdateEventDateFunc] = React.useState<
    EventDate
  >({});

  const [metaData, changeMetaDataState]: [
    CollectingEventAttributes,
    UpdateEventFunc
  ] = React.useState<CollectingEventAttributes>({ name: '' });
  const [personData, changePersonDataState]: [
    ActorAndRelation[],
    UpdatePersonsFunc
  ] = React.useState<ActorAndRelation[]>([]);
  const [placeData, changePlaceDataState] = React.useState<Place>({});

  const [relatedMuseumObjects, changeRelatedMuseumObjects] = React.useState<
    MuseumObjectAndRelation[]
  >([]);
  const [relatedFullObjects, changeRelatedFullObjects] = React.useState<
    MuseumObjectSearchResult[]
  >([]);

  const [fromEffect, setFromEffect] = React.useState<boolean>(false);

  const appSession = React.useContext(AppSessionContext);
  const eventHeaderData = createEventHeaderData(appSession);
  const collectingEventCopy = localStorage.getItem('collectingEventCopy');

  React.useEffect(() => {
    setFromEffect(false);
    const collectingEvent = collectingEventCopy
      ? JSON.parse(collectingEventCopy)
      : undefined;
    if (collectingEvent) {
      processLocalStorage(collectingEvent);
      setFromEffect(true);
    }
    return () => localStorage.removeItem('collectingEventCopy');
  }, [collectingEventCopy]);

  const processLocalStorage = (c: CollectingEvent) => {
    changeDateState(c.date || {});
    changeMetaDataState(c.attributes ? { ...c.attributes, name: '' } : { name: '' });
    changePlaceDataState(c.place || {});
    changeRelatedMuseumObjects(c.relatedMuseumObjects || []);
    changePersonDataState(c.relatedActors || []);
  };

  const saveFunction = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const postJson: CollectingEvent = createJson(
      eventHeaderData,
      dateData,
      metaData,
      personData,
      placeData,
      isChangedData,
      undefined,
      undefined,
      relatedMuseumObjects,
      fromEffect
    );

    //do post and get response
    const postUrl = config.api.collectingEvent.addEventUrl;

    try {
      const response = await post(
        postUrl,
        JSON.stringify(postJson),
        appSession.accessToken
      );

      //everything is ok
      const saveEvent = {
        eventTypeId: 'saveSuccess',
        message: I18n.t('musit.texts.saveConfirmed')
      };

      const res = response.ok ? await response.json() : undefined;

      if (res && res.eventUuid) {
        emitSuccess(saveEvent);

        const url = config.magasin.urls.client.collectingEvent.editNew(
          appSession,
          res.eventUuid
        );
        history && history.replace(url);
      } else {
        emitError({
          eventTypeId: 'errorOnSave',
          message: I18n.t('musit.errorMainMessages.saveError')
        });
      }
    } catch (e) {
      emitError({
        eventTypeId: 'errorOnSave',
        message: I18n.t('musit.errorMainMessages.saveError')
      });
    }
  };

  const fetchObjectDataAndDisplay = async (
    relatedMuseumObjects: MuseumObjectAndRelation[]
  ) => {
    const url = config.api.objects.getPostObjectSearchResult;
    const d = {
      museumObjectUuids: relatedMuseumObjects.map(
        (r: MuseumObjectAndRelation) => r.museumObjectUuid
      )
    };
    const result = await post(url, JSON.stringify(d), appSession.accessToken);
    const json = await result.json();

    changeRelatedFullObjects(json || []);
  };

  const addRelatedObjects = async (objects: MuseumObjectUuid[]) => {
    const relatedObjectsToAdd: MuseumObjectAndRelation[] = objects.map(o => ({
      museumObjectUuid: o,
      roleId: RoleTypeId.museumObjectFor
    }));
    changeRelatedMuseumObjects(a => {
      const newRelatedObjects = uniqBy(
        [...a, ...relatedObjectsToAdd],
        'museumObjectUuid'
      );
      return newRelatedObjects;
    });
    await fetchObjectDataAndDisplay([...relatedMuseumObjects, ...relatedObjectsToAdd]);
    updateIsChangedState({ ...isChangedData, objects: { isChanged: true } });
  };

  const [isChangedData, updateIsChangedState]: [
    IsChangedType,
    UpdateIsChangedFunc
  ] = React.useState<IsChangedType>(initialChangeState);

  const changeDateObserved = (e: EventDate) => {
    updateIsChangedState({ ...isChangedData, date: { isChanged: true } });
    changeDateState(e);
  };
  const changeMetaDataObserved = (e: CollectingEventAttributes) => {
    updateIsChangedState({ ...isChangedData, metaData: { isChanged: true } });
    changeMetaDataState(e);
  };
  const changePersonDataObserved = (p: ActorAndRelation[]) => {
    updateIsChangedState({ ...isChangedData, persons: { isChanged: true } });
    changePersonDataState(p);
  };
  const changePlaceDataObserved = (p: Place) => {
    updateIsChangedState({ ...isChangedData, place: { isChanged: true } });
    const admPlaceUuid = p.admPlace ? { admPlaceUuid: p.admPlace.admPlaceUuid } : null;
    changePlaceDataState({ ...p, ...admPlaceUuid });
  };

  const [validationState, changeValidationState]: [
    ValidationFields,
    SetValidateStateFunc
  ] = React.useState<ValidationFields>(initialTouchedState);

  const changeValidationStateField = (fieldName: string) => (value: boolean) => {
    const a = { ...validationState, [fieldName]: value };
    changeValidationState(a);
  };

  return (
    <CollectingEventComponent
      formHaveChanged={canSave(isChangedData, metaData.name)}
      eventHeaderData={eventHeaderData}
      dateData={dateData}
      changeDateState={changeDateObserved}
      metaData={metaData}
      relatedObjects={relatedFullObjects}
      changeMetaDataState={changeMetaDataObserved}
      personData={personData}
      changePersonDataState={changePersonDataObserved}
      placeData={placeData}
      addRelatedMuseumObjects={addRelatedObjects}
      changePlaceDataState={changePlaceDataObserved}
      saveFunction={saveFunction}
      backFunction={history.goBack}
      validationFileds={validationState}
      changeValidationStateField={changeValidationStateField}
      changeRelatedMuseumObjects={changeRelatedMuseumObjects}
      relatedMuseumObjects={relatedMuseumObjects}
    />
  );
});

export default AddNewCollectingEventContainer;
