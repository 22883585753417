export type ESQueryField = {
  mainOperator?: string;
  operator: string;
  field: string;
  content: string | number | object[];
};
export type Sorting = {
  field: string;
  order: 'asc' | 'desc';
};
export type ESPayLoad = {
  esIndex: string;
  size: number;
  from: number;
  sorting?: Sorting;
  searchFields: ESQueryField[];
  shouldFields: ESQueryField[];
  eventFilters: ESQueryField[];
  aggregations: AggregationQuery[];
  resultDescription: {
    userId: string;
    columnDelimiter: string;
    resultFileLocation: string;
    columnHeaders: string[];
    resultTemplate: string[];
  };
};

export type SelectAbleField = {
  label: string;
  value: string;
  selected?: boolean;
  sortOrder?: number;
  indexPlace?: { eventIndex: number; fieldIndex: number };
};
export type SelectType = {
  show: boolean;
  eventName: string;
  fields: SelectAbleField[];
};

export type AttributeField = {
  prefix: string;
  fieldName: string;
  attributeFieldPrefix: string;
};
export type EventWithFields = {
  event: string;
  fields: AttributeField[];
};

export enum SearchKind {
  NoKind = 0,
  Single = 1,
  Sequence = 2,
  CommaSep = 3,
  LessThan = 4,
  GreaterThan = 5
}

export type NumSearch = {
  numString: string;
  searchKind: SearchKind;
  fieldName: string;
  contentPrefix?: string;
  fromTo?: {
    from?: number;
    to?: number;
  };
  separatedValues?: number[];
  singleNum?: number;
};

export type EventFilter = {
  dateFrom?: string;
  eventTypeId: number;
  dateTo?: string;
  numFields: NumSearch[];
};
export type QueryField = {
  mainOperator?: string;
  operator: string;
  field: string;
  content: string | number;
};

export type AggregationItem = {
  key: string;
  doc_count: number;
};

export type AggregationQuery = {
  name: string;
  type: string;
  field: string;
  order: string;
  size: number;
  prefix: string;
};

export type Result = {
  objectList: {
    grid: string[][];
    count: number;
  };
  aggregations?: {
    [field: string]: AggregationItem[];
  };
};

export type Opt = { label: string; value?: string };
export type OptGroup = {
  groupName: string;
  options: Opt[];
};

/* export const isOptGrp = (e: OptGroup | Opt) => {
  const r = (e as OptGroup).groupName;
  return r !== undefined ? true : false;
}; */

export type Field = {
  label: string;
  value: string;
  contentPrefix?: string;
};
export type FieldsForEventType = {
  [eventType: string]: {
    fields: Field[];
    eventTypeId: number;
  };
};

export type ResultFieldSelect = {
  [key: string]: string[][];
};

export type Attribute = {
  attribute: string;
  event: string;
  fieldName: string;
  phrasePrefix: string;
  value?: string;
};

export type SortableField = {
  attribute: string;
  sortIndex?: number;
  sortType: 'asc' | 'desc';
};

export type TField = {
  value: string;
  isAttribute: boolean;
  event?: string;
  fieldName: string;
  isOfType: string;
};
