// !!This is an auto generated file, please do not edit this file manually!
// To update this file, go to metadata.http and click on: GET {{host}}metadata/generateEventFiles

import { EventAttributes, Uuid } from './event';

export type MusitDate = string | Date;
// -- Event type constants
export enum EventTypeId {
  Revision = -2,
  Event = -1,
  EditName = 1,
  SynonymAssignment = 2,
  EditPersonAttribute = 3,
  MergePerson = 4,
  AddPerson = 5,
  CollectingEvent = 6,
  Coordinate_revision = 7,
  PlaceRevision = 8,
  DateRevision = 9,
  PersonRevision = 10,
  AttributeRevision = 11,
  MuseumNoAssignment = 12,
  MuseumObjectRevision = 13,
  MuseumObjectAttributeAssignment = 14,
  Taxon = 15,
  EventOrdering = 16,
  DeleteEventRevision = 17,
  SexAndStage = 18,
  TypeInfo = 19,
  RegistrationStatus = 20,
  DisseminationStatus = 21,
  Legacy = 22
}

// -- Role type constants
export enum RoleTypeId {
  wasEdited = 1,
  editedBy = 2,
  receivedSynonyms = 3,
  assignedSynonymsBy = 4,
  givenPersonAttribute = 5,
  givenPersonAttributeBy = 6,
  mergedFrom = 7,
  mergedTo = 8,
  mergedBy = 9,
  createdBy = 10,
  collectedBy = 11,
  bleFjernet = 12,
  fjernetAv = 13,
  fjernetSynonym = 14,
  stedFor = 15,
  attributeRevisionFor = 17,
  datoFor = 18,
  revidertSted = 20,
  actorRevisionFor = 21,
  placeRevisionFor = 22,
  dateRevisionFor = 23,
  museumObjectFor = 24,
  museumObjectRevisionFor = 25,
  givenMuseumObjectAttribute = 26,
  givenMuseumObjectAttributeOf = 27,
  classified = 28,
  classifiedBy = 29,
  ordered = 30,
  orderBy = 31,
  deleteEventRevisionFor = 32,
  givenMuseumNoOf = 33,
  designatedBy = 34,
  personMergedIn = 35,
  in = 36,
  givenBy = 37,
  witheld = 38,
  witheldBy = 39,
  migrated = 40,
  migratedBy = 41
}

// -- Data Types
export interface InputSynonym_EtAuto {
  actorNameUuid?: Uuid;
  firstName?: string;
  lastName?: string;
  name: string;
  title?: string;
}
export interface ScientificName_EtAuto {
  scientificNameId: number;
  scientificName: string;
  scientificNameAuthorship?: string;
}
export interface HigherClassification_EtAuto {
  taxonRank: string;
  scientificName: string;
  scientificNameAuthorship?: string;
}
export interface TaxonTypeSave_EtAuto {
  selectedName: ScientificName_EtAuto;
  taxonRank: string;
  acceptedNameUsage?: ScientificName_EtAuto;
  higherClassification?: HigherClassification_EtAuto[];
  precisionCode?: string;
  precisionRank?: string;
  sensu?: string;
}
export interface EventOrdering_EtAuto {
  eventUuid: Uuid;
  orderNo: number;
}
export interface SexAndStage_EtAuto {
  sexId?: number;
  stageId?: number;
  count?: number;
  estimated?: boolean;
}

// -- Attribute interfaces
export interface RevisionEventAttributes extends EventAttributes {
  note?: string;
}

export interface EventEventAttributes extends EventAttributes {
  note?: string;
}

export interface EditNameEventAttributes extends EventAttributes {
  actorNameUuid: Uuid;
  firstName?: string;
  lastName?: string;
  name: string;
  note?: string;
  title?: string;
}

export interface SynonymAssignmentEventAttributes extends EventAttributes {
  note?: string;
  synonyms?: InputSynonym_EtAuto[];
}

export interface EditPersonAttributeEventAttributes extends EventAttributes {
  note?: string;
}

export interface MergePersonEventAttributes extends EventAttributes {
  note?: string;
}

export interface AddPersonEventAttributes extends EventAttributes {
  note?: string;
}

export interface CollectingEventEventAttributes extends EventAttributes {
  method?: string;
  methodId?: number;
  name: string;
  namesOnLabel?: string;
  note?: string;
}

export interface CoordinateRevisionEventAttributes extends EventAttributes {
  note?: string;
}

export interface PlaceRevisionEventAttributes extends EventAttributes {
  note?: string;
}

export interface DateRevisionEventAttributes extends EventAttributes {
  eventDateFrom?: MusitDate;
  eventDateTo?: MusitDate;
  eventDateVerbatim?: string;
  lengthOfFormat?: number;
  note?: string;
}

export interface PersonRevisionEventAttributes extends EventAttributes {
  note?: string;
}

export interface AttributeRevisionEventAttributes extends EventAttributes {
  note?: string;
}

export interface MuseumNoAssignmentEventAttributes extends EventAttributes {
  museumNo: string;
  museumNoSeq: number;
  note?: string;
  prefix?: string;
  subNo?: string;
  subNo2?: string;
}

export interface MuseumObjectRevisionEventAttributes extends EventAttributes {
  note?: string;
}

export interface MuseumObjectAttributeAssignmentEventAttributes extends EventAttributes {
  note?: string;
}

export interface TaxonEventAttributes extends EventAttributes {
  note?: string;
  taxons: TaxonTypeSave_EtAuto[];
  taxonTypeId: number;
}

export interface EventOrderingEventAttributes extends EventAttributes {
  eventOrdering: EventOrdering_EtAuto[];
  note?: string;
}

export interface DeleteEventRevisionEventAttributes extends EventAttributes {
  note?: string;
}

export interface SexAndStageEventAttributes extends EventAttributes {
  note?: string;
  sexAndStage?: SexAndStage_EtAuto[];
}

export interface TypeInfoEventAttributes extends EventAttributes {
  basionym?: string;
  namesOnLabel?: string;
  note?: string;
  publication?: string;
  typeInfoType: number;
  year?: number;
}

export interface DisseminationStatusTableCC {
  comment?: string;
  disseminationStatusEn: string;
  disseminationStatusId: number;
  disseminationStatusNo: string;
  kommentar?: string;
}
export type DisseminationStatusTable = DisseminationStatusTableCC;

export interface RegistrationStatusTableCC {
  comment?: string;
  kommentar?: string;
  registrationStatusEn: string;
  registrationStatusId: number;
  registrationStatusNo: string;
}
export type RegistrationStatusTable = RegistrationStatusTableCC;

export interface RegistrationStatusEventAttributes extends EventAttributes {
  note?: string;
  registrationStatusId: number;
}

export interface DisseminationStatusEventAttributes extends EventAttributes {
  disseminationStatusId: number;
  note?: string;
}

export interface LegacyEventAttributes extends EventAttributes {
  legacyData: string;
  note?: string;
}
