import * as React from 'react';
import { InputOutputPerson, Person } from '../types/person/personName';
import { I18n } from 'react-i18nify';
import Config from '../../../config';
import { get } from '../../../shared/ajaxFetch/ajaxFetch';
import { Uuid } from 'src/types/common';

type Props = {
  personToSynonymizeWith: InputOutputPerson;
  token: string;
  mergePersons: (
    token: string,
    actorUuidA: Uuid,
    actorUuidB: Uuid
  ) => Promise<string | undefined>;
  personHasChanges: boolean;
};

const searchForPersons = async (personName: string, token: string) => {
  const url = Config.api.persons.searchPersonBySynonymOrName(personName);
  const result = await get(url, token);
  return result.json();
};

const SearchField = ({
  searchValue,
  onChange,
  onSearch,
  token
}: {
  searchValue: string;
  onChange: (v: React.ChangeEvent<HTMLInputElement>) => void;
  onSearch: (pn: string, token: string) => void;
  token: string;
}) => (
  <React.Fragment>
    <form
      className="form-horizontal"
      onSubmit={e => {
        e.preventDefault();
        onSearch(searchValue, token);
      }}
    >
      <div className="row">
        <label htmlFor="inputSearch" className="col-md-2 control-label form-group">
          {I18n.t('musit.objectModule.person.searchForPersons')}
        </label>
        <div className="col-md-4 form-group">
          <input
            className="form-control"
            type="text"
            id="inputSearch"
            value={searchValue}
            onChange={onChange}
          />
        </div>
        <div className="col-md-1 form-group">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => onSearch(searchValue, token)}
          >
            {I18n.t('musit.texts.search')}
          </button>
        </div>
      </div>
    </form>
  </React.Fragment>
);

const PersonsToSynonymize = ({
  currentActorUuid,
  synList,
  synonymize,
  personHasChanges
}: {
  currentActorUuid: string;
  synList: Person[];
  synonymize: (actorUuid: string, synUuid: string) => void;
  personHasChanges: boolean;
}) => {
  const onClickSynonymize = async (
    currentActorUuid: string,
    actorUuid: string,
    personName: string
  ) => {
    const value = window.confirm(
      I18n.t('musit.objectModule.person.mergePersons', {
        personName: personName
      })
    );
    if (value === true) {
      await synonymize(currentActorUuid, actorUuid);
    }
  };
  const onClickSynonymizeWithParams = (
    currentActorUuid: string,
    actorUuid: string,
    personName: string
  ) => () => {
    // tslint:disable-next-line:no-floating-promises
    onClickSynonymize(currentActorUuid, actorUuid, personName);
  };
  return (
    <div className="col-md-6">
      <table className="table">
        <thead>
          <tr>
            <th>{I18n.t('musit.objectModule.person.personName')}</th>
          </tr>
        </thead>
        <tbody>
          {synList
            .filter(e => e.actorUuid !== currentActorUuid)
            .map((p: Person, i: number) => (
              <tr key={`pn-key-${i}`}>
                <td>{p.name} </td>
                <td>
                  <button
                    className="btn btn-link btn-xs"
                    type="button"
                    disabled={personHasChanges}
                    onClick={onClickSynonymizeWithParams(
                      currentActorUuid,
                      p.actorUuid,
                      p.name
                    )}
                  >
                    {I18n.t('musit.objectModule.person.synonymize')}
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

const Synonymizer = (props: Props) => {
  const [search, updateSeach] = React.useState<string>('');
  const [searchResults, updateSearchResult] = React.useState<Person[] | undefined>(
    undefined
  );
  const onSearchForPersons = async (personName: string) => {
    const results = await searchForPersons(personName, props.token);
    updateSearchResult(results);
  };

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateSeach(e.target.value);
  };

  return (
    <React.Fragment>
      <SearchField
        onChange={onChangeSearch}
        searchValue={search}
        onSearch={onSearchForPersons}
        token={props.token}
      />
      <div className="row">
        <PersonsToSynonymize
          currentActorUuid={props.personToSynonymizeWith.actorUuid || ''}
          synList={searchResults || []}
          synonymize={(a: string, b: string) => {
            // tslint:disable-next-line:no-floating-promises
            props.mergePersons(props.token, a, b);
          }}
          personHasChanges={props.personHasChanges}
        />
      </div>
    </React.Fragment>
  );
};

export default Synonymizer;
