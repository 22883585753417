//import { postAnalysis } from './analysisAPI';
import { postUpdatedAnalysis } from './analysisAPI';
import Loader from 'react-loader';
import * as React from 'react';
import AnalysisEditor from './AnalysisEditor';
import { I18n } from 'react-i18nify';
import { queryAnalysisAPI, getAttachmentTitles } from './analysisAPI';
import { AppSessionContext } from '../../modules/app/AppComponent';

export default (props: any) => {
  // show loader if analysis object is not yet initialised from API
  const [analysis, setAnalysis] = React.useState<any>(undefined);
  const appSession = React.useContext(AppSessionContext);

  const [analysisResults, setAnalysisResults] = React.useState<any[]>([]);

  const [analysisResultAttachments, setAnalysisResultAttachments] = React.useState<any[]>(
    []
  );

  React.useEffect(() => {
    // tslint:disable-next-line: no-floating-promises
    queryAnalysisAPI(props.match.params).then(async analysis => {
      // TODO: is this needed? Can it be removed?
      analysis.objects = analysis.events.map((ev: any) =>
        ev.affectedThing ? ev.affectedThing : ev
      );

      const results = [analysis]
        .concat(analysis.events)
        .map((ev: any) => ({ id: ev.id, ...ev.result }));

      // tslint:disable-next-line: no-floating-promises
      Promise.all(
        results
          // older analyses might not have an attachments object
          .map(r => Object.assign({ attachments: [] }, r))
          .map(r => {
            r.attachments = r.attachments || [];
            return r.attachments.length > 0
              ? getAttachmentTitles(r.attachments, r.id, appSession)
              : [];
          })
      )
        .then(setAnalysisResultAttachments)
        .then(() => setAnalysisResults(results))
        .then(() => {
          if (analysis.caseNumbers) {
            analysis.caseNumber = analysis.caseNumbers[0];
            delete analysis.caseNumbers;
          }
          setAnalysis(analysis);
        });
    });
  }, [appSession, props.match.params]); // include array as second argument to only run on mount/unmount

  // only call AnalysisEditor once objects are inited
  return (
    <>
      {analysis ? (
        <>
          <div className="container" style={{ marginTop: 100 }}>
            <div className="row">
              <div className="col">
                <h1>{I18n.t('musit.analysis.updateAnalysis')}</h1>
              </div>
            </div>
          </div>
          <AnalysisEditor
            analysisResults={analysisResults}
            analysisResultAttachments={analysisResultAttachments}
            handleSubmit={postUpdatedAnalysis}
            handleCancelRestriction={() => {}}
            handleCancel={() => props.history.goBack()}
            submitButtonTitle={I18n.t('musit.texts.save')}
            cancelButtonTitle={I18n.t('musit.texts.cancel')}
            analysisTypeReadOnly={true}
            formValues={analysis}
            history={props.history}
          />
        </>
      ) : (
        <Loader loaded={false} />
      )}
    </>
  );
};
