import * as React from 'react';
import PersonContainer, { createPersonForPost } from './PersonContainer';
import { InputOutputPerson, PersonAttribute, Synonym } from '../types/person/personName';
import { MuseumAndCollection } from '../types/common';
import Config from '../../../config';
import { put, get } from '../../../shared/ajaxFetch/ajaxFetch';
import { AppSessionContext } from '../../app/AppComponent';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-i18nify';
import { emitSuccess, emitError } from '../../../shared/errors';
import { Uuid } from 'src/types/common';
import { post } from '../../../shared/ajaxFetch/ajaxFetch';
import { AppSession } from 'src/types/appSession';
import * as H from 'history';
import * as M from 'react-router';
const initPerson: InputOutputPerson = { name: '' };

const editPerson = (token: string, actorUuid: string, synonymsUpdated: boolean) => async (
  data: InputOutputPerson
) => {
  try {
    const editUrl = Config.api.persons.editUrl(actorUuid);
    const response = await put(
      editUrl,
      JSON.stringify(createPersonForPost(data, synonymsUpdated)),
      token
    );
    if (response.ok) {
      emitSuccess({
        eventTypeId: 'saveSuccess',
        message: I18n.t('musit.texts.saveConfirmed')
      });
      return actorUuid;
    } else {
      emitError({
        eventTypeId: 'errorOnSave',
        message: I18n.t('musit.errorMainMessages.saveError')
      });
    }
  } catch {
    emitError({
      eventTypeId: 'errorOnSave',
      message: I18n.t('musit.errorMainMessages.saveError')
    });
  }
  return;
};
// actorUuidA is merged with actorUuidB
// actorB is removed and added to actorA
export const mergePersons = (
  appSession: AppSession,
  updatePerson: (p: InputOutputPerson) => void
) => async (token: string, actorUuidA: Uuid, actorUuidB: Uuid) => {
  const mergeUrl = Config.api.persons.mergeUrl(actorUuidA)(actorUuidB);
  try {
    const response = await post(mergeUrl, JSON.stringify({}), token);

    if (response.ok) {
      emitSuccess({
        eventTypeId: 'saveSuccess',
        message: I18n.t('musit.texts.saveConfirmed')
      });
      const url = Config.api.persons.getUrl(actorUuidA);
      const response = await get(url, appSession.accessToken);
      const data = await response.json();
      updatePerson(data);
      return 'ok';
    } else {
      emitError({
        eventTypeId: 'errorOnSave',
        message: I18n.t('musit.errorMainMessages.saveError')
      });
      return undefined;
    }
  } catch (e) {
    emitError({
      eventTypeId: 'errorOnSave',
      message: I18n.t('musit.errorMainMessages.saveError')
    });
  }
  return undefined;
};

const Wrapper = ({
  actorUuid,
  id,
  fromModal = false,
  closeModal,
  history,
  match
}: {
  id: string;
  actorUuid?: string;
  fromModal?: boolean;
  closeModal?: any;
  match: M.match;
  history: H.History;
}) => {
  const appSession = React.useContext(AppSessionContext);
  const [personState, updatePerson] = React.useState<InputOutputPerson>(initPerson);
  const [synonymsUpdated, changeSynonymsUpdated] = React.useState<boolean>(false);
  const [hasChanges, updateHasChanges] = React.useState<boolean>(false);

  const [dataFromBackend, updateDataFromBackend] = React.useState<InputOutputPerson>(
    initPerson
  );

  const updatePersonObserved = (p: InputOutputPerson) => {
    updatePerson(p);
    updateHasChanges(true);
  };
  const updatePersonResetChanges = (p: InputOutputPerson) => {
    updatePerson(p);
    updateHasChanges(false);
  };
  const updatePersonAttributes = (a: PersonAttribute) => {
    updatePersonObserved((p: InputOutputPerson) => ({ ...p, personAttribute: a }));
  };
  const onChangeTextField = (fieldName: string) => (value: string) => {
    updatePersonObserved({ ...personState, [fieldName]: value });
  };
  const onChangeSynonyms = (
    displaySynonyms: Synonym[],
    addedOrChangedSynonyms: Synonym[],
    removedActorNames: Uuid[]
  ) => {
    updatePersonObserved({
      ...personState,
      synonyms: displaySynonyms,
      addedOrUpdatedActorNames: addedOrChangedSynonyms,
      removedActorNames: removedActorNames
    });
    changeSynonymsUpdated(true);
  };
  const onChangeMuseumAndCollection = (museumAndCollection: MuseumAndCollection[]) => {
    updatePersonObserved({
      ...personState,
      collections: museumAndCollection
    });
  };

  const onCancel = () => {
    updatePerson(dataFromBackend);
  };

  React.useEffect(() => {
    const url = Config.api.persons.getUrl(actorUuid || match.params['id']);
    const getData = async () => {
      const response = await get(url, appSession.accessToken);
      const data = await response.json();
      updatePerson(data);
      updateDataFromBackend(data);
    };
    // tslint:disable-next-line:no-floating-promises
    getData();
  }, [actorUuid, appSession.accessToken, match.params]);

  const saveEnabled = hasChanges && personState.name !== '';
  return (
    <PersonContainer
      id={id}
      onCancel={onCancel}
      fromModal={fromModal}
      savePerson={editPerson(appSession.accessToken, match.params['id'], synonymsUpdated)}
      mergePersons={mergePersons(appSession, updatePersonResetChanges)}
      saveEnabled={saveEnabled}
      hasChanges={hasChanges}
      initPerson={personState}
      updatePersonState={updatePersonObserved}
      history={history}
      updatePersonAttributes={updatePersonAttributes}
      onChangeTextField={onChangeTextField}
      onChangeSynonyms={onChangeSynonyms}
      onChangeMuseumAndCollection={onChangeMuseumAndCollection}
      personState={personState}
      closeModal={closeModal}
    />
  );
};

const EditPersonContainer = ({
  actorUuid,
  id,
  fromModal = false,
  closeModal
}: {
  id: string;
  actorUuid?: string;
  fromModal?: boolean;
  closeModal?: any;
}) =>
  withRouter(({ match, history }) => (
    <Wrapper
      actorUuid={actorUuid}
      id={id}
      fromModal={fromModal}
      closeModal={closeModal}
      match={match}
      history={history}
    />
  ));

export default EditPersonContainer;
