import Select, { Creatable } from 'react-select';
import 'react-select/dist/react-select.css';

import {
  PrecisionCode,
  TaxonTypeEdit,
  formatTaxonStrings,
  TaxonTypeSave,
  TaxonTypeMini,
  getPrecisionRanksFromTaxon,
  convertTaxonData
} from './taxonLib';
import * as React from 'react';
import config from '../../../config';
import { getExternal } from '../../../shared/ajaxFetch/ajaxFetch';
import { AppSession } from 'src/types/appSession';

import { getTranslator } from '../../../shared/language';
import FontAwesome from 'react-fontawesome';

const words = getTranslator({
  en: {
    acceptedName: 'Accepted name',
    precision: 'Precision',
    precisionRank: 'Precision rank',
    sensu: 'Sensu',
    taxonSearchArtsdatabanken: 'Taxon search in Artsdatabanken',
    taxonSearchArtsdatabankenPlaceholder: 'Search in Artsdatabanken',
    validName: 'Valid name or museum name'
  },
  no: {
    acceptedName: 'Akseptert navn',
    precision: 'Presisjon',
    precisionRank: 'Presisjonsrangering',
    validName: 'Gyldig navn eller museumsnavn',
    sensu: 'Sensu',
    taxonSearchArtsdatabanken: 'Søk takson i artsdatabanken',
    taxonSearchArtsdatabankenPlaceholder: 'Søk i artsdatabanken'
  }
});

type Option<T> = {
  value: T;
  label: string;
};

export const TaxonPrecisionSensu = ({
  selectedTaxon,
  updateSelectedTaxonEdit,
  taxonData,
  isSearching,
  updateIsSearching,
  appSession
}: {
  selectedTaxon: TaxonTypeEdit | undefined;
  updateSelectedTaxonEdit: React.Dispatch<React.SetStateAction<TaxonTypeEdit>>;
  taxonData: TaxonTypeSave[];
  isSearching: boolean;
  updateIsSearching: (searching: boolean) => void;
  appSession: AppSession;
}) => {
  const [inputState, changeInputState] = React.useState<string>('');
  const [taxonSuggestions, setTaxonSuggestions] = React.useState<TaxonTypeMini[]>([]);
  const [taxonDropDownOpen, setTaxonDropDownOpen] = React.useState(false);

  const taxonDropdown = React.useRef<HTMLUListElement>(null);

  // listen to all clicks on the page (use for closing dropdown)
  React.useEffect(() => {
    const handleClick = (e: any) => {
      const isTaxonDropDownClicked: boolean =
        (taxonDropdown &&
          taxonDropdown.current &&
          taxonDropdown.current.contains(e.target)) === true;
      setTaxonDropDownOpen(isTaxonDropDownClicked); // if clicked outside of the person dropdown, close dropdown
    };
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  // call this function when "add person" field is typed in
  const handleAddTaxonChange = async (taxonQuery: string) => {
    // query API
    changeInputState(taxonQuery);
    const taxonSuggestions = await getExternal(
      config.magasin.urls.api.taxon.getLatinNamesMatch(taxonQuery, '', '1')
    );
    let data = await taxonSuggestions.json();
    // get the resultset and then make the dropdown
    if (data.error) data = []; // if error response, just return emtpy array
    setTaxonSuggestions(convertTaxonData(data));
    setTaxonDropDownOpen(true);
  };

  // call this function when a person is clicked on
  const handleAddTaxonClick = (taxon: TaxonTypeMini) => {
    // get the resultset and then make the dropdown
    changeInputState('');
    setTaxonSuggestions([]);
    updateSelectedTaxonEdit({ ...selectedTaxon, ...taxon });
    updateIsSearching(false);
  };

  const getRanks = (taxon: TaxonTypeMini) => getPrecisionRanksFromTaxon(taxon);

  const getHigherClassificationNames = (taxon: TaxonTypeMini) =>
    taxon.higherClassification
      ? taxon.higherClassification.map(cl => ({
          scientificName: cl.scientificName,
          taxonRank: cl.taxonRank
        }))
      : [];
  const creatBreadCrumbList = (list: string[]) => {
    if (list == null || list.length === 0) {
      return '';
    }
    return list.splice(0, list.length - 1).join(' \\ ');
  };

  const filteredPrecisionSuggestions: (t?: TaxonTypeMini) => string[] = (
    ta: TaxonTypeMini
  ) => (ta ? getRanks(ta) : []);

  const selectedTaxonPath =
    isSearching === false && selectedTaxon && selectedTaxon
      ? creatBreadCrumbList(
          getHigherClassificationNames(selectedTaxon)
            .filter((el, i, a) => {
              const ind = a.findIndex(e => e.taxonRank === 'genus');
              return i < ind;
            })
            .map(e => e.scientificName)
        )
      : '';

  const formatTaxon = (taxon: {
    scientificNameId: number;
    scientificName: string;
    scientificNameAuthorship?: string;
  }) => {
    return (
      <React.Fragment key={taxon.scientificNameId}>
        {taxon.scientificName}{' '}
        {taxon.scientificNameAuthorship !== '' && taxon.scientificNameAuthorship != null
          ? ' ' + taxon.scientificNameAuthorship
          : ''}
      </React.Fragment>
    );
  };

  var sensuOptions = [
    { value: 's.lat.', label: 'Sensu lat.' },
    { value: 's.str.', label: 'Sensu str.' }
  ];
  if (
    isSearching === false &&
    selectedTaxon &&
    selectedTaxon.sensu &&
    sensuOptions.findIndex(opt => opt.value === selectedTaxon.sensu) === -1
  ) {
    sensuOptions.push({ value: selectedTaxon.sensu, label: selectedTaxon.sensu });
  }

  const searchIcon = <FontAwesome name={'search'} />;
  const placeholderText = $(<FontAwesome name={'search'} />);
  return (
    <React.Fragment>
      <div className="form-row">
        <div className="col-md-5 form-group">
          <label htmlFor="taxonSuggestADB">{words.taxonSearchArtsdatabanken}</label>
          <div className="input-group mb-3">
            <input
              className="form-control border-primary"
              id="taxonSuggestADB"
              placeholder={words.taxonSearchArtsdatabankenPlaceholder}
              value={inputState}
              onChange={e => handleAddTaxonChange(e.target.value)}
              //style={{ width: '100%' }}
              aria-describedby="basic-addon2"
              autoComplete="off"
            />
            <div className="input-group-append">
              <span className="input-group-text" id="basic-addon2">
                <FontAwesome name={'search'} />
              </span>
            </div>
          </div>
          <ul
            className="dropdown-menu mt-n3"
            ref={taxonDropdown}
            style={{
              display: taxonSuggestions.length && taxonDropDownOpen ? 'block' : 'none'
              //marginLeft: 16
            }}
          >
            {taxonSuggestions.map((taxon: TaxonTypeMini, i: number) => (
              <li
                onClick={e => handleAddTaxonClick(taxon)}
                key={'tax-sugg-' + i}
                title={'Scientific name: ' + taxon.selectedName.scientificName}
              >
                <a role="menuitem" href="#/">
                  {formatTaxonStrings(taxon).join(' ')}
                  {' [' + taxon.taxonRank + ']'}
                  {' ' + Date.now()}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {selectedTaxonPath !== '' && (
        <div className="form-row">
          <div className="col-md-12 form-group">{selectedTaxonPath}</div>
        </div>
      )}
      <div className="form-row">
        <div className="col-md-6">
          <label htmlFor="taxonSelection">{words.validName}</label>
          <div
            className="card"
            style={{ padding: '2px', height: '2em', backgroundColor: '#eee' }}
          >
            {isSearching === false && selectedTaxon && selectedTaxon.selectedName && (
              <a
                role="menuitem"
                href={
                  'https://www.artsdatabanken.no/ScientificName//' +
                  selectedTaxon.selectedName.scientificNameId
                }
                rel="nofollow noopener noreferrer"
                target="_blank"
              >
                {formatTaxon(selectedTaxon.selectedName)}{' '}
              </a>
            )}
          </div>
        </div>
        <div className="col-md-6 form-group">
          <label htmlFor="taxonValid">{words.acceptedName}</label>
          <div
            className="card"
            style={{ padding: '2px', height: '2em', backgroundColor: '#eee' }}
          >
            {taxonData &&
              taxonData.map(taxonItem =>
                taxonItem.acceptedNameUsage
                  ? formatTaxon(taxonItem.acceptedNameUsage)
                  : formatTaxon(taxonItem.selectedName)
              )}
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-2">
          <div className="form-group">
            <label htmlFor="presicionType">{words.precision}</label>

            <Select
              id="presicionType"
              className="border border-primary rounded"
              multi={false}
              clearable={true}
              disabled={selectedTaxon && selectedTaxon.selectedName ? false : true}
              onChange={(selectedPrecision: any) => {
                if (selectedTaxon && isSearching === false) {
                  const precisionOption = selectedPrecision as Option<PrecisionCode>;
                  updateSelectedTaxonEdit({
                    ...selectedTaxon,
                    precisionCode: precisionOption ? precisionOption.value : undefined,
                    precisionRank: precisionOption
                      ? selectedTaxon.precisionRank
                      : undefined
                  });
                }
              }}
              options={[
                { value: 'aff', label: 'aff' },
                { value: 'cf', label: 'cf' }
              ]}
              value={
                isSearching === false && selectedTaxon
                  ? selectedTaxon.precisionCode
                  : undefined
              }
            />
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <label htmlFor="presicionRank">{words.precisionRank}</label>
            <Select
              id="presicionRank"
              className="border border-primary rounded"
              clearable={true}
              disabled={
                selectedTaxon && selectedTaxon.selectedName && selectedTaxon.precisionCode
                  ? false
                  : true
              }
              placeholder="Velg nivå"
              onChange={(option: any) => {
                if (selectedTaxon && isSearching === false) {
                  const precisionRankOption = option as Option<string>;
                  updateSelectedTaxonEdit({
                    ...selectedTaxon,
                    precisionRank: precisionRankOption
                      ? precisionRankOption.value
                      : undefined
                  });
                }
              }}
              options={filteredPrecisionSuggestions(
                isSearching === false && selectedTaxon ? selectedTaxon : undefined
              ).map(p => ({
                value: p,
                label: p
              }))}
              value={
                isSearching === false && selectedTaxon
                  ? selectedTaxon.precisionRank
                  : undefined
              }
            />
          </div>
        </div>
        <div className="col-md-2">
          <label htmlFor="sensuTerm">{words.sensu}</label>

          <Creatable
            id="sensuTerm"
            className="border border-primary rounded"
            disabled={selectedTaxon && selectedTaxon.selectedName ? false : true}
            value={
              isSearching === false && selectedTaxon ? selectedTaxon.sensu : undefined
            }
            onChange={(selected: any) => {
              const sensuOption = selected as Option<string>;
              if (selectedTaxon && isSearching === false)
                updateSelectedTaxonEdit({
                  ...selectedTaxon,
                  sensu: sensuOption ? sensuOption.value : undefined
                });
            }}
            options={sensuOptions}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
