export const compareMusNo = (m1: string, m2: string) => {
  const match1 = m1.match(/\d+/g) || [];
  const match2 = m2.match(/\d+/g) || [];
  const match1Num = match1.map(e => Number.parseInt(e));
  const match2Num = match2.map(e => Number.parseInt(e));

  if (match1Num.length === match2Num.length) {
    let i;
    for (i = 0; i < match1Num.length; i++) {
      if (match1Num[i] < match2Num[i]) return -1;
      if (match1Num[i] > match2Num[i]) return 1;
    }
  }

  if (match1Num.length > match2Num.length) {
    let i;
    for (i = 0; i < match2Num.length; i++) {
      if (match1Num[i] < match2Num[i]) return -1;
      if (match1Num[i] > match2Num[i]) return 1;
    }
    return 1;
  }

  if (match1Num.length < match2Num.length) {
    let i;
    for (i = 0; i < match1Num.length; i++) {
      if (match1Num[i] < match2Num[i]) return -1;
      if (match1Num[i] > match2Num[i]) return 1;
    }
    return -1;
  }
  return 0;
};
