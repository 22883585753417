import * as React from 'react';
import { capitalize } from 'lodash';
import { getTranslator } from '../../../shared/language';

const words = getTranslator({
  en: {
    method: 'Method',
    sample: 'Sample',
    ship: 'Ship',
    station: 'Station',
    stationSampleShip: 'Station/sample/ship'
  },
  no: {
    method: 'Innsamlingsmetode',
    sample: 'Prøve',
    ship: 'Skip',
    station: 'Stasjon',
    stationSampleShip: 'Stasjon/prøve/skip'
  }
});

type Props = {
  methodId?: number;
  station?: string;
  ship?: string;
  sample?: string;
  disabled?: boolean;
  onChange: (fieldName: string) => (value: string) => void;
  onChangeMethod: (value: string) => void;
  collectingEventMethods: { methodId: number; method: string }[];
  language: { isNo: boolean; isEn: boolean };
};

const MarineAttributes = (props: Props) => {
  const sortedCollectingMethods = props.language.isNo
    ? props.collectingEventMethods.sort((a: any, b: any) =>
        a.method > b.method ? 1 : -1
      )
    : props.collectingEventMethods.sort((a: any, b: any) =>
        a.methodEn > b.methodEn ? 1 : -1
      );

  return (
    <div style={{ padding: '5px 0px' }}>
      <div className="form-row">
        <div className="col-md-2 offset-md-2">
          <b>{words.station}</b>
        </div>
        <div className="col-md-2">
          <b>{words.sample}</b>
        </div>
        <div className="col-md-2">
          <b>{words.ship}</b>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-2" style={{ textAlign: 'right' }}>
          <b>{words.stationSampleShip}</b>
        </div>
        <div className="col-md-2">
          <input
            disabled={props.disabled}
            className="form-control"
            value={props.station ? props.station : ''}
            onChange={e => props.onChange('station')(e.target.value)}
          />
        </div>
        <div className="col-md-2">
          <input
            disabled={props.disabled}
            className="form-control"
            value={props.sample ? props.sample : ''}
            onChange={e => props.onChange('sample')(e.target.value)}
          />
        </div>
        <div className="col-md-2">
          <input
            disabled={props.disabled}
            className="form-control"
            value={props.ship ? props.ship : ''}
            onChange={e => props.onChange('ship')(e.target.value)}
          />
        </div>
      </div>
      <div className="form-row" style={{ padding: '5px 0px' }}>
        {' '}
        <div className="col-md-2" style={{ textAlign: 'right' }}>
          <b>{words.method}</b>
        </div>
        <div className="col-md-4">
          <select
            className="form-control"
            disabled={props.disabled}
            id="collectingMethod"
            placeholder={words.method}
            value={props.methodId}
            onChange={e => {
              props.onChangeMethod(e.target.value);
            }}
          >
            <option value={undefined}>{'Select value'}</option>
            {props.collectingEventMethods ? (
              sortedCollectingMethods.map(
                (
                  {
                    methodId,
                    method,
                    methodEn
                  }: { methodId: number; method: string; methodEn: string },
                  i: number
                ) => {
                  const m = capitalize(props.language.isNo ? method : methodEn);
                  return (
                    <option key={`optionRow_${i}`} value={methodId}>
                      {m}
                    </option>
                  );
                }
              )
            ) : (
              <option key={`optionRow_${1}`}>{'No data'}</option>
            )}
          </select>
        </div>
      </div>
    </div>
  );
};

export default MarineAttributes;
