import * as React from 'react';
import { AdmPlace } from '../types/place/place';
import Autosuggest from 'react-autosuggest';
import { get } from '../../../shared/ajaxFetch/ajaxFetch';
import { AppSessionContext } from '../../../modules/app/AppComponent';
import config from '../../../config';

export type SuggestionObj = {
  value: string;
  suggestions: AdmPlace[];
};

type Props = { admPlace?: AdmPlace } & {
  updateAdmplace: (admPlace?: AdmPlace) => void;
  disabled?: boolean;
};

const AdmPlaceComponent = (props: Props) => {
  const [sugg, updateSugg] = React.useState<SuggestionObj>({
    value: props.admPlace ? props.admPlace.name : '',
    suggestions: []
  });

  const context = React.useContext(AppSessionContext);

  async function getAdmPlaces(search: string, token: string) {
    const url = config.api.places.searchAdmPlaceURL(search);
    const retur = await get(url, token);
    return retur;
  }

  const onChangeValue = (event: any, { newValue }: { newValue: string }) => {
    updateSugg(p => ({ ...p, value: newValue }));
    if (newValue === '') {
      props.updateAdmplace();
    }
  };
  const onChangeSuggestions = (admPlaces: AdmPlace[]) =>
    updateSugg(p => ({
      ...p,
      suggestions: admPlaces.sort((a, b) => (a.name > b.name ? 1 : -1))
    }));

  const getSuggestionValue = (suggestion: AdmPlace) => {
    return suggestion.name;
  };

  const getSuggestions = async (value: string) => {
    const v = value.trim().toLowerCase();
    if (v === '') {
      return [];
    }
    const r = await getAdmPlaces(v, context.accessToken);
    if (r.ok) {
      return await r.json();
    } else {
      return [];
    }
  };

  const onSuggestionSelected = (event: any, { suggestion }: { suggestion: AdmPlace }) => {
    props.updateAdmplace(suggestion);
  };

  const inputProps = {
    value: sugg.value || (props.admPlace ? props.admPlace.name : ''),
    onChange: onChangeValue,
    type: 'search',
    disabled: props.disabled
  };
  const onSuggestionsFetchRequested = async ({
    value,
    reason
  }: {
    value: string;
    reason: string;
  }) => {
    if (value.length > 1 && reason === 'input-changed') {
      const response = await getSuggestions(value);
      if (response) onChangeSuggestions(response);
    }
  };

  const onSuggestionsClearRequested = () => {
    onChangeSuggestions([]);
  };

  return (
    <Autosuggest
      inputProps={inputProps}
      renderSuggestion={(suggestion: AdmPlace) => {
        return (
          <span>
            {suggestion.name}
            <b>{` : ${suggestion.path}`}</b>
          </span>
        );
      }}
      getSuggestionValue={getSuggestionValue}
      suggestions={sugg.suggestions}
      onSuggestionSelected={onSuggestionSelected}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
    />
  );
};

export default AdmPlaceComponent;
