import { ESPayLoad } from './types';

/* export type ESQueryField = {
  mainOperator: string;
  operator: string;
  field: string;
  content: string | number | Object;
};
export type ESPayLoad = {
  size: number;
  from: number;
  searchFields: ESQueryField[];
  shouldFields: ESQueryField[];
  eventFilters: ESQueryField[];
  resultDescription: {
    columnHeaders: string[];
    resultTemplate: string[];
  };
}; */

let buildTemplate = {
  esIndex: 'musitob',
  size: 10,
  from: 0,
  searchFields: [],
  shouldFields: [],
  eventFilters: [],
  aggregations: [],
  resultDescription: {
    resultFileLocation: '/Users/erlandse/Documents/musitload/',
    userId: 'erlandse',
    columnDelimiter: '\t',
    columnHeaders: ['Museumsnnummer', 'Dato for Taxon', 'Videnskabeligt navn'],
    resultTemplate: [
      'object.museumNo',
      'event.createdDate§Taxon',
      'attributes$Taxon§ScientificName§'
    ]
  }
};

let fieldTemplate = {
  operator: '',
  field: '',
  content: ''
};

let spaceSplitter = new RegExp('([\\s]+)', 'g');

export function initializeObject(
  textField: string,
  init: ESPayLoad = buildTemplate
): ESPayLoad {
  textField = textField.replace(/\n/g, ' ');
  let template: ESPayLoad = cloneJSON(init);
  try {
    textField = pickUpShouldClause(template, textField);
    textField = pickUpPhrases(template, textField);
    pickUpWords(template, textField);
  } catch (e) {
    let obj: any = {};
    obj.error = e;
    return obj;
  }
  return template;
}

/*
export function initializeObject(textField: string) {
  textField = textField.replace(/\n/g, ' ');
  let template = cloneJSON(buildTemplate);

  try {
    textField = pickUpShouldClause(template, textField);
    textField = pickUpPhrases(template, textField, 'must');
    pickUpWords(template, textField, 'must');
  } catch (e) {
    let obj: any = new Object();
    obj.error = e;
    return obj;
  }
  return template;
}
*/

function pickUpShouldClause(template: any, textField: string) {
  let pos,
    pos2 = -1;
  while (true) {
    pos = textField.indexOf('[');
    pos2 = textField.indexOf(']');
    if (pos !== -1 && pos2 !== -1) {
      if (pos > pos2) throw new Error('Error with square parenthesis');
      let should = textField.substring(pos + 1, pos2);
      let t2 = cloneJSON(buildTemplate);
      should = pickUpPhrases(t2, should);
      pickUpWords(t2, should);
      let obj: any = {};
      //    gamle  obj.searchFields = t2.searchFields;
      //NYE
      obj.content = t2.searchFields;
      obj.operator = 'shouldObject';
      template.searchFields.push(obj);
      //SLUT NYE

      //gamle template.shouldFields.push(obj);
      if (pos > 0)
        textField = textField.substring(0, pos) + ' ' + textField.substring(pos2 + 1);
      else textField = textField.substring(pos2 + 1);
    } else if (pos !== -1 || pos2 !== -1) {
      throw new Error('Unbalanced square paranthesis');
    } else break;
  }
  return textField;
}

/*
 Pick up words and words with wildcards and insert them in the template as matches or wildcards.
 If a word starts with a wildcard an error is thrown
*/
function pickUpWords(template: any, textField: string) {
  var q = textField.replace(spaceSplitter, '####');
  let rawWordForms: any[] = q.split('####');
  let pos,
    pos2 = 0;
  for (let temp = 0; temp < rawWordForms.length; temp++) {
    let term: string = rawWordForms[temp];
    if (term.length === 0) continue;
    pos = term.indexOf('*');
    pos2 = term.indexOf('?');
    if (pos === -1 && pos2 === -1) {
      let f = cloneJSON(fieldTemplate);
      f.operator = 'match';
      f.field = 'container';
      f.content = term;
      template.searchFields.push(f);
    } else {
      if (pos === 0 || pos2 === 0)
        throw new Error('A word must not start with a wildcard');
      let f = cloneJSON(fieldTemplate);
      f.operator = 'wildcard';
      f.field = 'container';
      f.content = term;
      template.searchFields.push(f);
    }
  }
}

/*
  pick up phrases in string marked with ()  and insert them  as phrases in the template
  The rest of the string is returned
  if there are errors with paranthesis it throws an error instead
*/
function pickUpPhrases(template: any, textField: string) {
  let pos,
    pos2 = -1;
  while (true) {
    pos = textField.indexOf('(');
    pos2 = textField.indexOf(')');
    if (pos !== -1 && pos2 !== -1) {
      if (pos > pos2) throw new Error('Error with paranthesis');
      let f = cloneJSON(fieldTemplate);
      let phrase = textField.substring(pos + 1, pos2);
      f.operator = 'match_phrase';
      f.field = 'container';
      f.content = phrase;
      template.searchFields.push(f);
      if (pos > 0)
        textField = textField.substring(0, pos) + ' ' + textField.substring(pos2 + 1);
      else textField = textField.substring(pos2 + 1);
    } else if (pos !== -1 || pos2 !== -1) {
      throw new Error('Unbalanced paranthesis');
    } else break;
  }
  return textField;
}

/*
  clones a json object
*/
function cloneJSON(obj: any): any {
  // basic type deep copy
  if (obj === null || obj === undefined || typeof obj !== 'object') {
    return obj;
  }
  // array deep copy
  if (obj instanceof Array) {
    var cloneA = [];
    for (let i = 0; i < obj.length; ++i) {
      cloneA[i] = cloneJSON(obj[i]);
    }
    return cloneA;
  }
  // object deep copy
  var cloneO: any = {};
  for (let i in obj) {
    cloneO[i] = cloneJSON(obj[i]);
  }
  return cloneO;
}
