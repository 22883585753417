import * as React from 'react';
import { Component } from 'react';
import * as PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import { formatFloatToString } from '../../shared/util';
import { Observable } from 'rxjs';
import store$, { loadKDReport$, clear$ } from './reportStore';
import { RxInjectLegacy as inject } from '../../shared/react-rxjs-patch';
import { TODO } from '../../types/common';
import { AppSession } from '../../types/appSession';

interface KDReportProps {
  store: TODO;
  appSession: AppSession;
  loadKDReport: Function;
  clear: Function;
}

export class KDReport extends Component<KDReportProps> {
  UNSAFE_componentWillMount() {
    this.loadKDReport();
  }

  loadKDReport(
    museumId = this.props.appSession.museumId,
    token = this.props.appSession.accessToken
  ) {
    this.props.clear();
    this.props.loadKDReport({ museumId, token });
  }

  componentWillReceiveProps(nextProps: KDReportProps) {
    if (nextProps.appSession.museumId !== this.props.appSession.museumId) {
      this.loadKDReport(nextProps.appSession.museumId);
    }
  }

  render() {
    const data = this.props.store.data.kdreport;
    return (
      <div className="container" style={{ marginTop: 90 }}>
        <h1>{I18n.t('musit.reports.securingCollections.header')}</h1>
        <div className="card p-3 border-0">
          <div className="row">
            <div className="col">
              <b>{I18n.t('musit.reports.securingCollections.totalArea')}</b>
            </div>
            <div className="col">{data && formatFloatToString(data.totalArea)} m²</div>
          </div>
          <div className="row">
            <div className="col">
              <b>{I18n.t('musit.reports.securingCollections.perimeter')}</b>
            </div>
            <div className="col">
              {data && formatFloatToString(data.perimeterSecurity)} m²
            </div>
          </div>
          <div className="row">
            <div className="col">
              <b>{I18n.t('musit.reports.securingCollections.theftProtection')}</b>
            </div>
            <div className="col">
              {data && formatFloatToString(data.theftProtection)} m²
            </div>
          </div>
          <div className="row">
            <div className="col">
              <b>{I18n.t('musit.reports.securingCollections.fireProtection')}</b>
            </div>
            <div className="col">
              {data && formatFloatToString(data.fireProtection)} m²
            </div>
          </div>
          <div className="row">
            <div className="col">
              <b>{I18n.t('musit.reports.securingCollections.waterDamage')}</b>
            </div>
            <div className="col">
              {data && formatFloatToString(data.waterDamageAssessment)} m²
            </div>
          </div>
          <div className="row">
            <div className="col">
              <b>
                {I18n.t('musit.reports.securingCollections.routinesAndContingencyPlan')}
              </b>
            </div>
            <div className="col">
              {data && formatFloatToString(data.routinesAndContingencyPlan)} m²
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const data = {
  appSession$: { type: PropTypes.instanceOf(Observable).isRequired },
  store$
};

const commands = {
  loadKDReport$,
  clear$
};

export default inject(data, commands)(KDReport);
