import * as React from 'react';
import * as PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import Config from '../../config';
import { RxInjectLegacy as inject } from '../../shared/react-rxjs-patch';
import { TODO } from '../../types/common';

const reports = [
  {
    title: 'musit.reports.securingCollections.title',
    url: Config.magasin.urls.client.report.goToKdReport,
    description: 'musit.reports.securingCollections.description'
  },
  {
    title: 'musit.reports.objectStatistics.title',
    url: Config.magasin.urls.client.report.goToObjectStatisticsReport,
    description: 'musit.reports.objectStatistics.description'
  }
];

export const ReportsOverview = (props: TODO) => {
  return (
    <div className="container" style={{ marginTop: 90 }}>
      <div className="row">
        <div className="col">
          <h1>{I18n.t('musit.reports.reports')}</h1>
        </div>
      </div>
      <div
        className="row"
        style={{
          marginTop: 30,
          borderBottom: '2px solid #ddd'
        }}
      >
        <div className="col">
          <b>{I18n.t('musit.reports.titleHeader')}</b>
        </div>
        <div className="col">
          <b>{I18n.t('musit.reports.descriptionHeader')}</b>
        </div>
      </div>
      {reports.map((report, index) => {
        const url = report.url(props.appSession);
        return (
          <div className="row" key={index}>
            <div className="col">
              <a
                href={url}
                onClick={e => {
                  e.preventDefault();
                  props.history.push(url);
                }}
              >
                {I18n.t(report.title)}
              </a>
            </div>
            <div className="col">{I18n.t(report.description)}</div>
          </div>
        );
      })}
    </div>
  );
};

const data = {
  appSession$: { type: PropTypes.object.isRequired }
};

export default inject(data)(ReportsOverview);
