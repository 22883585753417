import * as React from 'react';
import { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import * as ObservationRender from '../observation/render';
import { formatFloatToString } from '../../shared/util';
import { reduce, keys, map } from 'lodash';
import { I18n } from 'react-i18nify';
import { TODO } from '../../types/common';

interface ControlViewProps {
  id: string;
  controlsJson?: object;
}
/* Old:
  id: PropTypes.string.isRequired,
  controlsJson: PropTypes.object
*/

export default class ControlView extends Component<ControlViewProps> {
  static iconMap = {
    alcohol: 'musitalcoholicon',
    cleaning: 'musitcleaningicon',
    gas: 'musitgasicon',
    hypoxicAir: 'musithypoxicairicon',
    lightingCondition: 'musitlightingcondicon',
    mold: 'musitmoldicon',
    pest: 'musitpesticon',
    relativeHumidity: 'musitrelhumidityicon',
    temperature: 'musittemperatureicon'
  };

  static typeMap = {
    alcohol: 'controlAlcohol',
    cleaning: 'controlCleaning',
    gas: 'controlGas',
    hypoxicAir: 'controlHypoxicAir',
    lightingCondition: 'controlLightingCondition',
    mold: 'controlMold',
    pest: 'controlPest',
    relativeHumidity: 'controlRelativeHumidity',
    temperature: 'controlTemperature'
  };

  constructor(props: ControlViewProps) {
    super(props);
    this.state = {
      alcohol: {
        open: false
      },
      cleaning: {
        open: false
      },
      gas: {
        open: false
      },
      hypoxicAir: {
        open: false
      },
      lightingCondition: {
        open: false
      },
      mold: {
        open: false
      },
      pest: {
        open: false
      },
      relativeHumidity: {
        open: false
      },
      temperature: {
        open: false
      }
    };

    this.downButton = this.downButton.bind(this);
    this.oneTableRow = this.oneTableRow.bind(this);
    this.getControls = this.getControls.bind(this);
    this.showObservation = this.showObservation.bind(this);
  }

  observation(fontName: string, observationType: string) {
    return (
      <div className="col-xs-5 col-sm-5 col-md-5">
        <span className={`icon icon-${fontName}`} style={{ fontSize: 'x-large' }} />
        {` ${observationType}`}
      </div>
    );
  }

  controlOk = (
    <div className="col-xs-5 col-sm-5 col-md-5">
      <FontAwesome name="check" style={{ fontSize: 'x-large' }} />
      {`  ${I18n.t('musit.texts.ok')}`}
    </div>
  );

  controlNotOk = (
    <div className="col-xs-5 col-sm-5 col-md-5">
      <FontAwesome name="close" style={{ fontSize: 'x-large' }} />
      {`  ${I18n.t('musit.texts.notOk')}`}
    </div>
  );

  downButton(observationType: string, ok: TODO) {
    return (
      <div className="col-xs-1 col-sm-1">
        <button
          id={`${this.props.id}_${observationType}_downButton`}
          className="btn btn-link"
          onClick={() =>
            this.setState(() => ({
              [observationType]: { open: !this.state[observationType].open }
            }))
          }
          type="button"
        >
          {ok ? null : <FontAwesome name="sort-desc" style={{ fontSize: 'x-large' }} />}
        </button>
      </div>
    );
  }

  oneTableRow(control: TODO, eventType: TODO, index: TODO) {
    return (
      <div key={index}>
        <div className="row border-top p-2" style={{ top: '0', bottom: '0' }}>
          {this.observation(
            ControlView.iconMap[eventType],
            I18n.t(`musit.viewControl.${ControlView.typeMap[eventType]}`)
          )}
          {control.ok ? this.controlOk : this.controlNotOk}
          {this.downButton(eventType, control.ok)}
        </div>
        <div
          className={`row card p-3 ${
            this.state[eventType].open ? 'collapse show' : 'collapse'
          }`}
        >
          {this.showObservation(control, eventType)}
        </div>
      </div>
    );
  }

  getControls(controls: TODO) {
    const withIndexAndKey = map(keys({ ...controls }), (type, index) => {
      return { index, item: controls[type], type };
    });
    return reduce(
      withIndexAndKey,
      (result: TODO, withIndex) => {
        if (ControlView.typeMap[withIndex.type]) {
          result.push(this.oneTableRow(withIndex.item, withIndex.type, withIndex.index));
        }
        return result;
      },
      []
    );
  }

  showObservation(control: TODO, controlType: string) {
    let lv;
    const { ok } = control;
    if (!ok) {
      const observation = control.observation;
      switch (controlType) {
        case 'temperature':
          lv = (
            <ObservationRender.RenderFromToNumberComment
              disabled
              type="temperature"
              valueProps={{
                fromValue: formatFloatToString(observation.range.from),
                toValue: formatFloatToString(observation.range.to),
                commentValue: observation.note
              }}
              layoutProps={{
                fromWidth: 3,
                toWidth: 3,
                commentWidth: 6
              }}
            />
          );

          break;
        case 'alcohol':
          lv = (
            <ObservationRender.RenderAlcohol
              disabled
              valueProps={{
                statusValue: observation.condition,
                volumeValue: formatFloatToString(observation.volume),
                commentValue: observation.note
              }}
              layoutProps={{
                statusWidth: 3,
                volumeWidth: 3,
                commentWidth: 6
              }}
            />
          );

          break;
        case 'cleaning':
          lv = (
            <ObservationRender.RenderDoubleTextArea
              disabled
              type="cleaning"
              valueProps={{
                leftValue: observation.cleaning,
                rightValue: observation.note
              }}
              layoutProps={{
                leftWidth: 6,
                rightWidth: 6
              }}
            />
          );

          break;
        case 'gas':
          lv = (
            <ObservationRender.RenderDoubleTextArea
              disabled
              type="gas"
              valueProps={{
                leftValue: observation.gas,
                rightValue: observation.note
              }}
              layoutProps={{
                leftWidth: 6,
                rightWidth: 6
              }}
            />
          );

          break;
        case 'hypoxicAir':
          lv = (
            <ObservationRender.RenderFromToNumberComment
              disabled
              type="hypoxicAir"
              valueProps={{
                fromValue: formatFloatToString(observation.range.from),
                toValue: formatFloatToString(observation.range.to),
                commentValue: observation.note
              }}
              layoutProps={{
                fromWidth: 3,
                toWidth: 3,
                commentWidth: 6
              }}
            />
          );

          break;
        case 'lightingCondition':
          lv = (
            <ObservationRender.RenderDoubleTextArea
              disabled
              type="lightCondition"
              valueProps={{
                leftValue: observation.lightingCondition,
                rightValue: observation.note
              }}
              layoutProps={{
                leftWidth: 6,
                rightWidth: 6
              }}
            />
          );

          break;
        case 'mold':
          lv = (
            <ObservationRender.RenderDoubleTextArea
              disabled
              type="mold"
              valueProps={{
                leftValue: observation.mold,
                rightValue: observation.note
              }}
              layoutProps={{
                leftWidth: 6,
                rightWidth: 6
              }}
            />
          );

          break;
        case 'pest':
          lv = (
            <ObservationRender.RenderPest
              disabled
              canEdit={false}
              onChangeField={() => true} //Dummy handler (because it made some sense to make this handler required.)
              valueProps={{
                observations: observation.lifecycles.map((lc: TODO) => {
                  return {
                    lifeCycle: lc.stage,
                    count: formatFloatToString(lc.quantity)
                  };
                }),
                identificationValue: observation.identification,
                commentValue: observation.note
              }}
              layoutProps={{
                lifeCycleWidth: 3,
                countWidth: 3,
                removeIconWidth: 1,
                addIconWidth: 1,
                commentsLeftWidth: 6,
                commentsRightWidth: 6
              }}
            />
          );

          break;
        case 'relativeHumidity':
          lv = (
            <ObservationRender.RenderFromToNumberComment
              disabled
              type="relativeHumidity"
              valueProps={{
                fromValue: formatFloatToString(observation.range.from),
                toValue: formatFloatToString(observation.range.to),
                commentValue: observation.note
              }}
              layoutProps={{
                fromWidth: 3,
                toWidth: 3,
                commentWidth: 6
              }}
            />
          );

          break;
        default:
          lv = '';
          break;
      }
    }
    return lv;
  }

  render() {
    return <div className="form-group">{this.getControls(this.props.controlsJson)}</div>;
  }
}
