import * as React from 'react';

const ObjectProperties = () => (
  <React.Fragment>
    <div className="container-fluid">
      <form className="form-horizontal" />
      <div className="form-group">
        <div className="row">
          {inputTextarea('referenceLitterature', 'Referanse litteratur', 2, 6)}
        </div>
      </div>
      <div className="form-group">
        <div className="row">
          {inputTextarea('otherLitterature', 'Annen litteratur', 2, 6)}
        </div>
      </div>
    </div>
  </React.Fragment>
);

const inputTextarea = (
  fieldId: string,
  description: string,
  widthLabel: number,
  widthInput: number
) => (
  <React.Fragment>
    <label className={'control-label col-md-' + widthLabel} htmlFor={fieldId}>
      {description}
    </label>
    <div className={'col-md-' + widthInput}>
      <textarea
        className="form-control"
        id={fieldId}
        placeholder={description}
        value={''}
      />
    </div>
  </React.Fragment>
);

export default ObjectProperties;
