// @flow
import * as React from 'react';
import { I18n } from 'react-i18nify';
import { Maybe } from '../../../types/common';

type Props = {
  sampleType: Maybe<string>;
  subTypeValue: Maybe<string>;
};

export default function ReadOnlySampleType({ sampleType, subTypeValue }: Props) {
  return (
    <div className="form-group row align-items-start py-4">
      <label className="col-form-label col-md-2 align-self-end">
        <b> {I18n.t('musit.sample.sample')}</b>
      </label>
      <div className="col-md-5">
        <label className="col-form-label">
          <b> {I18n.t('musit.sample.sampleType')}</b>
        </label>{' '}
        <br />
        <p className="readonly form-control-plaintext">{sampleType}</p>
      </div>
      {sampleType !== subTypeValue && (
        <div className="col-md-5">
          <label className="col-form-label">
            <b>{I18n.t('musit.sample.sampleSubType')}</b>
          </label>
          <br />
          <p className="readonly form-control-plaintext">{subTypeValue}</p>
        </div>
      )}
    </div>
  );
}
