import * as React from 'react';

export const CheckBox = (props: {
  id: string;
  checked: boolean;
  displayValue: string;
  onChange: Function;
  viewMode?: boolean;
}) => (
  <div className="form-check">
    <input
      className="form-check-input"
      type="checkbox"
      id={props.id}
      checked={props.checked}
      disabled={props.viewMode}
      onChange={e => props.onChange(e)}
    />{' '}
    <label className="form-check-label" htmlFor={props.id}>
      {props.displayValue}
    </label>
  </div>
);
