import * as React from 'react';
import { I18n } from 'react-i18nify';

type Props = {
  legalEntityType?: string;
  onChange: (value: string) => void;
};

export const PersonType = (p: Props) => (
  <div className="form-group">
    <label htmlFor="legalEntityType">
      {I18n.t('musit.objectModule.person.legalEntityType')}
    </label>
    <select
      className="form-control"
      id="legalEntityTypeDropDown"
      value={p.legalEntityType}
      onChange={v => {
        p.onChange(v.target.value);
      }}
    >
      <option value="Person">{I18n.t('musit.objectModule.person.person')}</option>
      <option value="Group">{I18n.t('musit.objectModule.person.group')}</option>
      <option value="Organisation">
        {I18n.t('musit.objectModule.person.organisation')}
      </option>
      <option value="Institution">
        {I18n.t('musit.objectModule.person.institution')}
      </option>
      <option value="Business">{I18n.t('musit.objectModule.person.business')}</option>
    </select>
  </div>
);

export default PersonType;
