/*
 *
 * HANDLES THE "PERSON" PART OF "COLLECTING EVENT"
 *
 * */
import ModalComponent from '../person/PersonModalComponent';
import * as React from 'react';
import config from '../../../config';
import {
  UpdatePersonsFunc,
  UpdateEventFunc
} from '../collectingEvent/CollectingEventContainer';
import { ActorAndRelation } from '../types/event/event';
import { PersonNameSearchResult as PersonSearchHit } from '../types/person/personName';
import { CollectingEventEventAttributes } from '../types/event/eventTypes_auto';
import { AppSessionContext } from '../../../modules/app/AppComponent';
import { get } from '../../../shared/ajaxFetch/ajaxFetch';
import { fixInitials } from './FullName';
import '../css/Compact.css';
import { getTranslator } from '../../../shared/language';
import './PersonModalComponent.css';

const words = getTranslator({
  en: {
    cantFindMsg: "Can't find the person you are looking for",
    namesUsedOnLabels: 'Names used on label',
    people: 'People',
    personNames: 'Person names',
    registerPersonMsg: 'Register new person',
    cancel: 'Cancel',
    return: 'Close'
  },
  no: {
    cantFindMsg: 'Finner du ikke den personen du leter etter',
    namesUsedOnLabels: 'Navn brukt på etikett',
    people: 'Personer',
    personNames: 'Personnavn',
    registerPersonMsg: 'Opprett ny person her',
    cancel: 'Avbryt',
    return: 'Lukk'
  }
});

export const SimplePersonsComponent = ({
  id,
  personData, // Hook for people that have been selected,
  eventMetadata,
  changePersonDataState, // Hook for CHANGING people that have been selected
  changeEventMetadataState,
  displayNameOnLabel = true,
  personLabel = words.people,
  personRole = 11,
  disabled = false,
  extraClass
}: {
  id: string;
  personData: ActorAndRelation[];
  eventMetadata?: CollectingEventEventAttributes;
  changePersonDataState: UpdatePersonsFunc;
  changeEventMetadataState: UpdateEventFunc | undefined;
  displayNameOnLabel?: boolean;
  personLabel?: string;
  personRole?: number;
  disabled?: boolean;
  extraClass?: string;
}) => {
  const context = React.useContext(AppSessionContext);
  const [personSuggestions, setPersonSuggestions] = React.useState([]); // list of people fetched from API
  const [dropDownOpen, setDropDownOpen] = React.useState(false); // flag for whether the dropdown should be visible
  const [inputState, changeInputState] = React.useState<string>('');
  const personDropdown = React.useRef(null); // <- reference to the person dropdown
  const [showOrHideModal, setShowOrHideModal] = React.useState(false);
  // listen to all clicks on the page (use for closing dropdown)
  React.useEffect(() => {
    const handleClick = (e: any) => {
      // @ts-ignore
      if (personDropdown.current.contains(e.target)) return; // ignore clicks in the person dropdown
      setDropDownOpen(false); // if clicked outside of the person dropdown, close dropdown
    };
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);
  // call this function when "add person" field is typed in
  const handleAddPersonChange = async (personQuery: string) => {
    // query API

    changeInputState(personQuery);
    const personSuggestions = await get(
      config.api.persons.searchUrl(personQuery),
      context.accessToken
    );
    let data;
    if (personSuggestions.ok) data = await personSuggestions.json();
    // get the resultset and then make the dropdown
    else data = []; // if error response, just return emtpy array
    setPersonSuggestions(
      data
        .sort((a: PersonSearchHit, b: PersonSearchHit) => {
          const aName = a.matchedName ? a.matchedName : a.currentName;
          const bName = b.matchedName ? b.matchedName : b.currentName;
          return aName > bName ? 1 : -1;
        })
        .map((p: PersonSearchHit) => ({
          ...p,
          matchedName: fixInitials(p.matchedName),
          currentName: fixInitials(p.currentName)
        }))
    );
    setDropDownOpen(true);
  };
  // call this function when a person is clicked on
  const handleAddPersonClick = async (person: PersonSearchHit) => {
    // get the resultset and then make the dropdown
    const personToAdd: ActorAndRelation = {
      actorUuid: person.actorUuid,
      roleId: personRole,
      actorName: person.matchedName ? person.matchedName : person.currentName,
      orderNo: new Date().getTime() % 2000000000 // <- make an arbitrary sorting id that is guaranteed to be greater than all that have come before
      // (modulus 2000000000 means that number will fit into postgres INTEGER datatype: http://www.postgresqltutorial.com/postgresql-integer/)
    };
    changePersonDataState([...personData, personToAdd]);
    changeInputState('');

    setPersonSuggestions([]);
  };

  const deleteSelectedPerson = (uuid: any) => {
    changePersonDataState(personData.filter((p: any) => p.actorUuid !== uuid));
  };
  const selectedPeopleTable = (
    <div className="row" style={{ paddingTop: 10, paddingBottom: 10 }}>
      <div className="col-md-2" style={{ textAlign: 'right' }}>
        <b>{words.personNames}</b>
      </div>
      <div className="col-md-6">
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          {personData.map((p: ActorAndRelation, i: number) => (
            <li key={p.actorUuid || 'id-' + i}>
              {p.actorName}
              <sub>{'Valid actorname: ' + p.currentActorName}</sub>{' '}
              <a
                href="/#"
                onClick={e => {
                  e.preventDefault();
                  deleteSelectedPerson(p.actorUuid);
                }}
              >
                (delete)
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );

  // person suggestions from API minus already selected people
  const filteredPersonSuggestions = personSuggestions.filter(
    (p: any) => personData.map((sp: any) => sp.actorUuid).indexOf(p.actorUuid) < 0
  );

  const addPersonInterface = (
    <div className="row" style={{ paddingTop: 10, paddingBottom: 10 }}>
      {/*       <div className="col-md-2" style={{ textAlign: 'right' }}>
        <b>{personLabel}</b>
      </div> */}
      <div className="col-md-6">
        <label htmlFor="personInterfaceInput">{personLabel}</label>
        <input
          id="personInterfaceInput"
          className={'form-control ' + extraClass}
          disabled={disabled}
          value={inputState}
          onChange={e => handleAddPersonChange(e.target.value)}
          style={{ width: '100%' }}
        />
        <ul
          className="dropdown-menu"
          ref={personDropdown}
          style={{
            display: filteredPersonSuggestions.length && dropDownOpen ? 'block' : 'none',
            marginLeft: 16
          }}
        >
          {filteredPersonSuggestions.map((p: PersonSearchHit, i: number) => (
            <li
              title={'UUID: ' + p.actorUuid}
              key={p.actorUuid + i}
              onClick={e => {
                if (!disabled) {
                  // tslint:disable-next-line:no-floating-promises
                  handleAddPersonClick(p);
                }
              }}
            >
              <a role="menuitem" href="#/">
                {p.matchedName + ' =[ ' + p.currentName + ' ]'}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
  const addNamesUsedOnLabel = (
    <div className="row">
      <div className="col-md-2" style={{ textAlign: 'right' }}>
        <b>{words.namesUsedOnLabels}</b>
      </div>
      <div className="col-md-6">
        <textarea
          className="form-control"
          rows={5}
          disabled={disabled}
          value={eventMetadata?.namesOnLabel || ''}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            changeEventMetadataState &&
            changeEventMetadataState(
              eventMetadata
                ? { ...eventMetadata, namesOnLabel: e.target.value }
                : { name: '', namesOnLabel: e.target.value }
            )
          }
        />
      </div>
    </div>
  );

  const openModal = () => {
    setShowOrHideModal(true);
  };

  const closeModal = () => {
    setShowOrHideModal(false);
  };

  return (
    <div>
      {personData && personData.length ? selectedPeopleTable : <div />}
      {addPersonInterface}
      <div className="openModal-div" onClick={openModal}>
        {words.registerPersonMsg}{' '}
        <i className="fa fa-external-link" aria-hidden="true"></i>
      </div>
      {showOrHideModal ? (
        <ModalComponent
          closeModal={closeModal}
          id={id}
          goBackText={words.return}
          helpText={words.registerPersonMsg}
        />
      ) : (
        ''
      )}
      {displayNameOnLabel ? addNamesUsedOnLabel : null}
    </div>
  );
};

export const ViewPersonsComponent = ({
  personList,
  eventMetadata,
  label
}: {
  personList?: ActorAndRelation[];
  eventMetadata?: CollectingEventEventAttributes;
  label?: string;
}) => (
  <div className="container-fluid">
    <form>
      <div className="form-group row">
        <div className="col-md-8">
          <label className="narrow">{label || 'Persons'}</label>
          {personList && renderPersons(personList)}
        </div>
        <div className="col-md-4">
          <label className="control-label narrow" htmlFor="namesOnLabel">
            {words.namesUsedOnLabels}
          </label>
          <p id="namesOnLabel" className="form-control-static">
            {eventMetadata ? eventMetadata.namesOnLabel : ''}
          </p>
        </div>
      </div>
    </form>
  </div>
);

const renderPersons = (personList: ActorAndRelation[]) =>
  personList.map((p: ActorAndRelation, i: number) => (
    <li key={p.actorUuid || 'id-' + i}>
      <b>{p.actorName}</b> <i>{' UUID: ' + p.actorUuid}</i>{' '}
    </li>
  ));

export default SimplePersonsComponent;
