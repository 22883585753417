// @flow
import * as React from 'react';
import { ObjectData } from '../../types/object';
import { Samples, SampleStatus, SampleData } from '../../types/samples';
import { Events } from '../../types/events';
import { AnalysisTypesObject } from '../../types/analysis';
import { SampleTypesObject } from '../../types/sample';
import EventTable from '../events/components/EventTable';
import { PredefinedConservation } from '../../types/predefinedConservation';
import SampleTable from '../sample/components/SampleTable';
import ViewObjectData from './components/ViewObjectData';
import Config from '../../config';
import { AppSession } from '../../types/appSession';
//import { History } from 'types/Routes';
import { History } from 'history';
import { I18n } from 'react-i18nify';
import { TODO } from '../../types/common';
import { PicklistData } from '../../types/picklist';

type ViewObjectComponentProps = {
  objectStore: { objectData: ObjectData; events: Events; samples: Samples };
  appSession: AppSession;
  analysisTypes: AnalysisTypesObject;
  sampleTypes: SampleTypesObject;
  predefinedConservation: PredefinedConservation;
  pickObject: Function;
  isItemAdded: Function;
  pickList: PicklistData;
  sampleStatus: SampleStatus;
  history: History;
  loading: boolean;
};

export const ViewObjectComponent = ({
  objectStore: { objectData, events, samples },
  appSession,
  analysisTypes,
  sampleTypes,
  predefinedConservation,
  pickObject,
  isItemAdded,
  pickList,
  sampleStatus,
  history,
  loading
}: ViewObjectComponentProps) =>
  !loading ? (
    <div className="container" style={{ paddingTop: 100 }}>
      <h1>{I18n.t('musit.objects.objectsView.objectView')}</h1>
      <ViewObjectData objectData={objectData} />

      {appSession.rolesForModules.collectionManagementWrite && (
        <div className="row">
          <div className="col-md-2 pt-2">
            <label>{I18n.t('musit.objects.objectsView.createNewEvent')}</label>
          </div>
          <div className="col-md-2">
            <div className="dropdown">
              <button
                className="btn btn-secondary dropdown-toggle"
                aria-haspopup="true"
                aria-expanded="false"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
              >
                {' '}
                {I18n.t('musit.objects.objectsView.selectEvent')}
              </button>
              <div className="dropdown-menu">
                <div
                  className="dropdown-item"
                  onClick={() => {
                    console.log('OD', objectData);
                    history.push(
                      Config.magasin.urls.client.analysis.addAnalysisWithObjectids(
                        appSession,
                        objectData.uuid ? 'objectId=' + objectData.uuid : ''
                      )
                    );
                  }}
                >
                  {I18n.t('musit.objects.objectsView.newAnalysis')}
                </div>
                {
                  // hide for Archaeology & Ethnography collections
                  //appSession &&
                  //appSession.collectionId !== '2e4f2455-1b3b-4a04-80a1-ba92715ff613' &&
                  //appSession.collectionId !== '88b35138-24b5-4e62-bae4-de80fae7df82' &&
                  <div
                    className="dropdown-item"
                    onClick={() =>
                      history.push({
                        pathname: Config.magasin.urls.client.analysis.addSample(
                          appSession,
                          objectData.uuid
                        )
                      })
                    }
                  >
                    {I18n.t('musit.objects.objectsView.newSample')}
                  </div>
                }
                <div
                  className="dropdown-item"
                  onClick={() =>
                    history.push({
                      pathname: Config.magasin.urls.client.conservation.addConservation(
                        appSession
                      ),
                      state: [objectData]
                    })
                  }
                >
                  {I18n.t('musit.conservation.newConservation')}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <hr />
      <ul className="nav nav-tabs" id="eventsAndObjects" role="tablist">
        <li className="nav-item">
          <a
            className="nav-link active"
            id="events-tab"
            data-toggle="tab"
            href="#events-id"
            role="tab"
            aria-controls="events-id"
            aria-selected="true"
          >
            {I18n.t('musit.objects.objectsView.events.events')}
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="samples-tab"
            data-toggle="tab"
            href="#samples-id"
            role="tab"
            aria-controls="samples-id"
            aria-selected="false"
          >
            {I18n.t('musit.objects.objectsView.samples.samples')}
          </a>
        </li>
      </ul>
      <div className="tab-content" id="tab-content-objectView">
        <div
          className="tab-pane fade show active"
          id="events-id"
          role="tabpanel"
          aria-labelledby="events-tab"
        >
          <EventTable
            events={events}
            analysisTypes={analysisTypes}
            sampleTypes={sampleTypes}
            conservationTypes={
              predefinedConservation && predefinedConservation.conservationTypes
                ? predefinedConservation.conservationTypes
                : []
            }
            appSession={appSession}
            onClick={(event: TODO) => {
              if (event.type === 'Analysis' || event.type === 'AnalysisCollection') {
                history.push({
                  pathname: Config.magasin.urls.client.analysis.viewAnalysis(
                    appSession,
                    event.id
                  )
                });
              } else if (event.type === 'Conservation') {
                history.push({
                  pathname: Config.magasin.urls.client.conservation.viewConservation(
                    appSession,
                    event.eventId
                  )
                });
              }
            }}
          />
        </div>
        <div
          className="tab-pane fade"
          id="samples-id"
          role="tabpanel"
          aria-labelledby="samples-tab"
        >
          {
            <SampleTable
              samples={samples}
              onClick={(sample: SampleData) => {
                history.push({
                  pathname: Config.magasin.urls.client.analysis.gotoSample(
                    appSession,
                    sample.objectId
                  )
                });
              }}
              pickObject={pickObject}
              isItemAdded={isItemAdded}
              pickList={pickList}
              appSession={appSession}
              objectData={objectData}
              sampleTypes={sampleTypes}
              sampleStatus={sampleStatus}
            />
          }
        </div>
      </div>
    </div>
  ) : (
    <div className="loading" />
  );

export default ViewObjectComponent;
