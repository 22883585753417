const Methods = [
  {
    methodId: 1,
    method: 'Activity trap'
  },
  {
    methodId: 2,
    method: 'Barber trap'
  },
  {
    methodId: 3,
    method: 'Brundin trap'
  },
  {
    methodId: 4,
    method: 'Benthos sampler'
  },
  {
    methodId: 5,
    method: 'Core sampler'
  },
  {
    methodId: 6,
    method: 'Dredge'
  },
  {
    methodId: 7,
    method: 'Drift net'
  },
  {
    methodId: 8,
    method: 'Emergence trap'
  },
  {
    methodId: 9,
    method: 'Pitfall trap'
  },
  {
    methodId: 10,
    method: 'Coloured pan trap'
  },
  {
    methodId: 11,
    method: 'Flight interception trap'
  },
  {
    methodId: 12,
    method: 'Photo'
  },
  {
    methodId: 13,
    method: 'Grab'
  },
  {
    methodId: 14,
    method: 'High gradient extractor'
  },
  {
    methodId: 15,
    method: 'Sorted by hand'
  },
  {
    methodId: 16,
    method: 'Light trap'
  },
  {
    methodId: 17,
    method: 'Malaise trap'
  },
  {
    methodId: 18,
    method: 'Mosquito Magnet'
  },
  {
    methodId: 19,
    method: 'Plankton tow net'
  },
  {
    methodId: 20,
    method: 'Netting'
  },
  {
    methodId: 21,
    method: 'Observation'
  },
  {
    methodId: 22,
    method: 'Hand picked sample'
  },
  {
    methodId: 23,
    method: 'Pollen trap'
  },
  {
    methodId: 24,
    method: 'Quick trap'
  },
  {
    methodId: 25,
    method: 'Frame'
  },
  {
    methodId: 26,
    method: 'Kick sample'
  },
  {
    methodId: 27,
    method: 'Tube sampler'
  },
  {
    methodId: 28,
    method: 'Schindler trap'
  },
  {
    methodId: 29,
    method: 'Scrape'
  },
  {
    methodId: 30,
    method: 'Beating net'
  },
  {
    methodId: 31,
    method: 'Stangsil'
  },
  {
    methodId: 32,
    method: 'Suger trap'
  },
  {
    methodId: 33,
    method: 'Surber sampler'
  },
  {
    methodId: 34,
    method: 'Sweep net'
  },
  {
    methodId: 35,
    method: 'Triangular bottom scrape'
  },
  {
    methodId: 36,
    method: 'Vacuum trap'
  },
  {
    methodId: 37,
    method: 'Window trap'
  },
  {
    methodId: 38,
    method: 'Funnel trap'
  },
  {
    methodId: 39,
    method: 'Water trap'
  },
  {
    methodId: 40,
    method: 'Z-sveip'
  },
  {
    methodId: 41,
    method: 'Pan trap'
  },
  {
    methodId: 42,
    method: 'Car net'
  },
  {
    methodId: 43,
    method: 'NZI Biting Fly Trap'
  },
  {
    methodId: 44,
    method: 'Bait trap'
  },
  {
    methodId: 45,
    method: 'Hatched'
  },
  {
    methodId: 46,
    method: 'Sifter'
  },
  {
    methodId: 47,
    method: 'Sieve'
  },
  {
    methodId: 48,
    method: 'Triangular dredge'
  },
  {
    methodId: 49,
    method: 'Agassiz-trawl'
  },
  {
    methodId: 50,
    method: 'Fishing rod'
  },
  {
    methodId: 51,
    method: 'Midwater Trawl'
  },
  {
    methodId: 52,
    method: 'Detritus-sledge'
  },
  {
    methodId: 53,
    method: 'Deepwater longline'
  },
  {
    methodId: 54,
    method: 'Sledge'
  },
  {
    methodId: 55,
    method: 'Freediving'
  },
  {
    methodId: 56,
    method: 'Epibenthic dredge'
  },
  {
    methodId: 57,
    method: 'Gillnet'
  },
  {
    methodId: 58,
    method: 'Scubadiving'
  },
  {
    methodId: 59,
    method: 'Seine'
  },
  {
    methodId: 60,
    method: 'Fishtrap'
  },
  {
    methodId: 61,
    method: 'Bottom-trawl'
  },
  {
    methodId: 62,
    method: 'Beyer-sledge'
  },
  {
    methodId: 63,
    method: 'Beam trawl'
  },
  {
    methodId: 64,
    method: 'Boxcorer'
  },
  {
    methodId: 65,
    method: 'Fishhook'
  },
  {
    methodId: 66,
    method: 'Kelp dredge'
  },
  {
    methodId: 67,
    method: 'Purseseine'
  },
  {
    methodId: 68,
    method: 'Longline'
  },
  {
    methodId: 69,
    method: 'Sircular dredge'
  },
  {
    methodId: 70,
    method: 'Isaacs Kidd Midwater Trawl (IKMT'
  },
  {
    methodId: 71,
    method: 'Trawl'
  },
  {
    methodId: 72,
    method: 'Handline'
  },
  {
    methodId: 73,
    method: 'Submersible'
  },
  {
    methodId: 74,
    method: 'ROV'
  },
  {
    methodId: 75,
    method: 'Shrimp-trawl'
  },
  {
    methodId: 76,
    method: 'RP-sledge'
  },
  {
    methodId: 77,
    method: 'Ockelman-sledge'
  },
  {
    methodId: 78,
    method: 'Suction pump'
  },
  {
    methodId: 79,
    method: 'Sneli-sledge'
  },
  {
    methodId: 80,
    method: 'Warén-sledge'
  }
];

export default Methods;
