// @flow
import * as React from 'react';
import { Field } from '../form';
import { Star, TODO } from '../../types/common';

interface FieldTextAreaProps {
  field: Field<Star>;
  title: string;
  onChangeInput: Function;
  inputProps?: {
    className?: string;
    style?: {};
    rows?: number;
  };
  labelWidth?: number;
  controlWidth?: number;
}

export default function FieldTextArea({
  field,
  title,
  onChangeInput,
  inputProps,
  labelWidth = 2,
  controlWidth = 12
}: FieldTextAreaProps) {
  return (
    <div className={`form-group col-md-${controlWidth}`}>
      {title !== '' && (
        <label className={`col-form-label`} htmlFor={field.name}>
          <b>{title}</b>
        </label>
      )}
      <textarea
        {...inputProps}
        className={`form-control ${inputProps ? inputProps.className || '' : ''} ${
          field.status ? (field.status.valid ? '' : 'is-invalid') : ''
        }`}
        id={field.name}
        value={(field.rawValue as TODO) || ''}
        onChange={e => onChangeInput({ name: field.name, rawValue: e.target.value })}
      />
    </div>
  );
}
