import * as React from 'react';
import { MusitEvent } from '../types/event/event';
import { typeStatusList } from './data';
import { getTranslator } from '../../../shared/language';

import {
  SimplePersonsComponent,
  ViewPersonsComponent
} from '../person/SimplePersonsComponent';
import {
  getEvent,
  createObjectEventHeaderData,
  setEventAttribute,
  updateEventField,
  updateEvent
} from '../object/ObjectContainerFunctions';
import { EventTypeId, TypeInfoEventAttributes } from '../types/event/eventTypes_auto';
import { OutActorAndRelation } from '../types/event/event';
import { AppSession } from '../../../types/appSession';
import '../css/Compact.css';

type OptionType = {
  typeInfoTypeId: number;
  typeInfoTypeNo: string;
  typeInfoTypeEn: string;
};

const words = getTranslator({
  en: {
    typeStatus: 'Type status',
    placeHolder: 'Choose type status',
    basionyme: 'Basiomyme',
    designatedBy: 'Designated by',
    publication: 'Publication',
    note: 'Note',
    year: 'Year'
  },
  no: {
    typeStatus: 'Type status',
    basionyme: 'Basionym',
    designatedBy: 'Utpekt av',
    publication: 'Publikasjon',
    placeHolder: 'Velg type status',
    note: 'Kommentar',
    year: 'År'
  }
});

const getTypeStatusValue = (typeStatusId: number) => {
  const t = typeStatusList.find(e => e.typeInfoTypeId === typeStatusId);
  return t ? t.typeInfoTypeNo : '';
};

const noUpdateFunction = (param: any): void => {};
const emptyEventMetadata = {
  name: ''
};

const SelectData = ({
  data,
  options,
  field,
  onChange,
  label,
  width,
  readonly,
  id
}: {
  data: number;
  options: Array<OptionType>;
  field: string;
  onChange: (value: number) => void;
  label: string;
  width: number;
  readonly: boolean;
  id: string;
}) => (
  <div className={`col-md-${width}`}>
    <label htmlFor={id} className="narrow">
      {label}
    </label>
    {readonly ? (
      <input
        readOnly={true}
        className="form-control-plaintext"
        value={getTypeStatusValue(data)}
      />
    ) : (
      <select
        id={id}
        className="form-control"
        value={data}
        onChange={e => {
          const v = e.target.value;
          console.log(v);
          onChange(Number.parseInt(v));
        }}
      >
        <option value={0}>{words.placeHolder}</option>
        {options.map((o: OptionType, i: number) => (
          <option key={`option-${i}`} label={o.typeInfoTypeNo} value={o.typeInfoTypeId} />
        ))}
      </select>
    )}{' '}
  </div>
);

const NoteData = ({
  readonly,
  data,
  id,
  onChange,
  field,
  width,
  rows,
  label
}: {
  readonly: boolean;
  id: string;
  field: string;
  data: string;
  rows: number;
  onChange?: (value: string) => void;
  width: number;
  label: string;
}) => (
  <React.Fragment>
    <div className={'col-md-' + width}>
      <label className="narrow" htmlFor={id}>
        {label}
      </label>
      <textarea
        className={`form-control${readonly ? '-plaintext' : ''}`}
        rows={rows}
        readOnly={readonly}
        value={data}
        id={id}
        onChange={e => {
          if (onChange) onChange(e.target.value);
        }}
      />
    </div>
  </React.Fragment>
);

const InputData = ({
  readonly,
  data,
  id,
  onChange,
  field,
  width,
  label
}: {
  readonly: boolean;
  id: string;
  field: string;
  data: string;
  onChange?: (value: string) => void;
  width: number;
  label: string;
}) => (
  <React.Fragment>
    <div className={'col-md-' + width}>
      <label htmlFor={id} className="narrow">
        {label}
      </label>
      <input
        type="text"
        readOnly={readonly}
        className={`form-control${readonly ? '-plaintext' : ''}`}
        value={data}
        id={id}
        onChange={e => {
          if (onChange) onChange(e.target.value);
        }}
      />
    </div>
  </React.Fragment>
);

const TypeInfoComponent = ({
  display,
  genericEvents,
  appSession,
  changeGenericEvents,
  registerChangeEventId
}: {
  display?: boolean;
  genericEvents: MusitEvent[];
  appSession: AppSession;
  changeGenericEvents: React.Dispatch<React.SetStateAction<MusitEvent[]>>;
  registerChangeEventId: (eventId: number) => void;
}) => {
  const typeInfoId = EventTypeId.TypeInfo;
  const typeInfoEvent: MusitEvent | undefined = getEvent(typeInfoId, genericEvents);

  const attributes: TypeInfoEventAttributes | undefined = typeInfoEvent
    ? typeInfoEvent.attributes
    : undefined;
  const existingOrNewTypeInfo = typeInfoEvent
    ? typeInfoEvent
    : createObjectEventHeaderData(appSession, typeInfoId);

  const onChangeFieldValue = (fieldName: string) => (value: string | number) => {
    const updatedEvent = setEventAttribute(existingOrNewTypeInfo, fieldName, value);
    const updatedEvents = updateEvent(updatedEvent, genericEvents);
    changeGenericEvents(updatedEvents);
    registerChangeEventId(typeInfoId);
  };

  const changePersonData = (r: OutActorAndRelation[]) => {
    const newEvent = updateEventField(typeInfoId, genericEvents, 'relatedActors', r);
    changeGenericEvents(newEvent);
  };

  return (
    <div className="container-fluid">
      <form>
        <div className="form-row">
          <SelectData
            field="typeInfoType"
            readonly={display ? true : false}
            options={typeStatusList}
            data={attributes && attributes.typeInfoType ? attributes.typeInfoType : 0}
            width={4}
            label={words.typeStatus}
            id="idTypeInfoType"
            onChange={onChangeFieldValue('typeInfoType')}
          />
          <InputData
            field="basionym"
            readonly={display ? true : false}
            data={attributes && attributes.basionym ? attributes.basionym : ''}
            width={4}
            label={words.basionyme}
            id="idBasionym"
            onChange={onChangeFieldValue('basionym')}
          />
          <InputData
            field="publication"
            readonly={display ? true : false}
            data={attributes && attributes.publication ? attributes.publication : ''}
            width={4}
            label={words.publication}
            id="idPublication"
            onChange={onChangeFieldValue('publication')}
          />
        </div>
        <div className="form-row">
          <NoteData
            field="note"
            readonly={display ? true : false}
            rows={1}
            data={attributes && attributes.note ? attributes.note : ''}
            width={12}
            label={words.note}
            id="idNote"
            onChange={onChangeFieldValue('note')}
          />
        </div>
        <div className="form-row">
          <InputData
            field="year"
            readonly={display ? true : false}
            data={attributes && attributes.year ? attributes.year.toString() : ''}
            width={2}
            label={words.year}
            id="idYear"
            onChange={onChangeFieldValue('year')}
          />
          <div className="col-md-10">
            {display ? (
              <ViewPersonsComponent
                personList={typeInfoEvent && typeInfoEvent.relatedActors}
                label={words.designatedBy}
              />
            ) : (
              <SimplePersonsComponent
                id={'typeInfoComp'}
                personData={
                  typeInfoEvent && typeInfoEvent.relatedActors
                    ? typeInfoEvent.relatedActors
                    : []
                }
                eventMetadata={emptyEventMetadata}
                changeEventMetadataState={noUpdateFunction}
                changePersonDataState={changePersonData}
                displayNameOnLabel={false}
                personLabel={words.designatedBy}
                personRole={34}
              />
            )}{' '}
          </div>
        </div>
      </form>
    </div>
  );
};

export default TypeInfoComponent;
