import * as React from 'react';
import SexAndStagesAdd from './SexAndStagesAdd';
import SexAndStagesEdit from './SexAndStagesEdit';
import { SexAndStagesEvent, SexAndStage } from '../types/event/event';
import { getTranslator } from '../../../shared/language';
import { sexList, stadiumList } from '../constants/sexAndStages';

const words = getTranslator({
  en: {
    count: 'Count',
    editThisRow: 'Edit this row',
    estimatedCount: 'Estimated count',
    sex: 'Sex',
    stage: 'Stage',
    editRow: 'Edit row',
    delete: 'Delete',
    saveChanges: 'Save changes'
  },
  no: {
    count: 'Opptelling',
    editThisRow: 'Rediger denne raden',
    estimatedCount: 'Estimert opptelling',
    sex: 'Kjønn',
    stage: 'Stadium',
    editRow: 'Rediger rad',
    delete: 'Fjern',
    saveChanges: 'Lagre endringer'
  }
});

type PropTypes = {
  sexAndStagesEvent: SexAndStagesEvent;
  changeSexAndStagesEvent: (SexAndStagesEvent: SexAndStagesEvent) => void;
};

const ModalEdit = ({
  onChangeSexAndStages,
  insSexAndStages,
  editingRow
}: {
  onChangeSexAndStages: (sexAndStages: SexAndStage[]) => void;
  insSexAndStages: SexAndStage[];
  editingRow: number;
}) => {
  const insertSexAndStage = (sexAndStage: SexAndStage) => {
    const newSexAndStages = [
      ...insSexAndStages.slice(0, editingRow),
      sexAndStage,
      ...insSexAndStages.slice(editingRow + 1)
    ];
    onChangeSexAndStages(newSexAndStages);
  };

  return (
    <div
      className="modal fade"
      id="sexAndStagesEdit"
      tabIndex={-1}
      role="dialog"
      style={{ position: 'relative' }}
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit row</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <SexAndStagesEdit
              key="modal"
              onChangeSexAndStages={insertSexAndStage}
              editingSexAndStage={insSexAndStages[editingRow] || {}}
            />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
              onClick={() => (s: SexAndStage) => insertSexAndStage(s)}
            >
              {words.saveChanges}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SexAndStagesComponent = (props: PropTypes) => {
  const [editingRowIndex, changeRowIndex] = React.useState<number>(-1);
  //const [sexAndStage, changeSexAndStage] = React.useState<SexAndStage>({});

  const addRow = (newRow: SexAndStage) => {
    const oldAttributes = props.sexAndStagesEvent.attributes || {};
    const oldSexAndStage =
      (props.sexAndStagesEvent.attributes &&
        props.sexAndStagesEvent.attributes.sexAndStage) ||
      [];
    const newSexAndStage = [...oldSexAndStage, newRow];
    const newAttributes = { ...oldAttributes, sexAndStage: newSexAndStage };
    const newSexAndStagesEvent = {
      ...props.sexAndStagesEvent,

      attributes: newAttributes
    };
    changeRowIndex(-1);

    props.changeSexAndStagesEvent(newSexAndStagesEvent);
  };

  const onDelete = (i: number) => {
    const newSexAndStageList =
      props.sexAndStagesEvent.attributes && props.sexAndStagesEvent.attributes.sexAndStage
        ? [
            ...props.sexAndStagesEvent.attributes.sexAndStage.slice(0, i),
            ...props.sexAndStagesEvent.attributes.sexAndStage.slice(i + 1)
          ]
        : [];

    if (props.sexAndStagesEvent.attributes) {
      const newAttributes = {
        ...props.sexAndStagesEvent.attributes,
        sexAndStage: newSexAndStageList.length > 0 ? newSexAndStageList : []
      };

      props.changeSexAndStagesEvent({
        ...props.sexAndStagesEvent,
        attributes: newAttributes
      });
      changeRowIndex(i);
    }
  };

  const getStageTerm = (id?: number) => {
    const el = stadiumList.find(a => a.stageId === id);
    return el ? el.stageEn : '';
  };

  const getSexTerm = (id?: number) => {
    const el = sexList.find(a => a.sexId === id);
    return el ? el.sexEn : '';
  };

  const changeSexAndStageEvent = (e: SexAndStage[]) => {
    const newSexAndStageEvent = props.sexAndStagesEvent.attributes
      ? {
          ...props.sexAndStagesEvent,
          attributes: { ...props.sexAndStagesEvent.attributes, sexAndStage: e }
        }
      : {
          ...props.sexAndStagesEvent,
          attributes: { sexAndStage: e }
        };

    props.changeSexAndStagesEvent(newSexAndStageEvent);
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        <SexAndStagesAdd
          key="add"
          addRow={addRow}
          numRows={
            (props.sexAndStagesEvent.attributes &&
              props.sexAndStagesEvent.attributes.sexAndStage &&
              props.sexAndStagesEvent.attributes.sexAndStage.length) ||
            -1
          }
        />
        <ModalEdit
          insSexAndStages={
            (props.sexAndStagesEvent.attributes &&
              props.sexAndStagesEvent.attributes.sexAndStage) ||
            []
          }
          onChangeSexAndStages={changeSexAndStageEvent}
          editingRow={editingRowIndex}
        />
      </div>
      <div className="row">
        <div className="col-md-10">
          <table className="table table-sm table-hover">
            <thead>
              <tr>
                <th scope="col">{words.sex}</th>
                <th scope="col">{words.stage}</th>
                <th scope="col">{words.count}</th>
                <th scope="col">{words.estimatedCount}?</th>
                <th scope="col" />
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {props.sexAndStagesEvent &&
                props.sexAndStagesEvent.attributes &&
                props.sexAndStagesEvent.attributes.sexAndStage &&
                props.sexAndStagesEvent.attributes.sexAndStage.map((a, i) => (
                  <tr key={`tr-row -${i}`}>
                    <td>{getSexTerm(a.sexId)}</td>
                    <td>{getStageTerm(a.stageId)}</td>
                    <td>{a.count}</td>
                    <td>{a.estimated ? words.yes : words.no}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={e => {
                          e.preventDefault();
                          onDelete(i);
                        }}
                      >
                        {words.delete}
                      </button>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-seconday btn-sm"
                        data-toggle="modal"
                        data-target="#sexAndStagesEdit"
                        onClick={e => {
                          e.preventDefault();
                          changeRowIndex(i);
                        }}
                      >
                        {words.editThisRow}
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SexAndStagesComponent;
