import * as React from 'react';
import coordinateSource from './mockdata/coordinateSources';
import { CheckBox } from '../components/CheckBox';
import { InputCoordinateAttribute as CoordinateAttribute } from '../types/place/place';
import { altDepthUnits } from './mockdata/data';
import { getTranslator } from '../../../shared/language';

const words = getTranslator({
  en: {
    accuracy: 'Accuracy',
    addedLater: 'Added later',
    altitude: 'Altitude',
    caAltitude: 'Approximate altitude',
    caCoordinate: 'Approximate coordinate',
    caDepth: ' Approximate depth',
    coordinateSource: 'Coordinate source',
    depth: 'Depth',
    from: 'From',
    precision: 'Precision',
    precisionAccuracy: 'Precision/accuracy',
    to: 'To',
    unit: 'Unit'
  },
  no: {
    accuracy: 'Nøyaktighet',
    addedLater: 'Lagt til senere',
    altitude: 'Høyde',
    caAltitude: 'Circa høyde',
    caCoordinate: 'Circa koordinat',
    caDepth: 'Circa dybde',
    coordinateSource: 'Koordinatkilde',
    depth: 'Dybde',
    from: 'Fra',
    precision: 'Presisjon',
    precisionAccuracy: 'Presisjon/nøyaktighet',
    to: 'Til',
    unit: 'Enhet'
  }
});

type Props = { attributes?: CoordinateAttribute } & {
  updateAttributes: (fieldName: string) => (value: string | boolean | number) => void;
  disabled?: boolean;
};

const CoordinatAttributesComponent = (props: Props) => {
  return (
    <React.Fragment>
      <div className="form-row" style={{ paddingBottom: '10px' }}>
        <div className="col-md-3 offset-md-2">
          <div className="checkbox" id="checkBoxCoordinateCa">
            <CheckBox
              id={'checkBoxCoordinateCa'}
              checked={props.attributes && props.attributes.coordinateCa ? true : false}
              displayValue={words.caCoordinate}
              viewMode={props.disabled}
              onChange={() => {
                props.attributes && props.attributes.coordinateCa
                  ? props.updateAttributes('coordinateCa')(false)
                  : props.updateAttributes('coordinateCa')(true);
              }}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="checkbox" id="coordinateAddedLater">
            <CheckBox
              id={'checkBoxCoordinateAddedLater'}
              checked={props.attributes && props.attributes.addedLater ? true : false}
              viewMode={props.disabled}
              displayValue={words.addedLater}
              onChange={() => {
                props.attributes && props.attributes.addedLater
                  ? props.updateAttributes('addedLater')(false)
                  : props.updateAttributes('addedLater')(true);
              }}
            />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-2" style={{ textAlign: 'right' }}>
          <b>{words.coordinateSource}</b>
        </div>
        <div className="col-md-6">
          <select
            className="form-control"
            id="coordinateSource"
            disabled={props.disabled}
            value={
              props.attributes && props.attributes.coordinateSource
                ? props.attributes.coordinateSource
                : ''
            }
            onChange={e => {
              props.updateAttributes('coordinateSource')(e.target.value);
            }}
          >
            <option value={''} key={`optionRow_${'x'}`}>
              {'--- Not selected ---'}
            </option>
            {coordinateSource.map(({ source }: { source: string }, i: number) => (
              <option key={`optionRow_${i}`} value={source}>
                {source}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2 offset-md-2">
          <b>{words.precision}</b>
        </div>
        <div className="col-md-2">
          <b>{words.accuracy}</b>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-2" style={{ textAlign: 'right' }}>
          <b>{words.precisionAccuracy}</b>
        </div>
        <div className="col-md-2">
          <input
            className="form-control"
            type="number"
            placeholder={words.precision}
            disabled={props.disabled}
            onChange={e => {
              props.updateAttributes('precision')(parseFloat(e.target.value));
            }}
            value={
              props.attributes && props.attributes.precision
                ? props.attributes.precision
                : ''
            }
            id="precision"
          />
        </div>
        <div className="col-md-2">
          <input
            className="form-control"
            type="number"
            placeholder={words.accuracy}
            disabled={props.disabled}
            onChange={e => {
              props.updateAttributes('gpsAccuracy')(parseFloat(e.target.value));
            }}
            value={
              props.attributes && props.attributes.gpsAccuracy
                ? props.attributes.gpsAccuracy
                : ''
            }
            id="accuracy"
          />
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-2 offset-md-2">
          <b>{words.from}</b>
        </div>
        <div className="col-md-2">
          <b>{words.to}</b>
        </div>{' '}
        <div className="col-md-2">
          <b>{words.unit}</b>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-2" style={{ textAlign: 'right' }}>
          <b>{words.altitude}</b>
        </div>
        <div className="col-md-2">
          <input
            placeholder={words.from}
            className="form-control"
            disabled={props.disabled}
            type="number"
            onChange={e => {
              props.updateAttributes('altitudeFrom')(parseFloat(e.target.value));
            }}
            value={
              props.attributes && props.attributes.altitudeFrom
                ? props.attributes.altitudeFrom
                : ''
            }
            id="altitudeFrom"
          />
        </div>

        <div className="col-md-2">
          <input
            placeholder={words.to}
            className="form-control"
            disabled={props.disabled}
            id="alt-h"
            type="number"
            onChange={e => {
              props.updateAttributes('altitudeTo')(parseFloat(e.target.value));
            }}
            value={
              props.attributes && props.attributes.altitudeTo
                ? props.attributes.altitudeTo
                : ''
            }
          />
        </div>
        <div className="col-md-2">
          <select
            className="form-control"
            id="altitudeUnit"
            disabled={props.disabled}
            value={
              props.attributes && props.attributes.altitudeUnit
                ? props.attributes.altitudeUnit
                : ''
            }
            onChange={e => {
              props.updateAttributes('altitudeUnit')(e.target.value);
            }}
          >
            {altDepthUnits.map((type: string, i: number) => (
              <option key={`optionRow_${i}`}>{type}</option>
            ))}
          </select>
        </div>
        <div className="col-md-3">
          <div className="checkbox" id="caAltitude">
            <CheckBox
              id={'checkBoxCaAltitude'}
              checked={props.attributes && props.attributes.altitudeCa ? true : false}
              displayValue={words.caAltitude}
              viewMode={props.disabled}
              onChange={() => {
                props.attributes && props.attributes.altitudeCa
                  ? props.updateAttributes('altitudeCa')(false)
                  : props.updateAttributes('altitudeCa')(true);
              }}
            />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-2 offset-md-2">
          <b>{words.from}</b>
        </div>
        <div className="col-md-2">
          <b>{words.to}</b>
        </div>{' '}
        <div className="col-md-2">
          <b>{words.unit}</b>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-2" style={{ textAlign: 'right' }}>
          <b>{words.depth}</b>
        </div>
        <div className="col-md-2">
          <input
            placeholder={words.from}
            className="form-control"
            type="number"
            disabled={props.disabled}
            onChange={e => {
              props.updateAttributes('depthFrom')(parseFloat(e.target.value));
            }}
            value={
              props.attributes && props.attributes.depthFrom
                ? props.attributes.depthFrom
                : ''
            }
            id="depth-l"
          />
        </div>

        <div className="col-md-2">
          <input
            placeholder={words.to}
            className="form-control"
            id="depth-h"
            disabled={props.disabled}
            type="number"
            onChange={e => {
              props.updateAttributes('depthTo')(parseFloat(e.target.value));
            }}
            value={
              props.attributes && props.attributes.depthTo ? props.attributes.depthTo : ''
            }
          />
        </div>
        <div className="col-md-2">
          <select
            className="form-control"
            id="depthUnit"
            disabled={props.disabled}
            value={
              props.attributes && props.attributes.depthUnit
                ? props.attributes.depthUnit
                : ''
            }
            onChange={e => {
              props.updateAttributes('depthUnit')(e.target.value);
            }}
          >
            {altDepthUnits.map((type: string, i: number) => (
              <option key={`optionRow_${i}`}>{type}</option>
            ))}
          </select>
        </div>
        <div className="col-md-3">
          <div className="checkbox" id="caDepth">
            <CheckBox
              id={'checkBoxCaDepth'}
              checked={props.attributes && props.attributes.depthCa ? true : false}
              displayValue={words.caDepth}
              viewMode={props.disabled}
              onChange={() => {
                props.attributes && props.attributes.depthCa
                  ? props.updateAttributes('depthCa')(false)
                  : props.updateAttributes('depthCa')(true);
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CoordinatAttributesComponent;
