// @flow

import * as React from 'react';
import FontAwesome from 'react-fontawesome';
import { I18n } from 'react-i18nify';
import {
  validateNumberRangeField,
  removeInvalidKeysForNumberRangeString
} from '../../shared/validation';
import { SearchStoreState } from '../../search/searchStore';
import { emitWarning } from '../../shared/errors';
import { TODO } from '../../types/common';

const SearchParam = (props: TODO) => (
  <div className="row" style={{ marginTop: 10 }}>
    <div className="col-md-2 text-right col-form-label font-weight-bold">
      {I18n.t(`musit.objectsearch.${props.id}.label`)}
    </div>

    <div className="col-md-6">
      <input
        id={'search-' + props.id}
        className="form-control border-primary"
        // style={{ ...style }}
        type="text"
        value={
          props && props.searchStore && props.searchStore.queryParam
            ? props.id === 'museumNoAsANumber'
              ? removeInvalidKeysForNumberRangeString(
                  props.searchStore.queryParam.museumNoAsANumber
                    ? props.searchStore.queryParam.museumNoAsANumber
                    : localStorage.getItem(props.id)
                    ? localStorage.getItem(props.id)
                    : ''
                )
              : props.searchStore.queryParam[props.id]
              ? props.searchStore.queryParam[props.id]
              : localStorage.getItem(props.id)
              ? localStorage.getItem(props.id)
              : ''
            : localStorage.getItem(props.id)
            ? localStorage.getItem(props.id)
            : ''
        }
        placeholder={I18n.t(`musit.objectsearch.${props.id}.placeHolder`)}
        onChange={e => {
          localStorage.setItem(props.id, e.target.value || '');
          props.onChange(
            props.id,
            props.id === 'museumNoAsANumber'
              ? removeInvalidKeysForNumberRangeString(e.target.value)
              : e.target.value
          );
        }}
        // ref={field => (this[id] = ReactDOM.findDOMNode(field))}
      />
    </div>
  </div>
);

export type Props = {
  onChange: (name: string, value: string) => void;
  search: (databaseSearch: boolean) => void;
  searchStore: SearchStoreState;
  onClearSearch: () => void;
};

const SearchInputFormComponent = (props: Props) => {
  const [pageSize, changePageSize] = React.useState<string | undefined>(undefined);
  return (
    <div className="container">
      <SearchParam
        id="museumNo"
        onChange={props.onChange}
        searchStore={props.searchStore}
      />
      <SearchParam
        id="museumNoAsANumber"
        onChange={props.onChange}
        searchStore={props.searchStore}
      />
      <SearchParam id="subNo" onChange={props.onChange} searchStore={props.searchStore} />
      <SearchParam id="term" onChange={props.onChange} searchStore={props.searchStore} />
      <SearchParam id="q" onChange={props.onChange} searchStore={props.searchStore} />

      <div className="row" style={{ marginTop: 10 }}>
        <div className="col-md-2 text-right col-form-label font-weight-bold">
          {I18n.t(`musit.objectsearch.pageSize`)}
        </div>
        <div className="col-md-2">
          <select
            className="form-control border-primary"
            id="pageSize"
            onChange={e => {
              const v = e.target.value;
              changePageSize(v);
              localStorage.setItem('SearchPageSize', v);
              Number(v) === 10000 &&
                emitWarning({
                  message: I18n.t('musit.texts.pageSizeMessage')
                });
            }}
            value={pageSize || localStorage.getItem('SearchPageSize') || '100'}
          >
            <option>10</option>
            <option>100</option>
            <option>200</option>
            <option>500</option>
            <option>1000</option>
          </select>
        </div>
      </div>

      <div className="row" style={{ marginTop: 10 }}>
        <div className="offset-md-2 col-md-6">
          <button
            id="executeSearch"
            className="btn btn-primary float-right"
            type="submit"
            onClick={e => {
              e.preventDefault();
              return validateNumberRangeField(
                props &&
                  props.searchStore &&
                  props.searchStore.queryParam &&
                  props.searchStore.queryParam.museumNoAsANumber
              )
                ? props.search(false)
                : emitWarning({
                    message: I18n.t('musit.texts.numberRangeMessage')
                  });
            }}
          >
            {I18n.t('musit.texts.search')} <FontAwesome name="search" />
          </button>

          <button
            className="btn btn-outline-primary mr-2 float-right"
            onClick={e => {
              e.preventDefault();
              localStorage.setItem('museumNo', '');
              localStorage.setItem('museumNoAsANumber', '');
              localStorage.setItem('subNo', '');
              localStorage.setItem('term', '');
              localStorage.setItem('q', '');
              return props.onClearSearch();
            }}
          >
            {I18n.t('musit.texts.clearSearch')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchInputFormComponent;
