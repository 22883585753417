import * as React from 'react';
import { AggregationItem } from './types';

const AddPersonInterface = ({
  label,
  value,
  dropDownRef,
  cleanUp,
  disabled,
  results,
  dropDownOpen,
  handleClick,
  handleChangeAggregationSearchInput
}: {
  dropDownOpen: boolean;
  value: string;
  cleanUp: () => void;
  disabled: boolean;
  label: string;
  handleChangeAggregationSearchInput: (e: string) => Promise<void>;
  handleClick: (p: string) => void;
  dropDownRef: React.MutableRefObject<null>;
  results: AggregationItem[];
}) => {
  return (
    <div className="row" style={{ paddingTop: 10, paddingBottom: 10 }}>
      <div className="col-md-3" style={{ textAlign: 'right' }}>
        <b>{label}</b>
      </div>
      <div className="col-md-6">
        <input
          className="form-control"
          tabIndex={0}
          disabled={disabled}
          value={value}
          onChange={async e => {
            const v = e.target.value;
            await handleChangeAggregationSearchInput(v);
          }}
          style={{ width: '100%' }}
        />
        <ul
          className="dropdown-menu"
          ref={dropDownRef}
          style={{
            display: results.length && dropDownOpen ? 'block' : 'none',
            width: '100%',
            marginLeft: 16
          }}
        >
          {results.map((p: AggregationItem, i: number) => (
            <li
              title={'UUID: ' + p}
              key={p.key + i}
              onClick={e => {
                if (!disabled) {
                  // tslint:disable-next-line:no-floating-promises
                  handleClick(p.key);
                  cleanUp();
                  $('#attributeModalId').hide();
                }
              }}
            >
              <a data-dismiss="modal" href="/#">
                {p.key}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const AttributeLookup = ({
  handleChangeAggregationSearchInput,
  aggregationSearchInput,
  fieldName,
  label,
  aggregations,
  handleClick,
  cleanUp,
  size = 10
}: {
  handleChangeAggregationSearchInput: (v: string) => Promise<void>;
  aggregationSearchInput: string;
  fieldName?: string;
  cleanUp: () => void;
  label?: string;
  handleClick: (v: string, attribute: string) => void;
  aggregations: AggregationItem[];
  size?: number;
}) => {
  const dropDownRef = React.useRef(null);

  const dropDownOpen = aggregations && aggregations.length >= 1;

  return (
    <div className="modal" id="attributeModalId" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title">
              <h3>{label}</h3>
            </div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => $('#attributeModalId').hide()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AddPersonInterface
              label={label || ''}
              cleanUp={cleanUp}
              value={aggregationSearchInput}
              handleChangeAggregationSearchInput={handleChangeAggregationSearchInput}
              disabled={false}
              dropDownRef={dropDownRef}
              dropDownOpen={dropDownOpen}
              results={aggregations}
              handleClick={e => handleClick(e, fieldName || '')}
            />
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </div>
  );
};

export default AttributeLookup;
