import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import {
  personsReducer,
  objectSearchReducer,
  objectSearchCurrPageReducer,
  collectingEventSearchReducers,
  numisSearchReducer,
  loadingReducer
} from './reducers/reducers';

const configureStore = (initialState = {}) => {
  const reducer = combineReducers({
    personsSearch: personsReducer,
    newObjectSearch: objectSearchReducer,
    numisSearch: numisSearchReducer,
    currentSearchPage: objectSearchCurrPageReducer,
    collectingEventSearch: collectingEventSearchReducers,
    loading: loadingReducer
  });

  const store = createStore(reducer, initialState, compose(applyMiddleware(thunk)));
  return store;
};
export default configureStore;
