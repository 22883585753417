import * as React from 'react';

interface LoginButtonProps {
  children?: JSX.Element;
  navigate?: Function;
}

/* Old:
  static propTypes = {
    children: PropTypes.element,
    navigate: PropTypes.func.isRequired
  };
*/

export default class LoginButton extends React.Component<LoginButtonProps> {
  static defaultProps = {
    navigate: (url: string) => (window.location.href = url)
  };

  render() {
    return (
      <button
        type="button"
        className="btn btn-lg"
        style={{
          marginTop: '1em',
          backgroundColor: '#e6e6e6'
        }}
        onClick={() =>
          this.props.navigate && this.props.navigate('/api/auth/rest/authenticate')
        }
      >
        {this.props.children}
      </button>
    );
  }
}
