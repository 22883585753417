import * as React from 'react';
import * as T from './types';
import MusNoSearch from './Search';
import Attributes from './AttributesSearch';
import EventFilter from './EventFilter';
import CreateNewObject from './CreateNewObjectComponent';
import { initPayLoad, defaultResultFields } from './initData'; //sortableFields
import { initializeObject } from './searchbuild';
import { post } from '../../../shared/ajaxFetch/ajaxFetch';
import { AppSessionContext } from '../../app/AppComponent';
import ResultGrid from './ResultGrid';
import { withRouter } from 'react-router';
import config from '../../../config';
import ModalResultFieldSelector from './ModalSelectResults';
import { getTranslator } from '../../../shared/language';
import NavigateSearch from '../../../search/NavigateSearch';
import { connect } from 'react-redux';
import * as actions from '../../../stores/actions/actions';
import './Main.css';
import FontAwesome from 'react-fontawesome';

const words = getTranslator({
  en: {
    search: 'Search objects',
    numPerPage: 'Hits per page',
    createObject: 'Create new object'
  },
  no: {
    search: 'Søk i objekter',
    numPerPage: 'Antall treff per side',
    createObject: 'Opprett nytt objekt'
  }
});

const resultFieldsTranslator: { [k: string]: string } = getTranslator({
  en: {
    'object.museumNo': 'Museum number',
    'object.museumNoAsNumber': 'Museum number (number)',
    'attributes§CollectingEvent§name§': 'Event name',
    'attributes§CollectingEvent§method§': 'Collecting method',
    'attributes§CollectingEvent§PlaceName§': 'Place name',
    'attributes§CollectingEvent§PlacePath§': 'Place hierarchy',
    'attributes§CollectingEvent§PlaceLocality§': 'Locality',
    'attributes§CollectingEvent§placeDepth§': 'Depth',
    'attributes§CollectingEvent§placeAltitude§': 'Altitude',
    'attributes§CollectingEvent§placePrecision§': 'Precision',
    'attributes§CollectingEvent§placeAccuracy§': 'Accuracy',
    'attributes§CollectingEvent§actorName§': 'Leg',
    'attributes§Taxon§actorName§': 'Det',
    'attributes§Taxon§ScientificName§': 'Scientific name',
    'attributes§Taxon§HigherClassification§': 'Higher taxa',
    'attributes§MuseumNoAssignment§museumNoSeq§': 'Museum number as number',
    'attributes§MuseumNoAssignment§museumNo§': 'Museum number',
    'attributes§MuseumNoAssignment§prefix§': 'Museum number prefix',
    'attributes§MuseumNoAssignment§subNo§': 'Sub number',
    'event.eventDateFrom§CollectingEvent': 'Leg date (from)',
    'event.eventDateTo§CollectingEvent': 'Leg date (to)',
    'object.subNo': 'Sub number',
    'object.term': 'Taxon-term',
    'object.museum': 'Museum',
    'object.objectId': 'Object-uuid',
    'event.createdDate§CollectingEvent': 'Created date (Collecting event)',
    'event.eventDateFrom§Taxon': 'Det date (from)',
    'event.eventDateTo§Taxon': 'Det date (to)',
    'event.createdDate§Taxon': 'Created date (taxon)'
  },
  no: {
    'object.museumNo': 'Museumsnummer',
    'object.museumNoAsNumber': 'Museumsnr (tall)',
    'attributes§CollectingEvent§name§': 'Hendelsesnavn',
    'attributes§CollectingEvent§method§': 'Innsamlingsmetode',
    'attributes§CollectingEvent§PlaceName§': 'Stedsnavn',
    'attributes§CollectingEvent§PlacePath§': 'Sted-hierarki',
    'attributes§CollectingEvent§PlaceLocality§': 'Lokalitet',
    'attributes§CollectingEvent§placeDepth§': 'Dybde',
    'attributes§CollectingEvent§placeAltitude§': 'Høyde',
    'attributes§CollectingEvent§placePrecision§': 'Presisjon',
    'attributes§CollectingEvent§placeAccuracy§': 'Nøyaktighet',
    'attributes§CollectingEvent§actorName§': 'Leg',
    'attributes§Taxon§actorName§': 'Det',
    'attributes§Taxon§ScientificName§': 'Vitenskapelig navn',
    'attributes§Taxon§HigherClassification§': 'Høyere taxa',
    'attributes§MuseumNoAssignment§museumNoSeq§': 'Museumsnummer (tall)',
    'attributes§MuseumNoAssignment§museumNo§': 'Museumsnummer',
    'attributes§MuseumNoAssignment§prefix§': 'Museumsnummer-prefiks',
    'attributes§MuseumNoAssignment§subNo§': 'Undernummer',
    'event.eventDateFrom§CollectingEvent': 'Legdato (fra)',
    'event.eventDateTo§CollectingEvent': 'Legdato (til)',
    'object.subNo': 'Undernummer',
    'object.term': 'Taxon-term',
    'object.museum': 'Museum',
    'object.objectId': 'Objekt-uuid',
    'event.createdDate§CollectingEvent': 'Opprettet dato (innsamlingshendelse)',
    'event.eventDateFrom§Taxon': 'Detdato (fra)',
    'event.eventDateTo§Taxon': 'Detdato (til)',
    'event.createdDate§Taxon': 'Opprettet dato (taxonhendelse)'
  }
});

/* const SelectSortField = ({
  onSelect,
  sortedField,
  sortableFields,
  label
}: {
  onSelect: (field: string) => void;
  sortedField: string;
  sortableFields: T.SortableField[];
  label: string;
}) => {
  return (
    <div className="form-row">
      <label
        className="col-md-4 col-form-label"
        style={{ textAlign: 'right' }}
        htmlFor="selectSortID"
      >
        {label}
      </label>
      <div className="col-md-5">
        <select
          id="selectSortID"
          className="form-control"
          value={sortedField}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            const v = e.target.value;
            onSelect(v);
          }}
        >
          {sortableFields.map(e => (
            <option key={e.attribute} value={e.attribute}>
              {resultFieldsTranslator[e.attribute]}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}; */

const searchCall = async (url: string, data: any, token: string) => {
  const r = await post(url, data, token);
  if (r.ok) {
    return await r.json();
  }
};

export const getFieldNameFromAttribute = (attribute: T.Attribute | null) => {
  if (attribute) {
    return attribute.attribute;
  }
  return '';
};

const createAggregationForField: (
  fieldName: string,
  value: string,
  size: number
) => T.AggregationQuery = (fieldName: string, value: string, size: number) => {
  const searchValue = value ? value.toLowerCase() : '';
  return {
    name: `${fieldName}`,
    type: 'event',
    field: 'events.attributes.attribAsLower',
    order: 'asc',
    size: size,
    prefix: `${fieldName}${searchValue}.*`.toLowerCase()
  };
};

export const removeField = (s: T.SelectAbleField, resultFields: T.SelectType[]) => {
  if (s.indexPlace) {
    const oldEvent = resultFields[s.indexPlace.eventIndex];
    const newField = { ...s, selected: false, sortOrder: undefined };
    const newEvent = {
      ...oldEvent,
      fields: [
        ...oldEvent.fields.slice(0, s.indexPlace.fieldIndex),
        newField,
        ...oldEvent.fields.slice(s.indexPlace.fieldIndex + 1)
      ]
    };
    return [
      ...resultFields.slice(0, s.indexPlace.eventIndex),
      newEvent,
      ...resultFields.slice(s.indexPlace.eventIndex + 1)
    ];
  }
  return resultFields;
};

export const sortSelectable = (s: T.SelectAbleField[]) =>
  s.sort((a, b) => {
    if (a.sortOrder && b.sortOrder) return a.sortOrder - b.sortOrder;

    if (a.sortOrder) return 1;
    if (b.sortOrder) return -1;
    return 0;
  });

const toggleShow = (s: T.SelectType) => ({ ...s, show: s.show ? false : true });

const removeAllSelected: (s: T.SelectType[]) => T.SelectType[] = (s: T.SelectType[]) => {
  return s.map(s => ({
    ...s,
    fields: s.fields.map(f => ({ ...f, selected: false, sortOrder: 0 }))
  }));
};

const newQueryObject = (
  mainOperator: string,
  operator: string,
  field: string,
  content: string | number | Object[],
  prefix?: string
) => {
  const r: T.ESQueryField = {
    mainOperator,
    operator,
    field,
    content: typeof content === 'number' ? content : prefix ? prefix + content : content
  };
  return r;
};

const resultFieldsToColumnHeadersAndResultTemplate: (
  selectFields: T.SelectType[]
) => { resultTemplate: string[]; columnHeaders: string[] } = (
  selectFields: T.SelectType[]
) => {
  const selectedFields = selectFields.reduce(
    (p, c) => [...p, ...c.fields.filter(f => f.selected)],
    []
  );

  const sortedSelectedFields = sortSelectable(selectedFields);

  const columnHeaders = sortedSelectedFields.map(v => resultFieldsTranslator[v.value]);
  const resultTemplate = sortedSelectedFields.map(v => v.value);

  return {
    columnHeaders,
    resultTemplate: ['object.objectId', ...resultTemplate]
  };
};

const attributesSearchToQuerys = (
  attributeText: string,
  payLoad: T.ESPayLoad = initPayLoad
) => {
  const r = initializeObject(attributeText, payLoad);
  return r;
};

const numSearchToSearcQuerys = (numSearches: T.NumSearch[]) => {
  const queries = numSearches.reduce((pn: T.QueryField[], n: T.NumSearch) => {
    if (n.searchKind === T.SearchKind.Single && n.singleNum)
      return [...pn, newQueryObject('must', 'match', n.fieldName, n.singleNum)];
    if (n.searchKind === T.SearchKind.LessThan && n.fromTo && n.fromTo.to)
      return [...pn, newQueryObject('must', 'lte', n.fieldName, n.fromTo.to)];
    if (n.searchKind === T.SearchKind.GreaterThan && n.fromTo && n.fromTo.from)
      return [...pn, newQueryObject('must', 'gte', n.fieldName, n.fromTo.from)];
    if (
      n.searchKind === T.SearchKind.Sequence &&
      n.fromTo &&
      n.fromTo.from &&
      n.fromTo.to
    )
      return [
        ...pn,
        newQueryObject('must', 'gte', n.fieldName, n.fromTo.from),
        newQueryObject('must', 'lte', n.fieldName, n.fromTo.to)
      ];
    if (n.searchKind === T.SearchKind.CommaSep) console.log(n);
    if (n.searchKind === T.SearchKind.CommaSep && n.separatedValues) {
      return [
        ...pn,
        ...n.separatedValues.map(num =>
          newQueryObject('should', 'match', n.fieldName, num)
        )
      ];
    }
    return [];
  }, []);
  return queries;
};

const numSearchToEventFilterSearchQuerys = (numSearches: T.NumSearch[]) => {
  const queries = numSearches.reduce((pr: T.QueryField[], c: T.NumSearch) => {
    if (c.searchKind === T.SearchKind.Single && c.singleNum) {
      return [
        ...pr,
        newQueryObject(
          'must',
          'match',
          'events.attributes',
          c.singleNum.toString().padStart(6, '0'),
          c.contentPrefix
        )
      ];
    }
    if (c.searchKind === T.SearchKind.GreaterThan && c.fromTo && c.fromTo.from) {
      return [
        ...pr,
        newQueryObject(
          'must',
          'gte',
          'events.attributes',
          c.fromTo.from.toString().padStart(6, '0'),
          c.contentPrefix
        )
      ];
    }
    if (c.searchKind === T.SearchKind.LessThan && c.fromTo && c.fromTo.to) {
      return [
        ...pr,
        newQueryObject(
          'must',
          'lte',
          'events.attributes',
          c.fromTo.to.toString().padStart(6, '0'),
          c.contentPrefix
        )
      ];
    }
    if (
      c.searchKind === T.SearchKind.Sequence &&
      c.fromTo &&
      c.fromTo.to &&
      c.fromTo.from
    ) {
      return [
        ...pr,
        newQueryObject(
          'must',
          'gte',
          'events.attributes',
          c.fromTo.from.toString().padStart(6, '0'),
          c.contentPrefix
        ),
        newQueryObject(
          'must',
          'lte',
          'events.attributes',
          c.fromTo.to.toString().padStart(6, '0'),
          c.contentPrefix
        )
      ];
    }
    if (c.searchKind === T.SearchKind.CommaSep && c.separatedValues) {
      return [
        ...pr,
        ...c.separatedValues.map(n =>
          newQueryObject(
            'should',
            'match',
            'events.attributes',
            n.toString().padStart(6, '0'),
            c.contentPrefix
          )
        )
      ];
    }
    return [];
  }, []);

  return queries || [];
};

const eventFilterToQueries = (e: T.EventFilter | null) => {
  let ret: T.ESQueryField[] = [];
  if (e === null) return ret;
  if (e.eventTypeId) {
    ret = [...ret, newQueryObject('must', 'match', `events.eventTypeId`, e.eventTypeId)];
  }
  if (e.dateFrom) {
    ret = [...ret, newQueryObject('must', 'gte', `events.eventDateFrom`, e.dateFrom)];
  }
  if (e.dateTo) {
    ret = [...ret, newQueryObject('must', 'lte', `events.eventDateTo`, e.dateTo)];
  }
  if (e.numFields) {
    ret = [...ret, ...numSearchToEventFilterSearchQuerys(e.numFields || [])];
  }
  return [{ searchFields: ret.map(e => ({ ...e, mainOperator: undefined })) }];
};

const Main = withRouter((props: any) => {
  const [aggregationSearchInput, changeAggreationSearchInput] = React.useState<string>(
    ''
  );
  const [resultGrid, changeResultGrid] = React.useState<T.Result>({
    objectList: {
      grid: [],
      count: 0
    }
  });
  const [collapse, setCollapse] = React.useState<boolean>(false);

  React.useEffect(() => {
    changeResultGrid(props.newObjectSearch);
  }, [props]);

  const [pageSize, changePageSize] = React.useState<number>(10);
  const [currPage, changeCurrPage] = React.useState<number>(props.currentSearchPage);

  React.useEffect(() => {
    props.dispatch(actions.saveCurrentSearchPage(currPage));
  }, [currPage, props]);

  const [ctrlIsDown, toggleCtrlIsDown] = React.useState<boolean>(false);

  const [resultFields, changeResultFields] = React.useState<T.SelectType[]>(
    defaultResultFields.map((s: T.SelectType) => ({
      ...s,
      fields: s.fields.map((f: T.SelectAbleField) => ({
        ...f,
        label: resultFieldsTranslator[f.value]
      }))
    }))
  );

  const [sortField, changeSortField] = React.useState<T.SortableField | null>(null);
  const [numSearch, changeNumSeach] = React.useState<T.NumSearch[] | null>(null);
  const [attributesSearch, changeAttributeSearch] = React.useState<string>('');
  const [
    currentAttributeField,
    changeCurrentAttributeField
  ] = React.useState<T.Attribute | null>(null);
  const [eventFilter, changeEventFilter] = React.useState<T.EventFilter | null>(null);
  const [aggregations, changeAggregations] = React.useState<T.AggregationQuery[]>([]);

  React.useEffect(() => {
    if (currPage === 0 && resultGrid.objectList.count > 0) changeCurrPage(1);
  }, [resultGrid.objectList.count, currPage]);

  const appSession = React.useContext(AppSessionContext);
  /* 
  const filterSorfieldsFromResultFields = (resultFields: T.SelectType[]) => {
    const selectedFields = resultFields
      .reduce((p, f) => [...p, ...f.fields], [])
      .filter(t => t.selected);
    const ret = sortableFields.filter(f =>
      selectedFields.find(e => f.attribute === e.value)
    );
    return ret;
  };
 */
  /*   const onSelectSortField = async (sortFieldAttribute: string) => {
    const newsortField = sortableFields.find(s => s.attribute === sortFieldAttribute);
    changeSortField(newsortField || null);
    await doSearch(1, pageSize, undefined, undefined, newsortField || undefined);
  }; */

  const onChangeSortOrder = async () => {
    let newSortField = sortField;
    if (sortField) {
      newSortField =
        sortField.sortType === 'asc'
          ? { ...sortField, sortType: 'desc' }
          : { ...sortField, sortType: 'asc' };
    }
    changeSortField(newSortField);
    await doSearch(1, 10, undefined, undefined, newSortField || undefined);
  };

  const cleanupAggregationSearch = () => {
    changeAggreationSearchInput('');
  };

  const onChangePageSize = async (newPageSize: number) => {
    changePageSize(newPageSize);
    await doSearch(1, newPageSize);
    changeCurrPage(1);
  };

  const gotoObject = (objectId: string) => {
    const url = config.magasin.urls.client.object.editObject(appSession, objectId);
    props.history.push(url);
  };

  const onClickRemove = (s: T.SelectAbleField) => {
    changeResultFields(e => removeField(s, e));
  };

  const onClick = (i: number) => (e: React.MouseEvent<HTMLOptGroupElement>) => {
    e.preventDefault();
    if (e.target instanceof HTMLOptGroupElement)
      changeResultFields(f => [...f.slice(0, i), toggleShow(f[i]), ...f.slice(i + 1)]);
  };

  const getMaxNum = (resFields: T.SelectType[]) => {
    const selectedFields: T.SelectAbleField[] = resFields
      .reduce((p, c) => [...p, ...c.fields], [])
      .filter(f => f.selected);
    return selectedFields.reduce(
      (p, f) => (f.sortOrder ? (f.sortOrder > p ? f.sortOrder : p) : p),
      0
    );
  };

  const clearSelected = () => changeResultFields(s => removeAllSelected(s));
  const createNewFields = (s: T.SelectType[], eventIndex: number, fieldIndex: number) => {
    const maxNum = getMaxNum(s);
    const e = s[eventIndex];
    const fields = e.fields;
    const newField = {
      ...fields[fieldIndex],
      selected: true,
      sortOrder: maxNum + 1,
      indexPlace: { eventIndex: eventIndex, fieldIndex: fieldIndex }
    };
    const newFields = [
      ...fields.slice(0, fieldIndex),
      newField,
      ...fields.slice(fieldIndex + 1)
    ];
    const newEvent = { ...e, fields: newFields };
    const r = [...s.slice(0, eventIndex), newEvent, ...s.slice(eventIndex + 1)];
    return r;
  };

  const onCloseModal = async (e: React.MouseEvent<HTMLButtonElement>) => {
    await doSearch(currPage, pageSize);
  };

  const addToAggregations = (aggregationQuery: T.AggregationQuery | undefined) => {
    const index = aggregationQuery
      ? aggregations.findIndex(e => e.name === aggregationQuery.name)
      : -1;

    changeAggregations(a => {
      if (aggregationQuery) {
        return index >= 0
          ? a
              .slice(0, index)
              .concat([aggregationQuery])
              .concat(a.slice(index + 1))
          : a.concat([aggregationQuery]);
      }
      return a;
    });
  };

  const onDoubleClickField = (eventIndex: number, fieldIndex: number) => (
    event: React.MouseEvent<HTMLOptionElement>
  ) => {
    event.preventDefault();
    const r = createNewFields(resultFields, eventIndex, fieldIndex);

    changeResultFields(r);
  };

  const buildSortTerm = (sortField: T.SortableField | null): T.Sorting[] | undefined => {
    if (sortField) {
      return [
        {
          field: sortField.attribute,
          order: sortField.sortType
        }
      ];
    }
    return undefined;
  };

  const buildSearch = (
    currentPage: number,
    pageSize: number,
    resFields: T.SelectType[] = resultFields,
    withAggregation?: T.AggregationQuery,
    withSorting?: T.SortableField
  ) => {
    const from = (currentPage - 1) * pageSize;
    const payLoad = { ...initPayLoad, size: pageSize, from: from };
    const resTempl = resultFieldsToColumnHeadersAndResultTemplate(resFields);
    const resultFieldsToUse = resTempl;
    const r1 = attributesSearchToQuerys(attributesSearch, payLoad);
    const r2 = {
      ...r1,
      resultDescription: {
        ...r1.resultDescription,
        ...resultFieldsToUse
      },
      searchFields: [
        ...r1.searchFields,
        ...numSearchToSearcQuerys(numSearch || [])
          .filter(f => f.mainOperator === 'must')
          .map(e => ({ ...e, mainOperator: undefined }))
      ]
    };
    const r3 = {
      ...r2,
      shouldFields: [
        ...r2.shouldFields,
        {
          searchFields: numSearchToSearcQuerys(numSearch || [])
            .filter(f => f.mainOperator === 'should')
            .map(e => ({ ...e, mainOperator: undefined }))
        }
      ]
    };
    const r4 = {
      ...r3,
      eventFilters: eventFilterToQueries(eventFilter),
      aggregations: withAggregation ? [withAggregation] : [],
      sorting: buildSortTerm(withSorting || sortField)
    };

    return r4;
  };

  const handleChangeAggregationSearchInput = async (s: string) => {
    const aggregationQuery = fieldName
      ? createAggregationForField(fieldName, s, 10)
      : undefined;
    addToAggregations(aggregationQuery);
    await doSearch(1, 10, undefined, aggregationQuery);
    changeAggreationSearchInput(s);
  };

  const doSearch = async (
    pageNo: number,
    pageSize: number,
    resFields?: T.SelectType[],
    withAggregation?: T.AggregationQuery,
    withSorting?: T.SortableField
  ) => {
    const url = '/api/es_backend/SearchObjects';
    const payLoad = buildSearch(
      pageNo,
      pageSize,
      undefined,
      withAggregation,
      withSorting
    );
    const r = await searchCall(url, JSON.stringify(payLoad), appSession.accessToken);
    if (r && r.objectList.grid) {
      changeResultGrid((res: T.Result) => r);
      changeCurrPage(pageNo);
      props.dispatch(actions.saveObjectSearchResult(r));
    } else {
      changeResultGrid((res: T.Result) => ({
        ...res,
        objectList: { count: 0, grid: [] }
      }));
      changeCurrPage(0);
    }
  };

  const gotoPage = async (pageNo: number) => {
    await doSearch(pageNo, pageSize);
  };

  const changePS = async (ps: string) => {
    const pageSizeAsNumber = Number.parseInt(ps);
    await onChangePageSize(pageSizeAsNumber);
  };

  const fieldName = getFieldNameFromAttribute(currentAttributeField);
  return (
    <div
      className="container"
      onKeyDown={e => {
        if (e.key === 'Control') {
          toggleCtrlIsDown(true);
        }
      }}
      onKeyUp={e => {
        if (e.key === 'Control') {
          toggleCtrlIsDown(false);
        }
      }}
    >
      <div className="row">
        <div className="col">
          <NavigateSearch
            appSession={appSession}
            history={props.history.push}
            disableCollectingEvent={true}
            activeNav="musit.newObjectSearch.newObjectSearch"
          />
        </div>
      </div>
      <div className="objectContent">
        <form id="objectAdd">
          <h3>{words.createObject}</h3>
          <CreateNewObject appSession={appSession} history={props.history} />
        </form>
      </div>

      <br />

      <div className="objectContent">
        <div className="row">
          <div className="col-md-12">
            <button
              data-toggle="tooltip"
              style={{ float: 'right', boxShadow: 'none' }}
              title={collapse ? 'Click to Expand' : 'Click to Hide'}
              onClick={() => setCollapse(!collapse)}
              className="btn btn-link float-right btn-sm"
            >
              <FontAwesome
                name={collapse ? 'chevron-up' : 'chevron-down'}
                style={{ color: '#007bff' }}
                size={'2x'}
              />
            </button>
            <h3>{words.search}</h3>
          </div>
        </div>
        <div className={collapse ? 'show' : 'hide'}>
          <form id="objectSok">
            <MusNoSearch
              eventType={'object'}
              numSearch={numSearch}
              changeNumSearch={changeNumSeach}
            />
            <Attributes
              aggregationSearchInput={aggregationSearchInput}
              cleanupAggregationSearchField={cleanupAggregationSearch}
              handleChangeAggregationSearchInput={handleChangeAggregationSearchInput}
              changeAttributeSearch={changeAttributeSearch}
              changeCurrentAttributeField={changeCurrentAttributeField}
              currentAttributeField={currentAttributeField}
              toggleCtrlIsDown={toggleCtrlIsDown}
              ctrlDown={ctrlIsDown}
              attributesSearch={attributesSearch}
              attributeFieldTranslator={resultFieldsTranslator}
              aggregations={
                resultGrid.aggregations && currentAttributeField && fieldName
                  ? resultGrid.aggregations[fieldName] || []
                  : []
              }
            />

            <EventFilter
              eventFilter={eventFilter}
              fieldTranslate={resultFieldsTranslator}
              changeEventFilter={changeEventFilter}
            />

            <div className="form-row">
              <div className="col-md-2">
                <label htmlFor="id-numpages" style={{ paddingLeft: '15px' }}>
                  {words.numPerPage}
                </label>
              </div>
              <div className="col-md-2">
                <select
                  id="id-numpages"
                  className="form-control search-result-number"
                  value={pageSize}
                  onChange={async (e: React.ChangeEvent<HTMLSelectElement>) => {
                    const v = e.target.value;
                    e.preventDefault();
                    await changePS(v);
                  }}
                >
                  <option value={10}>{'10'}</option>
                  <option value={50}>{'50'}</option>
                  <option value={100}>{'100'}</option>
                </select>
              </div>
              <div className="col-md-6"></div>
              <div className="col-md-2">
                <ModalResultFieldSelector
                  onDoubleClickField={onDoubleClickField}
                  onCloseModal={onCloseModal}
                  allFields={resultFields}
                  clear={clearSelected}
                  onClick={onClick}
                />
              </div>
              {/*           <div className="col-md-9">
            <SelectSortField
              sortableFields={filterSorfieldsFromResultFields(resultFields)}
              sortedField={sortField ? sortField.attribute : ''}
              onSelect={onSelectSortField}
              label="Velg felt å sortere på"
            />
          </div> */}
            </div>
            <ResultGrid
              onChangeSortOrder={onChangeSortOrder}
              currPage={currPage}
              sortField={sortField}
              doSearch={doSearch}
              changeCurrPage={changeCurrPage}
              gotoObject={gotoObject}
              pageSize={pageSize}
              onChangePageSize={onChangePageSize}
              changeResult={changeResultGrid}
              gotoPage={gotoPage}
              resultFields={resultFields}
              clear={clearSelected}
              onClickRemove={onClickRemove}
              result={resultGrid}
            />
          </form>
        </div>
      </div>
    </div>
  );
});

function mapStateToProps(state: any) {
  return state;
}
export default connect(mapStateToProps)(Main);
