import * as React from 'react';
import { getTranslator } from '../../../shared/language';

const words = getTranslator();

export type EditAndSaveButtonProps = {
  onClickSave: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClickEdit: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClickCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
  editButtonState: { visible: boolean; disabled: boolean };
  saveButtonState: { visible: boolean; disabled: boolean };
  cancelButtonState: { visible: boolean; disabled: boolean };
  saveButtonText?: string;
  editButtonText?: string;
  cancelButtonText?: string;
};

const EditAndSaveButtons = (props: EditAndSaveButtonProps) => {
  return (
    <div
      className="container-fluid d-flex justify-content-end"
      style={{ padding: '10px' }}
    >
      {props.cancelButtonState.visible && (
        <button
          type="button"
          className="btn btn-link"
          onClick={props.onClickCancel}
          disabled={props.cancelButtonState.disabled}
        >
          {props.cancelButtonText || words.cancel}
        </button>
      )}{' '}
      {props.editButtonState.visible ? (
        <button
          type="button"
          className="btn btn-default"
          onClick={props.onClickEdit}
          disabled={props.editButtonState.disabled}
        >
          {props.editButtonText || words.edit}
        </button>
      ) : (
        <span />
      )}{' '}
      <button
        type="button"
        className="btn btn-success"
        onClick={props.onClickSave}
        disabled={props.saveButtonState.disabled}
      >
        {props.saveButtonText || words.save}
      </button>
    </div>
  );
};

export default EditAndSaveButtons;
