/* eslint-disable eqeqeq */
import { get, post, put, sendFile } from '../../shared/ajaxFetch/ajaxFetch';
import { getAccessToken } from '../../shared/token';
import { getTranslator } from '../../shared/language';
import { ExternalFiles, Attachment } from './AnalysisEditor';
import config from '../../config';

const words = getTranslator({
  en: {
    fileSizeError1: 'file ',
    fileSizeError2: ' is too large!'
  },
  no: {
    fileSizeError1: 'fil ',
    fileSizeError2: ' er for stor! '
  }
});

/* TODO: this is a pretty terrible way to set up an API endpoint because
 * you have to fire off several auxilliary queries and then JOIN them
 * into the "main" query. This is a horrible, terrible anit-patttern that
 * serves no purpose. At some point in the future, we should make it such
 * that it is possible to get out ONE analysis with ONE API call.*/
const getAnalysisFromAPI = (params: any) => {
  // get the main anaylsis object
  return get(
    '/api/management/' +
      params.museumId +
      '/analyses/' +
      new URLSearchParams(window.location.search).get('analysisId'),
    getAccessToken()
  )
    .then(res => res.json())
    .then(analysis => {
      // use analysis object to identify people to get
      const peopleIds: any = [];
      if (analysis.completedBy) peopleIds.push(analysis.completedBy);
      if (analysis.doneBy) peopleIds.push(analysis.doneBy);
      if (analysis.registeredBy) peopleIds.push(analysis.registeredBy);
      if (analysis.responsible) peopleIds.push(analysis.responsible);
      if (analysis.updatedBy) peopleIds.push(analysis.updatedBy); // TODO -> this should be taken from appSession
      if (analysis.administrator) peopleIds.push(analysis.administrator);
      if (analysis.restriction && analysis.restriction.requester)
        peopleIds.push(analysis.restriction.requester);
      if (
        analysis.restriction &&
        analysis.restriction.cancelledStamp &&
        analysis.restriction.cancelledStamp.user
      )
        peopleIds.push(analysis.restriction.cancelledStamp.user);

      return new Promise(resolve =>
        post(
          '/api/actor/person/details',
          JSON.stringify([...new Set(peopleIds)]), // dedupe
          getAccessToken()
        )
          .then(res => res.json())
          .then(people => {
            return {
              analysis: analysis,
              people: people.reduce((acc: any, p: any) => {
                acc[p.applicationId] = p;
                return acc;
              }, {})
            };
          })
          .then(resolve)
      );
    })
    .then((responseMap: any) => {
      return new Promise(resolve => {
        // tslint:disable-next-line: no-floating-promises
        get(
          config.magasin.urls.api.analysisType.getAllAnalysisTypes(params.museumId),
          getAccessToken()
        )
          .then(res => res.json())
          .then(resultTypes => {
            responseMap.resultTypes = resultTypes;
            return resolve(responseMap);
          });
      });
    });
};

/* Now that we have downloaded all of the neccessary API responses, start
 * to combine them together into an analysis object*/
const processAPIResponse = (response: any) => {
  const findPerson = (people: any[], uuid: string) => {
    // this is expected path if the user has an id
    if (people[uuid]) {
      return people[uuid].fn;
    }
    // this is for users with just dataPortenId
    const key = Object.keys(people).find(key => people[key].dataportenId === uuid);
    return key ? people[key].fn : '';
  };

  //                         CAN THIS BE REMOVED?
  // JOIN PEOPLE
  const joinPeople = (item: any) => {
    if (item.result)
      item.result.registeredBy = findPerson(response.people, item.result.registeredBy);
    if (response.people[item.registeredBy])
      item.registeredBy = response.people[item.registeredBy].fn; // SHOULD NOT BE POSSIBLE BUT API IS A BIT BROKEN
    if (item.updatedBy) item.updatedBy = findPerson(response.people, item.updatedBy);
    if (item.responsible)
      if (response.people[item.responsible])
        item.responsible = response.people[item.responsible].applicationId;
    return item;
  };
  joinPeople(response.analysis);
  response.analysis.events.map(joinPeople);

  response.analysis.events.map((item: any) => {
    // JOIN RESULTS
    //    item.affectedThing = response.results[item.affectedThing];
    // JOIN RESULT TYPES
    item.analysisType = response.resultTypes.find(
      (e: any) => e.id == item.analysisTypeId
    );
    return item;
  });
  //                           END REMOVAL

  // JOIN MAIN RESULT TYPE
  response.analysis.analysisType = response.resultTypes.find(
    (e: any) => e.id == response.analysis.analysisTypeId
  );

  // PUT PHONEBOOK INTO ANALYSIS OBJECT
  response.analysis.people = response.people;

  response.analysis.id = new URLSearchParams(window.location.search).get('analysisId');

  return response.analysis;
};

export const getAttachmentTitles = (fids: any, eventId: any, appSession: any) =>
  get(
    config.magasin.urls.api.analysis.getFilesUrl(
      fids,
      appSession.museumId,
      Number.parseInt(eventId)
    ),
    getAccessToken()
  )
    .then(res => res.json())
    // if status message, assume error and return empty array
    .then(resJson => (resJson.status ? [] : resJson));

// the first item in the resultIds array is a result ID that
// corresponds to the main analysis. The rest of the items are
// result IDs that pertain to the associated objects in this
// analysis
export const saveResults = (results: any, newAttachments: any, appSession: any) => {
  const errors = new Array<any>(); // TODO: why does this array have to be inited like this?

  return Promise.all(
    results.map((r: any, i: any) =>
      Promise.all(
        // upload new files per result
        (newAttachments[i] || []).map((a: any) =>
          sendFile(
            config.magasin.urls.api.analysis.addFileToEventUrl(
              appSession.museumId,
              appSession.collectionId,
              r.id
            ),
            a,
            getAccessToken()
          ).then(res => {
            return res
              .json()
              .then(resJson => {
                console.log(res.status);
                console.log(res.statusText);
                if (res.status === 413) {
                  errors.push({
                    message: words.fileSizeError1 + a.name + words.fileSizeError2
                  });
                } else if (res.status >= 400) {
                  // TODO: the server should return a translateable
                  // string so the the user can see the error message
                  // in Norwegian or English
                  console.error(res.status);
                  console.error(res.statusText);
                  console.error(resJson.message);
                  errors.push(resJson);
                }
                return resJson;
              })
              .catch(e => {
                // some error responses are not in JSON and so must be
                // hand-rolled here
                if (res.status === 413)
                  errors.push({
                    message: words.fileSizeError1 + a.name + words.fileSizeError2
                  });
              });
          })
        )
      )
    )
  )
    .then((newAttachmentIDs: any) =>
      results.map((r: any, i: any) => {
        // having recieved attachment IDs from API- assign these IDs to
        // the relevant result
        // If there has been an error, use filter() to take away that
        // id placeholder
        r.attachments = r.attachments.concat(
          newAttachmentIDs[i].filter((i: any) => i).map((a: any) => a.fid)
        );
        return r;
      })
    )
    .then((resultsWithAttachments: any) =>
      Promise.all(
        // Reupload the results with the new attachment ids
        resultsWithAttachments.map((r: any) =>
          post(
            config.magasin.urls.api.analysis.saveResult(appSession.museumId, r.id),
            JSON.stringify(r),
            getAccessToken()
          )
        )
      )
    )
    .then(() => errors);
};

export const postResult = async (
  postUrl: string,
  analysisFiles: ExternalFiles,
  attachments: Attachment[]
) => {
  const attachmentsFids =
    attachments && attachments.length > 0
      ? { attachments: attachments.map(a => a.fid) }
      : [];
  const postJson = {
    type: 'GenericResult',
    extRef:
      analysisFiles.extRef && Array.isArray(analysisFiles.extRef)
        ? analysisFiles.extRef
        : [analysisFiles.extRef],
    comment: analysisFiles.comment,
    ...attachmentsFids
  };
  // tslint:disable-next-line: no-floating-promises
  await post(postUrl, JSON.stringify(postJson), getAccessToken()).then(res => {});
};

export const queryAnalysisAPI = async (params: any) =>
  getAnalysisFromAPI(params).then(processAPIResponse);

const removeUndefined = (newAnalysis: any) => ({
  ...newAnalysis,
  orgId: newAnalysis.orgId === 'undefined' ? undefined : newAnalysis.orgId,
  status: newAnalysis.status === 'undefined' ? undefined : newAnalysis.status,
  reason: newAnalysis.reason === 'undefined' ? undefined : newAnalysis.reason
});

function fixAnalysisCaseNumber(analysis: any): any {
  if (analysis.caseNumber) {
    analysis.caseNumbers = [analysis.caseNumber];
    delete analysis.caseNumber;
  }
  return analysis;
}

export const postUpdatedAnalysis = async (
  updatedAnalysis: any,
  results: any,
  newAttachments: any,
  setErrors: any,
  history: any,
  appSession: any
) =>
  postAnalysis(
    // Stripping information about who registered the result so that API
    // doesn't throw a wobbly
    results.map((r: any) => {
      delete r.registeredBy;
      return r;
    }),
    newAttachments,
    setErrors,
    history,
    appSession,
    put(
      // <- note that this is a PUT
      config.magasin.urls.api.analysis.saveAnalysisEvent(
        appSession.museumId,
        updatedAnalysis.id
      ),
      JSON.stringify(removeUndefined(fixAnalysisCaseNumber(updatedAnalysis))),
      getAccessToken()
    )
  );

export const postNewAnalysis = async (
  newAnalysis: any,
  results: any,
  newAttachments: any,
  setErrors: any,
  history: any,
  appSession: any
) =>
  postAnalysis(
    results,
    newAttachments,
    setErrors,
    history,
    appSession,
    post(
      // <- note that this is a POST
      config.magasin.urls.api.analysis.saveAnalysisEvent(appSession.museumId),
      JSON.stringify(removeUndefined(fixAnalysisCaseNumber(newAnalysis))),
      getAccessToken()
    )
  );

export const postAnalysis = async (
  //  newAnalysis: any,
  results: any,
  newAttachments: any,
  setErrors: any,
  history: any,
  appSession: any,
  apiCall: any
) => {
  // submit the analysis so that the backend can assign IDs to the
  // analysis events/results
  const response = await apiCall;
  const res = await response.json();
  const resultIds = [res.id].concat(res.events.map((ev: any) => ev.id));

  const errors = await saveResults(
    results.map((r: any, i: any) =>
      Object.assign(
        {
          // API expects these defaults
          id: resultIds[i],
          attachments: [],
          comment: null,
          extRef: []
        },
        r
      )
    ),
    newAttachments,
    appSession
  );

  // if errors, don't move to next page, instead display errors
  if (errors.length) {
    setErrors(errors);
  } else {
    if (history) {
      history.push(config.magasin.urls.client.analysis.viewAnalysis(appSession, res.id));
    }
  }
};
