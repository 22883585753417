import * as React from 'react';
import { I18n } from 'react-i18nify';
import { withRouter } from 'react-router-dom';
import { uniqBy } from 'lodash';
import CollectingEventComponent from './CollectingEventComponent';
import { post, get } from '../../../shared/ajaxFetch/ajaxFetch';
import config from '../../../config';
import { AppSessionContext } from '../../app/AppComponent';
import { MuseumObjectUuid } from '../types/museumObject/museumObject';
import {
  ActorAndRelation,
  DateAttributes,
  MuseumObjectAndRelation,
  EventRevision
} from '../types/event/event';
import {
  CollectingEventEventAttributes,
  RoleTypeId,
  EventTypeId
} from '../types/event/eventTypes_auto';
import { OutPlace as Place } from '../types/place/place';
import { MuseumObjectSearchResult } from '../types/museumObject/museumObject';
import {
  ValidationFields,
  CollectingEvent,
  CollectingEventHeader
} from '../types/frontend/collectingEventForm';
import {
  UpdateEventDateFunc,
  UpdateEventFunc,
  UpdatePersonsFunc,
  IsChangedType,
  createJson,
  initialChangeState,
  canSave,
  createEventHeaderData,
  initialTouchedState,
  SetValidateStateFunc
} from './CollectingEventContainer';
import { emitSuccess, emitError } from '../../../shared/errors';
import { AppSession } from '../../../types/appSession';

const EditNewCollectingEventContainer = withRouter(({ match, history }) => {
  const postUrl = config.api.collectingEvent.editEventNew;
  const getUrl = config.api.collectingEvent.getEvent;
  const personsData: ActorAndRelation[] = [];

  const [dateData, changeDateState]: [
    DateAttributes,
    UpdateEventDateFunc
  ] = React.useState<DateAttributes>({});

  const appSession = React.useContext(AppSessionContext);
  const eventHeaderData = createEventHeaderData(appSession);

  React.useEffect(() => {
    const fetchData = async () => {
      const result: Response = await get(getUrl(match.params.id), appSession.accessToken);
      const data = await result.json();
      if (data.date) {
        changeDateState(data.date);
      }
      if (data.attributes) {
        changeMetaDataState(data.attributes);
      }
      if (data.place) {
        changePlaceDataState(data.place);
      }
      if (data.relatedActors) {
        changePersonDataState(data.relatedActors);
      }
      if (data.relatedMuseumObjects) {
        await fetchObjectDataAndDisplay(data.relatedMuseumObjects);
        changeRelatedMuseumObjects(data.relatedMuseumObjects);
      }
    };
    // tslint:disable-next-line:no-floating-promises
    fetchData();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);
  //TO scroll to top when navigating to this

  const fetchObjectDataAndDisplay = async (
    relatedMuseumObjects: MuseumObjectAndRelation[]
  ) => {
    const url = config.api.objects.getPostObjectSearchResult;
    const d = {
      museumObjectUuids: relatedMuseumObjects.map(
        (r: MuseumObjectAndRelation) => r.museumObjectUuid
      )
    };
    const result = await post(url, JSON.stringify(d), appSession.accessToken);
    const json = await result.json();
    const relatedObjects = json;
    changeRelatedFullObjects(relatedObjects || []);
  };

  const getData: (id: any, appSession: AppSession) => Promise<void> = async (
    id,
    appSession
  ) => {
    const result: Response = await get(getUrl(id), appSession.accessToken);
    const data = await result.json();

    if (data.date) {
      changeDateState(data.date);
    }
    if (data.attributes) {
      changeMetaDataState(data.attributes);
    }
    if (data.place) {
      changePlaceDataState(data.place);
    }
    if (data.relatedActors) {
      changePersonDataState(data.relatedActors);
    }
    if (data.relatedMuseumObjects) {
      await fetchObjectDataAndDisplay(data.relatedMuseumObjects);
      changeRelatedMuseumObjects(data.relatedMuseumObjects);
    }
  };

  const getCallBack = React.useCallback(getData, [match.params.id, appSession]);

  React.useEffect(() => {
    const fetchData = async () => {
      await getCallBack(match.params.id, appSession);
    };
    // tslint:disable-next-line:no-floating-promises
    fetchData();
  }, [getCallBack, appSession, match.params]);

  const getRelobjUrl = (appSession: AppSession) => (id: string) => {
    return config.magasin.urls.client.object.editObject(appSession, id);
  };

  const gotoUrl = (url: string) => {
    history.push(url);
  };

  const [metaData, changeMetaDataState]: [
    CollectingEventEventAttributes | undefined,
    UpdateEventFunc
  ] = React.useState<CollectingEventEventAttributes>({ name: '' });

  const [relatedMuseumObjects, changeRelatedMuseumObjects] = React.useState<
    MuseumObjectAndRelation[]
  >([]);

  const [relatedFullObjects, changeRelatedFullObjects] = React.useState<
    MuseumObjectSearchResult[]
  >([]);

  const [personData, changePersonDataState]: [
    ActorAndRelation[],
    UpdatePersonsFunc
  ] = React.useState<ActorAndRelation[]>(personsData);
  const [placeData, changePlaceDataState] = React.useState<Place>({});

  const addRelatedObjects = async (objects: MuseumObjectUuid[]) => {
    const relatedObjectsToAdd: MuseumObjectAndRelation[] = objects.map(o => ({
      museumObjectUuid: o,
      roleId: RoleTypeId.museumObjectFor
    }));

    const newRelatedObjectList = [...relatedMuseumObjects, ...relatedObjectsToAdd];
    const uniqRelatedObjectList = uniqBy(newRelatedObjectList, 'museumObjectUuid');
    changeRelatedMuseumObjects(uniqRelatedObjectList);

    await fetchObjectDataAndDisplay(uniqRelatedObjectList);
    updateIsChangedState({ ...isChangedData, objects: { isChanged: true } });
  };

  const saveFunction = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const postJson: CollectingEvent = createJson(
      { ...eventHeaderData, revisionOf: match.params.id },
      dateData,
      metaData,
      personData,
      placeData,
      isChangedData,
      undefined,
      undefined,
      relatedMuseumObjects
    );
    const json = JSON.stringify(postJson);
    try {
      const response = await post(postUrl(match.params.id), json, appSession.accessToken);

      //everything is ok
      const saveEvent = {
        eventTypeId: 'saveSuccess',
        message: I18n.t('musit.texts.saveConfirmed')
      };

      if (response.ok) {
        emitSuccess(saveEvent);
        updateIsChangedState(initialChangeState);
      } else {
        emitError({
          eventTypeId: 'errorOnSave',
          message: I18n.t('musit.errorMainMessages.saveError')
        });
      }
    } catch (e) {
      emitError({
        eventTypeId: 'errorOnSave',
        message: I18n.t('musit.errorMainMessages.saveError')
      });
    }
  };

  const [isChangedData, updateIsChangedState] = React.useState<IsChangedType>(
    initialChangeState
  );

  const changeDateObserved = (e: DateAttributes) => {
    updateIsChangedState({ ...isChangedData, date: { isChanged: true } });
    changeDateState(e);
  };
  const changeMetaDataObserved = (e: CollectingEventEventAttributes) => {
    updateIsChangedState({ ...isChangedData, metaData: { isChanged: true } });
    changeMetaDataState(e);
  };
  const changePersonDataObserved = (p: ActorAndRelation[]) => {
    updateIsChangedState({ ...isChangedData, persons: { isChanged: true } });
    changePersonDataState(p);
  };
  const changePlaceDataObserved = (p: Place) => {
    updateIsChangedState({ ...isChangedData, place: { isChanged: true } });
    const admPlaceUuid = p.admPlace ? { admPlaceUuid: p.admPlace.admPlaceUuid } : null;
    changePlaceDataState({ ...p, ...admPlaceUuid });
  };

  const [validationState, changeValidationState]: [
    ValidationFields,
    SetValidateStateFunc
  ] = React.useState<ValidationFields>(initialTouchedState);

  const changeValidationStateField = (fieldName: string) => (value: boolean) => {
    changeValidationState({ ...validationState, [fieldName]: value });
  };

  const saveCollectingEventCopyAndCreateNew = (
    eventHeaderData: CollectingEventHeader,
    dateData: DateAttributes,
    attributes: CollectingEventEventAttributes,
    place: Place,
    relatedActors: ActorAndRelation[],
    relatedObjects: MuseumObjectAndRelation[]
  ) => async (museumObjectUuid: MuseumObjectUuid) => {
    const url = config.magasin.urls.client.collectingEvent.addnew(appSession);

    const collectingEventCopy: CollectingEvent = {
      ...eventHeaderData,
      eventUuid: undefined,
      attributes: { ...attributes, name: '' },
      date: dateData,
      place: {
        ...place,
        admPlaceUuid: place.admPlace ? place.admPlace.admPlaceUuid : place.admPlaceUuid
      },
      relatedActors,
      relatedMuseumObjects: [
        { museumObjectUuid: museumObjectUuid, roleId: RoleTypeId.museumObjectFor }
      ]
    };
    localStorage.setItem('collectingEventCopy', JSON.stringify(collectingEventCopy));
    const removeMuseumObjecJSON: EventRevision = {
      eventTypeId: EventTypeId.CollectingEvent,
      revisionOf: eventHeaderData.eventUuid!,
      relatedMuseumObjects: relatedObjects.filter(
        o => o.museumObjectUuid !== museumObjectUuid
      )
    };
    const deleteURL = config.api.collectingEvent.editEventNew(eventHeaderData.eventUuid!);

    await post(deleteURL, JSON.stringify(removeMuseumObjecJSON), appSession.accessToken);
    gotoUrl(url);
  };

  return (
    <CollectingEventComponent
      formHaveChanged={canSave(isChangedData, metaData && metaData.name)}
      eventHeaderData={eventHeaderData}
      dateData={dateData}
      changeDateState={changeDateObserved}
      metaData={metaData}
      changeMetaDataState={changeMetaDataObserved}
      personData={personData}
      changePersonDataState={changePersonDataObserved}
      changeRelatedMuseumObjects={changeRelatedMuseumObjects}
      placeData={placeData}
      changePlaceDataState={changePlaceDataObserved}
      addRelatedMuseumObjects={addRelatedObjects}
      saveFunction={saveFunction}
      backFunction={history.goBack}
      validationFileds={validationState}
      relatedObjects={relatedFullObjects}
      changeValidationStateField={changeValidationStateField}
      relatedMuseumObjects={relatedMuseumObjects}
      getUrl={getRelobjUrl(appSession)}
      gotoUrl={gotoUrl}
      saveCollectingEventCopyAndCreateNew={saveCollectingEventCopyAndCreateNew(
        { ...eventHeaderData, eventUuid: match.params.id },
        dateData,
        metaData,
        placeData,
        personData,
        relatedMuseumObjects
      )}
    />
  );
});

export default EditNewCollectingEventContainer;
