import * as React from 'react';
import './AboutPage.css';
import { I18n } from 'react-i18nify';
import NorwegianTranslation from './AboutPage_no.html';
import EnglishTranslation from './AboutPage_en.html';
import Logos from '../../components/logos/Logos';
import { RxInjectLegacy as inject } from '../../shared/react-rxjs-patch';
import { TODO } from '../../types/common';

export const AboutPage = (props: TODO) => {
  const Translated =
    props.getLocale() === 'no' ? NorwegianTranslation : EnglishTranslation;
  return (
    <div>
      <main className="d-flex mt-5">
        <div className="container">
          <div className="row-centered">
            <div className="aboutPanel">
              <div>
                <Translated {...props} />
                <Logos />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default inject({}, {}, { getLocale: () => I18n._locale })(AboutPage);
