import * as React from 'react';
import { ExternalID } from '../types/person/personName';
import { dataBaseValues } from './data';
import { I18n } from 'react-i18nify';

type Props = {
  onChange: (e: ExternalID[]) => void;
  externalIds: ExternalID[];
};

const initial = { database: dataBaseValues[0].value, uuid: '' };
const ExternalIds = (props: Props) => {
  const [external, changeExternal] = React.useState<ExternalID>(initial);

  const changeValue = (field: string) => (value: string) =>
    changeExternal((p: ExternalID) => ({ ...p, [field]: value }));

  const deleteId = (i: number) => {
    const newExtIds = [
      ...props.externalIds.slice(0, i),
      ...props.externalIds.slice(i + 1)
    ];
    props.onChange(newExtIds);
  };

  return (
    <div className="container-fluid">
      <div
        className="form-row"
        style={{
          fontWeight: 'bold',
          paddingBottom: '0px'
        }}
      >
        <div className="col-md-3">{I18n.t('musit.objectModule.person.database')}</div>
        <div className="col-md-7">{I18n.t('musit.objectModule.person.uuid')}</div>
        <div className="col-md-2">{I18n.t('musit.objectModule.person.action')}</div>
      </div>
      <hr />
      {props.externalIds.map((s: ExternalID, i: number) => (
        <div
          className="form-row"
          style={{
            lineHeight: 1
          }}
        >
          <div className="col-md-3">{s.database}</div>
          <div className="col-md-6">{s.uuid}</div>
          <div className="col-md-3">
            <button
              type="button"
              className="btn btn-link"
              onClick={e => {
                e.preventDefault();
                deleteId(i);
              }}
            >
              {I18n.t('musit.texts.delete')}
            </button>
          </div>
        </div>
      ))}

      <div className="form-row">
        <div className="col-md-3">
          <select
            className="form-control"
            value={external.database}
            onChange={(v: React.ChangeEvent<HTMLSelectElement>) =>
              changeValue('database')(v.target.value)
            }
          >
            {dataBaseValues.map((m, i) => (
              <option key={`ext-opt-${i}`} value={m.value}>
                {m.label}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-7">
          <input
            type="text"
            className="form-control"
            value={external ? external.uuid : ''}
            onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
              changeValue('uuid')(v.target.value);
            }}
          />
        </div>
        <div className="col-md-2">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              props.onChange([...props.externalIds, external]);
              changeExternal(initial);
            }}
          >
            {I18n.t('musit.texts.add')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExternalIds;
