import * as React from 'react';
import { I18n } from 'react-i18nify';

interface SaveCancelProps {
  id?: string;
  saveLabel?: string;
  saveDisabled?: boolean;
  onClickSave: React.MouseEventHandler<HTMLButtonElement>;
  cancelLabel?: string;
  cancelDisabled?: boolean;
  onClickCancel: React.MouseEventHandler<HTMLButtonElement>;
  translate?: Function;
}
/* Old:
SaveCancel.propTypes = {
  id: PropTypes.string,
  saveLabel: PropTypes.string,
  saveDisabled: PropTypes.bool,
  onClickSave: PropTypes.func,
  cancelLabel: PropTypes.string,
  cancelDisabled: PropTypes.bool,
  onClickCancel: PropTypes.func
};
*/

const SaveCancel: React.SFC<SaveCancelProps> = props => (
  <div className="container">
    <div className="row">
      <div
        className="col-md-3 col-sm-5 col-6 md-offset-3"
        style={{ border: 'none', textAlign: 'center' }}
      >
        <button
          id={`Save_${props.id || 1}`}
          onClick={props.onClickSave}
          className="btn btn-primary"
          type="submit"
          disabled={props.saveDisabled}
        >
          {props.saveLabel || I18n.t('musit.texts.save')}
        </button>
      </div>
      <div
        className="col-md-3 col-sm-5 col-6"
        style={{ border: 'none', textAlign: 'center' }}
      >
        <button
          id={`Cancel_${props.id || 1}`}
          onClick={props.onClickCancel}
          className="btn btn-link"
          type="button"
          disabled={props.cancelDisabled}
        >
          {props.cancelLabel || I18n.t('musit.texts.cancel')}
        </button>
      </div>
    </div>
  </div>
);

SaveCancel.displayName = 'SaveCancel';

export default SaveCancel;
