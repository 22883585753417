// @flow
import * as React from 'react';
import { I18n } from 'react-i18nify';
import { SampleData } from '../../types/samples';
import { AppSession } from '../../types/appSession';
import { History } from 'history';
import { FormDetails } from './types/form';
import { ObjectData } from '../../types/object';
import MetaInformation from '../../components/metainfo';
import ReadOnlySampleType from './components/ReadOnlySampleType';
import ViewPersonRoleDate from '../../components/person/ViewPersonRoleDate';
import ObjectAndSampleDetails from './components/ObjectAndSampleDetails';
import { mixed, Maybe, TODO } from '../../types/common';

type Props = {
  form: FormDetails;
  appSession: AppSession;
  objectData: ObjectData & SampleData;
  sampleStore: { sample: SampleData };
  objectStore: { objectData: ObjectData };
};

type ClickEventReturn = (e: { preventDefault: Function }) => void;

export type SampleProps = {
  statusText: Maybe<string>;
  sampleSubType: Maybe<string>;
  sampleType: Maybe<string>;
  persons: Array<any>;
  clickEditSample: (
    appSession: AppSession,
    sampleId: string,
    object: ObjectData
  ) => ClickEventReturn;
  clickCreateAnalysis: (
    appSession: AppSession,
    sample: SampleData,
    form: FormDetails,
    object: mixed
  ) => ClickEventReturn;
  clickCreateSample: (
    appSession: AppSession,
    sample: SampleData,
    form: FormDetails,
    object: mixed
  ) => ClickEventReturn;
  goBack: () => void;
  history: History;
};

export default function SampleViewComponent(props: Props & SampleProps) {
  const sample = props.sampleStore ? props.sampleStore.sample : null;
  const objectData = props.objectStore ? props.objectStore.objectData : null;
  if (!sample || !objectData) {
    return <div className="loading" />;
  }
  const derivedFrom: any = sample.parentObject.sampleOrObjectData || {};
  return (
    <div className="container" style={{ marginTop: 100 }}>
      <form className="form-horizontal">
        <h1>{I18n.t('musit.sample.sample')}</h1>

        {props.appSession.rolesForModules.collectionManagementWrite && (
          <div>
            <div className="row justify-content-end my-2">
              <button
                type="button"
                className="btn btn-light"
                onClick={props.clickEditSample as TODO}
              >
                {I18n.t('musit.sample.updateSample')}
              </button>
            </div>
            <div className="row justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={props.clickCreateAnalysis as TODO}
              >
                {I18n.t('musit.analysis.createAnalysis')}
              </button>
              <button
                type="button"
                className="btn btn-light"
                onClick={props.clickCreateSample as TODO}
              >
                {` ${I18n.t('musit.analysis.createSample')}`}
              </button>
            </div>
          </div>
        )}
        <div className="row">
          <MetaInformation
            updatedBy={sample.updatedStamp ? sample.updatedStamp.name : ''}
            updatedDate={sample.updatedStamp ? sample.updatedStamp.date : ''}
            registeredBy={sample.registeredStamp.name}
            registeredDate={sample.registeredStamp.date}
          />
          <hr />
        </div>
        <h4 className="mt-4">
          {derivedFrom.sampleNum
            ? I18n.t('musit.sample.derivedFromObjectAndSample')
            : I18n.t('musit.sample.derivedFromObject')}
        </h4>
        <ObjectAndSampleDetails
          appSession={props.appSession}
          history={props.history}
          objectData={{ ...objectData, derivedFrom }}
        />
        <hr />
        <div className="row py-3">
          {' '}
          <h4>{I18n.t('musit.sample.personsAssociatedWithSampleTaking')}</h4>
          <ViewPersonRoleDate
            personData={props.persons}
            getDisplayNameForRole={(roleName: string) =>
              I18n.t(`musit.sample.roles.${roleName}`)
            }
          />
        </div>
        <div className="form-row">
          <label className="col-form-label col-md-2">
            <b>{I18n.t('musit.sample.sampleNumber')}</b>
          </label>
          <div className="col-md-3">
            <p className="readonly form-control-plaintext">{sample.sampleNum}</p>
          </div>
        </div>

        <div className="form-row">
          <label className="col-form-label col-md-2">
            <b> {I18n.t('musit.sample.sampleId')}</b>
          </label>
          <div className="col-md-5">
            <input
              style={{ textOverflow: 'ellipsis' }}
              className="form-control-plaintext"
              readOnly
              value={sample.sampleId ? sample.sampleId : ''}
            />
          </div>
        </div>

        <div className="form-row align-items-start py-4">
          <label className="col-form-label col-md-2 d-flex align-self-end">
            <b> {I18n.t('musit.sample.externalId')}</b>
          </label>
          <div className="col-md-5">
            <label className="col-form-label">
              <b> {I18n.t('musit.sample.externalIdValue')}</b>
            </label>{' '}
            <br />
            <input
              className="form-control-plaintext"
              value={sample.externalId ? sample.externalId.value : ''}
              style={{ textOverflow: 'ellipsis' }}
              readOnly
            />
          </div>
          <div className="col-md-5">
            <label className="col-form-label">
              <b> {I18n.t('musit.sample.externalIdSource')}</b>
            </label>
            <br />
            <input
              className="form-control-plaintext"
              value={sample.externalId ? sample.externalId.source : ''}
              style={{ textOverflow: 'ellipsis' }}
              readOnly
            />
          </div>
        </div>

        <ReadOnlySampleType
          sampleType={props.sampleType}
          subTypeValue={props.sampleSubType}
        />
        <div className="form-row">
          <label className="col-form-label col-md-2">
            <b>{I18n.t('musit.sample.description')}</b>
          </label>
          <div className="col-md-8">
            <p className="form-control-plaintext">{sample.description}</p>
          </div>
        </div>
        <div className="form-row">
          <label className="col-form-label col-md-2">
            <b> {I18n.t('musit.sample.status')}</b>
          </label>
          <div className="col-md-2">
            <p className="form-control-plaintext">{props.statusText}</p>
          </div>
        </div>
        <div className="form-row">
          <label className="col-form-label col-md-2">
            <b>{I18n.t('musit.sample.volumeOrWeight')}</b>
          </label>
          <div className="col-md-2">
            <p className="form-control-plaintext">
              {sample.size && sample.size.value + ' ' + sample.size.unit}
            </p>
          </div>
        </div>

        <div className="form-row align-items-start py-4">
          <label className="col-form-label col-md-2 d-flex align-self-end">
            <b> {I18n.t('musit.sample.storage')}</b>
          </label>
          <div className="col-md-3">
            <label className="col-form-label">
              <b> {I18n.t('musit.sample.storageContainer')}</b>
            </label>{' '}
            <br />
            <p className="readonly form-control-plaintext"> {sample.container}</p>
          </div>
          <div className="col-md-3">
            <label className="col-form-label">
              <b> {I18n.t('musit.sample.storageMedium')}</b>
            </label>
            <br />
            <p className="readonly form-control-plaintext"> {sample.storageMedium}</p>
          </div>
        </div>

        <div className="form-row">
          <label className="for-form-label col-md-2">
            <b>{I18n.t('musit.sample.treatment')}</b>
          </label>
          <div className="col-md-2">
            <p className="form-control-plaintext">{sample.treatment}</p>
          </div>
        </div>
        <div className="form-row">
          <label className="control-label col-md-2">
            <b>{I18n.t('musit.sample.hasResidualMaterial')}</b>
          </label>
          <div className="col-md-2">
            <p className="form-control-plaintext">
              {(sample.leftoverSample === 3 && 'Ja') ||
                (sample.leftoverSample === 2 && 'Nei') ||
                ''}
            </p>
          </div>
        </div>
        <div className="form-row">
          <label className="col-form-label col-md-2">
            <b>{I18n.t('musit.sample.comments')}</b>
          </label>
          <div className="col-md-8">
            <p className="form-control-plaintext">{sample.note}</p>
          </div>
        </div>
        <hr />
        <button
          type="button"
          className="btn btn-link"
          style={{ marginLeft: 20 }}
          onClick={props.goBack}
        >
          {I18n.t('musit.texts.cancel')}
        </button>
      </form>
    </div>
  );
}
