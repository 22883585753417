import * as React from 'react';
import EventMetadataComponent from './EventMetadataComponent';
import SimplePersonsComponent, {
  ViewPersonsComponent
} from '../person/SimplePersonsComponent';
import {
  UpdateEventFunc,
  UpdatePersonsFunc,
  UpdatePlaceFunc,
  UpdateEventDateFunc,
  UpdateTouchFunc
} from './CollectingEventContainer';
import EditAndSaveButtons from '../components/EditAndSaveButtons';
import { FuncState } from '../types/frontend/collectingEventForm';
import PlaceComponent from '../place/PlaceComponent';
import PlaceViewComponent from '../place/PlaceViewComponent';
import ObjectSearchComponent from '../objectSearchPG/objectSearchCollectingEvent';
import {
  CollectingEventHeader,
  ValidationFields
} from '../types/frontend/collectingEventForm';
import {
  ActorAndRelation,
  DateAttributes as EventDate,
  MuseumObjectAndRelation
} from '../types/event/event';
import { CollectingEventEventAttributes } from '../types/event/eventTypes_auto';
import { OutPlace as Place } from '../types/place/place';
import { AppSessionContext } from '../../app/AppComponent';
import CollapseComponent from '../components/Collapse';
import { ViewEventMetaData } from './ViewEventMetaData';
import {
  MuseumObjectSearchResult,
  MuseumObjectUuid
} from '../types/museumObject/museumObject';
import { getTranslator } from '../../../shared/language';
import { compareMusNo } from '../utils/compareFunctions';
import './CollectingEventComponent.css';

const words = getTranslator({
  en: {
    addPerson: 'Add person',
    back: 'Back',
    collectingEvent: 'Collecting Event',
    objects: 'Objects',
    people: 'People',
    place: 'Place',
    save: 'Save'
  },
  no: {
    addPerson: 'Legg til person',
    back: 'Tilbake',
    collectingEvent: 'Innsamlinghendelse',
    objects: 'Objekter',
    people: 'Personer',
    place: 'Sted',
    save: 'Lagre'
  }
});

export type EventMetadataProps = {
  relatedObjects?: MuseumObjectSearchResult[];
  eventHeaderData: CollectingEventHeader;
  disabled?: boolean;
  dataState?: CollectingEventEventAttributes;
  changeData: (d: CollectingEventEventAttributes) => void;
  dateState: EventDate;
  changeDate: (d: EventDate) => void;
  funcState: FuncState;
  validationFileds?: ValidationFields;
  changeValidationStateField?: UpdateTouchFunc;
  getUrl?: (id: string) => string;
  gotoUrl?: (url: string) => void;
  saveCollectingEventCopyAndCreateNew?: (
    museumObjectUuid: MuseumObjectUuid
  ) => Promise<void>;
};

const CollectingEventComponent = ({
  eventHeaderData,
  dateData,
  changeDateState,
  metaData,
  changeMetaDataState,
  personData,
  changePersonDataState,
  placeData,
  changePlaceDataState,
  saveFunction,
  backFunction,
  formHaveChanged,
  validationFileds,
  changeValidationStateField,
  addRelatedMuseumObjects,
  getUrl,
  gotoUrl,
  relatedObjects,
  relatedMuseumObjects,
  changeRelatedMuseumObjects,
  saveCollectingEventCopyAndCreateNew
}: {
  eventHeaderData: CollectingEventHeader;
  dateData: EventDate;
  changeDateState: UpdateEventDateFunc;
  metaData: CollectingEventEventAttributes | undefined;
  changeMetaDataState: UpdateEventFunc;
  personData: ActorAndRelation[];
  changePersonDataState: UpdatePersonsFunc;
  placeData: Place;
  changePlaceDataState: UpdatePlaceFunc;
  addRelatedMuseumObjects: (objects: MuseumObjectUuid[]) => void;
  saveFunction: (e: React.MouseEvent<HTMLButtonElement>) => void;
  backFunction: (e: React.MouseEvent<HTMLButtonElement>) => void;
  formHaveChanged: boolean;
  validationFileds: ValidationFields;
  changeValidationStateField: UpdateTouchFunc;
  relatedObjects?: MuseumObjectSearchResult[];
  relatedMuseumObjects?: MuseumObjectAndRelation[];
  getUrl?: (id: string) => string;
  gotoUrl?: (url: string) => void;
  changeRelatedMuseumObjects: React.Dispatch<
    React.SetStateAction<MuseumObjectAndRelation[]>
  >;

  saveCollectingEventCopyAndCreateNew?: (
    museumObjectUuid: MuseumObjectUuid
  ) => Promise<void>;
}) => {
  const buttonRow = saveAndEditButtonRow({
    onClickSave: saveFunction,
    onClickCancel: backFunction,
    readOnly: false,
    disabled: false,
    formHaveChanged: formHaveChanged
  });

  const updateCollectingEventMethod = (methodId: string) => {
    const metaDataNew = Object.assign({}, metaData);
    if (metaDataNew) {
      metaDataNew.methodId = Number.parseFloat(methodId);
      changeMetaDataState(metaDataNew);
    }
  };
  const getMethodeId = () => (metaData ? metaData.methodId : undefined);
  const context = React.useContext(AppSessionContext);
  const eventMetadataComp = (
    <React.Fragment>
      {metaData && (
        <EventMetadataComponent
          getUrl={getUrl}
          gotoUrl={gotoUrl}
          relatedObjects={relatedObjects}
          eventHeaderData={eventHeaderData}
          dataState={metaData}
          changeData={changeMetaDataState}
          dateState={dateData}
          changeDate={changeDateState}
          funcState={{ disabled: false, readOnly: false }}
          validationFileds={validationFileds}
          changeValidationStateField={changeValidationStateField}
          saveCollectingEventCopyAndCreateNew={saveCollectingEventCopyAndCreateNew}
        />
      )}
      {buttonRow}
    </React.Fragment>
  );
  const eventViewMetaDataComp = metaData ? (
    <ViewEventMetaData dataState={metaData} dateState={dateData} />
  ) : (
    <React.Fragment />
  );

  const personsComp = metaData ? (
    <React.Fragment>
      <SimplePersonsComponent
        id={'collectingEventComp'}
        personData={personData}
        personLabel={words.addPerson}
        eventMetadata={metaData}
        changePersonDataState={changePersonDataState}
        changeEventMetadataState={changeMetaDataState}
      />
      {buttonRow}
    </React.Fragment>
  ) : (
    <React.Fragment />
  );
  const personsViewComp = ViewPersonsComponent({
    personList: personData,
    eventMetadata: metaData
  });
  const placeComp = (
    <React.Fragment>
      <PlaceComponent
        place={placeData}
        updatePlace={changePlaceDataState}
        methodId={getMethodeId()} //Because one of the fields is part of event metadata
        updateMethodId={updateCollectingEventMethod} //Because one of the fields is part of event metadata
      />
      {buttonRow}
    </React.Fragment>
  );
  const placeViewComp = (
    <PlaceViewComponent
      place={placeData}
      methodId={getMethodeId()} //Because one of the fields is part of event metadata
    />
  );

  const displayStructureComp = (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="form-group">
            <ul>
              {relatedObjects &&
                relatedObjects
                  .sort((m1, m2) =>
                    compareMusNo(
                      m1.mnr + '/' + m1.subNo || '1000000000',
                      m2.mnr + '/' + m2.subNo || '1000000000'
                    )
                  )
                  .map((o: MuseumObjectSearchResult, i) => (
                    <li key={`key-${i}`}>
                      <div
                        className="divAsLink"
                        onClick={a => {
                          a.preventDefault();
                          if (gotoUrl && getUrl && o.museumObjectUuid) {
                            gotoUrl(getUrl(o.museumObjectUuid));
                          }
                        }}
                      >
                        <p>
                          {o.mnr}
                          {o.subNo ? '/' + o.subNo : null}
                        </p>
                      </div>
                    </li>
                  ))}
            </ul>
          </div>
        </div>
        <ObjectSearchComponent
          addRelatedMuseumObjects={addRelatedMuseumObjects}
          appSession={context}
          relatedMuseumObjects={relatedMuseumObjects}
          changeRelatedMuseumObjects={changeRelatedMuseumObjects}
        />
      </div>
    </React.Fragment>
  );

  return (
    <div className="container" style={{ marginTop: 90 }}>
      <div className="panel-body" style={{ backgroundColor: '#f6f6f2' }}>
        <CollapseComponent
          heading={words.collectingEvent}
          Head={eventViewMetaDataComp}
          Body={eventMetadataComp}
          showHead={true}
        />
        <br />
        <CollapseComponent
          heading={words.people}
          Head={personsViewComp}
          Body={personsComp}
          showHead={true}
        />
        <br />
        <CollapseComponent
          heading={words.place}
          Head={placeViewComp}
          Body={placeComp}
          showHead={true}
        />
        <br />
        <CollapseComponent
          heading={words.objects}
          Head={<p />}
          Body={displayStructureComp}
          showHead={true}
        />
      </div>
    </div>
  );
};

type ButtonRowPropType = {
  onClickSave: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClickCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
  readOnly: boolean;
  disabled: boolean;
  formHaveChanged: boolean;
};

const saveAndEditButtonRow = (props: ButtonRowPropType) => (
  <div className="row">
    <EditAndSaveButtons
      onClickCancel={props.onClickCancel}
      onClickEdit={() => {}}
      onClickSave={props.onClickSave}
      editButtonState={{ visible: false, disabled: true }}
      saveButtonState={{
        visible: true,
        disabled: !props.formHaveChanged || props.disabled
      }}
      cancelButtonState={{ visible: true, disabled: false }}
      cancelButtonText={words.back}
      saveButtonText={words.save}
    />
  </div>
);

export default CollectingEventComponent;
