import * as React from 'react';
import { I18n } from 'react-i18nify';
import DatePicker from '../../../components/DatePicker';

type Props = {
  onChangeText: (fieldName: string) => (value: string) => void;
  bornDate?: string;
  deathDate?: string;
  verbatimDate?: string;
  id: string;
};

const PersonDate = (props: Props) => {
  return (
    <React.Fragment>
      <div className="col-md-auto">
        <label htmlFor="bornDate"> {I18n.t('musit.objectModule.person.birthDate')}</label>
        <br />
        <DatePicker
          id={`bornDate-${props.id}`}
          className="form-control"
          onClear={() => props.onChangeText('bornDate')('')}
          onChange={props.onChangeText('bornDate')}
          value={props.bornDate}
        />
      </div>
      <div className="col-md-auto">
        <label htmlFor="deathDate">{I18n.t('musit.objectModule.person.deathDate')}</label>
        <br />
        <DatePicker
          className="form-control"
          id={`deathDate-${props.id}`}
          onClear={() => props.onChangeText('deathDate')('')}
          onChange={props.onChangeText('deathDate')}
          value={props.deathDate}
        />
      </div>

      <div className="col-md-auto">
        <label htmlFor="verbatimDate">
          {I18n.t('musit.objectModule.shared.verbatimDate')}
        </label>
        <input
          id={`verbatimDate-${props.id}`}
          className="form-control"
          value={props.verbatimDate || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            props.onChangeText('verbatimDate')(e.target.value)
          }
        />
      </div>
    </React.Fragment>
  );
};

export default PersonDate;
