import * as React from 'react';
import { ViewEventMetaData } from './ViewEventMetaData';
import ViewPlaceData from '../place/PlaceViewComponent';
import { ViewPersonsComponent } from '../person/SimplePersonsComponent';
import { CollectingEvent } from '../types/event/event';

const ViewCollectingEventComponent = (props: CollectingEvent) => {
  return (
    <div className="component-fluid">
      {props.attributes && props.date && (
        <ViewEventMetaData dataState={props.attributes} dateState={props.date} />
      )}
      <ViewPersonsComponent
        personList={
          props.relatedActors
            ? props.relatedActors.map(p => ({ ...p, actorUuid: p.actorUuid || 'xx' }))
            : []
        }
        eventMetadata={props.attributes}
        label="Leg"
      />
      {props.place && (
        <ViewPlaceData
          place={props.place}
          methodId={props.attributes && props.attributes.methodId}
        />
      )}
    </div>
  );
};

export default ViewCollectingEventComponent;
