import * as React from 'react';
import ObjectComponent from './ObjectComponent';
import { collections, museum } from '../constants/data';

import { AppSessionContext } from '../../app/AppComponent';
import { TaxonEvent } from '../taxon/taxonLib';
import { CollectingEvent, NextMuseumNoAndSubNo } from '../types/event/event';
import { InputMuseumObject } from '../types/museumObject/museumObject';
import { SexAndStagesEvent } from '../types/event/event';
import { AppSession } from '../../../../src/types/appSession';
import { AdmProps } from './AdminComponent';
import { I18n } from 'react-i18nify';
import { withRouter } from 'react-router-dom';
import { Person } from '../types/person/personName';
import { post, get } from '../../../shared/ajaxFetch/ajaxFetch';
import config from '../../../config';
import { emitSuccess, emitError } from '../../../shared/errors';
import * as H from 'history';
import {
  createJson,
  createObjectHeaderData,
  IsAddedType,
  ChangeResult,
  createEmptySexAndStagesEvent,
  createObjectEventHeaderData,
  IsChangedType,
  getCollection,
  ObjectChangeStatus,
  taxonEventToInputEvent
} from './ObjectContainerFunctions';
import { EventTypeId } from '../types/event/eventTypes_auto';
import { MusitEvent } from '../types/event/event';

import { Uuid } from '../../../../src/types/common';
import uuidv1 from 'uuid/v1';

const getCollectionShortName = (appSession: AppSession) => {
  const coll = collections.find(c => c.collectionUUID === appSession.collectionId);
  return coll ? coll.collectionName : '';
};

export const getMuseumShortName = (appSession: AppSession) => {
  const coll = museum.find(c => c.museumId === appSession.museumId);
  return coll ? coll.abbreviation : '';
};

export const createMuseumObject = async (
  appSession: AppSession,
  history: H.History,
  museumNoAttributes: NextMuseumNoAndSubNo,
  subCollectionId?: number
) => {
  const selectedCollection = getCollection(appSession.collectionId);
  const museumObjectHeader = createObjectHeaderData(
    appSession,
    selectedCollection ? selectedCollection.objectType : -1,
    uuidv1()
  );

  const museumObject = {
    ...museumObjectHeader,
    subCollectionId: subCollectionId,
    attributes: {
      uuids: []
    },
    subObjects: [],
    events: []
  };
  const objectChangeStatus = ObjectChangeStatus.notAdded;
  const sexAndStages = createEmptySexAndStagesEvent(
    createObjectEventHeaderData(appSession, EventTypeId.SexAndStage)
  );
  const emptyMusNoEvent = createObjectEventHeaderData(
    appSession,
    EventTypeId.MuseumNoAssignment
  );
  const musNoEvent = { ...emptyMusNoEvent, attributes: { ...museumNoAttributes } };
  const genericEditEvents: MusitEvent[] = [musNoEvent];

  const postUrl = config.api.objects.addMuseumObjectUrl;
  const postJson: InputMuseumObject = createJson(
    objectChangeStatus,
    appSession,
    museumObject,
    genericEditEvents,
    [],
    sexAndStages,
    {},
    { [EventTypeId.MuseumNoAssignment]: true },
    {},
    undefined,
    undefined
  );
  await callPostUrl(history, appSession, postUrl, JSON.stringify(postJson));
};

const ObjectContainerCreate = withRouter(({ history }) => {
  const appSession = React.useContext(AppSessionContext);
  const selectedCollection = getCollection(appSession.collectionId);
  const museumObjectHeader = createObjectHeaderData(
    appSession,
    selectedCollection ? selectedCollection.objectType : -1,
    uuidv1()
  );
  const objectChangeStatus = ObjectChangeStatus.notAdded;

  const [taxonEvents, changeTaxonEvents] = React.useState<TaxonEvent[]>([]);
  const [museumObject, changeMuseumObject] = React.useState<InputMuseumObject>({
    ...museumObjectHeader,
    attributes: {
      museumNo: { collection: getCollectionShortName(appSession) },
      uuids: []
    },
    subObjects: [],
    events: []
  });
  const [genericEditEvents, changeGenericEditEvents] = React.useState<MusitEvent[]>([]);
  const emptyAdmProps: AdmProps = {
    regBy: '',
    regDate: '',
    lastChangeBy: '',
    lastChangedDate: '',
    registrationStatuses: [],
    publicationStatuses: [],
    changeStatus: { registrationStatusChanged: false, publicationStatusChanged: false },
    genericEvents: [],
    appSession: appSession,
    changeGenericEvents: () => '',
    registerChangeEventId: () => ''
  };

  // new Collecting event to be added
  const [newCollectingEvent, changeNewCollectingEvent] = React.useState<
    CollectingEvent | undefined
  >(undefined);

  //Current collecting event
  const [collectingEventData, changeCollectingEventData] = React.useState<
    CollectingEvent | undefined
  >(undefined);

  const [sexAndStages, changeSexAndStages] = React.useState<SexAndStagesEvent>(
    createEmptySexAndStagesEvent(
      createObjectEventHeaderData(appSession, EventTypeId.SexAndStage)
    )
  );

  const [collectingEventSearchResult, changeCollectingEventSearchResult] = React.useState<
    MusitEvent[]
  >([]);
  const [isAddedData, updateIsAddedState] = React.useState<IsAddedType>({});
  const [isChangedData, updateIsChangedState] = React.useState<IsChangedType>({});
  const addEventUuid = (eventUuid: string) =>
    updateIsAddedState({ ...isAddedData, [eventUuid]: true });
  const changeEventUuid = (eventUuid: Uuid): ChangeResult => ChangeResult.alreadyAdded;
  const registerChangeEventId = (eventId: number) => {
    updateIsChangedState({ ...isChangedData, [eventId.toString()]: true });
  };

  const onChangeSexAndStages = (sexAndStagesEvent: SexAndStagesEvent) => {
    changeSexAndStages(sexAndStagesEvent);
  };

  const saveFunction = async (collectingEventToSwap?: CollectingEvent) => {
    const postUrl = config.api.objects.addMuseumObjectUrl;
    const postJson: InputMuseumObject = createJson(
      objectChangeStatus,
      appSession,
      museumObject,
      genericEditEvents,
      taxonEventToInputEvent(taxonEvents),
      sexAndStages,
      isAddedData,
      isChangedData,
      {},
      newCollectingEvent,
      collectingEventData
    );
    await callPostUrl(history, appSession, postUrl, JSON.stringify(postJson));
  };

  const searchCollectingEventSearchFunction = async (searchTerm: string) => {
    const getURL = config.api.collectingEvent.collectingEventsSearch(
      appSession.museumId,
      10,
      searchTerm
    );

    const res = await callGetUrl(history, appSession, getURL);
    if (res) {
      changeCollectingEventSearchResult(res);
    }
  };

  const clearSearch = () => changeCollectingEventSearchResult([]);

  const addToCollectingEvent = (collectingEvent: CollectingEvent) => {
    changeNewCollectingEvent(collectingEvent);
  };

  const changeCollectingEvent = (collectingEventData: CollectingEvent) => {
    changeCollectingEventData(collectingEventData);
  };

  return (
    <ObjectComponent
      appSession={appSession}
      updateIsChanged={(e: any) => {}}
      admProps={emptyAdmProps}
      collectingEventData={collectingEventData}
      searchResult={collectingEventSearchResult}
      museumObject={museumObject}
      clearSearch={clearSearch}
      allEvents={[]}
      admStatuses={{ pubStatuses: [], regStatuses: [] }}
      changeMuseumObject={changeMuseumObject}
      taxonEvents={taxonEvents}
      changeTaxonEvents={changeTaxonEvents}
      genericEvents={genericEditEvents}
      changeGenericEditEvents={changeGenericEditEvents}
      registerChangeEventId={registerChangeEventId}
      sexAndStagesEvent={sexAndStages}
      changeSexAndStagesEvent={onChangeSexAndStages}
      addEventUuid={addEventUuid}
      changeEventUuid={changeEventUuid}
      deleteEventUuid={() => {}}
      saveFunction={saveFunction}
      searchFunction={searchCollectingEventSearchFunction}
      addCollectingEvent={addToCollectingEvent}
      changeCollectingEvent={changeCollectingEvent}
      registrationStatus={''}
      dissiminationStatus={''}
      history={history}
    />
  );
});

export const callGetActor: (
  appSession: AppSession,
  url: string
) => Promise<Person> | undefined = async (appSession: AppSession, url: string) => {
  try {
    const response: Response = await get(url, appSession.accessToken);
    return await response.json();
  } catch (e) {
    return undefined;
  }
};

export const callGetUrl = async (
  history: H.History,
  appSession: AppSession,
  getUrl: string
) => {
  try {
    const response = await get(getUrl, appSession.accessToken);
    const res: MusitEvent[] = response.ok ? await response.json() : undefined;

    if (res) {
      return res;
    } else {
      emitError({
        eventTypeId: 'errorOnSearch',
        message: I18n.t('musit.errorMainMessages.searchError')
      });
      return undefined;
    }
  } catch (e) {
    emitError({
      eventTypeId: 'errorOnSearch',
      message: I18n.t('musit.errorMainMessages.searchError xx')
    });
    return undefined;
  }
};

const callPostUrl = async (
  history: H.History,
  appSession: AppSession,
  postUrl: string,
  postJson: string
) => {
  try {
    const response = await post(postUrl, postJson, appSession.accessToken);

    //everything is ok
    const saveEvent = {
      eventTypeId: 'saveSuccess',
      message: I18n.t('musit.texts.saveConfirmed')
    };

    const res: InputMuseumObject = response.ok ? await response.json() : undefined;

    if (res && res.museumObjectUuid) {
      emitSuccess(saveEvent);
      const url = config.magasin.urls.client.object.editObject(
        appSession,
        res.museumObjectUuid
      );
      history && history.replace(url);
    } else {
      emitError({
        type: 'errorWhitoutTitle',
        message: I18n.t('musit.errorMainMessages.createError')
      });
    }
  } catch (e) {
    emitError({
      eventTypeId: 'errorOnSave',
      message: I18n.t('musit.errorMainMessages.saveError')
    });
  }
};

export default ObjectContainerCreate;
