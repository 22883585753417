import * as React from 'react';
import MuseumAndCollections from './MuseumAndCollection';
import PersonType from './PersonTypeComponent';
import ExternalIds from './ExternalId';
import FullName from './FullName';
import Synonyms from './Synonyms';
import { AppSessionContext } from '../../app/AppComponent';
import { AppSession } from '../../../types/appSession';

import PersonDates from './PersonDates';
import { I18n } from 'react-i18nify';
import * as H from 'history';
import Config from '../../../config';

import {
  PersonAttribute,
  InputOutputPerson,
  ExternalID,
  Synonym
} from '../types/person/personName';
import { MuseumAndCollection } from '../types/common';
import Synonymizer from './Synonymizer-new';
import { Uuid } from 'src/types/common';

export type PersonProps = {
  id: string;
  fromModal: boolean;
  onCancel: () => void;
  onChangeMuseumAndCollection: (museumAndCollection: MuseumAndCollection[]) => void;
  onChangePersonAttributes: (a: PersonAttribute) => void;
  onChangePersonStateWithName: (
    fieldName: string
  ) => (value: string, nameValue: string) => void;
  onChangeText: (fieldName: string) => (value: string) => void;
  onChangeSynonyms: (
    displaySynonyms: Synonym[],
    addedOrChangedSynonyms: Synonym[],
    removedActorNames: Uuid[]
  ) => void;
  savePerson: (data: InputOutputPerson) => Promise<string | undefined>;
  mergePersons?: (
    token: string,
    actorUuidA: Uuid,
    actorUuidB: Uuid
  ) => Promise<string | undefined>;
  saveEnabled: boolean;
  hasChanges: boolean;
  personState: InputOutputPerson;
  history: H.History<any>;
  closeModal?: () => void;
};

const Buttons = (p: PersonProps, appSession: AppSession) => (
  <div className="row">
    <div className="col-md-12" style={{ textAlign: 'right' }}>
      <button
        className="btn btn-primary"
        type="button"
        disabled={!p.saveEnabled}
        onClick={async () => {
          const uuid = await p.savePerson(p.personState);
          if (!p.fromModal) {
            const url = uuid
              ? Config.magasin.urls.client.person.editPerson(appSession, uuid)
              : undefined;
            p.history && url && p.history.push(url);
          }
          p.closeModal && p.closeModal();
        }}
      >
        {I18n.t('musit.texts.save')}
      </button>
      <button className="btn btn-link" type="button" onClick={p.onCancel}>
        {I18n.t('musit.texts.cancel')}
      </button>
    </div>
  </div>
);

const PersonComponent = (p: PersonProps) => {
  const context = React.useContext(AppSessionContext);
  const onChangeExternalId = (ids: ExternalID[]) => {
    const personAttribute: PersonAttribute = p.personState.personAttribute
      ? p.personState.personAttribute
      : { legalEntityType: 'Person' };
    p.onChangePersonAttributes({ ...personAttribute, externalIds: ids });
  };

  const onChangeAttributeField = (field: string) => (value: string) => {
    const personAttrbutes: PersonAttribute = p.personState.personAttribute
      ? p.personState.personAttribute
      : { legalEntityType: 'Person' };
    p.onChangePersonAttributes({ ...personAttrbutes, [field]: value });
  };

  return (
    <div
      className="container"
      style={{
        paddingTop: '10px',
        paddingBottom: '50px',
        lineHeight: 1
      }}
    >
      <h1>{I18n.t('musit.objectModule.person.personModule')}</h1>

      <div className="container-fluid py-3 my-4" style={{ backgroundColor: '#f2f2f2' }}>
        <div className="row">
          <div className="col-md-3">
            <PersonType
              onChange={onChangeAttributeField('legalEntityType')}
              legalEntityType={
                p.personState.personAttribute
                  ? p.personState.personAttribute.legalEntityType
                  : 'Person'
              }
            />
          </div>
          <div className="col-md-6">
            <MuseumAndCollections
              onChange={p.onChangeMuseumAndCollection}
              collections={p.personState.collections || []}
            />
          </div>
        </div>
        <div className="row">
          <FullName
            id={p.id}
            uuid={p.personState.actorUuid || 'rrr'}
            displayNameOnly={
              (p.personState.personAttribute &&
                p.personState.personAttribute.legalEntityType) !== 'Person'
            }
            title={p.personState.title}
            firstName={p.personState.firstName}
            onChangePersonStateWithName={p.onChangePersonStateWithName}
            lastName={p.personState.lastName}
            name={p.personState.name}
            onChangeText={p.onChangeText}
          />
        </div>

        <div className="row">
          <PersonDates
            id={p.id}
            bornDate={
              p.personState.personAttribute && p.personState.personAttribute.bornDate
            }
            deathDate={
              p.personState.personAttribute && p.personState.personAttribute.deathDate
            }
            verbatimDate={
              p.personState.personAttribute && p.personState.personAttribute.verbatimDate
            }
            onChangeText={onChangeAttributeField}
          />{' '}
          <div className="col-md">
            <label htmlFor="url">{I18n.t('musit.objectModule.shared.url')}</label>
            <input
              type="text"
              className="form-control"
              id="url"
              value={
                p.personState.personAttribute && p.personState.personAttribute.url
                  ? p.personState.personAttribute.url
                  : ''
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangeAttributeField('url')(e.target.value)
              }
            />
          </div>
        </div>

        <div
          className="container-fluid py-3 my-4"
          style={{ borderColor: '#fff', borderStyle: 'solid', borderWidth: '2px' }}
        >
          <h4>{I18n.t('musit.objectModule.person.externalIds')}</h4>
          <ExternalIds
            onChange={onChangeExternalId}
            externalIds={
              (p.personState &&
                p.personState &&
                p.personState.personAttribute &&
                p.personState.personAttribute.externalIds) ||
              []
            }
          />
        </div>
      </div>

      <div className="container-fluid py-3 my-4" style={{ backgroundColor: '#f2f2f2' }}>
        <h4>{I18n.t('musit.objectModule.person.synonyms')}</h4>
        <Synonyms
          synonyms={p.personState.synonyms ? p.personState.synonyms : []}
          onChange={p.onChangeSynonyms}
          deletedSynonyms={
            p.personState.removedActorNames ? p.personState.removedActorNames : []
          }
          addedSynonyms={
            p.personState.addedOrUpdatedActorNames
              ? p.personState.addedOrUpdatedActorNames
              : []
          }
        />
      </div>
      {p.mergePersons && (
        <div className="container-fluid py-3 my-4" style={{ backgroundColor: '#f2f2f2' }}>
          <h4>{I18n.t('musit.objectModule.person.synonymizer')}</h4>
          <Synonymizer
            token={context.accessToken}
            personToSynonymizeWith={p.personState}
            mergePersons={p.mergePersons}
            personHasChanges={p.hasChanges}
          />
        </div>
      )}
      {Buttons(p, context)}
    </div>
  );
};

export default PersonComponent;
