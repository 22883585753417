import * as React from 'react';
import { TODO } from '../../types/common';

export default (props: TODO) => (
  <div>
    <p>MUSIT - Universitetsmuseenes IT-organisasjon</p>
    <br />
    <p>MUSITbasen</p>
    <br />
    <p>
      <a
        href="/moreinfo"
        onClick={props.showModal}
        data-toggle="modal"
        data-target="#termsAndConditionsNO"
        data-backdrop="true"
        className="moreInfo"
      >
        Bruk av personopplysninger i MUSITbasen
      </a>
    </p>
  </div>
);
