import * as React from 'react';
import { SexAndStage } from '../types/event/event';
import { sexList, stadiumList } from '../constants/sexAndStages';
import { CheckBox } from '../components/CheckBox';
import { getTranslator } from '../../../shared/language';

const words = getTranslator({
  en: {
    add: 'Add',
    count: 'Count',
    estimatedCount: 'Estimated count',
    sex: 'Sex',
    stage: 'Stage'
  },
  no: {
    add: 'Legg til',
    count: 'Opptelling',
    estimatedCount: 'Estimerte opptelling',
    sex: 'Kjønn',
    stage: 'Stadium'
  }
});

const SexAndStageComp = ({
  sexAndStage,
  onChangeSexAndStage,
  addRow
}: {
  sexAndStage: SexAndStage;
  onChangeSexAndStage: (
    field: string
  ) => (value: string | number | boolean | undefined) => void;
  addRow?: (s: SexAndStage) => void;
}) => {
  const sexId = sexAndStage.sexId || 0;
  const stageId = sexAndStage.stageId || 0;
  const count = sexAndStage.count ? sexAndStage.count.toString() : '';
  const estimated = sexAndStage.estimated || false;
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-3">
          <label htmlFor="sex">{words.sex}</label>
          <select
            className="form-control"
            onChange={e => onChangeSexAndStage('sexId')(parseInt(e.target.value))}
            value={sexId}
          >
            {sexList.map((t, i) => (
              <option key={i + 'key-sexes'} value={t.sexId}>
                {t.sexEn}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-3">
          <label htmlFor="stage">{words.stage}</label>
          <select
            className="form-control"
            onChange={e => {
              onChangeSexAndStage('stageId')(parseInt(e.target.value));
            }}
            value={stageId}
          >
            {stadiumList.map((t, i) => (
              <option key={i + 'key-stages'} value={t.stageId}>
                {t.stageEn}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-2">
          <label htmlFor="count">{words.count}</label>
          <input
            id="count"
            className="form-control"
            type="text"
            onChange={e =>
              onChangeSexAndStage('count')(
                e.target.value.match(/^\d+$/)
                  ? Number.parseInt(e.target.value)
                  : undefined
              )
            }
            value={count}
          />
        </div>
        <div className="col-md-3">
          <label htmlFor="count" />
          <CheckBox
            id="checkbox-estimated"
            displayValue={` ${words.estimatedCount}?`}
            checked={estimated ? true : false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeSexAndStage('estimated')(e.target.checked ? true : false)
            }
          />
        </div>
        {addRow && (
          <div className="col-md-1">
            <a
              href="/#"
              onClick={e => {
                e.preventDefault();
                addRow && addRow(sexAndStage);
              }}
            >
              <br />
              {words.add}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default SexAndStageComp;
