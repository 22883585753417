// This is the language translation mechanism

import { I18n } from 'react-i18nify';
const commonTranslations = {
  en: {
    cancel: 'Cancel',
    date: 'Date',
    delete: 'Delete',
    edit: 'Edit',
    no: 'No',
    object: 'Object',
    save: 'Save',
    yes: 'Yes',
    marineEvertebrates: 'Marine evertebrates'
  },
  no: {
    cancel: 'Avbrytt',
    date: 'Dato',
    delete: 'Slett',
    edit: 'Rediger',
    no: 'Nei',
    object: 'Objekt',
    save: 'Lagre',
    yes: 'Ja',
    marineEvertebrates: 'Marine evertebrater'
  }
};

export const getLanguage = () => localStorage.getItem('language') || 'no';

export const loadLanguage = () => {
  const language = getLanguage();
  localStorage.setItem('language', language);
  I18n.setLocale(language);
};

// This code uses Proxies
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy
export const getTranslator = (translations?: any) =>
  // "translations" is an object in the form of
  // {
  //   en: {
  //     string1: 'String one'
  //   },
  //   no: {
  //     string1: 'Streng en'
  //   }
  // }
  new Proxy(
    // TYPESCRIPT TODO: make translations a type that endforces {en:..., no:...}
    translations || {},
    {
      get: (obj, prop) =>
        Object.assign(commonTranslations[getLanguage()], obj[getLanguage()])[prop] ||
        String(prop)
    }
  );
