import { Actor } from '../../types/person';
import { Result } from '../../../src/modules/museumObject/objectSearchES/types';
import { SearchData } from '../../../src/modules/numismatic/searchPage/SearchContainer';
import { CollectingEventSearchResult } from '../../modules/museumObject/collectingEvent/SearchCollectingEventContainer';
const persons: Array<Actor> = [];

export const numisSearchReducer = (
  state: SearchData[] = [],
  action: {
    type: string;
    payload: SearchData[];
  }
) => {
  switch (action.type) {
    case 'SAVE_NUMIS_OBJECT_SEARCH_RESULT':
      return action.payload;
    default:
      return state;
  }
};

export const loadingReducer = (
  state = false,
  action: { type: string; payload: boolean }
) => {
  switch (action.type) {
    case 'SET_LOADING_STATUS':
      return action.payload;
    default:
      return false;
  }
};
export const personsReducer = (
  state = { persons },
  action: { type: string; payload: Actor }
) => {
  switch (action.type) {
    case 'SAVE_PERSON_SEARCH_RESULT':
      return {
        ...state,
        persons: action.payload
      };
    default:
      return state;
  }
};
export const objectSearchReducer = (
  state: Result = { objectList: { count: 0, grid: [] }, aggregations: {} },
  action: { type: string; payload: Result }
) => {
  switch (action.type) {
    case 'SAVE_OBJECT_SEARCH_RESULT':
      return action.payload;
    default:
      return state;
  }
};

export const objectSearchCurrPageReducer = (
  state = { currentPage: 0 },
  action: { type: string; payload: number }
) => {
  switch (action.type) {
    case 'SAVE_CURRENT_PAGE':
      return action.payload;
    default:
      return state;
  }
};

export const collectingEventSearchReducers = (
  state: Array<CollectingEventSearchResult> = [],
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case 'SAVE_COLLECTING_EVENT_SEARCH':
      return action.payload;
    default:
      return state;
  }
};
