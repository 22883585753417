import * as React from 'react';
import { TaxonTypeId, toDetTypeStr } from './taxonLib';
import { getTranslator } from '../../../shared/language';

const words = getTranslator({
  en: {
    taxonType: 'Taxon type'
  },
  no: {
    taxonType: 'Type takson'
  }
});

type DetTypeProps = {
  detType: TaxonTypeId;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  disabled: boolean;
  detTypeWords: any;
};

const DetTypeComponent = (props: DetTypeProps) => {
  return (
    <div className="row">
      <div className="form-group col-md-8">
        <label
          htmlFor="detType"
          className="col-form-label"
          style={{ textAlign: 'right' }}
        >
          <b>{words.taxonType}</b>
        </label>
        <select
          disabled={props.disabled}
          className="form-control border-primary"
          id="detType"
          value={props.detType}
          onChange={props.onChange}
        >
          <option value={TaxonTypeId.Det}>
            {props.detTypeWords[toDetTypeStr(TaxonTypeId.Det)]}
          </option>

          <option value={TaxonTypeId.Rev}>
            {props.detTypeWords[toDetTypeStr(TaxonTypeId.Rev)]}
          </option>

          <option value={TaxonTypeId.Conf}>
            {props.detTypeWords[toDetTypeStr(TaxonTypeId.Conf)]}
          </option>

          <option value={TaxonTypeId.ConfName}>
            {props.detTypeWords[toDetTypeStr(TaxonTypeId.ConfName)]}
          </option>
        </select>
      </div>
    </div>
  );
};

export default DetTypeComponent;
