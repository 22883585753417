const coordinateTypes = [
  {
    type_text: 'MGRS'
  },
  {
    type_text: 'LAT/LONG'
  },
  {
    type_text: 'UTM'
  }
];
export default coordinateTypes;
