import * as React from 'react';
import { Component } from 'react';
import { I18n } from 'react-i18nify';
import Config from '../../config';
import { get } from '../../shared/ajaxFetch/ajaxFetch';

import {
  AcquisitionsPerYear,
  MuseumWithCollections,
  CollectionType,
  CollectionStats
} from './ObjectStatisticsReportModel';
import Async from 'react-async';
import FontAwesome from 'react-fontawesome';
import { getAccessToken } from '../../shared/token';

const museumHeaderStyle = {
  margin: '8px 0px',
  fontWeight: 'bold' as 'bold',
  backgroundColor: 'black',
  color: 'white',
  padding: '8px'
};

/** Just like fetch, but return json and throws errors if not ok (and uses our token)*/
async function localFetch<T>(url: string): Promise<T> {
  const token = getAccessToken();
  const res = await get(url, token);
  if (!res.ok) {
    throw new Error(`${res.status} ${res.statusText}`);
  }
  const json = await res.json();
  return json as T;
}

function fetchMuseumOverview(): Promise<MuseumWithCollections[]> {
  return localFetch<MuseumWithCollections[]>(Config.api.statistics.museumOverviewUrl);
}

function fetchCollectionStats(
  museumId: number,
  collectionTypeId: number
): Promise<CollectionStats[]> {
  return localFetch<CollectionStats[]>(
    `${Config.api.statistics.collectionStatsUrl(museumId, collectionTypeId)}`
  );
}

export class ObjectStatisticsReport extends Component {
  renderAcquisitionCount(acquisitionsPerYear: AcquisitionsPerYear) {
    return `(${acquisitionsPerYear.year}: ${acquisitionsPerYear.acquisitionCount})`;
  }

  maybeRenderAcquisitionCounts(acquisitionsPerYear: AcquisitionsPerYear[]) {
    return acquisitionsPerYear && acquisitionsPerYear.length > 0 ? (
      <span>
        {acquisitionsPerYear
          .sort((a, b) => a.year - b.year)
          .map(acsPerYear => this.renderAcquisitionCount(acsPerYear))
          .join(',')}
      </span>
    ) : (
      <span></span>
    );
  }

  renderMuseumsAsync(museums: MuseumWithCollections[]) {
    return museums.map(m => this.renderMuseumAsync(m));
  }

  renderMuseumAsync(museum: MuseumWithCollections) {
    return (
      <div key={museum.museumId}>
        {' '}
        <div style={museumHeaderStyle}>
          {museum.abbreviation} - {museum.name}
        </div>
        <table className="table table-responsive table-hover">
          <thead>
            <tr>
              <td>
                <b>Samling</b>
              </td>
              <td>
                <b># Objekter</b>
              </td>
              <td>
                <b># Fotografier</b>
              </td>
              <td>
                <b>Tilvekst siste 3 år</b>
              </td>
            </tr>
          </thead>
          <tbody>
            {museum.collectionTypes.map(coll => this.renderCollectionType(museum, coll))}
          </tbody>
        </table>
      </div>
    );
  }
  renderCollectionType(museum: MuseumWithCollections, collType: CollectionType) {
    return (
      <Async
        key={`${museum.museumId}x${collType.collectionTypeId}`}
        promiseFn={() => fetchCollectionStats(museum.museumId, collType.collectionTypeId)}
      >
        <Async.Pending>
          <tr>
            <td>
              {collType.name} <FontAwesome name="spinner" spin />
            </td>
          </tr>
        </Async.Pending>
        <Async.Rejected>
          {error => (
            <tr>
              <td>Error: {error.toString()}</td>
            </tr>
          )}
        </Async.Rejected>
        <Async.Fulfilled>
          {(data: CollectionStats) => (
            <tr>
              <td>{collType.name}</td>
              <td>{data.objectCount} </td>

              <td>{data.photoCount} </td>
              <td>{this.maybeRenderAcquisitionCounts(data.acquisitionsPerYear)}</td>
            </tr>
          )}
        </Async.Fulfilled>
      </Async>
    );
  }

  render() {
    return (
      <div className="container" style={{ marginTop: 90 }}>
        <h1>{I18n.t('musit.reports.objectStatistics.header')}</h1>
        <div>{I18n.t('musit.reports.objectStatistics.warning')}</div>
        <p></p>

        <Async promiseFn={fetchMuseumOverview}>
          <Async.Pending>
            <FontAwesome name="spinner" spin />
          </Async.Pending>
          <Async.Rejected>{error => <div>Error: {error.toString()}</div>}</Async.Rejected>
          <Async.Fulfilled>
            {(museum: MuseumWithCollections[]) => (
              <div>{this.renderMuseumsAsync(museum)}</div>
            )}
          </Async.Fulfilled>
        </Async>
      </div>
    );
  }
}

export default ObjectStatisticsReport;
