import * as React from 'react';
import { post } from '../../../shared/ajaxFetch/ajaxFetch';
import CollectingEventSearchComponent, {
  SearchParams
} from './SearchCollectingEventComponent';
import { AppSessionContext } from '../../app/AppComponent';
import { createEventHeaderData } from './CollectingEventContainer';
import { EventUuid, DateAttributes, OutActorAndRelation } from '../types/event/event';
import { OutPlace } from '../types/place/place';

import { CollectingEventHeader } from '../types/frontend/collectingEventForm';
import config from '../../../config';
import { withRouter } from 'react-router';
import * as actions from '../../../stores/actions/actions';
import { connect } from 'react-redux';
async function searchEvents({
  eventTypeId,
  museumId,
  collectionUuid,
  params,
  accessToken
}: {
  eventTypeId: number;
  museumId: number;
  collectionUuid: string;
  params?: SearchParams;
  accessToken: string;
  eventUuid?: EventUuid;
  revisionOf?: EventUuid;
}) {
  const postUrl = config.api.collectingEvent.eventsSearchUrl;
  const body = {
    eventTypeId: eventTypeId,
    museumId: museumId,
    collectionUuid: collectionUuid,
    ...params
  };
  return post(postUrl, JSON.stringify(body), accessToken);
}

export type CollectingEventSearchResult = {
  attributes: { name: string };
  museumId: number;
  eventUuid: EventUuid;
  date?: DateAttributes;
  relatedActors?: OutActorAndRelation[];
  place?: OutPlace;
};
type UpdateCollectingEventSearchResultFunc = (
  personData: CollectingEventSearchResult[]
) => void;

const CollectingEventSearchContainer = withRouter((props: any) => {
  const appSession = React.useContext(AppSessionContext);
  const eventHeaderData: CollectingEventHeader = createEventHeaderData(appSession);

  const [searchParams, updateSearchParams] = React.useState<SearchParams>({});
  const [searchResultData, changeSearchResultState]: [
    CollectingEventSearchResult[],
    UpdateCollectingEventSearchResultFunc
  ] = React.useState<CollectingEventSearchResult[]>(props.collectingEventSearch);

  const onSearch = async () => {
    const result: Response = await searchEvents({
      ...eventHeaderData,
      accessToken: appSession.accessToken,
      params: { name: searchParams.name }
    });
    const data: CollectingEventSearchResult[] = await result.json();

    props.dispatch(actions.saveCollectingEventSearch(data));
    changeSearchResultState(data);
  };

  return (
    <CollectingEventSearchComponent
      searchResultData={searchResultData}
      history={props.history}
      searchParams={searchParams}
      updateSearchParams={updateSearchParams}
      onSearch={onSearch}
      clearSearch={() => updateSearchParams({})}
    />
  );
});

//export default CollectingEventSearchContainer;
function mapStateToProps(state: any) {
  return state;
}
export default connect(mapStateToProps)(CollectingEventSearchContainer);
