import * as React from 'react';
import FontAwesome from 'react-fontawesome';

type CollapseState = { collapsed: Boolean };
type CollapseProps<A, B> = {
  heading?: string;
  Head: JSX.Element;
  Body: JSX.Element;
  headProps?: A;
  bodyProps?: B;
  readOnly?: boolean;
  collapsed?: boolean;
  showHead?: boolean;
};

export class CollapseGroupBox<A, B> extends React.Component<
  CollapseProps<A, B>,
  CollapseState
> {
  constructor(props: CollapseProps<A, B>) {
    super(props);
    this.state = { collapsed: props.collapsed || false };
  }
  render() {
    const { Head, Body } = this.props;
    return (
      <fieldset className="objectContainer">
        {/* <legend className="w-auto">{this.props.heading}</legend> keep until design is finilized*/}
        <div className="form-row">
          <div className="col-md-8">
            <h3>{this.props.heading}</h3>
          </div>
          <div className="col-md-4">
            <button
              data-toggle="tooltip"
              style={{ float: 'right', boxShadow: 'none' }}
              title={this.state.collapsed ? 'Click to Expand' : 'Click to Hide'}
              onClick={e => {
                e.preventDefault();
                this.setState((ps: CollapseState) => ({
                  ...ps,
                  collapsed: !this.state.collapsed
                }));
              }}
              className="btn btn-link float-right btn-sm"
            >
              <FontAwesome
                name={this.state.collapsed ? 'chevron-down' : 'chevron-up'}
                style={{ color: '#164f88' }}
                size={this.state.collapsed ? '2x' : '2x'}
              />
            </button>
          </div>
        </div>
        {/*  this.state.collapsed && this.props.showHead && Head  */}
        <div className={`collapse${this.state.collapsed ? '' : ' show'}`}>{Body}</div>
      </fieldset>
    );
  }
}

export default class CollapseComponent<A, B> extends React.Component<
  CollapseProps<A, B>,
  CollapseState
> {
  constructor(props: CollapseProps<A, B>) {
    super(props);
    this.state = { collapsed: props.collapsed || false };
  }
  render() {
    const { Head, Body } = this.props;
    return (
      <div
        className="container-fluid p-3"
        style={{ borderStyle: 'solid', borderWidth: 'thin' }}
      >
        <div className="row">
          <div className="col-md-8">
            {this.props.heading && <div className="h4 m-1">{this.props.heading}</div>}
          </div>
          <div className="col-md-4">
            <button
              data-toggle="tooltip"
              style={{ float: 'right' }}
              title={this.state.collapsed ? 'Click to Expand' : 'Click to Hide'}
              onClick={e => {
                e.preventDefault();
                this.setState((ps: CollapseState) => ({
                  ...ps,
                  collapsed: !this.state.collapsed
                }));
              }}
              className="btn btn-link float-right"
            >
              <FontAwesome
                className="fa-2x"
                name={this.state.collapsed ? 'chevron-down' : 'chevron-up'}
                style={{ color: 'black' }}
              />
            </button>
          </div>
        </div>
        {this.state.collapsed && this.props.showHead && Head}
        <div className={`collapse${this.state.collapsed ? '' : ' show'}`}>{Body}</div>
      </div>
    );
  }
}
