// @flow
import * as React from 'react';
import { Field } from '../form';
import { I18n } from 'react-i18nify';
import { Star } from '../../types/common';

type FieldInputProps = {
  field: Field<Star>;
  title: string;
  yesValue: string | number;
  controlWitdh?: number;
  noValue: string | number;
  onChange: Function;
  inputProps?: {
    className?: string;
    style?: {};
  };
};

export default function CheckBoxInput({
  field,
  yesValue,
  noValue,
  title,
  onChange,
  controlWitdh = 3
}: FieldInputProps) {
  console.log('Field: ', field);
  return (
    <div className={`col-md-${controlWitdh}`}>
      {title !== '' && (
        <label className="col-form-label" htmlFor={'radioGroupDIV'}>
          {title}
        </label>
      )}
      <br />

      <div
        className="btn-group btn-group-toggle"
        id="radioGroupDIV"
        data-toogle="buttons"
      >
        <label
          className={`btn btn-secondary ${field.rawValue === yesValue ? ' active' : ''}`}
        >
          <input
            type="radio"
            name="radioGroup"
            id="option1"
            auto-complete="off"
            onChange={(e: React.SyntheticEvent<HTMLInputElement>) => {
              console.log(e);
              onChange({ name: field.name, rawValue: e.currentTarget.value });
            }}
            value={yesValue}
            checked={field.rawValue === yesValue}
          />{' '}
          {I18n.t('musit.texts.yes') || ' '}
        </label>
        <label
          className={`btn btn-secondary ${field.rawValue === noValue ? 'active' : ''}`}
        >
          <input
            type="radio"
            name="radioGroup"
            id="option2"
            auto-complete="off"
            onChange={(e: React.SyntheticEvent<HTMLInputElement>) => {
              console.log(e);
              onChange({ name: field.name, rawValue: e.currentTarget.value });
            }}
            value={noValue}
            checked={field.rawValue === noValue}
          />{' '}
          {I18n.t('musit.texts.no') || ' '}
        </label>
      </div>
      {/* <div className="btn-group-toggle" data-toggle="buttons" id="radioGroupDIV">
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="radioGroup"
            onChange={() => {
              (e: React.SyntheticEvent<HTMLInputElement>) =>
                onChange({ name: field.name, rawValue: e.target });
            }}
            id="yes-value"
            value={yesValue}
            checked={field.rawValue === yesValue}
          />{' '}
          <label className="form-check-label" htmlFor="yes-value">
            {I18n.t('musit.texts.yes') || ' '}
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="radioGroup"
            onChange={() => {
              (e: React.SyntheticEvent<HTMLInputElement>) =>
                onChange({ name: field.name, rawValue: e.target });
            }}
            id="no-value"
            value={noValue}
            checked={field.rawValue === noValue}
          />{' '}
          <label className="form-check-label" htmlFor="no-value">
            {I18n.t('musit.texts.no') || ' '}
          </label>
        </div>
      </div> */}
    </div>
  );
}
