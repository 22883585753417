/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import './analysis.css';
import 'react-datepicker/dist/react-datepicker.css';
import FontAwesome from 'react-fontawesome';
import Loader from 'react-loader';
import * as React from 'react';
import * as util from '../../shared/util';
import CancelRestriction from './components/CancelRestriction';
import MusitDatePicker from '../../components/DatePicker';
import { AppSessionContext } from '../../modules/app/AppComponent';
import { DATE_FORMAT_DISPLAY } from '../../shared/util';
import { get } from '../../shared/ajaxFetch/ajaxFetch';
import { getAccessToken } from '../../shared/token';
import { getMuseumId } from '../../shared/museumId';
import { getAnalysisResultFieldAllowedValues } from './shared/analysisResult';
import { getFileAsBlob } from '../../models/analysis/analysisResult';
import { getSortedObjectList, getSortedSampleList } from './shared/getters';
import { getTranslator } from '../../shared/language';
import { saveBlob } from '../../shared/download';
import { ViewRestricition } from './AnalysisViewComponent';
import { Restriction } from '../../types/analysis';
import { isRestrictionValidForCancellation } from './shared/formProps';
import { I18n } from 'react-i18nify';

enum RestrictionState {
  noRestriction = 0,
  newRestriction = 1,
  restrictionExists = 2,
  newRestrictionCancellation = 3,
  cancelledRestrictionExists = 4
}

const words = getTranslator({
  en: {
    administrator: 'Administrator',
    age: 'Age',
    newAnalysis: 'Create new analysis',
    ageEstimate: 'Age estimate',
    analysisSubtype: 'Analysis subtype',
    analysisType: 'Type of analysis',
    browse: 'Browse (max filesize 10mB)',
    caseNumber: 'Case Number',
    comment: 'Comment to result',
    completedBy: 'Completed by',
    concentration: 'Concentration',
    doneBy: 'Done by',
    endDate: 'End Date',
    extRef: 'External source',
    extractionType: 'Type of Extraction',
    error: 'Error',
    files: 'Files',
    toLargeFileErrorMessage: 'File is to large, max 10mB',
    findActor: 'Find actor',
    measurementId: 'Measurement id',
    measurementType: 'Measurement type',
    method: 'Method',
    musNo: 'Museum No.',
    note: 'Note',
    objects: 'Objects',
    place: 'Location of the analysis',
    precision: 'Precision',
    reason: 'Purpose of the analysis',
    reasonForRestriction: 'Cause of restrictions',
    registeredBy: 'Registered by',
    responsible: 'Responsible',
    restrictions: 'Restriction',
    restrictionCancelled: 'Restriction (cancelled)',
    restrictionsFor: 'Restriction for',
    sampleNumber: 'Sample No.',
    sampleType: 'Sample type',
    samples: 'Samples',
    size: 'Size',
    standardDeviation: 'Standard deviation',
    status: 'Status',
    storageMedium: 'Storage medium',
    subNo: 'Sub No.',
    term: 'Artefact/Taxon',
    types: 'Types',
    uploadFile: 'Upload file (max filesize 10mB)',
    validationError: 'Fix the invalid fields',
    volume: 'Volume',

    category: {
      '1': 'Chemical analysis',
      '2': 'Colour analysis',
      '3': 'Dating',
      '4': 'Genetic analysis',
      '5': 'Image analysis',
      '6': 'Macro fossil analysis',
      '7': 'Micro fossil analysis',
      '8': 'Morphological measurements',
      '9': 'Osteology',
      '10': 'Sediment analysis',
      '11': 'Species determination',
      '12': 'Textile and fibre analysis',
      '13': 'Wood anatomy'
    },
    statusType: {
      '1': 'In preparation',
      '2': 'Analysis initiated',
      '3': 'Analysis Finished',
      '4': 'Completed without results'
    }
  },

  no: {
    administrator: 'Administrert av',
    age: 'Alder',
    newAnalysis: 'Opprett ny analyse',
    ageEstimate: 'Estimat Alder',
    analysisSubtype: 'Subtype analyse',
    analysisType: 'Type analyse',
    browse: 'Bla gjennom  (maks filstørrelse 10mB)',
    caseNumber: 'Saksnummer',
    comment: 'Kommentar til resultat',
    completedBy: 'Avsluttet av',
    concentration: 'Konsentrasjon',
    doneBy: 'Analysert av',
    endDate: 'Sluttdato',
    error: 'Feil',
    extRef: 'Ekstern kilde',
    extractionType: 'Type Ekstraksjon',
    files: 'Filer',
    toLargeFileErrorMessage: 'Fila er for stor, maks 10mB',
    findActor: 'Finn aktør',
    measurementId: 'Mål-id',
    measurementType: 'Mål-type',
    method: 'Metode',
    musNo: 'Museumsnr.',
    note: 'Notat',
    objects: 'Objekter',
    place: 'Analysested',
    precision: 'Presisjon',
    reason: 'Formål med analysen',
    reasonForRestriction: 'Årsak til klausulering',
    registeredBy: 'Opprettet av',
    responsible: 'Ansvarlig',
    restrictions: 'Klausulering',
    restrictionCancelled: 'Klausulering (opphevet)',

    restrictionsFor: 'Klausulert for',
    sampleNumber: 'Prøvenr.',
    sampleType: 'Prøvetype',
    samples: 'Prøver',
    size: 'Størrelse',
    standardDeviation: 'Standardavvik',
    status: 'Status',
    storageMedium: 'Lagringsmedium',
    subNo: 'Unr.',
    term: 'Gjenstand/Takson',
    types: 'Typer',
    uploadFile: 'Last opp fil (maks filstørrelse 10 mB)',
    validationError: 'Valideringsfeil- se over skjemaet og legge inn gyldig data',
    volume: 'Volum',

    category: {
      '1': 'Kjemisk analyse',
      '2': 'Fargeanalyse',
      '3': 'Datering',
      '4': 'Genetisk analyse',
      '5': 'Bildeanalyse',
      '6': 'Makrofossilanalyse',
      '7': 'Mikrofossilanalyse',
      '8': 'Morfologiske målinger',
      '9': 'Osteologi',
      '10': 'Sedimentanalyse',
      '11': 'Artsbestemmelse',
      '12': 'Tekstil- og fiberanalyse',
      '13': 'Vedanatomi'
    },
    statusType: {
      '1': 'Under forberedelse',
      '2': 'Analyse påbegynt',
      '3': 'Analyse ferdig',
      '4': 'Avsluttet uten resultat'
    }
  }
});

export interface Attachment {
  fid: string;
  title?: string;
}

export interface ExternalFiles {
  id: string;
  eventid?: number;
  fileList?: FileList;
  attachments?: Attachment[];
  extRef: string;
  comment: string;
}

export interface ExternalFiles {
  id: string;
  eventid?: number;
  fileList?: FileList;
  extRef: string;
  comment: string;
}

const MiniCollapse = (props: any) => {
  const [collapse, changeCollapse] = React.useState(false);
  return (
    <td
      data-toggle="collapse"
      data-target={'.multi-collapse' + props.uuid}
      aria-expanded="false"
      title="toggle"
      className="text-right"
      onClick={() => changeCollapse(!collapse)}
    >
      <a title="toggle">
        <span
          className={'fa ' + (collapse ? 'fa-chevron-down' : 'fa-chevron-right')}
          style={{ color: 'black' }}
        />
      </a>
    </td>
  );
};

// const getMuseumId = () => window.localStorage.getItem('museumId');

const ObjectViewer = (props: any) => {
  const objects = props.objects;
  const [forceRerender] = React.useState(true);
  //  const appSession = React.useContext(AppSessionContext);

  const Result = (rProps: any) => (
    <>
      <tr title={rProps.o.uuid} style={{ borderTop: '1px solid #dddddd' }}>
        <td>{rProps.o.museumNo}</td>
        <td>{rProps.o.subNo ? rProps.o.subNo : ''}</td>
        <td>{rProps.o.term}</td>
        <MiniCollapse uuid={rProps.o.uuid} />
      </tr>
      <tr className={'collapse multi-collapse' + rProps.o.uuid}>
        <td colSpan={4}>
          <AnalysisResult
            extraResultAttributes={props.analysisType?.extraResultAttributes || {}}
            resultType={(props.analysisType as any)?.extraResultType}
            results={props.results}
            setResults={props.setResults}
            index={+rProps.i + 1}
            attachments={props.attachments}
            setAttachments={props.setAttachments}
            newAttachments={props.newAttachments}
            setNewAttachments={props.setNewAttachments}
          />
        </td>
      </tr>
    </>
  );

  return (
    <table style={{ width: '100%' }}>
      {forceRerender}
      <thead>
        <tr>
          <th>{words.musNo}</th>
          <th>{words.subNo}</th>
          <th>{words.term}</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {getSortedObjectList(objects).map((object, i) => (
          <Result o={object} i={i} key={i} />
        ))}
      </tbody>
    </table>
  );
};

const SampleViewer = (props: any) => {
  const samples = props.samples;

  // hack to force rerender (possible a better way to do this)
  const [forceRerender] = React.useState(true);
  const SampleResult = (SRProps: any) => (
    <>
      <tr title={SRProps.s.uuid} style={{ borderTop: '1px solid #dddddd' }}>
        <td>{SRProps.s.originatedObject.museumNo}</td>
        <td>
          {SRProps.s.originatedObject.subNo ? SRProps.s.originatedObject.subNo : ''}
        </td>
        <td>{SRProps.s.originatedObject.term}</td>
        <td>{SRProps.s.sampleNum}</td>
        <td>
          {
            props.sampleTypes.filter(
              (st: any) => st.sampleTypeId === SRProps.s.sampleTypeId
            )[0].enSampleType
          }
        </td>
        <MiniCollapse uuid={SRProps.s.uuid} />
      </tr>
      <tr className={'collapse multi-collapse' + SRProps.s.uuid}>
        <td colSpan={5}>
          <AnalysisResult
            extraResultAttributes={props.analysisType?.extraResultAttributes || {}}
            resultType={(props.analysisType as any)?.extraResultType}
            results={props.results}
            setResults={props.setResults}
            index={SRProps.i}
            attachments={props.attachments}
            setAttachments={props.setAttachments}
            newAttachments={props.newAttachments}
            setNewAttachments={props.setNewAttachments}
          />
        </td>
      </tr>
    </>
  );

  return (
    <table style={{ width: '100%' }}>
      {forceRerender}
      <thead>
        <tr>
          <th>{words.musNo}</th>
          <th>{words.subNo}</th>
          <th>{words.term}</th>
          <th>{words.sampleNumber}</th>
          <th>{words.sampleType}</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {getSortedSampleList(samples).map((sample: any, i: any) => (
          <SampleResult s={sample} key={sample.objectId} i={i + props.offset} />
        ))}
      </tbody>
    </table>
  );
};

const ResultField = (props: any) => {
  // have to use local hook here so that rerendering workd as intended
  const [forceRerender, setForceRerender] = React.useState(false);

  const unitRef = React.useRef(null);
  const valueRef = React.useRef<HTMLInputElement>(null);

  const allowedValues =
    getAnalysisResultFieldAllowedValues(
      props.extraResultType,
      props.era,
      React.useContext(AppSessionContext).language
    ) || [];

  // because of the way the API works we have to put results in at a
  // specified array index ([0] == main analysis, [1 ...] == analysis
  // objects):

  props.results[props.index] = props.results[props.index] || {};

  const DropDown = React.forwardRef((props: any, ref: any) => (
    <select
      ref={ref}
      className="form-control"
      onChange={props.onChange}
      defaultValue={props.defaultValue}
    >
      <option value="undefined" key="nowt" />
      {props.values.map((v: any) => (
        <option key={v} value={v}>
          {v}
        </option>
      ))}
    </select>
  ));

  if (props.dataType === 'Size') {
    return (
      <div className="row" style={{ paddingTop: 0 }}>
        <div className="col-md-6">
          <input
            ref={valueRef}
            defaultValue={
              props.results[props.index][props.era]
                ? props.results[props.index][props.era].value
                : ''
            }
            className="form-control"
            onChange={(e: any) => {
              if (validateNumericField(e.target)) {
                // if value is a valid number, then the unit ref must be set
                setFieldValidity(unitRef.current); // target the dropdown list
                e.target.setCustomValidity('');
                props.results[props.index][props.era] =
                  props.results[props.index][props.era] || {};
                props.results[props.index][props.era].value = +e.target.value;
                props.setResults(props.results);
              }
            }}
          />
        </div>
        <div className="col-md-6">
          <DropDown
            ref={unitRef}
            defaultValue={
              props.results[props.index][props.era]
                ? props.results[props.index][props.era].unit
                : 'undefined'
            }
            values={allowedValues}
            onChange={(e: any) => {
              validateNumericField(valueRef.current);
              setFieldValidity(e.target);
              props.results[props.index][props.era] =
                props.results[props.index][props.era] || {};
              props.results[props.index][props.era].unit = e.target.value;
              props.setResults(props.results);
            }}
          />
        </div>
      </div>
    );
  }

  if (props.dataType === 'File') {
    return (
      <>
        <div className="input-group">
          <input
            type="file"
            className="form-control-file"
            ref={valueRef}
            id="inputGroupFile02"
            onChange={(e: any) => {
              const f = e.target.files[0];
              e.target.classList.remove('invalidForm');
              e.target.setCustomValidity('');

              if (f) {
                if (
                  validateFileField(
                    e.target,
                    Number.parseInt(f.size),
                    10000000,
                    words.toLargeFileErrorMessage
                  )
                ) {
                  props.setNewAttachments((newAttachments: any) => {
                    newAttachments[props.index] = newAttachments[props.index] || [];
                    newAttachments[props.index].push(f);

                    return newAttachments;
                  });
                }
                setForceRerender(!forceRerender);
              }
            }}
          />
          {valueRef && valueRef.current?.files && valueRef.current.files.length === 0 && (
            <>
              {' '}
              <span style={{ fontSize: 'small', color: 'red' }}>
                {valueRef.current?.validationMessage}
              </span>
              <br />
            </>
          )}
          <label htmlFor="inputGroupFile02">{words.browse}</label>
        </div>

        {/* this stanza displays files that have just been uploaded (not yet saved) */}
        {(props.newAttachments[props.index] || []).map((a: any, i: any) => {
          return a instanceof File ? (
            <React.Fragment key={a.name}>
              <a href={window.URL.createObjectURL(a)} download>
                {a.name}
              </a>{' '}
              <a
                href="#/"
                onClick={() => {
                  props.setNewAttachments((na: any) => {
                    na[props.index].splice(i, 1);
                    return na;
                  });
                  setForceRerender(!forceRerender);
                }}
              >
                (<FontAwesome name="times" />){forceRerender}
              </a>
              <br />
            </React.Fragment>
          ) : (
            <div />
          );
        })}

        {/* this stanza displays previously saved files */}
        {props.attachments[props.index] &&
          props.attachments[props.index].map((a: any, i: any, attachmentList: any) => (
            <React.Fragment key={a.id}>
              <a
                href="#/"
                onClick={e => {
                  // tslint:disable-next-line: no-floating-promises
                  getFileAsBlob(a.fid, +getMuseumId(), getAccessToken())
                    .do(res => {
                      if (res instanceof Blob) {
                        saveBlob(res, a.title);
                      }
                    })
                    .toPromise();
                }}
              >
                {a.title}
              </a>{' '}
              <a
                href="#/"
                onClick={e => {
                  // update results
                  props.results[props.index].attachments.splice(i, 1);
                  props.setResults(props.results);
                  // update attachement placeholder
                  const newAttachmentLists = props.attachments;
                  attachmentList.splice(i, 1);
                  newAttachmentLists[props.index] = attachmentList;
                  props.setAttachments(newAttachmentLists);
                  setForceRerender(!forceRerender);
                }}
              >
                (<FontAwesome name="times" />)
              </a>
              <br />
            </React.Fragment>
          ))}
      </>
    );
  }

  if (props.dataType === 'TextArea')
    return (
      <textarea
        className="form-control"
        defaultValue={props.results[props.index][props.era]}
        onChange={e => {
          console.log(props.index);
          props.results[props.index][props.era] = e.target.value;
          props.setResults(props.results);
        }}
      />
    );

  if (allowedValues.length > 0)
    return (
      <DropDown
        values={allowedValues}
        defaultValue={props.results[props.index][props.era]}
        onChange={(e: any) => {
          props.results[props.index][props.era] = e.target.value;
          props.setResults(props.results);
        }}
      />
    );

  return (
    <input
      className="form-control"
      defaultValue={
        props.results[props.index][props.era]
          ? Array.isArray(props.results[props.index][props.era][0])
            ? props.results[props.index][props.era][0]
            : props.results[props.index][props.era]
          : ''
      }
      onChange={e => {
        console.log(props.index);
        // TODO: API expects extRef as an array- but why?
        props.results[props.index][props.era] =
          props.era === 'extRef' ? [e.target.value] : e.target.value;
        props.setResults(props.results);
      }}
    />
  );
};

const SearchBox = (props: any) => {
  const [suggestions, setSuggestions] = React.useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = React.useState(-1);
  const inputEl = React.useRef<HTMLInputElement>(null);

  // since modulo (%) in js isnt actually modulo
  // https://stackoverflow.com/questions/4467539/javascript-modulo-gives-a-negative-result-for-negative-numbers
  const mod = (n: number, m: number) => {
    return ((n % m) + m) % m;
  };

  const search = (str: any) =>
    get(
      // DIRTY HACK- an empty string returns everything in the index, ~ returns an empty list
      props.endPoint[0] + (str || '~') + props.endPoint[1],
      getAccessToken()
    )
      .then(res => res.json())
      .then(r => {
        console.log('Res', r);
        return r;
      })
      .then(setSuggestions);

  const keyHandler = (e: any) => {
    if (e.key === 'ArrowDown') setActiveSuggestionIndex(activeSuggestionIndex + 1);
    else if (e.key === 'ArrowUp') setActiveSuggestionIndex(activeSuggestionIndex - 1);
    // 'Process' == key is being held down
    else if (e.key === 'Process') return;
    else if (e.key === 'Enter') {
      if (document.querySelector('a.dropdown-item.active')) {
        // set name in input box
        inputEl.current!.value = document.querySelector(
          'a.dropdown-item.active'
        )!.innerHTML;

        // return uuid in callback
        props.onSelectNewValue(
          document.querySelector('a.dropdown-item.active')!.getAttribute('data-personid')
        );
        // suggestion list reset to "empty"
        setSuggestions([]);
      }
    } else setActiveSuggestionIndex(-1);
  };

  return (
    <div>
      <input
        className="form-control"
        onKeyDown={keyHandler}
        placeholder={words.findActor}
        defaultValue={props.defaultValue}
        onChange={e => {
          if (!e.target.value.length) props.onSelectNewValue(undefined);
          // tslint:disable-next-line: no-floating-promises
          else if (e.target.value.length > 2) search(e.target.value);
        }}
        ref={inputEl}
      />
      {suggestions && (
        <div
          className={'dropdown-menu' + (suggestions.length ? ' show' : '')}
          aria-labelledby="dropdownMenuLink"
          onClick={e => setSuggestions([])}
          style={{ marginTop: -2, marginLeft: 17 }}
        >
          {suggestions.slice(0, 20).map((p: any, i: any, arr: any) => {
            if (!p.applicationId) return undefined;
            return (
              <a
                data-personid={p.applicationId}
                key={p.applicationId + '-' + i}
                className={
                  'dropdown-item' +
                  (mod(activeSuggestionIndex, arr.length + 1) === i ? ' active' : '')
                }
                href="#/"
                onClick={e => {
                  if (inputEl.current) inputEl.current.value = p.fn;
                  props.onSelectNewValue(p.applicationId);
                }}
              >
                {p.fn}
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
};

const AnalysisResult = (props: any) => (
  <>
    {Object.entries(
      Object.assign(props.extraResultAttributes, {
        extRef: 'String',
        comment: 'TextArea',
        files: 'File'
      })
    ).map(([era, dataType]) => (
      <Row label={words[era]} key={era}>
        <ResultField
          extraResultType={props.resultType || 'GenericResult'}
          era={era}
          dataType={dataType}
          results={props.results}
          setResults={props.setResults}
          index={props.index}
          attachments={props.attachments}
          setAttachments={props.setAttachments}
          newAttachments={props.newAttachments}
          setNewAttachments={props.setNewAttachments}
        />
      </Row>
    ))}
  </>
);

const Row = (props: any) => (
  <div className="row" key={props.label} style={{ ...props.style }}>
    <div className="col-md-3 text-right">
      <b>{words[props.label]}</b>
    </div>
    <div className="col-md-9">{props.children}</div>
  </div>
);

const setFieldValidity = (input: HTMLInputElement | HTMLSelectElement | null) => {
  if (input) {
    if (input.value && input.value !== undefined) {
      input.setCustomValidity('');
      input.classList.remove('invalidForm');
      return true;
    } else {
      input.setCustomValidity('This value needs to be set');
      input.classList.add('invalidForm');
    }
  }
  return false;
};

//const validateFileNotExistsForAnalyse = (f: File) => { };

const validateFileField = (
  input: HTMLInputElement,
  size: number,
  sizeLimit: number,
  message: string
) => {
  if (input && input.value) {
    input.classList.remove('invalidForm');
    if (size > sizeLimit) {
      input.setCustomValidity(message);

      input.classList.add('invalidForm');
      return false;
    }
    input.setCustomValidity('');
    input.classList.remove('invalidForm');
    return true;
  }
  return false;
};

const validateNumericField = (input: any) => {
  if (input) {
    if (input.value === 0) {
      input.setCustomValidity('');
      input.classList.remove('invalidForm');
      return true;
    } else if (!input.value || isNaN(input.value)) {
      input.setCustomValidity('please enter a number');
      input.classList.add('invalidForm');
    } else {
      input.setCustomValidity('');
      input.classList.remove('invalidForm');
      return true;
    }
  }
  return false;
};

const checkFormValidity = (form: any) => form.current.checkValidity();

export const validateAnalysisData = (analysis: any) =>
  analysis.analysisTypeId > 0 &&
  analysis.status > 0 &&
  analysis.reason.length > 0 &&
  (analysis.restriction
    ? analysis.restriction?.reason?.length > 0 &&
      analysis.restriction?.expirationDate !== undefined &&
      analysis.restriction?.requester?.length > 0
      ? true
      : false
    : true);

const AnalysisEditor = (props: any) => {
  /* const [restrictions, setRestrictions] = React.useState(false);*/
  // DIRTY HACK TO FORCE RERENDERS ON DEMAND
  const [rerender, setRerender] = React.useState(false);

  const [errors, setErrors] = React.useState([]);

  const [validationErrorMessage] = React.useState('');

  const [analysisType, setAnalysisType] = React.useState(
    props.formOptions.analysisTypes.find(
      (at: any) => at.id == props.formValues.analysisTypeId
    ) || {}
  );

  const [results, setResults] = React.useState(props.analysisResults);
  let initRestrictionState: RestrictionState = RestrictionState.noRestriction;
  if (props.formValues.restriction) {
    if (
      props.formValues.restriction.reason ||
      props.formValues.restriction.registeredStamp ||
      props.formValues.restriction.expirationDate ||
      props.formValues.restriction.requester
    ) {
      initRestrictionState = RestrictionState.restrictionExists;
    }
    if (props.formValues.restriction.cancelledStamp) {
      initRestrictionState = RestrictionState.cancelledRestrictionExists;
    }
  }

  const [attachments, setAttachments] = React.useState(props.analysisResultAttachments);
  const [restrictionState, setRestrictionState] = React.useState<RestrictionState>(
    initRestrictionState
  );

  const [newAttachments, setNewAttachments] = React.useState([]);
  React.useEffect(() => {
    setRestrictionState(initRestrictionState);
  }, [initRestrictionState]);

  const appSession = React.useContext(AppSessionContext);
  // TODO: move url to config
  const personSearchEndpoint = [
    '/api/actor/person?museumId=' + appSession.museumId + '&search=[',
    ']'
  ];

  const getFullName = (uuid: any) => {
    if (!analysis.people) return '';
    if (uuid === undefined) return '';
    if (analysis.people[uuid]) return analysis.people[uuid].fn;
  };
  const setNewRestriction = (status: boolean) => {
    if (status) {
      setRestrictionState(RestrictionState.newRestriction);
    } else {
      setRestrictionState(RestrictionState.noRestriction);
    }
    updateRestriction({});
  };

  const typeRef = React.useRef(null);
  const reasonRef = React.useRef(null);
  const statusRef = React.useRef(null);
  const formRef = React.useRef(null);

  // Either init these values to existing values or set them to blank

  // TODO: do we need "|| undefined". Yes, we want blank  to force user to change and set the state
  const [analysis, setAnalysis] = React.useState({
    id: +props.formValues.id || 'undefined',
    analysisTypeId: props.formValues.analysisTypeId || 'undefined',
    doneBy: props.formValues.doneBy,
    doneDate: props.formValues.doneDate,
    // TODO: fix case where you are editing analyses by others
    registeredBy: appSession.actor.dataportenId,
    registeredDate: new Date(),
    completedBy: props.formValues.completedBy,
    completedDate: props.formValues.completedDate,
    administrator: props.formValues.administrator,
    note: props.formValues.note || '',
    responsible: props.formValues.responsible,
    orgId: props.formValues.orgId || 'undefined',
    objects: props.formOptions.objects.map((o: any) => ({
      objectId: o.uuid,
      objectType: 'collection'
    })),
    people: props.formValues.people || [],
    extraAttributes: props.formValues.extraAttributes,
    caseNumber: props.formValues.caseNumber,
    status: props.formValues.status || 'undefined',
    reason: props.formValues.reason || '',
    type: 'AnalysisCollection',
    restriction: props.formValues.restriction
  });

  const inputValue = (value: any, keyName: string) => {
    if (value === undefined) value = undefined;
    setAnalysis(
      Object.assign(analysis, {
        [keyName]: value
      })
    );
    setRerender(!rerender);
  };

  const inputRestrictionValue = (value: any, keyName: string) => {
    setAnalysis(
      Object.assign(analysis, {
        restriction: Object.assign(!analysis.restriction ? {} : analysis.restriction, {
          [keyName]: value
        })
      })
    );
    setRerender(!rerender);
  };

  const updateRestriction = (restriction: Restriction) => {
    inputValue(restriction, 'restriction');
  };

  const lang = appSession.language.isEn ? 'en' : 'no';

  return (
    <div className="container">
      <form ref={formRef} noValidate>
        {rerender}
        <Row label="registeredBy">
          <FontAwesome name="user" /> {appSession.actor.fn}
          <br />
          <FontAwesome name="clock-o" /> {util.formatISOString(new Date())}
        </Row>

        {/* should not be able to change analyse type in "edit" */}
        <Row label="analysisType">
          {props.analysisTypeReadOnly ? (
            props.formOptions.analysisTypesRaw.find(
              (item: any) => item.id === props.formValues.analysisTypeId
            )[lang + 'Name']
          ) : (
            <>
              <select
                required
                ref={typeRef}
                className="form-control"
                defaultValue={analysis.analysisTypeId}
                onChange={(e: any) => {
                  const analysisType = props.formOptions.analysisTypesRaw.find(
                    (item: any) => item.id == e.target.value
                  );
                  inputValue(+e.target.value, 'analysisTypeId');
                  setFieldValidity(e.target);
                  // set resultType here
                  // analysisType.extraResultType;
                  setAnalysisType(analysisType);
                }}
              >
                <option value="undefined" disabled key="nowt" />
                {Object.keys(words.category).map((i: any) => (
                  <optgroup label={words.category[i]} key={i}>
                    {props.formOptions.analysisTypesRaw
                      .filter((item: any) => item.category == i)
                      .map((item: any) => (
                        <option value={item.id} key={item.id}>
                          {item[lang + 'Name']}
                        </option>
                      ))}
                  </optgroup>
                ))}
              </select>
            </>
          )}
        </Row>

        {analysisType.extraDescriptionAttributes &&
          (analysisType as any).extraDescriptionAttributes.map((eda: any, i: any) => (
            <Row label={eda.attributeKey} key={i}>
              {(() => {
                const ea = analysis.extraAttributes || {};
                ea['type'] = (analysisType as any).extraDescriptionType;
                switch (eda.attributeType) {
                  case 'Int': // can return a single integer
                    return (
                      <select
                        className="form-control"
                        onChange={(e: any) => {
                          ea[eda.attributeKey] = +e.target.value;
                          inputValue(ea, 'extraAttributes');
                        }}
                      >
                        {eda.allowedValues.map((item: any) => (
                          <option value={item.id} key={item.id}>
                            {item[lang + 'Label']}
                          </option>
                        ))}
                      </select>
                    );
                  case 'String': // can return a single string
                    return (
                      <input
                        className="form-control"
                        defaultValue={ea[eda.attributeKey]}
                        onChange={(e: any) => {
                          ea[eda.attributeKey] = e.target.value;
                          inputValue(ea, 'extraAttributes');
                        }}
                      />
                    ); //MUSARK-2613
                  case 'Array[Int]': // can return muliple values (array of ints)
                    return (
                      <select
                        className="form-control"
                        multiple
                        size={eda.allowedValues.length}
                        onChange={(e: any) => {
                          const types = [...e.target.options]
                            .filter(x => x.selected)
                            .map(x => +x.value);

                          ea[eda.attributeKey] = types;
                          inputValue(ea, 'extraAttributes');
                        }}
                      >
                        {eda.allowedValues.map((item: any) => {
                          return (
                            <option
                              value={item.id}
                              key={item.id}
                              selected={
                                ea[eda.attributeKey]
                                  ? ea[eda.attributeKey].some(
                                      (id: number) => id === item.id
                                    )
                                  : false
                              }
                            >
                              {item[lang + 'Label']}
                            </option>
                          );
                        })}
                      </select>
                    );
                  default:
                    return <div />;
                }
              })()}
            </Row>
          ))}

        <Row label="reason">
          <input
            required
            ref={reasonRef}
            list="reason-list"
            className="form-control"
            defaultValue={analysis.reason}
            onChange={(e: any) => {
              setFieldValidity(e.target);
              inputValue(e.target.value, 'reason');
            }}
          />
          <datalist id="reason-list">
            {props.formOptions.purposes.map((p: any) => (
              <option value={p[lang + 'Purpose']} key={p[lang + 'Purpose']} />
            ))}
          </datalist>
        </Row>

        <Row label="status">
          <select
            required
            ref={statusRef}
            className="form-control"
            onChange={(e: any) => {
              setFieldValidity(e.target);
              inputValue(+e.target.value, 'status');
            }}
            defaultValue={analysis.status}
          >
            <option value="undefined" disabled />
            {Object.keys(words.statusType).map(st => (
              <option key={st} value={st}>
                {words.statusType[st]}
              </option>
            ))}
          </select>
        </Row>

        {/* TODO: IS PLACE ACTUALLY STORED IN DB? */}
        <Row label="place">
          <select
            className="form-control"
            onChange={(e: any) => inputValue(+e.target.value, 'orgId')}
            defaultValue={analysis.orgId}
          >
            <option value="undefined" disabled />
            {props.formOptions.labs.map((p: any) => (
              <option key={p.id} value={p.id}>
                {p.fullName}
              </option>
            ))}
          </select>
        </Row>

        <Row label="caseNumber">
          <input
            className="form-control"
            defaultValue={analysis.caseNumber}
            onChange={(e: any) => inputValue(e.target.value, 'caseNumber')}
          />
        </Row>

        <hr />

        <Row label="note">
          <textarea
            className="form-control"
            defaultValue={analysis.note}
            rows={10}
            onChange={(e: any) => inputValue(e.target.value, 'note')}
          />
        </Row>

        <hr />

        <Row label="doneBy">
          <div className="row" style={{ paddingTop: 0 }}>
            <div className="col-md-6">
              <SearchBox
                key={0}
                defaultValue={getFullName(analysis.doneBy)}
                endPoint={personSearchEndpoint}
                onSelectNewValue={(uuid: any) => inputValue(uuid, 'doneBy')}
              />
            </div>
            <div className="col-md-6">
              <MusitDatePicker
                className="form-control"
                onChange={(date: any) => inputValue(date, 'doneDate')}
                onClear={() => inputValue(null, 'doneDate')}
                value={analysis.doneDate || null}
                dateFormat={DATE_FORMAT_DISPLAY}
                placeHolder={words.date}
              />
            </div>
          </div>
        </Row>

        <Row label="completedBy">
          <div className="row" style={{ paddingTop: 0 }}>
            <div className="col-md-6">
              <SearchBox
                key={1}
                defaultValue={getFullName(analysis.completedBy)}
                endPoint={personSearchEndpoint}
                onSelectNewValue={(uuid: any) => inputValue(uuid, 'completedBy')}
              />
            </div>
            <div className="col-md-6">
              <MusitDatePicker
                className="form-control"
                onChange={(date: any) => inputValue(date, 'completedDate')}
                onClear={() => inputValue(null, 'completedDate')}
                value={analysis.completedDate || null}
                dateFormat={DATE_FORMAT_DISPLAY}
                placeHolder={words.date}
              />
            </div>
          </div>
        </Row>

        <Row label="administrator">
          <div className="row" style={{ paddingTop: 0 }}>
            <div className="col-md-6">
              <SearchBox
                key={2}
                endPoint={personSearchEndpoint}
                defaultValue={getFullName(analysis.administrator)}
                onSelectNewValue={(uuid: any) => inputValue(uuid, 'administrator')}
              />
            </div>
          </div>
        </Row>

        <Row label="responsible">
          <div className="row" style={{ paddingTop: 0 }}>
            <div className="col-md-6">
              <SearchBox
                key={3}
                defaultValue={getFullName(analysis.responsible)}
                endPoint={personSearchEndpoint}
                onSelectNewValue={(uuid: any) => inputValue(uuid, 'responsible')}
              />
            </div>
          </div>
        </Row>

        <hr />

        <Row
          label="objects"
          titleTranslationKey="musit.analysis.objects"
          style={{ backgroundColor: '#f7f7f7', marginTop: '10px' }}
        >
          <ObjectViewer
            analysisResults={props.analysisResults}
            analysis={analysis}
            callback={inputValue}
            objects={props.formOptions.objects}
            analysisType={analysisType}
            results={results}
            setResults={setResults}
            attachments={attachments}
            setAttachments={setAttachments}
            newAttachments={newAttachments}
            setNewAttachments={setNewAttachments}
          />
        </Row>

        <Row
          label="samples"
          titleTranslationKey="musit.analysis.samples"
          style={{ backgroundColor: '#f7f7f7', marginTop: '10px' }}
        >
          <SampleViewer
            analysisResults={props.analysisResults}
            sampleTypes={props.formOptions.sampleTypes}
            analysis={analysis}
            callback={inputValue}
            samples={props.formOptions.samples}
            analysisType={analysisType}
            results={results}
            setResults={setResults}
            attachments={attachments}
            setAttachments={setAttachments}
            newAttachments={newAttachments}
            setNewAttachments={setNewAttachments}
            offset={props.formOptions.objects.length + 1}
          />
        </Row>

        <hr />

        <AnalysisResult
          extraResultAttributes={(analysisType as any)?.extraResultAttributes || {}}
          resultType={(analysisType as any)?.extraResultType || {}}
          results={results}
          setResults={setResults}
          attachments={attachments}
          setAttachments={setAttachments}
          newAttachments={newAttachments}
          setNewAttachments={setNewAttachments}
          index={0}
        />

        <Row label={'restrictions'}>
          <div className="form-check-inline">
            <select
              disabled={
                restrictionState === RestrictionState.restrictionExists ||
                restrictionState === RestrictionState.newRestrictionCancellation
              }
              className="form-control"
              value={
                restrictionState === RestrictionState.newRestriction ||
                restrictionState === RestrictionState.restrictionExists
                  ? 'true'
                  : 'false'
              }
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                if (e.target.value === 'true') {
                  setNewRestriction(true);
                } else {
                  setNewRestriction(false);
                }
              }}
            >
              <option value={'false'}>{words.no}</option>
              <option value={'true'}>{words.yes}</option>
            </select>
          </div>
        </Row>

        {restrictionState === RestrictionState.newRestriction && (
          <>
            <Row label="restrictionsFor">
              {/* TODO: find a sensible way to get person name */}
              <SearchBox
                endPoint={personSearchEndpoint}
                defaultValue={
                  analysis.restriction && getFullName(analysis.restriction.requester)
                }
                onSelectNewValue={(uuid: any) => inputRestrictionValue(uuid, 'requester')}
              />
            </Row>

            <Row label="reasonForRestriction">
              <input
                className="form-control"
                defaultValue={analysis.restriction && analysis.restriction.reason}
                onChange={(e: any) => inputRestrictionValue(e.target.value, 'reason')}
              />
            </Row>

            <Row label="caseNumber">
              <input
                className="form-control"
                defaultValue={analysis.restriction && analysis.restriction.caseNumbers}
                onChange={(e: any) =>
                  inputRestrictionValue([e.target.value], 'caseNumbers')
                }
              />
            </Row>

            <Row label="endDate">
              <MusitDatePicker
                className="form-control"
                onChange={(date: any) => inputRestrictionValue(date, 'expirationDate')}
                value={analysis.restriction && analysis.restriction.expirationDate}
                onClear={() => inputRestrictionValue(null, 'expirationDate')}
                placeHolder={words.date}
                dateFormat="dd.MM.yyyy"
              />
            </Row>
          </>
        )}

        {restrictionState === RestrictionState.newRestrictionCancellation && (
          <CancelRestriction
            appSession={appSession}
            isRestrictionValidForCancellation={isRestrictionValidForCancellation(
              analysis.restriction
            )}
            restriction={{
              ...analysis.restriction,
              cancelledByName: analysis.restriction.cancelledStamp
                ? getFullName(analysis.restriction.cancelledStamp.user)
                : ''
            }}
            clickCancel={() => {
              setRestrictionState(RestrictionState.cancelledRestrictionExists);
              props.handleSubmit(
                analysis,
                results,
                attachments,
                setErrors,
                props.history,
                appSession
              );
            }}
            updateRestriction={updateRestriction}
          />
        )}
        {(restrictionState === RestrictionState.cancelledRestrictionExists ||
          restrictionState === RestrictionState.restrictionExists) && (
          <ViewRestricition
            title={
              restrictionState === RestrictionState.cancelledRestrictionExists
                ? I18n.t('musit.analysis.restrictions.restrictionsCancelled')
                : I18n.t('musit.analysis.restrictions.restrictions')
            }
            restriction={{
              ...analysis.restriction,
              requesterName: getFullName(analysis.restriction.requester),
              cancelledByName: analysis.restriction.cancelledStamp
                ? getFullName(analysis.restriction.cancelledStamp.user)
                : ''
            }}
            clickCancelRestriction={
              restrictionState === RestrictionState.restrictionExists
                ? () => setRestrictionState(RestrictionState.newRestrictionCancellation)
                : undefined
            }
          />
        )}

        <div className="row">
          <div className="col-md-12 text-right" style={{ paddingTop: 50 }}>
            {errors.map((err: any) => (
              <div className="alert alert-danger" role="alert">
                <b>{words.error}: </b> {err.message}
              </div>
            ))}

            <button
              type="button"
              className="btn btn-link"
              onClick={e => {
                e.preventDefault();
                props.handleCancel();
              }}
            >
              {props.cancelButtonTitle}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={e => {
                setFieldValidity(typeRef.current);
                setFieldValidity(reasonRef.current);
                setFieldValidity(statusRef.current);

                //                checkFormValidity(formRef);
                if (checkFormValidity(formRef) && validateAnalysisData(analysis)) {
                  // Merge samples with objects
                  analysis.objects = analysis.objects.concat(
                    props.formOptions.samples.map((s: any) => ({
                      objectId: s.objectId,
                      objectType: 'sample'
                    }))
                  );

                  console.log('submitting with this analysis ->');
                  console.log(analysis);

                  e.preventDefault(); // TODO: what does this do? can it be removed? No dont

                  props.handleSubmit(
                    analysis,
                    results.map((result: any) =>
                      Object.assign(result, {
                        type: (analysisType as any)?.extraResultType || 'GenericResult'
                      })
                    ),
                    newAttachments,
                    setErrors,
                    props.history,
                    appSession
                  );
                }
                /* disabled in bugfix/MUSARK-2695 reason is that it triggers a state update and rerender
and cancels the validationError for 
  else {
                  setValidationErrorMessage(words.validationError);
                } */
              }}
            >
              {props.submitButtonTitle}
            </button>
            <br />
            <span style={{ color: 'red' }}>{validationErrorMessage}</span>
          </div>
        </div>
      </form>
    </div>
  );
};

const json = (url: any) =>
  get(url, getAccessToken())
    .then((r: any) => r.json())
    .catch(err => err);

// Fetch all the information from the API that is needed to build up
// the analysis interface
const apiFetch = (props: any) => {
  const appSession = JSON.parse('' + localStorage.getItem('appSession'));
  return (
    Promise.all([
      json('/api/management/' + appSession.museumId + '/analyses/types'),
      json('/api/management/purposes'),
      json('/api/actor/organisation/labs'),
      // get OBJECTS that correspond to these IDs
      Promise.all(
        props.objectIds.map((id: any) =>
          json(
            '/api/thingaggregate/museum/' +
              appSession.museumId +
              '/objects/' +
              id +
              '?collectionIds=' +
              appSession.collectionId
          )
        )
      ),
      // get SAMPLES that correspond to these IDs
      Promise.all(
        props.objectIds.map((id: any) =>
          json('/api/management/' + appSession.museumId + '/samples/' + id)
        )
      ),
      json('/api/management/sampletypes')
    ])
      .then(results => ({
        analysisTypes: results[0],
        analysisTypesRaw: results[0], // TEMPORARY- REMOVE THIS
        purposes: results[1],
        labs: results[2],
        // filter out objects that were not found
        objects: results[3].filter((item: any) => item.id),
        // filter out samples that were not found
        samples: results[4]
          .filter((item: any) => !(item instanceof Error))
          .map((item: any) => ({ ...item, uuid: item.objectId })),
        sampleTypes: results[5]
      }))
      // get parent objects for samples
      .then(
        results =>
          new Promise(resolve =>
            Promise.all(
              results.samples.map((sample: any) =>
                json(
                  '/api/thingaggregate/museum/' +
                    appSession.museumId +
                    '/objects/' +
                    sample.originatedObjectUuid +
                    '?collectionIds=' +
                    appSession.collectionId
                )
              )
            ).then(parentObjects => {
              results.samples.map((sample: any) =>
                Object.assign(sample, { originatedObject: parentObjects.shift() })
              );
              resolve(results);
            })
          )
      )
  );
};

export default (props: any) => {
  const [formOptions, setFormOptions] = React.useState(false);
  // Need to useEffect() so that API is only hit once
  React.useEffect(() => {
    // tslint:disable-next-line: no-floating-promises
    apiFetch({ objectIds: props.formValues.objects }).then(setFormOptions);
  }, [props.formValues.objects]);

  return (
    <>
      {formOptions ? (
        <AnalysisEditor
          analysisResults={props.analysisResults || [{}]}
          analysisResultAttachments={props.analysisResultAttachments || [[]]}
          handleSubmit={props.handleSubmit}
          formOptions={formOptions}
          handleCancel={props.handleCancel}
          handleCancelRestriction={props.handleCancelRestriction}
          history={props.history}
          formValues={props.formValues || {}}
          submitButtonTitle={words[props.submitButtonTitle]}
          cancelButtonTitle={props.cancelButtonTitle}
          analysisTypeReadOnly={props.analysisTypeReadOnly}
        />
      ) : (
        <Loader loaded={false} />
      )}
    </>
  );
};
