import * as React from 'react';
import { DerivedCoordinate } from '../types/place/place';
import { getTranslator } from '../../../shared/language';

const words = getTranslator({
  en: {
    coordinateDatum: 'Coordinate datum',
    coordinateMetadata: 'Coordinate metadata',
    coordinateType: 'Coordinate type',
    noData: 'No data',
    utmBand: 'Band',
    utmZone: 'Zone'
  },
  no: {
    coordinateDatum: 'Datum',
    coordinateMetadata: 'Koordinat-metadata',
    coordinateType: 'Koordinattype',
    noData: 'Ingen data',
    utmBand: 'Belte',
    utmZone: 'Sone'
  }
});

type Props = {
  coordinate?: string;
  datum?: string;
  children: any;
  zone?: number;
  band?: string;
  coordinateType: string;
  disabled?: boolean;
  validCoordinate: boolean;
  datums: { datum: string }[];
  coordinateTypes: { type_text: string }[];
  updateCoordinate: (fieldName: string) => (value?: string | DerivedCoordinate) => void;
  updateShowMap: () => void;
  onToggleCoordinateFormats: () => void;
  showValidCoordinate: boolean;
  coordinateSideText: JSX.Element;
};

const MGRSComponent = (p: Props) => {
  return (
    <div>
      <div className="form-row">
        <div className="col-md-2 offset-md-2">
          <b>{words.coordinateType}</b>
        </div>

        <div className="col-md-2">
          <b>{words.coordinateDatum}</b>
        </div>

        <div className="col-md-2">
          <b>{words.utmZone}</b>
        </div>

        <div className="col-md-2">
          <b>{words.utmBand}</b>
        </div>
      </div>
      <div className="form-row" style={{ paddingBottom: '15px' }}>
        <div className="col-md-2" style={{ textAlign: 'right' }}>
          <b>{'Coordinate metadata'}</b>
        </div>
        <div className="col-md-2">
          <select
            className="form-control"
            id="coordinateType"
            value={p.coordinateType}
            disabled={p.disabled}
            onChange={e => {
              p.updateCoordinate('coordinateType')(e.target.value);
            }}
          >
            {p.coordinateTypes ? (
              p.coordinateTypes.map(({ type_text }: { type_text: string }, i: number) => (
                <option key={`optionRow_${i}`} value={type_text}>
                  {type_text}
                </option>
              ))
            ) : (
              <option key={`optionRow_${1}`}>{words.noData}</option>
            )}
          </select>
        </div>
        <div className="col-md-2">
          <select
            className="form-control"
            value={p.datum}
            id="datum"
            disabled={p.disabled}
            placeholder={words.coordinateDatum}
            onChange={e => {
              p.updateCoordinate('datum')(e.target.value);
            }}
          >
            {p.datums ? (
              p.datums.map(({ datum }: { datum: string }, i: number) => (
                <option value={datum} key={`optionRow_${i}`}>
                  {datum}
                </option>
              ))
            ) : (
              <option key={`optionRow_${1}`}>{words.noData}</option>
            )}
          </select>
        </div>
        <div className="col-md-2">
          <input
            type="number"
            className="form-control"
            value={p.zone || ''}
            placeholder={words.utmZone}
            id="zone"
            onChange={e => {
              p.updateCoordinate('zone')(e.target.value);
            }}
            disabled={p.disabled}
          />
        </div>
        <div className="col-md-2">
          <input
            type="text"
            className="form-control"
            value={p.band || ''}
            placeholder={words.utmBand}
            id="mgrsBand"
            onChange={e => {
              p.updateCoordinate('band')(e.target.value.toUpperCase());
            }}
            disabled={p.disabled}
          />
        </div>
      </div>
      <div className="form-row">
        {p.children}
        {p.coordinateSideText}
      </div>
    </div>
  );
};

export default MGRSComponent;
