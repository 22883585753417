import FontAwesome from 'react-fontawesome';
import * as React from 'react';
import * as level from 'level';
// import validate from '../../components/formfields/common/validators';
// import { AppSession } from '../../types/appSession';
// import { History } from 'history';

export const NewPickListComponent = () => {
  const [newPickListNameValue, setNewPickListNameValue] = React.useState('');
  const [pickListList, setPickListList] = React.useState([]);

  const submitNewPickListButton = React.useRef<HTMLButtonElement>(null);

  const db = level('picklists');

  const getPickListNames = (db: any) =>
    new Promise((resolve, reject) => {
      const pickLists: any = [];
      db.createReadStream()
        .on('data', (data: any) => {
          pickLists.push(data);
        })
        .on('end', () => {
          console.log(pickLists);
          setPickListList(pickLists);
        });
    });

  const getPickListNamesEffect = React.useCallback(getPickListNames, db);

  React.useLayoutEffect(() => {
    console.log('in useEffect()');
    // tslint:disable-next-line:no-floating-promises
    getPickListNames(db);
  }, [getPickListNamesEffect, db]);

  const addPickListToDB = () => {
    // tslint:disable-next-line:no-floating-promises
    getPickListNames(db);
    console.log('creating picklist! ss');
    const newPickListInput = document.getElementById(
      'newPickListName'
    ) as HTMLInputElement;
    console.log(newPickListInput.value);
    db.put(newPickListInput.value, 'true')
      .then(() => console.log('I just PUTted'))
      .catch(console.error);
    // nullify pick list value (should this be in .then?)
    newPickListInput.value = '';
    setNewPickListNameValue(newPickListInput.value);
  };

  const deletePickListFromDB = (id: any) => {
    console.log('deleting picklist from db');
    db.del(id).then(() => {
      // tslint:disable-next-line:no-floating-promises
      getPickListNames(db);
    });
  };

  const AddPickListModal = (
    <div
      className="modal fade"
      id="exampleModal"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Opprett ny plukkliste
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <label htmlFor="newPickListName">Navn på plukklisten</label>
            <input
              autoFocus
              onChange={e => setNewPickListNameValue(e.target.value)}
              id="newPickListName"
              className="form-control"
              type="text"
              placeholder="navn på plukklisten"
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              id="newPickListName"
            >
              Lukk
            </button>
            <button
              disabled={!newPickListNameValue.length}
              ref={submitNewPickListButton}
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
              onClick={addPickListToDB}
            >
              Opprett
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container" style={{ marginTop: 100 }}>
      <div className="row">
        <div className="col">
          <h1>Mine plukklister</h1>
          <ul>
            {pickListList.map((pl: any) => (
              <li key={pl.key}>
                {pl.key}{' '}
                <a href="#/" onClick={() => deletePickListFromDB(pl.key)}>
                  (delete)
                </a>
              </li>
            ))}
          </ul>
          {AddPickListModal}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <button
            type="button"
            className="btn btn-outline-primary"
            data-toggle="modal"
            data-target="#exampleModal"
          >
            <FontAwesome name="plus" /> ny plukkliste
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewPickListComponent;

//TODO: autofocus on modal- seems to be quite tricky with react/bootstrap
