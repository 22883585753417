// @flow

import * as React from 'react';
import FontAwesome from 'react-fontawesome';
import { I18n } from 'react-i18nify';
import { SearchStoreState } from '../../../search/searchStore';

export type Props = {
  onChangeQueryParam: (key: string, value: string) => void;
  onSearch: () => void;
  searchStore: SearchStoreState;
};

const ConservationInputFormComponent = (props: Props) => (
  <div className="col">
    <div className="input-group">
      <input
        className="form-control border-primary"
        onChange={e => props.onChangeQueryParam('q', e.target.value)}
        placeholder={I18n.t('musit.conservation.queryPlaceholder')}
        type="text"
        value={
          (props &&
            props.searchStore &&
            props.searchStore.queryParam &&
            props.searchStore.queryParam.q) ||
          ''
        }
      />
      <div className="input-group-append">
        <button className="btn btn-primary" type="submit" onClick={e => props.onSearch()}>
          <FontAwesome name="search" /> {I18n.t('musit.texts.search')}
        </button>
      </div>
    </div>
  </div>
);

export default ConservationInputFormComponent;
