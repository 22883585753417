import { MuseumAndCollection } from '../../types/common';
import { ActorUuid } from '../person/personName';
import { InputPlace, OutPlace } from '../place/place';
import {
  DateRevisionEventAttributes,
  EventTypeId,
  SexAndStageEventAttributes,
  CollectingEventEventAttributes,
  SexAndStage_EtAuto
} from './eventTypes_auto';
import {
  MuseumObjectUuid,
  MuseumObjectSearchResult
} from '../museumObject/museumObject old';

export type Uuid = string;

export type EventUuid = Uuid;

/* I found this trick to make opaque/nominal basic types here: https://github.com/Microsoft/TypeScript/issues/202
In the future TS may perhaps support nominal/opaque types natively, then this can be simplified.
*/
export interface OrdinaryEventUuid {
  'I am nominal type OrdinaryEventUuid, nobody can match me to anything I am not': OrdinaryEventUuid;
  value: EventUuid;
}

export interface RevisionEventUuid {
  'I am nominal type RevisionEventUuid, nobody can match me to anything I am not': RevisionEventUuid;
  value: EventUuid;
}

export const createOrdinaryEventUuid = (eventUuid: EventUuid) =>
  ({ value: eventUuid } as OrdinaryEventUuid);
export const createRevisionEventUuid = (eventUuid: EventUuid) =>
  ({ value: eventUuid } as RevisionEventUuid);
//export type OrdinaryEventUuid = Uuid;
//export type RevisionEventUuid = Uuid;

//export type EventUuid = OrdinaryEventUuid | RevisionEventUuid;
export type RoleId = number;

export interface OutPlaceAndRelation {
  placeUuid: Uuid;
  roleId: RoleId;
  roleText: string;
}

export interface InputPlaceAndRelation {
  placeUuid: Uuid;
  roleId: RoleId;
}

export interface InputActorAndRelation {
  actorUuid: ActorUuid;
  roleId: RoleId;
  orderNo: number;
  actorName: string;
}

export interface OutActorAndRelation {
  actorUuid?: ActorUuid;
  roleId: RoleId;
  roleText: string;

  //The actor name on the label, may be different from the actor's current name/official name
  actorName: string;
  currentActorName: string;
  orderNo: number;
}
export interface ActorAndRelation {
  actorUuid?: ActorUuid;
  roleId: RoleId;
  roleText?: string;

  //The actor name on the label, may be different from the actor's current name/official name
  actorName: string;
  currentActorName?: string;
  orderNo: number;
}

export interface MuseumObjectAndRelation {
  museumObjectUuid: MuseumObjectUuid;
  roleId: RoleId;
}

//export type SexAndStageEventAttributes = SexAndStageEventAttributes;

export interface SexAndStagesEvent {
  eventUuid?: EventUuid;
  eventTypeId: number;
  museumId: number;
  collectionUuid: Uuid;
  attributes?: SexAndStageEventAttributes;
  revisionOf?: Uuid;
  note?: string;
  partOf?: EventUuid;
  relatedActors?: OutActorAndRelation[];
  date?: DateAttributes;
  place?: OutPlace;
  relatedMuseumObjects?: MuseumObjectAndRelation[];
}

export type SexAndStage = SexAndStage_EtAuto;

export interface DateAttributes {
  eventDateFrom?: string;
  eventDateTo?: string;
  eventDateVerbatim?: string;
  lengthOfFormat?: number;
}

export type InputDate = DateRevisionEventAttributes;

export interface MusitEvent {
  eventUuid?: EventUuid;
  eventTypeId: number;
  museumId: number;
  collectionUuid: Uuid;
  attributes?: EventAttributes;
  revisionOf?: EventUuid;
  note?: string;
  partOf?: EventUuid;
  createdBy?: ActorUuid;
  createdDate?: string;
  relatedActors?: OutActorAndRelation[];
  date?: DateAttributes;
  place?: OutPlace;
  relatedMuseumObjects?: MuseumObjectAndRelation[];
  orderNo?: number;
}

export interface CollectingEvent {
  eventUuid?: EventUuid;
  eventTypeId: number;
  museumId: number;
  collectionUuid: Uuid;
  attributes?: CollectingEventEventAttributes;
  revisionOf?: EventUuid;
  note?: string;
  partOf?: EventUuid;
  createdBy: ActorUuid;
  createdDate: string;
  relatedActors?: OutActorAndRelation[];
  date?: DateAttributes;
  place?: OutPlace;
  relatedMuseumObjects?: MuseumObjectAndRelation[];
  relatedEnrichedMuseumObjects?: MuseumObjectSearchResult[];
  orderNo?: number;
}

export type EventAttributes = any; //We know nothing of the attributes on the generic level!

export interface InputEvent {
  eventUuid?: EventUuid;
  eventTypeId: number;
  museumId: number;
  collectionUuid: Uuid;
  //note?: string;
  partOf?: EventUuid;
  relatedActors?: ActorAndRelation[];
  date?: InputDate;
  place?: InputPlace;
  attributes?: EventAttributes;
  revisionOf?: EventUuid;
  relatedMuseumObjects?: MuseumObjectAndRelation[];
  orderNo?: number;
}

export interface CollectingEventSearch extends InputEvent {
  name: string;
  methodId?: number;
  method?: string;
  note?: string;
}

export interface CollectingMethod {
  methodId: number;
  method: string;
}

export interface SubCollectionMuseumAndCollection extends MuseumAndCollection {
  collectionNameNo: string;
  collectionNameEn?: string;
  prefix?: string;
  startNo?: number;
  lastNo?: number;
  museumNumberSequenceId: number;
  subCollectionId: number;
}
export interface NextMuseumNoAndSubNo {
  museumCollectionId: number;
  prefix?: string;
  museumNoSeq: number;
  museumNo: string;
  subNo: number;
}

export interface EventRevision {
  revisionOf: EventUuid;
  eventTypeId: EventTypeId;
  attributes?: EventAttributes;
  date?: DateRevisionEventAttributes;
  relatedActors?: ActorAndRelation[];
  place?: InputPlace;
  relatedMuseumObjects?: MuseumObjectAndRelation[];
}

export interface DeletedEvent {
  eventTypeId: EventTypeId;
  eventUuid: EventUuid;
}

export type CombinedEventRevision = EventRevision | DeletedEvent;

export interface EventType {
  eventTypeId: number;
  eventType: string;
}

export interface EventRevisionPattern<T> {
  Place: () => Promise<T>;
  Date: () => Promise<T>;
  Person: () => Promise<T>;
  Attribute: () => Promise<T>;
}

export function matchEventRevisionType<T>(
  p: EventTypeId,
  pattern: EventRevisionPattern<T>
): Promise<T> {
  switch (p) {
    case EventTypeId.PlaceRevision:
      return pattern.Place();
    case EventTypeId.DateRevision:
      return pattern.Date();
    case EventTypeId.PersonRevision:
      return pattern.Person();
    case EventTypeId.AttributeRevision:
      return pattern.Attribute();
    default:
      throw new Error('Unknown revisionType: ' + p);
  }
}

export interface EventTypeMuseumAndCollection extends MuseumAndCollection {
  eventTypeId: number;
}

export enum DateFormat {
  Year = 4,
  YearMonth = 7,
  Date = 10,
  DateTime = 19
}
