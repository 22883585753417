import * as React from 'react';
import { AppSessionContext } from '../../../modules/app/AppComponent';
import { getTranslator } from '../../../shared/language';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { I18n } from 'react-i18nify';
import FontAwesome from 'react-fontawesome';
import config from '../../../config';
import * as actions from '../../../stores/actions/actions';
import NavigateSearch from '../../../search/NavigateSearch';
import './AutoCompleteSearch.css';
import { get } from '../../../shared/ajaxFetch/ajaxFetch';
import { PersonNameSearchResult as PersonSearchHit } from '../types/person/personName';
import { fixInitials } from './FullName';

const words = getTranslator({
  en: {
    search: 'Search',
    name: 'Name',
    results: 'Results',
    personSearch: 'Person search',
    noData: 'No data, search again',
    personUUID: 'Person-UUID'
  },
  no: {
    search: 'Søk',
    name: 'Navn',
    results: 'Resultater',
    personSearch: 'Søk i personer',
    noData: 'Ingen treff, søk igen',
    personUUID: 'Person-UUID'
  }
});

export const PersonSeach = (props: any) => {
  const appSession = React.useContext(AppSessionContext);
  const [userInput, setUserInput] = React.useState<string>('');
  const [showSuggestions, setShowSuggestions] = React.useState<boolean>(false);
  const [activeSuggestion, setActiveSuggestion] = React.useState<number>(-1);
  const [showResults, setShowResults] = React.useState<boolean>(false);
  const [personSuggestions, setPersonSuggestions] = React.useState<PersonSearchHit[]>([]); // list of people fetched from API

  function onChangeSearch(event: React.ChangeEvent<HTMLInputElement>) {
    setUserInput(event.target.value);
    // tslint:disable-next-line:no-floating-promises
    personSuggest(event.target.value);
    setActiveSuggestion(-1);
    setShowSuggestions(true);
    setShowResults(false);
  }

  function onClickSearch(event: React.MouseEvent<HTMLLIElement, MouseEvent>) {
    const userInputLocal = event.currentTarget.innerText;
    let activeUser = event.currentTarget.tabIndex;
    selectUser(activeUser);
    setUserInput(userInputLocal);
    setShowSuggestions(false);
  }

  function onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    const KeyPressed = event.keyCode;
    const UpKey = 38,
      DownKey = 40,
      Enter = 13;

    if (KeyPressed === Enter) {
      let noActiveUser = -1;
      selectUser(activeSuggestion);

      if (personSuggestions.length >= 1 && activeSuggestion !== noActiveUser) {
        props.history.push(
          config.magasin.urls.client.person.editPerson(
            appSession,
            personSuggestions[activeSuggestion].actorUuid
          )
        );
      }

      setShowSuggestions(false);
      setActiveSuggestion(-1);
      if (personSuggestions.length < 30) {
        setShowResults(true);
      } else {
        //"To many users to show"
      }
    } else if (KeyPressed === UpKey) {
      if (activeSuggestion === -1) {
        return;
      }

      setActiveSuggestion(activeSuggestion - 1);
    } else if (KeyPressed === DownKey) {
      if (activeSuggestion === personSuggestions.length - 1) {
        return;
      }
      setActiveSuggestion(activeSuggestion + 1);
    }
  }

  function onClickSearchButton() {
    selectUser(activeSuggestion);
    setShowSuggestions(false);
    setShowResults(true);
  }

  let suggestionsListComponent;
  if (showSuggestions && userInput) {
    if (personSuggestions.length) {
      suggestionsListComponent = (
        <ul className="suggestions">
          {personSuggestions.map((suggestion, index) => {
            let className;

            // Flag the active suggestion with a class
            if (index === activeSuggestion) {
              className = 'suggestion-active';
            }

            return (
              <li
                id={`${index}`}
                tabIndex={index}
                className={className}
                key={`${suggestion.actorUuid}-${index}`}
                onClick={event => onClickSearch(event)}
              >
                {goToUserPage(suggestion)}
              </li>
            );
          })}
        </ul>
      );
    } else {
      suggestionsListComponent = (
        <div className="no-suggestions">
          <em>{words.noData}</em>
        </div>
      );
    }
  }

  function selectUser(index: number) {
    const { dispatch } = props;
    let showFullSearch = -1;
    if (index === showFullSearch) {
      dispatch(actions.savePersonSearchResult(personSuggestions));
    } else {
      setPersonSuggestions([personSuggestions[index]]);
      dispatch(actions.savePersonSearchResult([personSuggestions[index]]));
    }
  }

  function goToUserPage(userInfo: any) {
    return (
      <Link
        className=""
        to={config.magasin.urls.client.person.editPerson(appSession, userInfo.actorUuid)}
      >
        {`${userInfo.matchedName} = [ ${userInfo.currentName} ]`}
      </Link>
    );
  }

  async function personSuggest(userInputData: string) {
    const personSuggestions = await get(
      config.api.persons.searchUrl(userInputData),
      appSession.accessToken
    );

    let data;
    if (personSuggestions.ok) data = await personSuggestions.json();
    // get the resultset and then make the dropdown
    else data = []; // if error response, just return emtpy array
    data
      .sort((a: PersonSearchHit, b: PersonSearchHit) => {
        const aName = a.matchedName ? a.matchedName : a.currentName;
        const bName = b.matchedName ? b.matchedName : b.currentName;
        return aName > bName ? 1 : -1;
      })
      .map((p: PersonSearchHit) => ({
        ...p,
        matchedName: fixInitials(p.matchedName),
        currentName: fixInitials(p.currentName)
      }));
    setPersonSuggestions(data);
  }

  React.useEffect(() => {
    //Fetch stored users in Redux Store
    if (props.personsSearch.persons.length >= 1) {
      setPersonSuggestions(props.personsSearch.persons);
      setShowResults(true);
    }
  }, [props.personsSearch.persons]);

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <NavigateSearch
            appSession={appSession}
            history={props.history.push}
            disablePersonSearch={true}
            activeNav="musit.objectModule.person.personModule"
          />
        </div>
      </div>
      <h1>{words.personSearch}</h1>
      <div className="row mb-4">
        <div className="col">
          <div className="input-group mb-2">
            <input
              autoComplete="off"
              className={'form-control border-primary'}
              type="text"
              id="searchInput"
              placeholder={I18n.t('musit.objectModule.person.searchForPersons')}
              aria-describedby="basic-addon2"
              onChange={event => onChangeSearch(event)}
              onKeyDown={event => onKeyDown(event)}
              value={userInput}
            />

            <div className="input-group-append">
              <button
                className="btn btn-primary"
                type="submit"
                id="search"
                onClick={onClickSearchButton}
              >
                <FontAwesome name="search" /> {words.search}
              </button>
            </div>
          </div>
          {suggestionsListComponent}
        </div>
      </div>
      {showResults &&
        personSuggestions.map((suggestion, i) => (
          <div className="media musit__media--search" key={'personSearch' + i}>
            <div className="media-left pr-2">
              <FontAwesome name="user" style={{ fontSize: '1.3em', height: 25 }} />
            </div>
            <div className="media-body mt-n2">
              <h5>
                <Link
                  className="nav-link"
                  to={config.magasin.urls.client.person.editPerson(
                    appSession,
                    suggestion.actorUuid
                  )}
                >
                  {`${suggestion.matchedName}`}
                </Link>
              </h5>
              <div className="row">
                <div className="col-md-2 ml-3">
                  <p className="text-left">{words.personUUID} : </p>
                </div>
                <div className="col-md-10 mt-n2 ml-n5">
                  <Link
                    className="nav-link"
                    to={config.magasin.urls.client.person.editPerson(
                      appSession,
                      suggestion.actorUuid
                    )}
                  >
                    {suggestion.actorUuid}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

function mapStateToProps(state: any) {
  return state;
}
export default connect(mapStateToProps)(PersonSeach);
