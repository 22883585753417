import {
  DerivedCoordinate,
  InputCoordinate as Coordinate
} from '../../types/place/place';
import { musitCoodinateIsValid } from '../../../../shared/util';

import * as GeoDesy from 'geodesy';

const coordToDerived = (coordinate: Coordinate) => {
  if (
    coordinate.coordinateType === 'MGRS' &&
    musitCoodinateIsValid(coordinate.coordinateType)()(coordinate.coordinateString)
  ) {
    return coordMGRSStrToDerived(
      coordinate.coordinateString,
      coordinate.coordinateType,
      coordinate.datum,
      coordinate.zone,
      coordinate.band
    );
  }

  if (
    coordinate.coordinateType === 'UTM' &&
    musitCoodinateIsValid(coordinate.coordinateType)()(coordinate.coordinateString)
  ) {
    return coordUTMStrToDerived(
      coordinate.coordinateString,
      coordinate.coordinateType,
      coordinate.datum,
      coordinate.zone,
      coordinate.band
    );
  }

  if (
    coordinate.coordinateType === 'LAT/LONG' &&
    (coordinate.coordinateGeometry
      ? coordinate.coordinateGeometry.toUpperCase() === 'POINT'
      : true) &&
    musitCoodinateIsValid(coordinate.coordinateType)(coordinate.coordinateGeometry)(
      coordinate.coordinateString
    )
  ) {
    return coordLatLongStrToDerived(
      coordinate.coordinateString,
      coordinate.coordinateType,
      coordinate.datum
    );
  }

  if (
    coordinate.coordinateType === 'LAT/LONG' &&
    coordinate.coordinateGeometry &&
    coordinate.coordinateGeometry.toUpperCase() === 'POLYGON' &&
    musitCoodinateIsValid(coordinate.coordinateType)(coordinate.coordinateGeometry)(
      coordinate.coordinateString
    )
  ) {
    return coordWKTPolygonToDerived(coordinate.coordinateString, coordinate.datum);
  }

  if (
    coordinate.coordinateType === 'LAT/LONG' &&
    coordinate.coordinateGeometry &&
    coordinate.coordinateGeometry.toUpperCase() === 'LINE' &&
    musitCoodinateIsValid(coordinate.coordinateType)(coordinate.coordinateGeometry)(
      coordinate.coordinateString
    )
  ) {
    return coordWKTLinestringToDerived(coordinate.coordinateString, coordinate.datum);
  }
  return undefined;
};

export default coordToDerived;

export const coordWKTPolygonToDerived = (
  coordinatString: string,
  datum?:
    | 'ED50'
    | 'Irl1975'
    | 'NAD27'
    | 'NAD83'
    | 'NTF'
    | 'OSGB36'
    | 'Potsdam'
    | 'TokyoJapan'
    | 'WGS72'
    | 'WGS84'
    | undefined
) => {
  const regexpPoint = '-?\\d+(\\.\\d+)?\\s+-?\\d+(\\.\\d+)?';
  const regexp = new RegExp(`${regexpPoint}`, 'g');
  const a = coordinatString.match(regexp);
  const d =
    datum === 'ED50'
      ? GeoDesy.LatLonEllipsoidal.datum.ED50
      : GeoDesy.LatLonEllipsoidal.datum.WGS84;
  const pointArray = a
    ? a.map((p: string) => {
        const par = p.split(/\s+/);
        const latLong = new GeoDesy.LatLonEllipsoidal(
          Number.parseFloat(par[1]),
          Number.parseFloat(par[0])
        ).convertDatum(d);
        return latLong ? [latLong.lat, latLong.lon] : [];
      })
    : undefined;

  const coord: DerivedCoordinate = {
    polygon: pointArray,
    d1: coordinatString
  };
  return coord;
};

export const coordWKTLinestringToDerived = (
  coordinatString: string,
  datum?:
    | 'ED50'
    | 'Irl1975'
    | 'NAD27'
    | 'NAD83'
    | 'NTF'
    | 'OSGB36'
    | 'Potsdam'
    | 'TokyoJapan'
    | 'WGS72'
    | 'WGS84'
    | undefined
) => {
  const regexpPoint = '-?\\d+(\\.\\d+)?\\s+-?\\d+(\\.\\d+)?';
  const regexp = new RegExp(`${regexpPoint}`, 'g');
  const a = coordinatString.match(regexp);
  const d =
    datum === 'ED50'
      ? GeoDesy.LatLonEllipsoidal.datum.ED50
      : GeoDesy.LatLonEllipsoidal.datum.WGS84;
  const pointArray = a
    ? a.map((p: string) => {
        const par = p.split(/\s+/);
        const latLong = new GeoDesy.LatLonEllipsoidal(
          Number.parseFloat(par[1]),
          Number.parseFloat(par[0])
        ).convertDatum(d);
        return latLong ? [latLong.lat, latLong.lon] : [];
      })
    : undefined;
  const coord: DerivedCoordinate = {
    line: pointArray,
    d1: coordinatString
  };
  return coord;
};

export const coordMGRSStrToDerived: (
  coordStr: string, //Assume like ' 32V NN(-NM) 23455(-34567), 45432(-56789) [zone][band][100kmE][100kmN][Easting][Northing]
  coordType: string,
  datum?:
    | 'ED50'
    | 'Irl1975'
    | 'NAD27'
    | 'NAD83'
    | 'NTF'
    | 'OSGB36'
    | 'Potsdam'
    | 'TokyoJapan'
    | 'WGS72'
    | 'WGS84'
    | undefined,
  zone?: number,
  band?: string
) => DerivedCoordinate | undefined = (coordStr, coordType, datum, zone, band) => {
  if (coordType === 'MGRS' && coordStr && zone && band) {
    const parsedCoordStr = coordStr.replace(/^\d\d[A-Z]/i, '');
    const letterPart = parsedCoordStr.match(/[A-Z][A-Z](-[A-Z][A-Z])?/i);
    const digitPartArr = parsedCoordStr.match(
      /(\d{1}(-\d{1})?(,|\s)\d{1}(-\d{1})?)|(\d{2}(-\d{2})?(,|\s)\d{2}(-\d{2})?)|(\d{3}(-\d{3})?(,|\s)\d{3}(-\d{3})?)|(\d{4}(-\d{4})?(,|\s)\d{4}(-\d{4})?)|(\d{5}(-\d{5})?(,|\s)\d{5}(-\d{5})?)/g
    );
    let eastingAndNorthing = digitPartArr ? digitPartArr[0].trim().split(',') : undefined;

    if (eastingAndNorthing && eastingAndNorthing.length !== 2) {
      eastingAndNorthing = digitPartArr ? digitPartArr[0].trim().split(' ') : undefined;
    }
    let easting, northing;
    if (eastingAndNorthing && eastingAndNorthing.length === 2) {
      easting = eastingAndNorthing[0];
      northing = eastingAndNorthing[1];
    }
    const eastingArr = easting && easting.split('-');
    const northingArr = northing && northing.split('-');
    const letters = letterPart && letterPart[0];
    const letterArr = letters && letters.split('-');
    const b1 = letterArr && letterArr[0];
    const b2 = letterArr && letterArr[1] ? letterArr[1] : b1;
    const e1 = eastingArr && eastingArr[0] ? eastingArr[0] : '';
    const tmpOffset = 10 ** (5 - e1.length);

    const n1 = northingArr && northingArr[0] ? northingArr[0] : '';

    const e2 = eastingArr && eastingArr[1] ? eastingArr[1] + tmpOffset : e1;
    const n2 = northingArr && northingArr[1] ? northingArr[1] + tmpOffset : n1;
    const s1 = (zone ? zone : '') + (band ? band : '') + ' ' + b1 + ' ' + e1 + ' ' + n1;

    const s2 =
      (zone ? zone.toString() : '') +
      (band ? band : '') +
      ' ' +
      b2 +
      ' ' +
      +e2 +
      ' ' +
      n2;

    const padZeroesToLengthFive = (s: string) => {
      if (s.length === 1) return s + '0000';
      if (s.length === 2) return s + '000';
      if (s.length === 3) return s + '00';
      if (s.length === 4) return s + '0';
      return s;
    };
    if (zone && band && b1 && n1 && e1 && b2 && e2 && n2 && datum) {
      try {
        const mgrs = new GeoDesy.Mgrs( //GeoDesy.Mgrs.parse(s1.trim());
          zone,
          band,
          b1[0],
          b1[1],
          Number.parseInt(padZeroesToLengthFive(e1)),
          Number.parseInt(padZeroesToLengthFive(n1)),
          datum
        );

        const mgrs2 = new GeoDesy.Mgrs(
          zone,
          band,
          b2[0],
          b2[1],
          Number.parseInt(padZeroesToLengthFive(e2)),
          Number.parseInt(padZeroesToLengthFive(n2))
        );

        const u1Tmp = mgrs.toUtm();
        const u1 = new GeoDesy.Utm(
          u1Tmp.zone,
          u1Tmp.hemisphere,
          u1Tmp.easting,
          u1Tmp.northing
        );

        const u2Tmp = mgrs2.toUtm();
        const u2 = new GeoDesy.Utm(
          u2Tmp.zone,
          u2Tmp.hemisphere,
          u2Tmp.easting + tmpOffset,
          u2Tmp.northing + tmpOffset
        );

        const d =
          datum === 'ED50'
            ? GeoDesy.LatLonEllipsoidal.datum.ED50
            : datum === 'WGS84'
            ? GeoDesy.LatLonEllipsoidal.datum.WGS84
            : undefined;
        const latLng = d ? u1.toLatLonE().convertDatum(d) : u1.toLatLonE();
        const latLng2 = d ? u2.toLatLonE().convertDatum(d) : u2.toLatLonE();

        if (u1 && latLng) {
          return {
            utmX: u1.easting,
            utmY: u1.northing,
            utmX2: u2.easting,
            utmY2: u2.northing,

            lat: latLng.lat,
            lng: latLng.lon,
            lat2: latLng2.lat,
            lng2: latLng2.lon,
            d1: s1,
            d2: s2,
            rectangle: [
              [latLng.lon, latLng.lat],
              [latLng2.lon, latLng2.lat]
            ]
          };
        }
      } catch (e) {
        return {};
      }
    }
  }
  return {};
};

export const coordLatLongStrToDerived: (
  coordStr: string, //Assume like ' 32VNN2345545432 [zone][band][100kmE][100kmN][Easting][Northing]
  coordType: string,
  datum?: string
) => DerivedCoordinate | undefined = (coordStr, coordType, datum) => {
  if (coordType === 'LAT/LONG') {
    let coordStrings = coordStr.replace(/\s{2,}/, ' ').split(' ');

    if (coordStrings.length !== 2) {
      coordStrings = coordStr.match(/\d.*?(N|S|E|W)/g) as string[];
    } else {
      coordStrings = coordStrings.map(s => s.replace(/,/, '.'));
    }

    const latStr = coordStrings ? coordStrings[0].trim() : '';
    const longStr = coordStrings ? coordStrings[1].trim() : '';

    const lat = GeoDesy.Dms.parseDMS(latStr);
    const long = GeoDesy.Dms.parseDMS(longStr);

    const d =
      datum === 'ED50'
        ? GeoDesy.LatLonEllipsoidal.datum.ED50
        : datum === 'WGS84' || datum === 'EUREF89'
        ? GeoDesy.LatLonEllipsoidal.datum.WGS84
        : undefined;

    const latLng = new GeoDesy.LatLonEllipsoidal(lat, long, d);

    let u;
    try {
      u = latLng.toUtm();
    } catch {
      u = {
        easting: undefined,
        northing: undefined
      };
    }

    if (u || latLng) {
      return {
        utmX: u.easting,
        utmY: u.northing,
        lat: latLng.lat,
        lng: latLng.lon
      };
    }
  }
  return {};
};

export const coordUTMStrToDerived: (
  coordStr: string,
  coordType: string,
  datum?: string,
  zone?: number,
  NS?: string
) => DerivedCoordinate | undefined = (coordStr, coordType, datum, zone, NS) => {
  if (coordType === 'UTM' && coordStr && zone && NS) {
    const coordArr = coordStr.match(/\d+(\.+\d+)?/g);
    let easting;
    let northing;
    if (coordArr && coordArr.length === 2) {
      easting = Number.parseFloat(coordArr[0]);
      northing = Number.parseFloat(coordArr[1]);
    }
    const parseStr = zone + ' ' + NS + ' ' + easting + ' ' + northing;

    try {
      const utm = GeoDesy.Utm.parse(parseStr);

      const latLng = utm.toLatLonE();

      if (utm && latLng) {
        return {
          utmX: utm.easting,
          utmY: utm.northing,
          lat: latLng.lat,
          lng: latLng.lon,
          d1: parseStr
        };
      }
    } catch {
      return {};
    }
  }
  return {};
};
