import NewPickListComponent from './NewPickListComponent';

// import { emitError, emitSuccess } from '../../shared/errors';
// import {
//   loadChildren$,
//   loadNode$,
//   updateMoveDialog
// } from '../movedialog/moveDialogStore';
// import {
//   markNode$,
//   markMainObject$,
//   markObject$,
//   removeNode$,
//   removeObject$,
//   refreshNode$,
//   refreshObjects$,
//   addNode$,
//   addObject$
// } from '../../stores/pickList';
// import { RxInjectLegacy as inject } from '../../shared/react-rxjs-patch';
// import { showModal } from '../../shared/modal';
// import { flowRight } from 'lodash';
// import * as PropTypes from 'prop-types';
// import MusitNode from '../../models/node';
// import MusitObject from '../../models/object';
// import Config from '../../config';
// import { TODO } from '../../types/common';
// import { AppSession } from '../../types/appSession';

// const data = {
//   appSession$: { type: PropTypes.object.isRequired },
//   pickList$: { type: PropTypes.object.isRequired }
// };

// const commands = {
//   refreshNode$,
//   refreshObjects$,
//   markObject$,
//   markNode$,
//   markMainObject$,
//   removeObject$,
//   removeNode$,
//   addNode$,
//   addObject$,
//   loadChildren$,
//   loadNode$
// };

// const customProps = (props: TODO) => ({
//   ...props,
//   updateMoveDialog,
//   emitError,
//   emitSuccess,
//   showModal,
//   moveNode: MusitNode.moveNode(),
//   moveObject: MusitObject.moveSingleObject(),
//   isTypeNode: 'nodes' === props.type,
// //  moveItems,
//   createSample: (items: TODO) => {
//     if (items[0].objectType === 'sample') {
//       props.history.push({
//         pathname: Config.magasin.urls.client.analysis.addFromSample(
//           props.appSession,
//           items[0].sampleObject ? items[0].sampleObject.objectId : items[0].objectId
//         )
//       });
//     } else if (items[0].objectType === 'collection') {
//       props.history.push({
//         pathname: Config.magasin.urls.client.analysis.addSample(
//           props.appSession,
//           items[0].uuid
//         )
//       });
//     }
//   },
//   createMultipleSamples: () => {
//     props.history.push({
//       pathname: Config.magasin.urls.client.analysis.addMultipleSamples(props.appSession)
//     });
//   },
//   createAnalysis: (items: TODO, appSession: AppSession) => {
//     props.history.push({
//       pathname: Config.magasin.urls.client.analysis.addAnalysis(appSession),
//       state: items
//     });
//   },
//   createConservation: (items: TODO[], appSession: AppSession) => {
//     props.history.push({
//       pathname: Config.magasin.urls.client.conservation.addConservation(appSession),
//       state: items
//     });
//   }
// });

// export default flowRight([
//   inject(data, commands, customProps)
// ])(NewPickListComponent)

export default NewPickListComponent;
