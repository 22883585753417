const coordinateSources = [
  {
    source: 'From article/report'
  },
  {
    source: 'From object/label'
  },
  {
    source: 'Google Earth'
  },
  {
    source: 'Google map'
  },
  {
    source: 'GPS'
  },
  {
    source: 'Kart M 711'
  },
  {
    source: 'Kart 1:10 000'
  },
  {
    source: 'Kart 1:25 000'
  },
  {
    source: 'Kart 1:50 000'
  },
  {
    source: 'Kart 1:100 000'
  },
  {
    source: 'Kart 1:200 000'
  },
  {
    source: 'Kart 1:250 000'
  },
  {
    source: 'Kart 1:500 000'
  },
  {
    source: 'Kart 1:1 000 000'
  },
  {
    source: 'Länskartor'
  },
  {
    source: 'MSN Encarta'
  },
  {
    source: 'Norgesglasset'
  },
  {
    source: 'Norgeskart.no'
  },
  {
    source: 'Stedsnavn i norske polarområder (placenames.npolar.no/stadnamn)'
  },
  {
    source: 'Sjøkart'
  },
  {
    source: 'Wikipedia'
  },
  {
    source: 'Kart 1:5 000'
  },
  {
    source: 'Google'
  },
  {
    source: 'Station list'
  },
  {
    source: 'Eniro.se'
  }
];
export default coordinateSources;
