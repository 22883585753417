import * as React from 'react';
import { I18n } from 'react-i18nify';
import Config from '../config';
import { AppSession } from '../types/appSession';

export type Props = {
  activeNav?: string;
  appSession: AppSession;
  disableAnalysis?: boolean;
  disableConservation?: boolean;
  disableObject?: boolean;
  disableCollectingEvent?: boolean;
  disablePersonSearch?: boolean;
  history: (url: string) => void;
};

const NavigateSearch = (props: Props) => {
  const getNavItem = (link: any, title: string) => {
    return (
      <li className="nav-item">
        <a
          className={'nav-link' + (props.activeNav === title ? ' active' : '')}
          href="#/"
          onClick={e => props.history(link)}
        >
          {I18n.t(title)}
        </a>
      </li>
    );
  };

  return (
    <ul className="nav nav-tabs" style={{ marginTop: 80, marginBottom: 20 }}>
      {' '}
      {(props.appSession.rolesForModules.storageFacilityRead || props.appSession.isGod) &&
        getNavItem(
          Config.magasin.urls.client.searchObjects.goToSearchObjects(props.appSession),
          'musit.objectsearch.title'
        )}
      {(props.appSession.rolesForModules.collectionManagementRead ||
        props.appSession.isGod) &&
        getNavItem(
          Config.magasin.urls.client.conservation.baseUrl(props.appSession),
          'musit.conservation.conservation'
        )}
      {(props.appSession.rolesForModules.collectionManagementRead ||
        props.appSession.isGod) &&
        getNavItem(
          Config.magasin.urls.client.analysis.baseUrl(props.appSession),
          'musit.analysis.analysis'
        )}
    </ul>
  );
};

export default NavigateSearch;
