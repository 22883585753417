import * as React from 'react';
import { AppSession } from '../../../../src/types/appSession';
import { uuidValidate } from '../../../shared/util';
import {
  InputMuseumObject,
  //  MuseumObjectAttributes,
  MuseumNo
} from '../types/museumObject/museumObject';
import {
  getEvent,
  updateEvent,
  setEventAttribute,
  createObjectEventHeaderData,
  getCollection
} from './ObjectContainerFunctions';
import { EventTypeId } from '../types/event/eventTypes_auto';
import { MusitEvent, SubCollectionMuseumAndCollection } from '../types/event/event';
import { getTranslator } from '../../../shared/language';
import config from '../../../config';
import { get } from '../../../shared/ajaxFetch/ajaxFetch';
import './EditMetaDataComponent.css';

export const words = getTranslator({
  en: {
    collection: 'Collection',
    objectNumber: 'Object number',
    oldNumbers: 'Old number(s)',
    subCollection: 'Sub collection',
    subNumber: 'Sub number',
    uuid: 'UUID',
    oldBarcode: 'Barcode',
    boxOrJar: 'Box/Jar',
    slideOrTube: 'Slide/Tube',
    openOtherAttributes: '+ Other attributes',
    closeOtherAttributes: '- Close other attribues',
    resetButton: 'Reset'
  },
  no: {
    collection: 'Samling',
    objectNumber: 'Objektnummer',
    oldNumbers: 'Gamle nummere',
    subCollection: 'Subsamling',
    subNumber: 'Subnummer',
    uuid: 'UUID',
    oldBarcode: 'Barkode',
    boxOrJar: 'Boks/Krukke',
    slideOrTube: 'Glassplate/Rør',
    openOtherAttributes: '+ Annen registrering',
    closeOtherAttributes: '- Lukk annen registrering',
    resetButton: 'Nullstill'
  }
});

type PropTypes = {
  genericEvents: MusitEvent[];
  subCollectionId?: number;
  changeGenericEditEvents: React.Dispatch<React.SetStateAction<MusitEvent[]>>;
  appSession: AppSession;
  changeMuseumObjectData: React.Dispatch<React.SetStateAction<InputMuseumObject>>;
  registerChangeEventId: (eventId: number) => void;
};

const MuseumNoComponent = ({
  museumNoData,
  subCollectionId,
  updateMuseumNo,
  appSession
}: {
  museumNoData: MuseumNo | undefined;
  subCollectionId?: number;
  oldNumbers: string | undefined;
  owner: string | undefined;
  updateMuseumNo?: (field: string, value: string) => void;
  updateAttributes?: (field: string, value: string) => void;
  appSession: AppSession;
}) => {
  const selectedCollection = getCollection(
    museumNoData && museumNoData.collection
      ? museumNoData.collection
      : appSession.collectionId
  );
  const collectionName = selectedCollection ? words[selectedCollection.name] : '';
  const [subCollectionName, changeSubCollectionName] = React.useState<string>('');
  React.useEffect(() => {
    const fetchData = async () => {
      const result: Response = await get(
        config.api.objects.getSubcollections(appSession),
        appSession.accessToken
      );
      const subCollections: SubCollectionMuseumAndCollection[] = await result.json();
      const subCollection = subCollections.find(
        s => s.subCollectionId === subCollectionId
      );
      if (subCollection) {
        const word = getTranslator({
          en: { subCollectionName: subCollection.collectionNameEn },
          no: { subCollectionName: subCollection.collectionNameNo }
        });
        changeSubCollectionName(word.subCollectionName);
      }
    };
    // tslint:disable-next-line:no-floating-promises
    fetchData();
  }, [appSession, subCollectionId]);

  return (
    <>
      <div className="row">
        {inputData(
          'museumNo',
          words.objectNumber,
          3,
          museumNoData ? museumNoData.museumNo : '',
          updateMuseumNo,
          undefined,
          true,
          true
        )}
        {inputData(
          'subNo',
          words.subNumber,
          3,
          museumNoData ? museumNoData.subNo : '',
          updateMuseumNo,
          undefined,
          true,
          true
        )}
        {inputData(
          'collection',
          words.collection,
          3,
          collectionName,
          updateMuseumNo,
          undefined,
          true,
          true
        )}
        {inputData(
          'subColl',
          words.subCollection,
          3,
          subCollectionName,
          updateMuseumNo,
          undefined,
          true,
          true
        )}
      </div>
      <div className="row"></div>
    </>
  );
};

const EditMetaDataComponent = (props: PropTypes) => {
  const musNoEvent = getEvent(EventTypeId.MuseumNoAssignment, props.genericEvents);
  const attrEvent = getEvent(
    EventTypeId.MuseumObjectAttributeAssignment,
    props.genericEvents
  );
  const origEvent = React.useRef(
    getEvent(EventTypeId.MuseumObjectAttributeAssignment, props.genericEvents)
  ); // TODO
  /*  React.useEffect(() => {
    origEvent.current = attrEvent;
  },[attrEvent]); */

  const updateAndRegisterEvent = (event: MusitEvent) => {
    props.changeGenericEditEvents(updateEvent(event, props.genericEvents));
    props.registerChangeEventId(event.eventTypeId);
  };
  const [showExtraAttributes, setShowExtraAttributes] = React.useState<boolean>(false);
  const updateMuseumNo = (field: string, value: string) => {
    let museumNoSeq;
    if (field === 'museumNo') {
      const matchArray = value.match(/\d+/g);
      if (matchArray && matchArray.length === 1) {
        museumNoSeq = parseInt(matchArray[0]);
      }
    }
    const existingOrNewEvent = musNoEvent
      ? musNoEvent
      : createObjectEventHeaderData(props.appSession, EventTypeId.MuseumNoAssignment);
    const fieldUpdated = setEventAttribute(existingOrNewEvent, field, value);
    const updatedEvent = museumNoSeq
      ? setEventAttribute(fieldUpdated, 'museumNoSeq', museumNoSeq)
      : fieldUpdated;
    updateAndRegisterEvent(updatedEvent);
  };
  const oldNumbers =
    attrEvent &&
    attrEvent.attributes &&
    attrEvent.attributes.oldNumbers &&
    attrEvent.attributes.oldNumbers.join(',');

  const updateAttributes = (field: string, value: string) => {
    const convertedValue =
      field === 'oldNumbers' || field === 'owner'
        ? value.split(',')
        : field === 'uuids'
        ? [value]
        : value && value !== ''
        ? value
        : undefined;
    const existingOrNewEvent = attrEvent
      ? attrEvent
      : createObjectEventHeaderData(
          props.appSession,
          EventTypeId.MuseumObjectAttributeAssignment
        );
    const updatedEvent = setEventAttribute(existingOrNewEvent, field, convertedValue);
    updateAndRegisterEvent(updatedEvent);
  };

  const resetFields = () => {
    console.log(attrEvent?.attributes);
    //(document.getElementById('oldNumbers') as HTMLInputElement).value = '';
    if (attrEvent && attrEvent.attributes && origEvent.current?.attributes) {
      const updatedEvent = { ...attrEvent, attributes: origEvent.current.attributes };
      updateAndRegisterEvent(updatedEvent);
    }
  };

  return (
    <div className="container-fluid">
      <form className="form-group needs-validation" noValidate>
        <MuseumNoComponent
          appSession={props.appSession}
          museumNoData={musNoEvent && musNoEvent.attributes}
          oldNumbers={
            attrEvent &&
            attrEvent.attributes &&
            attrEvent.attributes.oldNumbers &&
            attrEvent.attributes.oldNumbers.join(',')
          }
          owner={
            attrEvent &&
            attrEvent.attributes &&
            attrEvent.attributes.owner &&
            attrEvent.attributes.owner.join(',')
          }
          subCollectionId={props.subCollectionId}
          updateMuseumNo={updateMuseumNo}
          updateAttributes={updateAttributes}
        />
        <div className="row ">
          {inputData(
            'uuids',
            words.uuid,
            8,
            attrEvent && attrEvent.attributes && attrEvent.attributes.uuids,
            updateAttributes,
            'color-darkslateblue',
            attrEvent && attrEvent.attributes && attrEvent.attributes.uuids
              ? uuidValidate(attrEvent.attributes.uuids[0])
              : undefined
          )}
          <div className={'col-md-4'}>
            <label></label>
            <div
              className="createLink"
              onClick={() => setShowExtraAttributes(!showExtraAttributes)}
            >
              {showExtraAttributes
                ? words.closeOtherAttributes
                : words.openOtherAttributes}
            </div>
          </div>
        </div>

        <div
          className={
            showExtraAttributes ? `show backgroundGrey mt-4 p-3 pb-4 rounded` : `hide`
          }
        >
          <div className="row">
            {inputData(
              'oldNumbers',
              words.oldNumbers,
              6,
              oldNumbers ? oldNumbers : '',
              updateAttributes,
              'color-darkslateblue'
            )}
            {inputData(
              'oldBarcode',
              words.oldBarcode,
              6,
              attrEvent && attrEvent.attributes && attrEvent.attributes.oldBarcode,
              updateAttributes,
              'color-darkslateblue',
              undefined,
              undefined,
              'number'
            )}
          </div>
          <div className="row">
            {inputData(
              'boxOrJar',
              words.boxOrJar,
              6,
              (attrEvent && attrEvent.attributes && attrEvent.attributes.boxOrJar) || '',
              updateAttributes,
              'color-darkslateblue',
              undefined,
              undefined
            )}
            {inputData(
              'slideOrTube',
              words.slideOrTube,
              6,
              (attrEvent && attrEvent.attributes && attrEvent.attributes.slideOrTube) ||
                '',
              updateAttributes,
              'color-darkslateblue',
              undefined,
              undefined
            )}
            <div className="col-md-12 createLink text-right">
              + Legg til nytt underobject {/* TODO */}
            </div>
          </div>
        </div>

        <button
          className="btn btn-outline-primary mt-2 float-right bg-white text-dark"
          onClick={e => {
            e.preventDefault();
            resetFields(); /* TODO */
          }}
        >
          {words.resetButton}
        </button>
      </form>
    </div>
  );
};

export const inputData = (
  fieldId: string,
  description: string,
  width: number,
  value?: string | number | string[],
  onUpdate?: (field: string, value: string) => void,
  classes?: string | '',
  valid = true,
  disabled = false,
  type = 'text'
) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onUpdate) {
      onUpdate(fieldId, e.target.value);
    }
  };

  return (
    <div className={'col-md-' + width}>
      <label htmlFor={fieldId}>{description}</label>
      <input
        type={type}
        disabled={disabled}
        className={`form-control${valid ? ' ' + classes : ' is-invalid'}`}
        id={fieldId}
        placeholder={description}
        value={value}
        onChange={onChange}
      />
      <div className="invalid-feedback">Ikke gyldig</div>
    </div>
  );
};

export default EditMetaDataComponent;
