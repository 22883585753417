import * as React from 'react';
import PersonContainer, { createPersonForPost } from './PersonContainer';
import { InputOutputPerson, PersonAttribute, Synonym } from '../types/person/personName';
import { MuseumAndCollection } from '../types/common';
import Config from '../../../config';
import { post } from '../../../shared/ajaxFetch/ajaxFetch';
import { AppSessionContext } from '../../app/AppComponent';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-i18nify';
import { emitSuccess, emitError } from '../../../shared/errors';
import { Uuid } from 'src/types/common';
import * as H from 'history';

const initPerson: InputOutputPerson = {
  name: '',
  personAttribute: { legalEntityType: 'Person' }
};

const addPerson = (token: string, synonymsUpdated: boolean) => async (
  data: InputOutputPerson
) => {
  const addUrl = Config.api.persons.addUrl;
  try {
    const response = await post(
      addUrl,
      JSON.stringify(createPersonForPost(data, synonymsUpdated)),
      token
    );

    if (response.ok) {
      const uuid = await response.json();

      emitSuccess({
        eventTypeId: 'saveSuccess',
        message: I18n.t('musit.texts.saveConfirmed')
      });
      return uuid.actorUuid;
    } else {
      emitError({
        eventTypeId: 'errorOnSave',
        message: I18n.t('musit.errorMainMessages.saveError')
      });
    }
  } catch (e) {
    emitError({
      eventTypeId: 'errorOnSave',
      message: I18n.t('musit.errorMainMessages.saveError')
    });
  }
};

const Wrapper = ({
  history,
  id,
  fromModal,
  closeModal
}: {
  history: H.History;
  id: string;
  fromModal: boolean;
  closeModal: any;
}) => {
  const appSession = React.useContext(AppSessionContext);
  const [personState, updatePerson] = React.useState<InputOutputPerson>(initPerson);
  const [hasChanges, updateHasChanges] = React.useState<boolean>(false);
  const updatePersonObserved = (p: InputOutputPerson) => {
    updatePerson(p);
    updateHasChanges(true);
  };

  const [synonymsUpdated, changeSynonymsUpdated] = React.useState<boolean>(false);

  const updatePersonAttributes = (a: PersonAttribute) => {
    updatePersonObserved((p: InputOutputPerson) => ({ ...p, personAttribute: a }));
  };
  const onChangeTextField = (fieldName: string) => (value: string) => {
    updatePersonObserved({ ...personState, [fieldName]: value });
  };
  const onChangeSynonyms = (
    displaySynonyms: Synonym[],
    addedOrChangedSynonyms: Synonym[],
    removedActorNames: Uuid[]
  ) => {
    updatePersonObserved({
      ...personState,
      synonyms: displaySynonyms,
      addedOrUpdatedActorNames: addedOrChangedSynonyms,
      removedActorNames: removedActorNames
    });
    changeSynonymsUpdated(true);
  };

  const onCancel = () => {
    updatePerson(initPerson);
  };
  const onChangeMuseumAndCollection = (museumAndCollection: MuseumAndCollection[]) => {
    updatePersonObserved({
      ...personState,
      collections: museumAndCollection
    });
  };
  const saveEnabled = hasChanges && personState.name !== '';
  return (
    <PersonContainer
      id={id}
      onCancel={onCancel}
      fromModal={fromModal || false}
      saveEnabled={saveEnabled}
      hasChanges={hasChanges}
      savePerson={addPerson(appSession.accessToken, synonymsUpdated)}
      mergePersons={undefined}
      updatePersonState={updatePersonObserved}
      initPerson={initPerson}
      history={history}
      updatePersonAttributes={updatePersonAttributes}
      onChangeTextField={onChangeTextField}
      onChangeSynonyms={onChangeSynonyms}
      onChangeMuseumAndCollection={onChangeMuseumAndCollection}
      personState={personState}
      closeModal={closeModal}
    />
  );
};

const AddPersonContainer = ({
  fromModal,
  id,
  closeModal
}: {
  fromModal: boolean;
  id: string;
  closeModal?: any;
}) =>
  withRouter(({ history }) => (
    <Wrapper history={history} fromModal={fromModal} id={id} closeModal={closeModal} />
  ));

export default AddPersonContainer;
