import { Maybe, Star, TODO } from '../types/common';
import { RawValue } from './form';

export const booleanMapper = {
  fromRaw: (s: Maybe<RawValue>) => (s ? true : false),
  toRaw: (s: Maybe<boolean>) => !!s
};

export const stringMapper = {
  fromRaw: (s: Maybe<Star>) => s,
  toRaw: (s: Maybe<string>) => (s ? '' + s : null)
};

export const noMapper = {
  fromRaw: (s: Maybe<TODO>) => s,
  toRaw: (s: Maybe<TODO>) => s
};

export const numberMapper = {
  fromRaw: (s: Maybe<RawValue>) => {
    if (!s) {
      return null;
    }
    return parseFloat((s + '').replace(/,/g, '.'));
  },
  toRaw: (s: Maybe<number>) => {
    if (!s) {
      return null;
    }
    return (s < 0 ? '-' : '') + ('' + s).replace(/\./g, ',');
  }
};
