import * as React from 'react';
import moment from 'moment';
import { getTranslator, getLanguage } from '../../../shared/language';
import {
  DisseminationStatusTable,
  EventTypeId,
  RegistrationStatusTable
} from '../types/event/eventTypes_auto';
import { MusitEvent } from '../types/event/event';
import { Person } from '../types/person/personName';
import {
  createObjectEventHeaderData,
  getEvent,
  setEventAttribute,
  updateEvent
} from './ObjectContainerFunctions';
import { AppSession } from 'src/types/appSession';

export type AdmProps = {
  regDate: string;
  changeStatus: { publicationStatusChanged: boolean; registrationStatusChanged: boolean };
  regBy: string;
  lastChangedDate: string;
  lastChangeBy: string;
  registrationStatuses: RegistrationStatusTable[];
  publicationStatuses: DisseminationStatusTable[];
  genericEvents: (MusitEvent & { actorName?: Person })[];
  appSession: AppSession;
  changeGenericEvents?: React.Dispatch<React.SetStateAction<MusitEvent[]>>;
  registerChangeEventId?: (eventId: number) => void;
  changeAdmProps?: React.Dispatch<React.SetStateAction<AdmProps>>;
};

const words = getTranslator({
  en: {
    dropDownRegTitle: 'Select registration status',
    dropDownRegInfoTitle: 'Last registration status change',
    dropDownRegInfoTitleNext: 'Next registration status change',
    dropDownPubTitle: 'Change publication status',
    dropDownPubInfoTitle: 'Last publication status change',
    noDate: 'No date set yet',
    object: {
      regDate: 'Register date',
      user: 'User',
      lastDate: 'Last changed date',
      lastBy: 'Last changed by'
    }
  },
  no: {
    dropDownRegTitle: 'Endre registreringsstatus',
    dropDownRegInfoTitle: 'Siste endring i registreringstatus',
    dropDownRegInfoTitleNext: 'Neste endring i registreringstatus',
    dropDownPubTitle: 'Endre publiseringsstatus',
    dropDownPubInfoTitle: 'Siste endring i publiseringsstatus',
    noDate: 'Ikke satt noe dato ennå',
    object: {
      regDate: 'Registreringsdato',
      user: 'Bruker',
      lastDate: 'Dato siste endring',
      lastBy: 'Sist endret av'
    }
  }
});

const AdminComponent = (props: AdmProps) => {
  const lang = getLanguage();
  const registrationStatusValueBE =
    getEvent(EventTypeId.RegistrationStatus, props.genericEvents) || undefined;
  const publicationStatusValueBE =
    getEvent(EventTypeId.DisseminationStatus, props.genericEvents) || undefined;
  const registrationStatus =
    registrationStatusValueBE?.attributes.registrationStatusId || '1';
  const publicationStatus =
    publicationStatusValueBE?.attributes.disseminationStatusId || '1';
  const lastChangePublicationStatusName = publicationStatusValueBE?.actorName?.name || '';
  const lastChangeRegistrationStatusName =
    registrationStatusValueBE?.actorName?.name || '';

  const setPublicationStatusChanged = (b: boolean) =>
    props.changeAdmProps &&
    props.changeAdmProps((a: AdmProps) => ({
      ...a,
      changeStatus: { ...a.changeStatus, publicationStatusChanged: b }
    }));
  const setRegistrationStatusChanged = (b: boolean) =>
    props.changeAdmProps &&
    props.changeAdmProps((a: AdmProps) => ({
      ...a,
      changeStatus: { ...a.changeStatus, registrationStatusChanged: b }
    }));

  let registrationStatusDateBE = moment(registrationStatusValueBE?.createdDate).format(
    'YYYY-MM-DD HH:mm:ss'
  );
  if (registrationStatusValueBE === undefined) {
    registrationStatusDateBE = words.noDate;
  }
  let publicationStatusDateBE = moment(publicationStatusValueBE?.createdDate).format(
    'YYYY-MM-DD HH:mm:ss'
  );
  if (publicationStatusValueBE === undefined) {
    publicationStatusDateBE = words.noDate;
  }
  const registrationDateToDisplay = moment(props.regDate).format('YYYY-MM-DD HH:mm:ss');
  const updDateToDisplay = moment(props.lastChangedDate).format('YYYY-MM-DD HH:mm:ss');

  const changeRegistrationStatusData = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    setRegistrationStatusChanged(true);
    const newValue = event.currentTarget.value;
    const attrEvent: MusitEvent | undefined = getEvent(
      EventTypeId.RegistrationStatus,
      props.genericEvents
    );
    const existingOrNewAttrEventReg = attrEvent
      ? attrEvent
      : createObjectEventHeaderData(props.appSession, EventTypeId.RegistrationStatus);
    const addEventAttrValue = setEventAttribute(
      existingOrNewAttrEventReg,
      'registrationStatusId',
      newValue
    );
    const updatedEvents = updateEvent(addEventAttrValue, props.genericEvents);
    props.changeGenericEvents && props.changeGenericEvents(updatedEvents);
    props.registerChangeEventId &&
      props.registerChangeEventId(addEventAttrValue.eventTypeId);
  };
  const changePublicationStatusData = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    setPublicationStatusChanged(true);

    const newValue = event.currentTarget.value;

    const attrEvent: MusitEvent | undefined = getEvent(
      EventTypeId.DisseminationStatus,
      props.genericEvents
    );
    const existingOrNewAttrEventPub = attrEvent
      ? attrEvent
      : createObjectEventHeaderData(props.appSession, EventTypeId.DisseminationStatus);
    const addEventAttrValue = setEventAttribute(
      existingOrNewAttrEventPub,
      'disseminationStatusId',
      newValue
    );
    const updatedEvents = updateEvent(addEventAttrValue, props.genericEvents);
    props.changeGenericEvents && props.changeGenericEvents(updatedEvents);
    props.registerChangeEventId &&
      props.registerChangeEventId(addEventAttrValue.eventTypeId);
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        <form className="form-horizontal">
          <div className="row">
            <div className={`col-md-4`}>
              <label className={'control-label'} htmlFor="registrationStatus">
                {words.dropDownRegTitle}
              </label>
              <select
                className="form-control"
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                  changeRegistrationStatusData(event)
                }
                value={registrationStatus}
              >
                {props.registrationStatuses.map(
                  (t: RegistrationStatusTable, i: number) => (
                    <option
                      key={`${t.registrationStatusId}`}
                      value={t.registrationStatusId}
                    >
                      {lang === 'en' ? t.registrationStatusEn : t.registrationStatusNo}
                    </option>
                  )
                )}
              </select>
            </div>

            {!props.changeStatus.registrationStatusChanged
              ? displayField(
                  'currentRegistration',
                  `${words.dropDownRegInfoTitle}`,
                  registrationStatusDateBE +
                    (lastChangeRegistrationStatusName
                      ? ' av ' + lastChangeRegistrationStatusName
                      : lastChangeRegistrationStatusName),
                  8
                )
              : displayField(
                  'nextRegistration',
                  `${words.dropDownRegInfoTitleNext}`,
                  ' av ' + props.appSession.actor.fn,
                  8
                )}
          </div>
          <div className="row">
            <div className={`col-md-4`}>
              <label className={'control-label'} htmlFor="selectPublicationStatus">
                {words.dropDownPubTitle}
              </label>
              <select
                className="form-control"
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  changePublicationStatusData(event);
                }}
                value={publicationStatus}
              >
                {props.publicationStatuses.map(
                  (t: DisseminationStatusTable, i: number) => (
                    <option
                      key={`${t.disseminationStatusId}`}
                      value={t.disseminationStatusId}
                    >
                      {lang === 'en' ? t.disseminationStatusEn : t.disseminationStatusNo}
                    </option>
                  )
                )}
              </select>
            </div>
            {!props.changeStatus.publicationStatusChanged
              ? displayField(
                  'currentPublicationStatus',
                  `${words.dropDownPubInfoTitle}`,
                  publicationStatusDateBE +
                    (lastChangePublicationStatusName
                      ? ' av ' + lastChangePublicationStatusName
                      : lastChangePublicationStatusName),
                  8
                )
              : displayField(
                  'nextPublication',
                  `${words.dropDownRegInfoTitleNext}`,
                  ' av ' + props.appSession.actor.fn,
                  8
                )}
          </div>
          <br />
          <hr style={{ border: '1px solid grey' }} />
          <div className="row">
            {displayField(
              'objRegDate',
              words.object.regDate,
              registrationDateToDisplay,
              3
            )}
            {displayField('objUserName', words.object.user, props.regBy, 3)}
            {displayField('objChangedDate', words.object.lastDate, updDateToDisplay, 3)}
            {displayField('objChangedUser', words.object.lastBy, props.lastChangeBy, 3)}
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

const displayField = (
  fieldId: string,
  description: string,
  content: string | undefined,
  widthContent: number
) => (
  <React.Fragment>
    <div className={'text-center col-md-' + widthContent}>
      <label className={'control-label'} htmlFor={fieldId}>
        {description}
      </label>
      <div id={fieldId}>{content}</div>
    </div>
  </React.Fragment>
);

export default AdminComponent;
