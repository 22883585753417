import * as React from 'react';

import { TODO } from '../../types/common';

export default (props: TODO) => (
  <button
    disabled={props.disabled}
    className="btn btn-link"
    type="button"
    onClick={props.onClick}
  >
    {props.label}
  </button>
);
