export const typeStatusList = [
  {
    typeInfoTypeId: 1,
    typeInfoTypeNo: 'Holotype',
    typeInfoTypeEn: 'Holotype'
  },
  {
    typeInfoTypeId: 2,
    typeInfoTypeNo: 'Isotype',
    typeInfoTypeEn: 'Isotype'
  },
  {
    typeInfoTypeId: 3,
    typeInfoTypeNo: 'Neotype',
    typeInfoTypeEn: 'Neotype'
  },
  {
    typeInfoTypeId: 4,
    typeInfoTypeNo: 'Epitype',
    typeInfoTypeEn: 'Epitype'
  },
  {
    typeInfoTypeId: 5,
    typeInfoTypeNo: 'Syntype',
    typeInfoTypeEn: 'Syntype'
  },
  {
    typeInfoTypeId: 6,
    typeInfoTypeNo: 'Paratype',
    typeInfoTypeEn: 'Paratype'
  },
  {
    typeInfoTypeId: 7,
    typeInfoTypeNo: 'Paralectotype',
    typeInfoTypeEn: 'Paralectotype'
  },
  {
    typeInfoTypeId: 8,
    typeInfoTypeNo: 'Annet - se kommentar',
    typeInfoTypeEn: 'Other - see comments'
  },
  {
    typeInfoTypeId: 9,
    typeInfoTypeNo: 'Allotype',
    typeInfoTypeEn: 'Allotype'
  },
  {
    typeInfoTypeId: 10,
    typeInfoTypeNo: 'Cotype',
    typeInfoTypeEn: 'Cotype'
  },
  {
    typeInfoTypeId: 11,
    typeInfoTypeNo: 'Lectotype',
    typeInfoTypeEn: 'Lectotype'
  },
  {
    typeInfoTypeId: 12,
    typeInfoTypeNo: 'Reference',
    typeInfoTypeEn: 'Reference'
  },
  {
    typeInfoTypeId: 13,
    typeInfoTypeNo: 'Paraneotype',
    typeInfoTypeEn: 'Paraneotype'
  }
];
