import * as React from 'react';
import {
  CollectingEvent,
  DateAttributes,
  OutActorAndRelation
} from '../../types/event/event';
import { CollectingEventEventAttributes } from '../../types/event/eventTypes_auto';
import { UpdateIsChangedFunc } from '../../collectingEvent/CollectingEventContainer';

import { CollectingEventHeader } from '../../types/frontend/collectingEventForm';

import { OutPlace } from '../../types/place/place';
import { AppSession } from '../../../../types/appSession';
import { CollEventInline, DisplayState } from './CollectingEventRelationComponent';
import * as H from 'history';
import { MuseumObjectSearchResult } from '../../types/museumObject/museumObject old';

interface ContainerProps {
  appSession: AppSession;
  objectUuid: string;
  eventUuid?: string;
  mode: 'UNSHARED' | 'SHARED';
  collAttr?: CollectingEventEventAttributes;
  date?: DateAttributes;
  headerData: CollectingEventHeader;
  history: H.History;

  actorAndRelations?: OutActorAndRelation[];
  changeDisplayCollEvent: React.Dispatch<React.SetStateAction<DisplayState>>;
  showCollectingEvent: DisplayState;

  updateIsChanged: UpdateIsChangedFunc;
  place?: OutPlace;
  changeCollectingEventData: React.Dispatch<React.SetStateAction<CollectingEvent>>;

  relatedEnrichedMuseumObjects?: MuseumObjectSearchResult[];
  copyAndAddCollectingEvent?: () => Promise<void>;
}

const Container = (p: ContainerProps) => {
  const onChangeMetadata = (attributes: CollectingEventEventAttributes) => {
    p.updateIsChanged(e => ({ ...e, metaData: { isChanged: true } }));
    p.changeCollectingEventData(e => ({ ...e, attributes }));
  };

  const onChangeDate = (date: DateAttributes) => {
    p.updateIsChanged(e => ({ ...e, date: { isChanged: true } }));

    p.changeCollectingEventData(e => ({ ...e, date }));
  };

  const onChangeActorAndRelation = (actorsAndRelations: OutActorAndRelation[]) => {
    p.updateIsChanged(e => ({ ...e, persons: { isChanged: true } }));
    p.changeCollectingEventData(e => ({ ...e, relatedActors: actorsAndRelations }));
  };

  const onChangeMethodId = (methodId: string) => {
    p.updateIsChanged(e => ({ ...e, metaData: { isChanged: true } }));
    p.changeCollectingEventData(e => {
      const attr = e.attributes;
      if (attr) {
        return {
          ...e,
          attributes: { ...attr, methodId: Number.parseInt(methodId) }
        };
      }
      return { ...e, attributes: { name: '', methodId: Number.parseInt(methodId) } };
    });
  };

  const onChangePlace = (place: OutPlace) => {
    p.updateIsChanged(e => ({ ...e, place: { isChanged: true } }));
    p.changeCollectingEventData(e => ({ ...e, place }));
  };

  return (
    <CollEventInline
      place={p.place}
      history={p.history}
      appSession={p.appSession}
      fromMuseumObjectUuid={p.objectUuid}
      collAttr={p.collAttr}
      actorAndRelations={p.actorAndRelations}
      showCollectingEvent={p.showCollectingEvent}
      changeDisplayCollEvent={p.changeDisplayCollEvent}
      date={p.date}
      headerData={p.headerData}
      mode={p.mode}
      onChangeMetaData={onChangeMetadata}
      onChangeActorAndRelations={onChangeActorAndRelation}
      onChangeDate={onChangeDate}
      onChangePlace={onChangePlace}
      updateIsChanged={p.updateIsChanged}
      onChangeMethodId={onChangeMethodId}
      copyAndAddCollectingEvent={p.copyAndAddCollectingEvent}
      relatedEnrichedMuseumObjects={p.relatedEnrichedMuseumObjects}
    />
  );
};

export default Container;
