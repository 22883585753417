import * as React from 'react';
import { TODO } from '../../types/common';

export default (props: TODO) => (
  <div className="modal fade" id="termsAndConditionsNO" role="dialog">
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content" style={{ borderRadius: '10px' }}>
        <div className="modal-header">
          <h5>Bruk av personopplysninger i MUSITbasen</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>MUSITbasen uses Dataporten to authenticate users at login.</p>
          <br />
          <p>In MUSITbasen the following personal information is stored:</p>
          <ul>
            <li>User name, full name and email address at your university.</li>
            <li>
              Information about which access a user has, and the changes made in the
              system by the user.
            </li>
          </ul>
        </div>
        <div className="modal-footer">
          <button
            className="btn btn-primary"
            type="button"
            onClick={props.hideModal}
            data-dismiss="modal"
          >
            Lukk
          </button>
        </div>
      </div>
    </div>
  </div>
);
