import * as React from 'react';
import FontAwesome from 'react-fontawesome';
import './ToggleButtons.css';
import { I18n } from 'react-i18nify';

interface ToggleButtonsProps {
  label: string;
  value: boolean;
  updatevalueOK: Function;
  updatevalueNotOK: Function;
}

/* Old:
    label: PropTypes.string.isRequired,
    value: PropTypes.bool,
    updatevalueOK: PropTypes.func.isRequired,
    updatevalueNotOK: PropTypes.func.isRequired


*/

export default class ToogleButtons extends React.Component<ToggleButtonsProps> {
  render() {
    const { label, value } = this.props;

    return (
      <div className="pageMargin">
        <div className="row">
          <div className="col-xs-2" />
          <div className="col-xs-10">
            <label>{label}</label>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-2">
            {value != null ? (
              <FontAwesome
                name={value ? 'check' : 'times'}
                style={{ padding: '2px', fontSize: 'x-large' }}
              />
            ) : null}
          </div>

          <div className="col-xs-10">
            {' '}
            <button
              className={
                value
                  ? 'btn btn-light buttonpaddingtrue'
                  : 'btn btn-light buttonpaddingfalse'
              }
              onClick={p => {
                p.preventDefault();
                this.props.updatevalueOK();
              }}
              type="button"
            >
              <FontAwesome name="check" />
              <span>&nbsp;</span>
              {I18n.t('musit.texts.ok')}
            </button>
            <button
              className={
                value != null && !value
                  ? 'btn btn-light buttonpaddingtrue'
                  : 'btn btn-light buttonpaddingfalse'
              }
              onClick={p => {
                p.preventDefault();
                this.props.updatevalueNotOK();
              }}
            >
              <FontAwesome name="times" />
              <span>&nbsp;</span>
              {I18n.t('musit.texts.notOk')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
