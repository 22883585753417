import * as T from './types';

export const fields: T.TField[] = [
  {
    value: 'object.museumNo',
    isAttribute: false,
    fieldName: 'museumNo',
    isOfType: 'string'
  },
  {
    value: 'object.museumNoAsNumber',
    isAttribute: false,
    fieldName: 'museumNoAsNumber',
    isOfType: 'number'
  },
  {
    value: 'object.museum',
    isAttribute: false,
    fieldName: 'museum',
    isOfType: 'string'
  },
  {
    value: 'object.term',
    isAttribute: false,
    fieldName: 'term',
    isOfType: 'string'
  },
  {
    value: 'object.subNo',
    isAttribute: false,
    fieldName: 'subNo',
    isOfType: 'number'
  },
  {
    value: 'event.name§CollectingEvent',
    isAttribute: false,
    event: 'CollectingEvent',
    fieldName: 'name',
    isOfType: 'string'
  },
  {
    value: 'event.eventDateFrom§CollectingEvent',
    isAttribute: false,
    event: 'CollectingEvent',
    fieldName: 'eventDateFrom',
    isOfType: 'string'
  },
  {
    value: 'event.createdDate§CollectingEvent',
    isAttribute: false,
    event: 'CollectingEvent',
    fieldName: 'createdDate',
    isOfType: 'string'
  },
  {
    value: 'event.eventDateTo§CollectingEvent',
    isAttribute: false,
    event: 'CollectingEvent',
    fieldName: 'eventDateTo',
    isOfType: 'string'
  },
  {
    value: 'event.createdDate§Taxon',
    isAttribute: false,
    event: 'Taxon',
    fieldName: 'createdDate',
    isOfType: 'string'
  },
  {
    value: 'event.eventDateFrom§Taxon',
    isAttribute: false,
    event: 'Taxon',
    fieldName: 'eventDateFrom',
    isOfType: 'string'
  },
  {
    value: 'event.eventDateTo§Taxon',
    isAttribute: false,
    event: 'Taxon',
    fieldName: 'eventDateTo',
    isOfType: 'string'
  },
  {
    value: 'attributes§CollectingEvent§actorName§',
    event: 'CollectingEvent',
    isAttribute: true,
    fieldName: 'leg',
    isOfType: 'string'
  },
  {
    value: 'attributes§CollectingEvent§actorName§',
    event: 'CollectingEvent',
    isAttribute: true,
    fieldName: 'actorName',
    isOfType: 'string'
  },
  {
    value: 'attributes§CollectingEvent§PlaceName§',
    event: 'CollectingEvent',
    isAttribute: true,
    fieldName: 'PlaceName',
    isOfType: 'string'
  },
  {
    value: 'attributes§CollectingEvent§PlacePath§',
    event: 'CollectingEvent',
    isAttribute: true,
    fieldName: 'PlacePath',
    isOfType: 'string'
  },
  {
    value: 'attributes§CollectingEvent§PlacePath§',
    event: 'CollectingEvent',
    isAttribute: true,
    fieldName: 'PlacePath',
    isOfType: 'string'
  },
  {
    value: 'attributes§CollectingEvent§PlaceLocality§',
    event: 'CollectingEvent',
    isAttribute: true,
    fieldName: 'PlaceLocality',
    isOfType: 'string'
  },
  {
    value: 'attributes§CollectingEvent§placePrecision§',
    event: 'CollectingEvent',
    isAttribute: true,
    fieldName: 'placePrecision',
    isOfType: 'number'
  },
  {
    value: 'attributes§CollectingEvent§placeAltitude§',
    event: 'CollectingEvent',
    isAttribute: true,
    fieldName: 'placeAltitude',
    isOfType: 'number'
  },
  {
    value: 'attributes§Taxon§ScientificName§',
    event: 'Taxon',
    isAttribute: true,
    fieldName: 'ScientificName',
    isOfType: 'string'
  },
  {
    value: 'attributes§Taxon§HigherClassification§',
    event: 'Taxon',
    isAttribute: true,
    fieldName: 'HigherClassification',
    isOfType: 'string'
  },
  {
    value: 'attributes§Taxon§actorName§',
    event: 'Taxon',
    isAttribute: true,
    fieldName: 'actorName',
    isOfType: 'string'
  },
  {
    value: 'attributes§MuseumNoAssignment§museumNoSeq§',
    fieldName: 'museumNoSeq',
    isOfType: 'number',
    event: 'MuseumNoAssignment',
    isAttribute: true
  },
  {
    value: 'attributes§MuseumNoAssignment§museumNo§',
    fieldName: 'museumNo',
    isOfType: 'string',
    event: 'MuseumNoAssignment',
    isAttribute: true
  },
  {
    value: 'attributes§MuseumNoAssignment§subNo§',
    fieldName: 'subNo',
    isOfType: 'number',
    event: 'MuseumNoAssignment',
    isAttribute: true
  },
  {
    value: 'attributes§attributes§MuseumNoAssignment§prefix§',
    fieldName: 'prefix',
    isOfType: 'string',
    event: 'MuseumNoAssignment',
    isAttribute: true
  }
];

export const numberFields: (T.Opt | T.OptGroup)[] = [
  { value: undefined, label: 'Velg nummerfelt å søke på' },
  {
    groupName: 'CollectingEvent',
    options: [
      { label: 'altitude', value: 'altitude' },
      { label: 'depth', value: 'depth' },
      { label: 'precision', value: 'precision' }
    ]
  },
  {
    groupName: 'SexAndStages',
    options: [{ label: 'count', value: 'SexAndStages§count' }]
  }
];

export const numberFieldsX: T.EventWithFields[] = [
  {
    event: 'Collecting event',
    fields: [
      {
        prefix: 'CollectingEvent§placeAltitude§',
        fieldName: 'Place altitude',
        attributeFieldPrefix: 'CollectingEvent placeAltitude'
      },
      {
        prefix: 'CollectingEvent§placeDepth§',
        fieldName: 'Place depth',
        attributeFieldPrefix: 'CollectingEvent placeDepth'
      }
    ]
  }
];

export const attributes: T.Attribute[] = [
  {
    attribute: 'CollectingEvent§PlaceLocality§',
    event: 'CollectingEvent',
    fieldName: 'PlaceLocality',
    phrasePrefix: 'CollectingEvent'
  },
  {
    attribute: 'CollectingEvent§PlaceName§',
    event: 'CollectingEvent',
    fieldName: 'PlaceName',
    phrasePrefix: 'CollectingEvent'
  },
  {
    attribute: 'CollectingEvent§PlacePath§',
    event: 'CollectingEvent',
    fieldName: 'PlacePath',
    phrasePrefix: 'CollectingEvent'
  },
  {
    attribute: 'CollectingEvent§actorName§',
    event: 'CollectingEvent',
    fieldName: 'actorName',
    phrasePrefix: 'CollectingEvent'
  },
  {
    attribute: 'CollectingEvent§method§',
    event: 'CollectingEvent',
    fieldName: 'method',
    phrasePrefix: 'CollectingEvent'
  },
  {
    attribute: 'CollectingEvent§name§',
    event: 'CollectingEvent',
    fieldName: 'name',
    phrasePrefix: 'CollectingEvent'
  },
  {
    attribute: 'MuseumNoAssignment§museumNoSeq§',
    event: 'MuseumNoAssignment',
    fieldName: 'museumNoSeq',
    phrasePrefix: 'MuseumNoAssignment'
  },
  {
    attribute: 'MuseumNoAssignment§museumNo§',
    event: 'MuseumNoAssignment',
    fieldName: 'museumNo',
    phrasePrefix: 'MuseumNoAssignment'
  },
  {
    attribute: 'MuseumNoAssignment§prefix§',
    event: 'MuseumNoAssignment',
    fieldName: 'prefix',
    phrasePrefix: 'MuseumNoAssignment'
  },
  {
    attribute: 'MuseumNoAssignment§subNo§',
    event: 'MuseumNoAssignment',
    fieldName: 'subNo',
    phrasePrefix: 'MuseumNoAssignment'
  },
  {
    attribute: 'Taxon§HigherClassification§',
    event: 'Taxon',
    fieldName: 'HigherClassification',
    phrasePrefix: 'Taxon'
  },
  {
    attribute: 'Taxon§ScientificName§',
    event: 'Taxon',
    fieldName: 'ScientificName',
    phrasePrefix: 'Taxon'
  },
  {
    attribute: 'Taxon§actorName§',
    fieldName: 'actorName',
    event: 'Taxon',
    phrasePrefix: 'Taxon'
  }
];

export const attributeFields: T.EventWithFields[] = [
  {
    event: 'Collecting event',
    fields: [
      {
        prefix: 'CollectingEvent§PlaceLocality§',
        fieldName: 'Place locality',
        attributeFieldPrefix: 'CollectingEvent PlaceLocality'
      },
      {
        prefix: 'CollectingEvent§PlaceName§',
        fieldName: 'Place name',
        attributeFieldPrefix: 'CollectingEvent PlaceName'
      },
      {
        prefix: 'CollectingEvent§PlacePath§',
        fieldName: 'Place path',
        attributeFieldPrefix: 'CollectingEvent PlacePath'
      },
      {
        prefix: 'CollectingEvent§actorName§',
        fieldName: 'Leg',
        attributeFieldPrefix: 'CollectingEvent actorName'
      },
      {
        prefix: 'CollectingEvent§method§',
        fieldName: 'Collecting method',
        attributeFieldPrefix: 'CollectingEvent method'
      },
      {
        prefix: 'CollectingEvent§name§',
        fieldName: 'Event name',
        attributeFieldPrefix: 'CollectingEvent name'
      }
    ]
  },
  {
    event: 'Museum number assignment',
    fields: [
      {
        prefix: 'MuseumNoAssignment§museumNoSeq§',
        fieldName: 'Museum number',
        attributeFieldPrefix: 'MuseumNoAssignment museumNoSeq'
      },
      {
        prefix: 'MuseumNoAssignment§museumNo§',
        fieldName: 'Museum number with prefix',
        attributeFieldPrefix: 'MuseumNoAssignment museumNo'
      },
      {
        prefix: 'MuseumNoAssignment§prefix§',
        fieldName: 'Prefix',
        attributeFieldPrefix: 'MuseumNoAssignment prefix'
      },
      {
        prefix: 'MuseumNoAssignment§subNo§',
        fieldName: 'Sub no',
        attributeFieldPrefix: 'MuseumNoAssignment subNo'
      }
    ]
  },
  {
    event: 'Taxon',
    fields: [
      {
        prefix: 'Taxon§HigherClassification§',
        fieldName: 'Higher classification',
        attributeFieldPrefix: 'Taxon HigherClassification'
      },
      {
        prefix: 'Taxon§ScientificName§',
        fieldName: 'Scientific name',
        attributeFieldPrefix: 'Taxon ScientificName'
      },
      {
        prefix: 'Taxon§actorName§',
        fieldName: 'Det',
        attributeFieldPrefix: 'Taxon actorName'
      }
    ]
  }
];

export const fieldsForEventType: T.FieldsForEventType = {
  collectingEvent: {
    eventTypeId: 6,
    fields: [
      {
        label: 'Presisjon',
        value: 'precision',
        contentPrefix: 'CollectingEvent§placePrecision§'
      },
      {
        label: 'Nøyaktighet',
        value: 'accuracy',
        contentPrefix: 'CollectingEvent§placeAccuracy§'
      },

      { label: 'Dybde', value: 'depth', contentPrefix: 'CollectingEvent§placeDepth§' },

      {
        label: 'Høyde over havet',
        value: 'altitude',
        contentPrefix: 'CollectingEvent§placeAltitude§'
      }
    ]
  },
  sexAndStages: {
    fields: [{ label: 'Antall', value: 'count', contentPrefix: 'SexAndStages§count§' }],
    eventTypeId: 18
  },
  taxon: { fields: [], eventTypeId: 15 }
};

export const initPayLoad: T.ESPayLoad = {
  esIndex: 'musitob',
  size: 100,
  from: 0,
  searchFields: [],
  shouldFields: [],
  eventFilters: [],
  aggregations: [],
  resultDescription: {
    resultFileLocation: '/homefolder/',
    userId: 'erlandse',
    columnDelimiter: '\t',
    columnHeaders: [
      'Object-ID',
      'Museumsnnummer',
      'Dato for Taxon',
      'Vitenskabeligt navn',
      'Place name'
    ],
    resultTemplate: [
      'object.objectId',
      'object.museumNo',
      'event.createdDate§Taxon',
      'attributes$Taxon§ScientificName§',
      'attributes$CollectingEvent§PlaceName§'
    ]
  }
};

export const resultFieldSelect: T.ResultFieldSelect = {
  commonFields: [
    ['Museumsnummer', 'object.museumNo'],
    ['Museumsnummer-number', 'object.museumNoAsNumber'],
    ['Object-uuid', 'object.objectId'],
    ['Innsamlingssted', 'attributes§CollectingEvent§PlaceName§'],
    ['Innsamlingssted hierarki', 'attributes§CollectingEvent§PlacePath§'],
    ['Innsamlingssted Lokalitet', 'attributes§CollectingEvent§PlaceLocality§'],
    ['Leg', 'attributes§CollectingEvent§actorName§'],
    ['Det', 'attributes§Taxon§actorName§'],
    ['Taxon-term', 'object.term'],
    ['Taxon-sti', 'attributes§Taxon§HigherClassification§'],
    ['Legdato fra', 'event.eventDateFrom§CollectingEvent'],
    ['Legdato til', 'event.eventDateTo§CollectingEvent']
  ],
  // OBJEKT-SELECTION-BOX
  museumObject: [
    ['Museumsnummer', 'object.museumNo'],
    ['SubNummer', 'object.subNo'],
    ['Term', 'object.term'],
    ['Museum', 'object.museum'],
    ['link til museumsobjekt', 'object.objectId']
  ],

  /*objectId svarer til museumObjectUuid og vil sikkert blive brugt i en link så man kan klikke sig på selve museumsobjektet.
Forestiller mig den altid skal være med?????*/

  //COLLECTINGEVENT-SELECTION-BOX

  collectingEvent: [
    ['Innsamlingsdato fra', 'event.eventDateFrom§CollectingEvent'],
    ['Innsamlingsdato til', 'event.eventDateTo§CollectingEvent'],
    ['Opprettet dato', 'event.createdDate§CollectingEvent'],
    ['Innsamlingssted', 'attributes§CollectingEvent§PlaceName§'],
    ['Innsamlingssted hierarki', 'attributes§CollectingEvent§PlacePath§'],
    ['Innsamlingssted Lokalitet', 'attributes§CollectingEvent§PlaceLocality§'],
    ['Leg', 'attributes§CollectingEvent§actorName§'],
    ['Innsamlingsmetode', 'attributes§CollectingEvent§method§']
  ],

  //TAXON EVENT-SELECTION-BOX
  /* Når det gælder Taxon§HigherClassification så giver det meneing at søge på, men som resultat felt giver det det samme som Path
I data er der en eventdatefrom og eventdateto ved taksering, så jeg tar det med....
 */
  taxon: [
    ['Detdato fra', 'event.eventDateFrom§Taxon'],
    ['Detdato til', 'event.eventDateTo§Taxon'],
    ['Klassificeret Opprettet dato', 'event.createdDate§Taxon'],
    ['Det', 'attributes§Taxon§actorName§'],
    ['Taxonnavn', 'attributes§Taxon§ScientificName§'],
    ['Taxon-sti', 'attributes§Taxon§HigherClassification§']
  ]
};

export const sortableFields: T.SortableField[] = [
  { attribute: 'event.eventDateFrom§CollectingEvent', sortType: 'asc' },
  { attribute: 'event.eventDateFrom§Taxon', sortType: 'asc' },
  { attribute: 'event.eventDateTo§CollectingEvent', sortType: 'asc' },
  { attribute: 'event.eventDateTo§Taxon', sortType: 'asc' },
  { attribute: 'object.museumNo', sortType: 'asc' },
  { attribute: 'object.museumNoAsNumber', sortType: 'asc' },
  { attribute: 'object.subNo', sortType: 'asc' },
  { attribute: 'object.term', sortType: 'asc' },
  { attribute: 'object.museum', sortType: 'asc' }
];
export const defaultResultFields: T.SelectType[] = [
  {
    show: true,
    eventName: 'commonFields',
    fields: [
      {
        label: 'Museumsnummer',
        value: 'object.museumNo',
        selected: true,
        sortOrder: 1,
        indexPlace: { eventIndex: 0, fieldIndex: 0 }
      },
      {
        label: 'Museumsnummer as number',
        value: 'object.museumNoAsNumber',
        selected: true,
        sortOrder: 2,
        indexPlace: { eventIndex: 0, fieldIndex: 1 }
      },
      { label: 'link til museumsobjekt', value: 'object.objectId' },
      {
        label: 'Innsamlingssted',
        value: 'attributes§CollectingEvent§PlaceName§',
        selected: true,
        sortOrder: 6,
        indexPlace: { eventIndex: 0, fieldIndex: 3 }
      },
      {
        label: 'Innsamlingssted hierarki',
        value: 'attributes§CollectingEvent§PlacePath§'
      },
      {
        label: 'Innsamlingssted Lokalitet',
        value: 'attributes§CollectingEvent§PlaceLocality§'
      },
      {
        label: 'Leg',
        value: 'attributes§CollectingEvent§actorName§',
        selected: true,
        sortOrder: 4,
        indexPlace: { eventIndex: 0, fieldIndex: 6 }
      },
      {
        label: 'Det',
        value: 'attributes§Taxon§actorName§',
        selected: true,
        sortOrder: 5,
        indexPlace: { eventIndex: 0, fieldIndex: 7 }
      },
      {
        label: 'Taxonnavn',
        value: 'attributes§Taxon§ScientificName§',
        selected: true,
        sortOrder: 3,
        indexPlace: { eventIndex: 0, fieldIndex: 8 }
      },
      { label: 'Taxon-sti', value: 'attributes§Taxon§HigherClassification§' },
      { label: 'Legdato fra', value: 'event.eventDateFrom§CollectingEvent' },
      { label: 'Legdato til', value: 'event.eventDateTo§CollectingEvent' }
    ]
  },
  {
    show: false,
    eventName: 'museumObject',
    fields: [
      { label: 'Museumsnummer', value: 'object.museumNo' },
      { label: 'SubNummer', value: 'object.subNo' },
      { label: 'Term', value: 'object.term' },
      { label: 'Museum', value: 'object.museum' },
      { label: 'link til museumsobjekt', value: 'object.objectId' }
    ]
  },
  {
    show: false,
    eventName: 'collectingEvent',
    fields: [
      { label: 'Innsamlingsdato fra', value: 'event.eventDateFrom§CollectingEvent' },
      { label: 'Innsamlingsdato til', value: 'event.eventDateTo§CollectingEvent' },
      { label: 'Opprettet dato', value: 'event.createdDate§CollectingEvent' },
      { label: 'Innsamlingssted', value: 'attributes§CollectingEvent§PlaceName§' },
      {
        label: 'Innsamlingssted hierarki',
        value: 'attributes§CollectingEvent§PlacePath§'
      },
      {
        label: 'Innsamlingssted Lokalitet',
        value: 'attributes§CollectingEvent§PlaceLocality§'
      },
      { label: 'Leg', value: 'attributes§CollectingEvent§actorName§' },
      { label: 'Innsamlingsmetode', value: 'attributes§CollectingEvent§method§' }
    ]
  },
  {
    show: false,
    eventName: 'taxon',
    fields: [
      { label: 'Detdato fra', value: 'event.eventDateFrom§Taxon' },
      { label: 'Detdato til', value: 'event.eventDateTo§Taxon' },
      { label: 'Klassificeret Opprettet dato', value: 'event.createdDate§Taxon' },
      { label: 'Det', value: 'attributes§Taxon§actorName§' },
      { label: 'Taxonnavn', value: 'attributes§Taxon§ScientificName§' },
      { label: 'Taxon-sti', value: 'attributes§Taxon§HigherClassification§' }
    ]
  }
];

export const eventAttributes: (e: string) => T.TField[] = (event: string) =>
  fields.filter(e => e.event === event && e.isAttribute);

export const eventFields: (e: string) => T.TField[] = (event: string) =>
  fields.filter(e => e.event === event && !e.isAttribute);

export const objectFields = () =>
  fields.filter(f => f.event === undefined).map(e => ({ ...e, isAttribute: undefined }));

export const attributesByEvents: () => { [key: string]: T.TField } = () =>
  fields
    .filter(f => f.isAttribute && f.event !== undefined)
    .reduce((p, c) => {
      if (c.event) return { ...p, [c.event]: p[c.event] ? [...p[c.event], c] : [c] };
      return p;
    }, {});
