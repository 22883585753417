import * as React from 'react';
import { I18n } from 'react-i18nify';
import moment from 'moment';
import '../css/Compact.css';
import { ShowComponent } from '../components/showComponent/ShowComponent';
import { CollectingEventEventAttributes } from '../types/event/eventTypes_auto';
import { DateAttributes } from '../types/event/event old';

export const ViewEventMetaData = ({
  dataState,
  dateState
}: {
  dataState: CollectingEventEventAttributes;
  dateState: DateAttributes;
}) => {
  const dateFrom =
    dateState && dateState.eventDateFrom ? new Date(dateState.eventDateFrom) : undefined;
  const dateTo =
    dateState && dateState.eventDateTo ? new Date(dateState.eventDateTo) : undefined;
  const d1 = dateFrom ? moment(dateFrom).format('D.M.YYYY') : '';
  const d2 = dateTo ? moment(dateTo).format('D.M.YYYY') : '';
  return (
    <div className="container-fluid">
      {
        <form>
          <div className="form-row narrow">
            <ShowComponent
              width={3}
              label={I18n.t('musit.objectModule.collectingEvent.name')}
              id="collectingEventNameId"
              data={dataState && dataState.name}
            />
            <ShowComponent
              width={2}
              label={I18n.t('musit.objectModule.shared.dateFrom')}
              id="collectingEventdateFromId"
              data={d1}
            />
            <ShowComponent
              width={2}
              label={I18n.t('musit.objectModule.shared.dateTo')}
              id="collectingEventdateToId"
              data={d2}
            />
            <ShowComponent
              width={2}
              label={I18n.t('musit.objectModule.shared.verbatimDate')}
              id="verbatimDateId"
              data={dateState && dateState.eventDateVerbatim}
            />
          </div>
        </form>
      }
    </div>
  );
};
