import * as React from 'react';
import { SexAndStage } from '../types/event/event';
import SexAndStageComp from './SexAndStageRowComp';

const SexAndStagesAdd = ({
  addRow,
  numRows
}: {
  addRow?: (newRow: SexAndStage) => void;
  numRows: number;
}) => {
  const [sexAndStage, changeSexAndStage] = React.useState<SexAndStage>({});

  const onChangeSexAndStage = (field: string) => (
    value: string | number | boolean | undefined
  ) => {
    const newSexAndStage = { ...sexAndStage, [field]: value };
    changeSexAndStage(newSexAndStage);
  };

  const clear = () => {
    changeSexAndStage({});
  };

  return (
    <SexAndStageComp
      key="Add"
      sexAndStage={sexAndStage}
      onChangeSexAndStage={onChangeSexAndStage}
      addRow={s => {
        addRow && addRow(s);
        clear();
      }}
    />
  );
};

export default SexAndStagesAdd;
