// @flow
import * as React from 'react';
import FontAwesome from 'react-fontawesome';
import moment from 'moment';
import { I18n } from 'react-i18nify';
import { Maybe } from '../../types/common';

type Props = {
  onClickEdit?: (e: { preventDefault: Function }) => void;
  updatedBy?: Maybe<string>;
  updatedDate?: Maybe<number> | Maybe<string>;
  registeredBy: Maybe<string>;
  registeredDate: Maybe<number> | Maybe<string>;
  aligned?: boolean;
};

const DateFormat = 'DD.MM.YYYY HH:mm';

export default function MetaInformation({
  updatedBy,
  updatedDate,
  registeredBy,
  registeredDate,
  aligned
}: Props) {
  // console.log('META', updatedBy, updatedDate, registeredBy, registeredDate);
  if (aligned) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm-2">
            <b>{I18n.t('musit.texts.registeredBy')}:</b>
          </div>
          <div className="col-sm-6">
            <FontAwesome name="user" /> {registeredBy} <FontAwesome name="clock-o" />{' '}
            {registeredDate && moment(registeredDate).format(DateFormat)}
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-sm-2">
            <b>{I18n.t('musit.texts.lastUpdateBy')}:</b>
          </div>
          <div className="col-sm-6">
            <FontAwesome name="user" /> {updatedBy} <FontAwesome name="clock-o" />{' '}
            {updatedDate && moment(updatedDate).format(DateFormat)}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="container">
      <div className="row">
        <label className="col-sm-2 col-form-label" htmlFor="registeredBy">
          {I18n.t('musit.texts.registeredBy')}:
        </label>
        <div className="col-sm-10">
          <span className="form-control-plaintext" id="registeredBy">
            <FontAwesome name="user" /> {registeredBy} <FontAwesome name="clock-o" />{' '}
            {registeredDate && moment(registeredDate).format(DateFormat)}
          </span>
        </div>
      </div>
      {updatedBy && (
        <div className="row">
          <label className="col-sm-2 col-form-label" htmlFor="updatedBy">
            {I18n.t('musit.texts.lastUpdateBy')}:
          </label>
          <div className="col-sm-10">
            <span className="form-control-plaintext" id="updatedBy">
              <FontAwesome name="user" /> {updatedBy} <FontAwesome name="clock-o" />{' '}
              {updatedDate && moment(updatedDate).format(DateFormat)}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
