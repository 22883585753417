import * as React from 'react';
import { I18n } from 'react-i18nify';
import { DerivedCoordinate } from '../types/place/place';

type Props = {
  coordinate?: string;
  datum?: string;
  zone?: number;
  band?: string;
  coordinateType: string;
  validCoordinate: boolean;
  children: any;
  datums: { datum: string }[];
  coordinateTypes: { type_text: string }[];
  updateCoordinate: (fieldName: string) => (value?: DerivedCoordinate | string) => void;
  updateShowMap: () => void;
  onToggleCoordinateFormats: () => void;
  showValidCoordinate: boolean;
  coordinateSideText: JSX.Element;
  disabled?: boolean;
};

const UTMComponent = (p: Props) => {
  return (
    <div>
      <div className="form-row">
        <div className="col-md-2 offset-md-2">
          <b>{I18n.t('musit.objectModule.place.coordinateType')}</b>
        </div>
        <div className="col-md-2">
          <b>{I18n.t('musit.objectModule.place.coordinateDatum')}</b>
        </div>

        <div className="col-md-2">
          <b>{I18n.t('musit.objectModule.place.utmSone')}</b>
        </div>

        <div className="col-md-2">
          <b>{I18n.t('musit.objectModule.place.utmBand')}</b>
        </div>
      </div>
      <div className="form-row" style={{ paddingBottom: '15px' }}>
        <div className="col-md-2" style={{ textAlign: 'right' }}>
          <b>{I18n.t('musit.objectModule.place.coordinateMetadata')}</b>
        </div>
        <div className="col-md-2">
          {' '}
          <select
            className="form-control"
            id="coordinateType"
            value={p.coordinateType}
            disabled={p.disabled}
            onChange={e => {
              p.updateCoordinate('coordinateType')(e.target.value);
            }}
          >
            {p.coordinateTypes ? (
              p.coordinateTypes.map(({ type_text }: { type_text: string }, i: number) => (
                <option key={`optionRow_${i}`} value={type_text}>
                  {type_text}
                </option>
              ))
            ) : (
              <option key={`optionRow_${1}`}>{'No data'}</option>
            )}
          </select>
        </div>{' '}
        <div className="col-md-2">
          <select
            className="form-control"
            value={p.datum}
            disabled={p.disabled}
            id="datum"
            placeholder={I18n.t('musit.objectModule.place.coordinateDatum')}
            onChange={e => {
              p.updateCoordinate('datum')(e.target.value);
            }}
          >
            {p.datums ? (
              p.datums.map(({ datum }: { datum: string }, i: number) => (
                <option value={datum} key={`optionRow_${i}`}>
                  {datum}
                </option>
              ))
            ) : (
              <option key={`optionRow_${1}`}>{'No datum'}</option>
            )}
          </select>
        </div>
        <div className="col-md-2">
          <input
            type="number"
            disabled={p.disabled}
            className="form-control"
            placeholder={I18n.t('musit.objectModule.place.utmSone')}
            id="zone"
            onChange={e => {
              p.updateCoordinate('zone')(e.target.value);
            }}
            value={p.zone}
          />
        </div>
        <div className="col-md-2">
          <input
            type="text"
            disabled={p.disabled}
            className="form-control"
            placeholder={I18n.t('musit.objectModule.place.utmBand')}
            id="mgrsBand"
            onChange={e => {
              p.updateCoordinate('band')(e.target.value.toUpperCase());
            }}
            value={p.band}
          />
        </div>
      </div>
      <div className="form-row">
        {p.children}
        {p.coordinateSideText}
      </div>
    </div>
  );
};

export default UTMComponent;
