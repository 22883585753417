import {
  InputMuseumObject,
  MuseumObjectHeader,
  MuseumObjectEventHeader
} from '../types/museumObject/museumObject';
import { AppSession } from '../../../../src/types/appSession';
import { TaxonEvent } from '../taxon/taxonLib';
import { Uuid } from '../../../../src/types/common';
import {
  EventTypeId,
  TaxonEventAttributes,
  RoleTypeId,
  CollectingEventEventAttributes
} from '../types/event/eventTypes_auto';
import {
  InputEvent,
  SexAndStagesEvent,
  MusitEvent,
  ActorAndRelation,
  MuseumObjectAndRelation
} from '../types/event/event';

import { Person } from '../types/person/personName';
import {
  CollectingEvent,
  CollectingEventHeader
} from '../types/frontend/collectingEventForm';
import { OutActorAndRelation } from '../taxon/RevisonComponent';
import { ObjectTypeId } from '../types/museumObject/objectTypes_auto';
import { DateRevisionEventAttributes } from '../types/event/eventTypes_auto';
import { DateRangeWithVerbatim } from '../../../components/DatePicker';
import { OutPlace } from '../types/place/place';
import {
  IsChangedType as IsChangedCollectingEventType,
  hasAnyChanges,
  initialChangeState
} from '../collectingEvent/CollectingEventContainer';

export type MuseumObjectEventType = TaxonEvent;

export type IsChangedType = {
  [key: string]: boolean;
};
export type IsAddedType = {
  [key: string]: boolean;
};
export type IsDeletedType = {
  [key: string]: number;
};
export enum ChangeResult {
  alreadyAdded = 0,
  changed = 1
}

export enum ObjectChangeStatus {
  notAdded = 0,
  added = 1,
  changed = 2
}

interface MuseumCollection {
  uuid: string;
  name: string;
  objectType: ObjectTypeId.MarineEvertebrates | ObjectTypeId.NumismaticObject;
  editList: string[];
}

const marineEvertebrates: MuseumCollection = {
  uuid: 'ef4dc066-b6f8-4155-89f8-7aa9aeeb2dc4',
  name: 'marineEvertebrates',
  objectType: ObjectTypeId.MarineEvertebrates,
  editList: [
    'metaData',
    'taxon',
    'collEventConnection',
    'sexAndStages',
    'typeinfo',
    'eventHistory',
    'otherData',
    'admin'
  ]
};

const vascularPlants: MuseumCollection = {
  uuid: '7352794d-4973-447b-b84e-2635cafe910a',
  name: 'vascularPlants',
  objectType: ObjectTypeId.MarineEvertebrates,
  editList: [
    'metaData',
    'taxon',
    'collEventConnection',
    'sexAndStages',
    'typeinfo',
    'otherData',
    'magazine',
    'eventHistory',
    'admin'
  ]
};

const entomology: MuseumCollection = {
  uuid: 'ba3d4d30-810b-4c07-81b3-37751f2196f0',
  name: 'entomology',
  objectType: ObjectTypeId.MarineEvertebrates,
  editList: [
    'metaData',
    'taxon',
    'collEventConnection',
    'sexAndStages',
    'typeinfo',
    'otherData',
    'magazine',
    'eventHistory',
    'admin'
  ]
};

const fungus: MuseumCollection = {
  uuid: '23ca0166-5f9e-44c2-ab0d-b4cdd704af07',
  name: 'fungus',
  objectType: ObjectTypeId.MarineEvertebrates,
  editList: [
    'metaData',
    'taxon',
    'collEventConnection',
    'sexAndStages',
    'typeinfo',
    'otherData',
    'magazine',
    'eventHistory',
    'admin'
  ]
};

const bryophytes: MuseumCollection = {
  uuid: 'd0dd5ad3-c22f-4ea0-8b52-dc5b0e17aa24',
  name: 'bryophytes',
  objectType: ObjectTypeId.MarineEvertebrates,
  editList: [
    'metaData',
    'taxon',
    'collEventConnection',
    'sexAndStages',
    'typeinfo',
    'otherData',
    'magazine',
    'eventHistory',
    'admin'
  ]
};

const lichens: MuseumCollection = {
  uuid: 'fcb4c598-8b05-4095-ac00-ce66247be38a',
  name: 'lichens',
  objectType: ObjectTypeId.MarineEvertebrates,
  editList: [
    'metaData',
    'taxon',
    'collEventConnection',
    'sexAndStages',
    'typeinfo',
    'otherData',
    'magazine',
    'eventHistory',
    'admin'
  ]
};

const algae: MuseumCollection = {
  uuid: '1d8dd4e6-1527-439c-ac86-fc315e0ce852',
  name: 'algae',
  objectType: ObjectTypeId.MarineEvertebrates,
  editList: [
    'metaData',
    'taxon',
    'collEventConnection',
    'sexAndStages',
    'typeinfo',
    'otherData',
    'magazine',
    'eventHistory',
    'admin'
  ]
};

const numis: MuseumCollection = {
  uuid: '8bbdf9b3-56d1-479a-9509-2ea82842e8f8',
  name: 'numismatics',
  objectType: ObjectTypeId.NumismaticObject,
  editList: [
    'metaData',
    'collEventConnection',
    'otherData',
    'magazine',
    'eventHistory',
    'admin'
  ]
};

const collections = [
  marineEvertebrates,
  vascularPlants,
  lichens,
  bryophytes,
  fungus,
  entomology,
  algae,
  numis
];
export const getCollection = (uuid: string): MuseumCollection | undefined =>
  collections.find(coll => coll.uuid === uuid);

export const createObjectHeaderData = (
  appSession: AppSession,
  museumObjectTypeId: number,
  museumObjectUuidIn?: string
): MuseumObjectHeader => {
  const header = createCollectionHeaderData(appSession);
  const museumObjectUuid = museumObjectUuidIn
    ? { museumObjectUuid: museumObjectUuidIn }
    : null;
  return {
    museumObjectTypeId: museumObjectTypeId,
    ...museumObjectUuid,
    ...header
  };
};
export const createCollectionHeaderData = (
  appSession: AppSession
): MuseumObjectHeader => ({
  museumId: appSession.museumId,
  collectionUuid: appSession.collectionId
});
export const createObjectEventHeaderData = (
  appSession: AppSession,
  eventTypeId: number,
  eventUuid?: Uuid
): MuseumObjectEventHeader => {
  const header = createCollectionHeaderData(appSession);
  return {
    eventTypeId: eventTypeId,
    eventUuid: eventUuid,
    ...header
  };
};

const createSortingEventJson = (
  headerJson: MuseumObjectEventHeader,
  events: InputEvent[],
  isAdded: IsAddedType,
  isDeleted: IsDeletedType
) => {
  const hasDeletedEvent = Object.keys(isDeleted).length > 0;
  const hasAddedEvent = events.find(
    e => e.eventUuid && (isAdded[e.eventUuid] || hasDeletedEvent)
  );
  if (hasAddedEvent) {
    return {
      ...headerJson,
      attributes: {
        eventOrdering: events.map((e, i) => ({ eventUuid: e.eventUuid, orderNo: i }))
      }
    };
  }
  return undefined;
};

export const getEvent = (
  eventTypeId: EventTypeId,
  events: MusitEvent[]
): (MusitEvent & { actorName?: Person }) | undefined => {
  const allEvents = events
    ? events.filter(e => e.eventTypeId === eventTypeId)
    : undefined;
  if (allEvents) return allEvents[allEvents.length - 1];
  return undefined;
};

export const getEventWithActorname = (
  eventTypeId: EventTypeId,
  events: (MusitEvent & { actorName?: Person })[]
): (MusitEvent & { actorName?: Person }) | undefined => {
  const e = events ? events.find(e => e.eventTypeId === eventTypeId) : undefined;
  return e;
};

export const updateEvent = (event: MusitEvent, events: MusitEvent[]) => {
  if (!event) return events;
  const eventsFiltered = events.filter(e => e.eventTypeId !== event.eventTypeId);
  return [...eventsFiltered, event];
};

export const updateEventAttribute = (
  eventTypeId: EventTypeId,
  events: MusitEvent[],
  fieldName: string,
  value: string
) => {
  const event = getEvent(eventTypeId, events);
  if (!event) {
    return events;
  }
  const updatedEvent = setEventAttribute(event, fieldName, value);
  return updateEvent(updatedEvent, events);
};
export const updateEventField = (
  eventTypeId: EventTypeId,
  events: MusitEvent[],
  fieldName: string,
  value: string | OutActorAndRelation[]
) => {
  const event = getEvent(eventTypeId, events);
  if (!event) {
    return events;
  }
  const updatedEvent = setEventField(event, fieldName, value);
  return updateEvent(updatedEvent, events);
};

export const setEventAttribute = (
  event: MusitEvent & { actorName?: Person },
  fieldName: string,
  value: string | number | string[] | undefined
) => {
  const eventAttributes = event ? event.attributes : null;
  return { ...event, attributes: { ...eventAttributes, [fieldName]: value } };
};
export const setEventField = (
  event: MusitEvent,
  fieldName: string,
  value: string | OutActorAndRelation[]
) => {
  return { ...event, [fieldName]: value };
};

export const createEmptySexAndStagesEvent = (
  headerJson: MuseumObjectEventHeader
): SexAndStagesEvent => ({
  ...headerJson,
  attributes: {
    sexAndStage: []
  }
});

export const XcreateCollectingEventJson: (
  collectingEvent: CollectingEvent,
  objectUuid: Uuid,
  appSession: AppSession
) => CollectingEvent | undefined = (
  collectingEvent: CollectingEvent,
  objectUuid: Uuid,
  appSession: AppSession
) => {
  if (collectingEvent) {
    const headerData: CollectingEventHeader = {
      collectionUuid: appSession.collectionId,
      museumId: appSession.museumId,
      eventTypeId: EventTypeId.CollectingEvent
    };
    const attributes: CollectingEventEventAttributes = collectingEvent.attributes || {
      name: ''
    };
    const place: OutPlace | undefined = collectingEvent.place;
    const relatedActors: ActorAndRelation[] = collectingEvent.relatedActors || [];
    const relatedMuseumObjects: MuseumObjectAndRelation[] = [
      { museumObjectUuid: objectUuid, roleId: RoleTypeId.museumObjectFor }
    ];
    return {
      ...headerData,
      attributes,
      place,
      relatedActors,
      relatedMuseumObjects: relatedMuseumObjects || []
    };
  } else return undefined;
};

export const createSexAndStagesEvent = (
  headerJson: MuseumObjectEventHeader,
  sexAndStagesEvent?: SexAndStagesEvent
): SexAndStagesEvent | undefined =>
  sexAndStagesEvent &&
  sexAndStagesEvent.eventUuid === undefined &&
  sexAndStagesEvent.attributes &&
  sexAndStagesEvent.attributes.sexAndStage &&
  sexAndStagesEvent.attributes.sexAndStage.length > 0
    ? { ...headerJson, ...sexAndStagesEvent }
    : undefined;

export const createSexAndStagesRevisions = (
  headerJson: MuseumObjectEventHeader,
  sexAndStagesEvent?: SexAndStagesEvent
): SexAndStagesEvent | undefined => {
  return sexAndStagesEvent &&
    sexAndStagesEvent.attributes &&
    sexAndStagesEvent.attributes.sexAndStage
    ? {
        ...headerJson,
        ...sexAndStagesEvent
      }
    : undefined;
};

export const taxonEventToInputEvent: (taxonEvents: TaxonEvent[]) => InputEvent[] = (
  taxonEvents: TaxonEvent[]
) => {
  const toDate: (d?: DateRangeWithVerbatim) => DateRevisionEventAttributes | undefined = (
    d: DateRangeWithVerbatim
  ) =>
    d
      ? {
          ...d,
          eventDateFrom: d.eventDateFrom ? d.eventDateFrom : '',
          eventDateTo: d.eventDateTo ? d.eventDateTo : '',
          eventDateVerbatim: d.eventDateVerbatim,
          lengthOfFormat: d.lengthOfFormat
        }
      : undefined;
  return taxonEvents.map(e => ({ ...e, date: toDate(e.date) }));
};

export const createAddCollEventRevisionJson = (
  museumObjectUuid: string,
  existingCollectingEvent?: CollectingEvent,
  newCollectingEvent?: CollectingEvent
) => {
  if (existingCollectingEvent)
    return {
      revisionOf: existingCollectingEvent?.eventUuid,
      eventTypeId: existingCollectingEvent?.eventTypeId,
      relatedMuseumObjects: existingCollectingEvent?.relatedMuseumObjects
        ? [
            ...existingCollectingEvent?.relatedMuseumObjects.filter(
              m => m.museumObjectUuid !== museumObjectUuid
            ),
            {
              museumObjectUuid: museumObjectUuid,
              roleId: RoleTypeId.museumObjectFor
            }
          ]
        : [
            {
              museumObjectUuid: museumObjectUuid,
              roleId: RoleTypeId.museumObjectFor
            }
          ]
    };
  else if (newCollectingEvent)
    return {
      revisionOf: newCollectingEvent?.eventUuid,
      eventTypeId: newCollectingEvent?.eventTypeId,
      relatedMuseumObjects: newCollectingEvent?.relatedMuseumObjects
        ? [
            ...newCollectingEvent?.relatedMuseumObjects,
            {
              museumObjectUuid: museumObjectUuid,
              roleId: RoleTypeId.museumObjectFor
            }
          ]
        : [
            {
              museumObjectUuid: museumObjectUuid,
              roleId: RoleTypeId.museumObjectFor
            }
          ]
    };

  return undefined;
};

export const createDeleteCollEventRevisionJson = (
  museumObjectUuid: string,
  existingCollectingEvent?: CollectingEvent,
  newCollectingEvent?: CollectingEvent
) => {
  if (newCollectingEvent && existingCollectingEvent && existingCollectingEvent.eventUuid)
    return {
      revisionOf: newCollectingEvent.eventUuid!,
      eventTypeId: existingCollectingEvent.eventTypeId,
      relatedMuseumObjects:
        newCollectingEvent && newCollectingEvent.relatedMuseumObjects
          ? newCollectingEvent.relatedMuseumObjects.filter(
              e => e.museumObjectUuid !== museumObjectUuid
            )
          : []
    };

  return undefined;
};

export const createJSONForSwap = (
  museumObject: InputMuseumObject,
  oldCollectingEvent: CollectingEvent,
  newCollectingEventUuid: CollectingEvent
) => {
  const revisionList = [
    {
      revisionOf: oldCollectingEvent.eventUuid,
      eventTypeId: oldCollectingEvent.eventTypeId,
      relatedMuseumObjects: oldCollectingEvent.relatedMuseumObjects?.filter(
        e => e.museumObjectUuid !== museumObject.museumObjectUuid
      )
    }
  ];

  return { ...museumObject, events: undefined, revisionList };
};

export const createJson = (
  objectChangeStatus: ObjectChangeStatus,
  appSession: AppSession,
  museumObject: InputMuseumObject,
  genericEvents: MusitEvent[],
  taxonEvents: InputEvent[],
  sexAndStage: SexAndStagesEvent,
  isAdded: IsAddedType,
  isChanged: IsChangedType,
  isDeleted: IsDeletedType,
  oldCollectingEvent?: CollectingEvent,
  collectingEvent?: CollectingEvent,
  isChangedCollectingEvent?: IsChangedCollectingEventType
): InputMuseumObject => {
  console.log('CreateJSON');
  const collectingEventRevisionDelete = createDeleteCollEventRevisionJson(
    museumObject.museumObjectUuid!,
    collectingEvent,
    oldCollectingEvent
  );
  let collectingEventRevisionAdd;
  let collectingEventAdd;
  let isChangedCE = false;
  if (collectingEvent?.eventUuid) {
    isChangedCE =
      isChanged[collectingEvent.eventUuid] &&
      hasAnyChanges(isChangedCollectingEvent || initialChangeState);
  }
  if (
    isChangedCE ||
    (objectChangeStatus === ObjectChangeStatus.changed && oldCollectingEvent)
  ) {
    collectingEventRevisionAdd = createAddCollEventRevisionJson(
      museumObject.museumObjectUuid!,
      collectingEvent,
      oldCollectingEvent
    );
  } else if (isChangedCE) {
    collectingEventRevisionAdd = createAddCollEventRevisionJson(
      museumObject.museumObjectUuid!,
      collectingEvent,
      collectingEvent
    );
  }

  const changedGenericEvents = genericEvents
    .filter(e => isChanged[e.eventTypeId] === true) //check if the event is changed
    .map(event => ({ ...event, eventUuid: undefined, relatedMuseumObjects: undefined })); // remove uuid and remove relations (this must be changed later)

  const addedTaxonEvents = taxonEvents.filter(
    e =>
      e.eventTypeId === EventTypeId.Taxon &&
      e.eventUuid &&
      isAdded[e.eventUuid] === true &&
      !isDeleted[e.eventUuid] &&
      e.attributes &&
      (e.attributes as TaxonEventAttributes).taxons &&
      (e.attributes as TaxonEventAttributes).taxons.length > 0
  );

  const allTaxonEvents = taxonEvents.filter(
    e =>
      e.eventTypeId === EventTypeId.Taxon &&
      e.eventUuid &&
      !isDeleted[e.eventUuid] &&
      (e.attributes as TaxonEventAttributes).taxons.length > 0
  );

  const orderEvent = createSortingEventJson(
    createObjectEventHeaderData(appSession, EventTypeId.EventOrdering),
    allTaxonEvents,
    isAdded,
    isDeleted
  );

  let sexAndStagesEvent;
  console.log('IC', isChanged);
  const isC = isChanged[sexAndStage.eventTypeId];
  console.log('IC', isC);

  if (
    sexAndStage &&
    isChanged[sexAndStage.eventTypeId] &&
    sexAndStage.revisionOf === undefined
  ) {
    sexAndStagesEvent = createSexAndStagesEvent(
      createObjectEventHeaderData(appSession, EventTypeId.SexAndStage),
      { ...sexAndStage, eventUuid: undefined }
    );
  }

  const taxonRevisions = taxonEvents.filter(
    e =>
      e.eventTypeId === EventTypeId.Taxon &&
      e.revisionOf &&
      isChanged[e.revisionOf] === true &&
      (e as TaxonEvent).attributes.taxons.length > 0
  );

  const deletedRevisions = Object.keys(isDeleted).map(key => ({
    eventUuid: key,
    eventTypeId: isDeleted[key]
  }));
  const returnValue: InputMuseumObject = {
    ...museumObject,
    subObjects: [],
    attributes: undefined,
    events:
      [
        ...addedTaxonEvents,
        collectingEventAdd,
        sexAndStagesEvent,
        ...changedGenericEvents,
        orderEvent
      ].filter(e => e !== undefined) || [],

    revisions: [
      ...taxonRevisions,
      collectingEventRevisionDelete,
      collectingEventRevisionAdd,
      ...deletedRevisions
    ].filter(e => e !== undefined)
  };
  return returnValue;
};
