import * as React from 'react';
import {
  TaxonTypeMini,
  PrecisionCode,
  TaxonEvent,
  fullFormatTaxon,
  TaxonTypeSave,
  createDetString,
  TaxonTypeId,
  toDetTypeStr
} from './taxonLib';
import uuid from 'uuid/v4';
import TaxonComponent from './TaxonComponent';
import { DateRangeWithVerbatim } from '../../../components/DatePicker';
import { AppSessionContext } from '../../app/AppComponent';
import { createObjectEventHeaderData } from '../object/ObjectContainerFunctions';
import { getTranslator } from '../../../shared/language';

const words = getTranslator({
  en: {
    addClassification: 'Add classification',
    addButtonText: 'New revision in front of this',
    closeButtonText: 'Close',
    date: 'Date',
    delete: 'Delete',
    detType: 'Determinator type',
    determinator: 'Determinator',
    hideButtonText: 'Hide',
    name: 'Name',
    revisions: 'Revisions',
    showOrEditButtonText: 'Show or edit'
  },
  no: {
    addClassification: 'Legg til klassifisering',
    addButtonText: 'Ny revisjon foran denne',
    closeButtonText: 'Lukk',
    date: 'Dato',
    delete: 'Slett',
    detType: 'Determinator type',
    determinator: 'Determinator',
    hideButtonText: 'Skjul',
    navn: 'Navn',
    revisions: 'Revisjoner',
    showOrEditButtonText: 'Vis eller rediger'
  }
});

export type Uuid = string;

export type EventUuid = Uuid;
export type ActorUuid = Uuid;
export type RoleId = number;
export type MuseumObjectUuid = Uuid;
export const NO_ACTIVE_REVISION = -1;

export interface InputActorAndRelation {
  actorUuid: ActorUuid;
  roleId: RoleId;
  orderNo: number;
  actorName: string;
}

export interface OutActorAndRelation {
  actorUuid?: ActorUuid;
  roleId: RoleId;
  roleText: string;

  //The actor name on the label, may be different from the actor's current name/official name
  actorName: string;
  currentActorName: string;
  orderNo: number;
}

export interface MuseumObjectAndRelation {
  museumObjectUuid: MuseumObjectUuid;
  roleId: RoleId;
}

export interface DateAttributes {
  eventDateFrom?: Date | string;
  eventDateTo?: Date | string;
  eventDateVerbatim?: string;
}
export interface ClassificationEvent {
  eventUuid?: string;
  eventTypeId?: number;
  museumId?: number;
  collectionUuid?: string;
  attributes?: TaxonAttributes;
  note?: string;
  partOf?: string;
  createdBy: string;
  createdDate: string;
  det?: InputActorAndRelation[];
  date?: DateAttributes;
  relatedMuseumObjects?: MuseumObjectAndRelation[];
}

export interface Taxon {
  taxon: TaxonTypeMini;
  precisionCode: PrecisionCode;
  precisionCathegory: string;
  sensu: string;
}

export interface TaxonAttributes {
  taxonList: Taxon[];
}

export enum TAXON_STATE {
  NEW = 1,
  EDIT = 2,
  SHOW = 3,
  CLOSED = 4
}
type TaxonRevisionsState = { state: TAXON_STATE };

export const showButtonText = (
  state: TAXON_STATE,
  i: number,
  currentRevision: number
) => {
  if (state === TAXON_STATE.CLOSED && i !== currentRevision)
    return words.showOrEditButtonText;
  if (state !== TAXON_STATE.CLOSED && i === currentRevision) return words.hideButtonText;
  if (state === TAXON_STATE.NEW && i === currentRevision) return words.hideButtonText;
  return 'Vis/rediger';
};

export const getNewButtonText = (
  state: TAXON_STATE,
  i: number,
  currentRevision: number
) => {
  if (state === TAXON_STATE.NEW && i === currentRevision) return '';
  return words.addButtonText;
};

export const getDisabledStateShowButton = (
  state: TAXON_STATE,
  i: number,
  currentRevision: number
) => {
  if (currentRevision === NO_ACTIVE_REVISION) return false;
  if (
    currentRevision >= 0 &&
    i === currentRevision &&
    (state === TAXON_STATE.SHOW || state === TAXON_STATE.NEW)
  )
    return false;
  return true;
};

export const getDisabledStateNewButton = (
  state: TAXON_STATE,
  i: number,
  currentRevision: number
) => {
  if (state === TAXON_STATE.CLOSED && currentRevision === NO_ACTIVE_REVISION)
    return false;
  return true;
};

type Props = {
  revisionsIn: TaxonEvent[];
  changeEvent: (index: number) => (taxonEvent: TaxonEvent) => void;
  addEventUuid: (eventUuid: string) => void;
  deleteEventUuid: (eventUuid: string, eventTypeId: number) => void;
  updateRevisions: React.Dispatch<React.SetStateAction<TaxonEvent[]>>;
  detTypeWords: any;
};

const TaxonRevisions = (props: Props) => {
  const [revisionStateState, updateRevisionState] = React.useState<TaxonRevisionsState[]>(
    props.revisionsIn.map(() => ({ state: TAXON_STATE.CLOSED }))
  );

  // this is to handle when last revision is deleted and a empty taxonEvent is created
  const revisionState =
    revisionStateState.length === 0
      ? props.revisionsIn.map(() => ({ state: TAXON_STATE.CLOSED }))
      : revisionStateState;

  const appSession = React.useContext(AppSessionContext);

  const [currentRevisionIndex, updateCurrentRevision] = React.useState<number>(
    NO_ACTIVE_REVISION
  );

  const removeCurrent = (i: number) => {
    const eventUuid = props.revisionsIn[i].eventUuid;
    if (eventUuid) {
      updateCurrentRevision(i);
      props.deleteEventUuid(eventUuid, props.revisionsIn[i].eventTypeId);
    }
    props.updateRevisions([
      ...props.revisionsIn.slice(0, i),
      ...props.revisionsIn.slice(i + 1)
    ]);
    updateRevisionState([...revisionState.slice(0, i), ...revisionState.slice(i + 1)]);
  };

  const addLast = () => {
    const newNum = props.revisionsIn.length;
    const eventHeaderData = createObjectEventHeaderData(appSession, 15, uuid());
    if (eventHeaderData.eventUuid) {
      props.addEventUuid(eventHeaderData.eventUuid);
    }
    const newRevList: TaxonEvent[] = [
      ...props.revisionsIn,
      {
        ...eventHeaderData,
        uuid: uuid(),
        attributes: {
          taxonTypeId: newNum === 0 ? TaxonTypeId.Det : TaxonTypeId.Rev,
          taxons: [],
          note: ''
        },
        relatedActors: undefined,
        date: undefined,
        orderNo: newNum
      }
    ];
    updateRevisionState([...revisionState, { state: TAXON_STATE.NEW }]);
    updateCurrentRevision(newNum);
    props.updateRevisions(newRevList);
  };

  const addNew = (newNum: number) => {
    const eventHeaderData = createObjectEventHeaderData(appSession, 15, uuid());
    if (eventHeaderData.eventUuid) {
      props.addEventUuid(eventHeaderData.eventUuid);
    }
    const newRevList: TaxonEvent[] = [
      ...props.revisionsIn.slice(0, newNum),
      {
        ...eventHeaderData,
        uuid: uuid(),
        attributes: {
          taxonTypeId: newNum === 0 ? TaxonTypeId.Det : TaxonTypeId.Rev,
          taxons: [],
          note: ''
        },
        relatedActors: undefined,
        orderNo: -1
      },
      ...props.revisionsIn.slice(newNum)
    ];
    updateRevisionState([
      ...revisionState.slice(0, newNum),
      { state: TAXON_STATE.NEW },
      ...revisionState.slice(newNum)
    ]);

    updateCurrentRevision(newNum);
    props.updateRevisions(newRevList);
  };

  const updateState = (state: TAXON_STATE, i: number) => {
    const n = [
      ...revisionState.slice(0, i),
      { state: state },
      ...revisionState.slice(i + 1)
    ];
    updateRevisionState(n);
  };
  const fromDate = (dateRange?: DateRangeWithVerbatim) =>
    dateRange && dateRange.eventDateFrom ? dateRange.eventDateFrom : '';

  return (
    <div className="container py-4">
      <h5>{words.revisions}</h5>
      <table className="table table-sm">
        <thead>
          <tr className="table-active">
            <th>{words.name}</th>
            <th>{words.detType}</th>
            <th>{words.date}</th>
            <th>{words.determinator}</th>
            <th colSpan={3} />
          </tr>
        </thead>
        <tbody>
          {props.revisionsIn.map((c: TaxonEvent, i: number) => (
            <React.Fragment key={`revKey${c.eventUuid ? c.eventUuid : i}`}>
              <tr className={currentRevisionIndex === i ? 'table-secondary' : ''}>
                <td>
                  {c.attributes.taxons.reduce(
                    (prev: string, t: TaxonTypeSave) =>
                      prev +
                      fullFormatTaxon(t, t.precisionCode, t.precisionRank, t.sensu),
                    ''
                  ) || ' -- '}
                </td>
                <td>{props.detTypeWords[toDetTypeStr(c.attributes.taxonTypeId)]}</td>
                <td>{fromDate(c.date)}</td>
                <td>{createDetString(c.relatedActors) || ' -- '}</td>
                <td>
                  <button
                    type="button"
                    disabled={getDisabledStateShowButton(
                      revisionState[i] ? revisionState[i].state : TAXON_STATE.NEW,
                      i,
                      currentRevisionIndex
                    )}
                    className="btn btn-link btn-sm"
                    onClick={() => {
                      if (currentRevisionIndex === i) {
                        updateState(TAXON_STATE.CLOSED, i);
                        updateCurrentRevision(NO_ACTIVE_REVISION);
                      } else {
                        updateCurrentRevision(i);
                        updateState(TAXON_STATE.SHOW, i);
                      }
                    }}
                  >
                    {showButtonText(revisionState[i].state, i, currentRevisionIndex)}
                  </button>
                </td>
                <td>
                  <button
                    disabled={getDisabledStateNewButton(
                      revisionState[i].state,
                      i,
                      currentRevisionIndex
                    )}
                    type="button"
                    className="btn btn-link btn-sm"
                    onClick={() => {
                      if (revisionState[i].state === TAXON_STATE.CLOSED) {
                        addNew(i);
                        return;
                      }
                      if (revisionState[i].state === TAXON_STATE.NEW) {
                        //removeCurrent(i);
                        updateCurrentRevision(NO_ACTIVE_REVISION);

                        return;
                      }
                    }}
                  >
                    {getNewButtonText(revisionState[i].state, i, currentRevisionIndex)}
                  </button>
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-link btn-sm"
                    onClick={() => {
                      removeCurrent(i);
                    }}
                  >
                    {words.delete}
                  </button>
                </td>
              </tr>
              {currentRevisionIndex === i ? (
                <tr
                  //className={`table-secondary ${currentRevisionIndex === i ? 'show' : 'hide'}`}
                  key={`classEvent${i}`}
                >
                  <td
                    colSpan={7}
                    style={{
                      //marginLeft: '3%',
                      borderStyle: 'solid',
                      borderWidth: 'thin'
                    }}
                  >
                    <TaxonComponent
                      key={c.eventUuid}
                      detTypeWords={props.detTypeWords}
                      appSession={appSession}
                      defaultDateFormat={'yyyy'}
                      taxonEvent={c}
                      changeTaxonEvent={props.changeEvent(i)}
                    />
                  </td>
                </tr>
              ) : null}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <div className="row">
        <div className="col-md-6">
          <button
            type="button"
            className="btn btn-outline-primary bg-white text-dark"
            onClick={() => addLast()}
          >
            + {words.addClassification}
          </button>
        </div>
      </div>
      <button
        className="btn btn-outline-primary mt-2 mr-n1 float-right bg-white text-dark"
        onClick={e => {
          e.preventDefault();
          /* TODO */
        }}
      >
        {words.resetButton}
      </button>
    </div>
  );
};

export default TaxonRevisions;
