import * as React from 'react';

import MGRSComponent from './MGRSComponent';
import UTMComponent from './UTMComponent';
import LatLongComponent from './LatLongComponent';
import datums from './mockdata/coordinateDatums';
import geometryTypes from './mockdata/geometryTypes';
import coordinateTypes from './mockdata/coordinateTypes';
import CoordinateField from './CoordinateField';
import { getTranslator } from '../../../shared/language';

const words = getTranslator({
  en: {
    coordinate: 'Coordinate',
    hideValidCoordinates: 'Skjul gyldige koordinatformater',
    showValidCoordinateFormats: 'Show valid coordinateformats',
    notValidCoordinate: 'Invalid coordinate',
    showCoordinateOnMap: 'Show coordinate on map',
    hideMap: 'Hide map'
  },
  no: {
    coordinate: 'Koordinat',
    hideValidCoordinates: 'Hide valid coordinatformats',
    notValidCoordinate: 'Ugyldig koordinat',
    showValidCoordinateFormats: 'Vis gyldige koordinatformater',
    showCoordinateOnMap: 'Vis koordinat på kart',
    hideMap: 'Skjul kart'
  }
});

type Props = {
  coordinateString: string;
  datum?: string;
  zone?: number;
  validCoordinate: boolean;
  band?: string;
  coordinateType: string;
  coordinateGeometry: string;
  showMap: boolean;
  updateShowMap: () => void;
  updateCoordinate: (fieldName: string) => (value: string) => void;
  onToggleCoordinateFormats: () => void;
  showValidCoordinate: boolean;
  disabled?: boolean;
};

type SideTextProps = {
  coordinateIsValid: boolean;
  showCoordinateFormat: boolean;
  showMap: boolean;
  onClickShowMap: () => void;
  onClickToggleShowCoordinateFormat: () => void;
};

const CoordinateStringSideTextComponent = (props: SideTextProps) => {
  const showMapText = props.coordinateIsValid
    ? props.showMap
      ? words.hideMap
      : words.showCoordinateOnMap
    : words.notValidCoordinate;
  return props.coordinateIsValid ? (
    <div className="col-md-3">
      <a
        href="/#"
        onClick={p => {
          p.preventDefault();
          props.onClickShowMap();
        }}
      >
        {showMapText}
      </a>
    </div>
  ) : (
    <>
      <div className="col-md-3" style={{ lineHeight: 1.2 }}>
        <span style={{ paddingLeft: '5px' }}>{showMapText}</span>
        <br />
        <span style={{ paddingLeft: '5px' }}>
          <a
            href="/#"
            data-toggle="popover"
            title={
              props.showCoordinateFormat
                ? words.hideValidCoordinates
                : words.seeValidCoordinates
            }
            onClick={p => {
              p.preventDefault();
              props.onClickToggleShowCoordinateFormat();
            }}
          >
            {props.showCoordinateFormat
              ? words.hideValidCoordinates
              : words.showValidCoordinateFormats}
          </a>
        </span>
      </div>
    </>
  );
};

const CoordinateComponent = (props: Props) => {
  const coordinateSideText = (
    <CoordinateStringSideTextComponent
      showMap={props.showMap}
      coordinateIsValid={props.validCoordinate}
      showCoordinateFormat={props.showValidCoordinate}
      onClickShowMap={props.updateShowMap}
      onClickToggleShowCoordinateFormat={props.onToggleCoordinateFormats}
    />
  );
  if (props.coordinateType === 'MGRS') {
    return (
      <MGRSComponent
        zone={props.zone}
        band={props.band}
        datums={datums}
        datum={props.datum}
        disabled={props.disabled}
        coordinateTypes={coordinateTypes}
        validCoordinate={props.validCoordinate}
        coordinate={props.coordinateString}
        coordinateType={props.coordinateType}
        updateCoordinate={props.updateCoordinate}
        updateShowMap={props.updateShowMap}
        onToggleCoordinateFormats={props.onToggleCoordinateFormats}
        showValidCoordinate={props.showValidCoordinate}
        coordinateSideText={coordinateSideText}
      >
        <CoordinateField
          coordinate={props.coordinateString}
          disabled={props.disabled}
          updateCoordinate={props.updateCoordinate('coordinateString')}
          validCoordinate={props.validCoordinate}
        />
      </MGRSComponent>
    );
  } else if (props.coordinateType === 'UTM') {
    return (
      <UTMComponent
        zone={props.zone}
        band={props.band}
        datums={datums}
        datum={props.datum}
        disabled={props.disabled}
        coordinateTypes={coordinateTypes}
        validCoordinate={props.validCoordinate}
        coordinate={props.coordinateString}
        coordinateType={props.coordinateType}
        updateCoordinate={props.updateCoordinate}
        updateShowMap={props.updateShowMap}
        onToggleCoordinateFormats={props.onToggleCoordinateFormats}
        showValidCoordinate={props.showValidCoordinate}
        coordinateSideText={coordinateSideText}
      >
        <CoordinateField
          coordinate={props.coordinateString}
          disabled={props.disabled}
          updateCoordinate={props.updateCoordinate('coordinateString')}
          validCoordinate={props.validCoordinate}
        />
      </UTMComponent>
    );
  } else if (props.coordinateType === 'LAT/LONG') {
    return (
      <LatLongComponent
        geometryType={props.coordinateGeometry || 'point'}
        coordinate={props.coordinateString}
        disabled={props.disabled}
        datum={props.datum}
        validCoordinate={props.validCoordinate}
        geometryTypes={geometryTypes}
        datums={datums}
        coordinateTypes={coordinateTypes}
        coordinateType={props.coordinateType}
        updateCoordinate={props.updateCoordinate}
        updateShowMap={props.updateShowMap}
        onToggleCoordinateFormats={props.onToggleCoordinateFormats}
        showValidCoordinate={props.showValidCoordinate}
        coordinateSideText={coordinateSideText}
      >
        <CoordinateField
          coordinate={props.coordinateString}
          disabled={props.disabled}
          updateCoordinate={props.updateCoordinate('coordinateString')}
          validCoordinate={props.validCoordinate}
        />{' '}
      </LatLongComponent>
    );
  } else {
    return <div>Unknown</div>;
  }
};

export default CoordinateComponent;
