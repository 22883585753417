// @flow
import * as React from 'react';
import { I18n } from 'react-i18nify';
import { AppSession } from '../../../types/appSession';
import { Restriction } from '../../../types/analysis';
import StatefulActorSuggest from './StatefulActorSuggest';

interface CancelRestrictionProps {
  appSession: AppSession;
  restriction: Restriction;
  updateRestriction: (restriction: Restriction) => void;
  clickCancel: () => void;
  isRestrictionValidForCancellation: boolean;
}

const XFormElement = (props: {
  id: string;
  label: string;
  labelWidth: number;
  elementWidth: number;
  children?: React.ReactNode;
  hasError?: boolean;
}) => {
  return (
    <div className="row">
      <div className={`col-md-${props.labelWidth} text-right`}>
        <b>{props.label}</b>
      </div>
      <div className={`col-md-${props.elementWidth}`}>{props.children}</div>
    </div>
  );
};

export default function CancelRestriction(props: CancelRestrictionProps) {
  return (
    <div>
      <XFormElement
        id="restrictedBy"
        label={I18n.t('musit.analysis.restrictions.cancelledBy')}
        labelWidth={3}
        elementWidth={9}
        hasError={!props.isRestrictionValidForCancellation}
      >
        <StatefulActorSuggest
          id="restrictedBy"
          value={props.restriction.cancelledByName}
          appSession={props.appSession}
          onChange={actorId =>
            props.updateRestriction({
              ...props.restriction,
              cancelledBy: actorId
            })
          }
        />
      </XFormElement>
      <XFormElement
        id="cancelCause"
        label={I18n.t('musit.analysis.restrictions.reasonForCancelling')}
        labelWidth={3}
        elementWidth={9}
        hasError={!props.isRestrictionValidForCancellation}
      >
        <input
          className="form-control"
          id="cancelCause"
          value={props.restriction.cancelledReason || ''}
          onChange={e =>
            props.updateRestriction({
              ...props.restriction,
              cancelledReason: e.target.value
            })
          }
        />
      </XFormElement>

      {!props.restriction.cancelledStamp && (
        <div className="row">
          <div className="col-md-9 offset-md-3">
            <button
              className="btn btn-secondary"
              type="button"
              disabled={!props.isRestrictionValidForCancellation}
              onClick={(e: any) => {
                e.preventDefault();
                props.clickCancel();
              }}
            >
              {I18n.t('musit.analysis.restrictions.cancelRestriction')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
