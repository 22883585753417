/* Get
Input: 
    URL: string  - URL of request
    token: access token from appSession
Output:
    Promise of return-data



How to use:
    const returValue= await get('/api/event/events/34', 'xxxx-333-23as-23444')
       .then(d => d (or do something with data here))
       .catch(e => do something with error)

    */

export async function get(url: string, token: string): Promise<Response> {
  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    })
  });
  return response;
}
export async function getExternal(url: string): Promise<Response> {
  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  });
  return response;
}

/* Post
Input: 
    URL: string  - URL of request
    data: data to post
    token: access token from appSession
Output:
    Promise of return-data


How to use:
    const returValue= await post('/api/event/events/add',{data}, 'xxxx-333-23as-23444')
       .then(d => d)
       .catch(e => do something with error)

    */

export async function post(url: string, data?: any, token?: string): Promise<Response> {
  console.log('I Post: ', url, data);
  const response = await fetch(url, {
    method: 'POST',
    body: data,
    headers: token
      ? new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        })
      : new Headers({
          'Content-Type': 'application/x-www-form-urlencoded'
        })
  });
  return response;
}
export async function sendFile(
  url: string,
  file: File,
  token: string
): Promise<Response> {
  var formData: FormData = new FormData();
  formData.append('upload', file, file.name);

  const response = await fetch(url, {
    method: 'POST',
    body: formData,
    headers: new Headers({
      Authorization: `Bearer ${token}`
    })
  });
  return response;
}
/* Put
Input: 
    URL: string  - URL of request
    data: data to post
    token: access token from appSession
Output:
    Promise of return-data

How to use:
    const returValue= await put('/api/event/events/edit',{data}, 'xxxx-333-23as-23444')
       .then(d => d)
       .catch(e => do something with error)

    */

export async function put(url: string, data: any, token: string): Promise<Response> {
  const response = await fetch(url, {
    method: 'PUT',
    body: data,
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    })
  });
  // .then((r: Response) => r.json())
  // .catch((e: Error) => e);
  return response;
}
