import * as React from 'react';
import { AppSession } from '../../../types/appSession';
import { CollapseGroupBox } from '../components/Collapse';
import EditMetaDataComponent from './EditMetaDataComponent';
import OtherDataComponent from './OtherDataComponent';
import ViewTaxonComponent from '../taxon/ViewTaxonComponent';
import AdminComponent from './AdminComponent';
import ObjectProperties from './ObjectPropertiesComponent';
import SexAndStagesComponent from '../SexAndStages/SexAndStagesComponent';
import TypeInfoComponent from '../typeinfo/TypeInfoComponent';
import ViewGenderAndStagesComponent from '../SexAndStages/ViewGenderAndStages';
import { AdmProps } from './AdminComponent';
//import EventHistoryComponent from './components/EventHistoryComponent';
import DisplayStructureComponent from './ObjectStructureComponent';
import CollectingEventRelationComponent, {
  DisplayState
} from '../components/CollectingEventRelations/CollectingEventRelationComponent';
import ViewCollectingEventComponent from '../collectingEvent/ViewCollectingEventComponent';
import { TaxonEvent } from '../taxon/taxonLib';
import ViewObjectMetadata from './ViewObjectMetadata';
import { InputMuseumObject } from '../types/museumObject/museumObject';
import TaxonTabComponent from '../taxon/TaxonNavComponent';
import { ChangeResult, getCollection } from './ObjectContainerFunctions';
import { SexAndStagesEvent, CollectingEvent, MusitEvent } from '../types/event/event';
import * as H from 'history';
import { getTranslator } from '../../../shared/language';
import { UpdateIsChangedFunc } from '../collectingEvent/CollectingEventContainer';
import { MuseumObjectUuid } from '../types/museumObject/museumObject old';
import config from '../../../config';
import './ObjectComponent.css';
import { AdmStatuses } from '../eventHistory/EventHistoryContainer';

const words = getTranslator({
  en: {
    newSubObject: 'New sub object',
    admin: 'Administration',
    collEventConnection: 'Collecting event attached to',
    eventHistory: 'Event history',
    magazine: 'Storage facility',
    metaData: 'Metadata',
    otherData: 'Other data',
    sexAndStages: 'Sex and stages',
    taxon: 'Taxon',
    typeInfo: 'Type information',
    object: 'Object',
    noCollection: 'Can not edit selected collection',
    save: 'Save'
  },
  no: {
    newSubObject: 'Nytt underobjekt',
    admin: 'Administrasjon',
    collEventConnection: 'Innsamlingshendelse koblet til',
    eventHistory: 'Hendelsehistorikk',
    magazine: 'Magasine',
    metaData: 'Metadata',
    otherData: 'Andre data',
    sexAndStages: 'Kjønn og stadier',
    taxon: 'Takson',
    typeInfo: 'Type informasjon',
    object: 'Objekt',
    noCollection: 'Kan ikke redigere objekt av samme type som valgt samling',
    save: 'Lagre'
  }
});

const ObjectComponent = ({
  appSession,
  museumObject,
  allEvents,
  collectingEventData,
  taxonEvents,
  changeTaxonEvents,
  genericEvents,
  changeGenericEditEvents,
  registerChangeEventId,
  changeMuseumObject,
  sexAndStagesEvent,
  changeSexAndStagesEvent,
  legacyEvents,
  addEventUuid,
  changeEventUuid,
  deleteEventUuid,
  saveFunction,
  searchFunction,
  searchResult,
  clearSearch,
  addCollectingEvent,
  changeCollectingEvent,
  copyAndAddCollectingEvent,
  oldCollectingEvent,
  createSubObject,
  history,
  admProps,
  dissiminationStatus,
  registrationStatus,
  updateIsChanged,
  swapCollectingEvents,
  changeAdmProps,
  incForceRefresh,
  deleteOldCollectingEvent
}: {
  appSession: AppSession;
  deleteOldCollectingEvent?: () => void;
  allEvents: MusitEvent[];
  createSubObject?: (parentObject: InputMuseumObject) => Promise<MuseumObjectUuid>;
  incForceRefresh?: () => void;
  museumObject: InputMuseumObject;
  collectingEventData?: CollectingEvent;
  admStatuses: AdmStatuses;
  legacyEvents?: MusitEvent[];
  taxonEvents: TaxonEvent[];
  changeTaxonEvents: React.Dispatch<React.SetStateAction<TaxonEvent[]>>;
  changeAdmProps?: React.Dispatch<React.SetStateAction<AdmProps>>;
  genericEvents: MusitEvent[];
  changeGenericEditEvents: React.Dispatch<React.SetStateAction<MusitEvent[]>>;
  registerChangeEventId: (eventId: number) => void;
  changeMuseumObject: React.Dispatch<React.SetStateAction<InputMuseumObject>>;
  sexAndStagesEvent: SexAndStagesEvent;
  changeSexAndStagesEvent: React.Dispatch<React.SetStateAction<SexAndStagesEvent>>;
  addEventUuid: (eventUuid: string) => void;
  changeEventUuid: (eventUuid: string) => ChangeResult;
  deleteEventUuid: (eventUuid: string, eventTypeId: number) => void;
  saveFunction?: (collectingEventTo?: CollectingEvent) => Promise<void>;
  searchFunction: (searchTerm: string) => void;
  searchResult: MusitEvent[] | undefined;
  clearSearch: () => void;
  copyAndAddCollectingEvent?: () => Promise<void>;
  swapCollectingEvents?: (
    museumObject: InputMuseumObject,
    newCollectingEvent: CollectingEvent,
    oldCollectingEvent?: CollectingEvent
  ) => Promise<Response | undefined>;
  addCollectingEvent: (newCollectingEvent: CollectingEvent) => void;
  changeCollectingEvent: React.Dispatch<React.SetStateAction<CollectingEvent>>;
  oldCollectingEvent?: CollectingEvent;
  history: H.History;
  admProps: AdmProps;
  registrationStatus: string | undefined;
  dissiminationStatus: string | undefined;
  updateIsChanged: UpdateIsChangedFunc;
}) => {
  const noUpdateFunction = (param: React.MouseEvent): void => {};
  const buttonRow = saveAndEditButtonRow({
    onClickSave: async () => {
      if (saveFunction) await saveFunction();
    },
    onClickCancel: noUpdateFunction,
    readOnly: false,
    disabled: false,
    formHaveChanged: true
  });

  const selectedCollection = getCollection(appSession.collectionId);
  const [displayCollEvent, changeDisplayCollEvent] = React.useState(
    collectingEventData ? DisplayState.showEdit : DisplayState.showSearch
  );

  let relatedEnrichedMuseumObjects =
    collectingEventData?.relatedEnrichedMuseumObjects || [];

  const objectMetaDataComp = (
    <EditMetaDataComponent
      appSession={appSession}
      changeMuseumObjectData={changeMuseumObject}
      genericEvents={genericEvents}
      subCollectionId={museumObject.subCollectionId}
      changeGenericEditEvents={changeGenericEditEvents}
      registerChangeEventId={registerChangeEventId}
    />
  );

  const typeInfoComponent = (
    <TypeInfoComponent
      appSession={appSession}
      genericEvents={genericEvents}
      changeGenericEvents={changeGenericEditEvents}
      registerChangeEventId={registerChangeEventId}
    />
  );
  const typeInfoDisplay = (
    <TypeInfoComponent
      appSession={appSession}
      genericEvents={genericEvents}
      changeGenericEvents={changeGenericEditEvents}
      registerChangeEventId={registerChangeEventId}
      display={true}
    />
  );
  const objectMetaDataViewComp = (
    <ViewObjectMetadata
      subObjects={museumObject.subObjects}
      genericEvents={genericEvents}
    />
  );
  const adminComp = (
    <AdminComponent
      lastChangeBy={admProps.lastChangeBy}
      lastChangedDate={admProps.lastChangedDate}
      regBy={admProps.regBy}
      regDate={admProps.regDate}
      registrationStatuses={admProps.registrationStatuses}
      publicationStatuses={admProps.publicationStatuses}
      changeStatus={admProps.changeStatus}
      genericEvents={genericEvents.map(e => ({
        ...e,
        actorName: admProps.genericEvents.find(a => (a.eventTypeId = e.eventTypeId))
          ?.actorName
      }))}
      appSession={appSession}
      changeGenericEvents={changeGenericEditEvents}
      registerChangeEventId={registerChangeEventId}
      changeAdmProps={changeAdmProps}
    />
  );
  const adminViewComp = (
    <React.Fragment>Visning av adm data er ikke laget ennå</React.Fragment>
  );

  const taxonViewComp = <ViewTaxonComponent taxonEvents={taxonEvents} />;

  const viewSexAndStages = (
    <ViewGenderAndStagesComponent sexAndStages={sexAndStagesEvent} />
  );

  const otherDataComp = (
    <OtherDataComponent
      appSession={appSession}
      genericEvents={genericEvents}
      legacyEvents={legacyEvents}
      changeGenericEvents={changeGenericEditEvents}
      registerChangeEventId={registerChangeEventId}
    />
  );

  const otherDataViewComp = (
    <OtherDataComponent
      appSession={appSession}
      legacyEvents={legacyEvents}
      genericEvents={genericEvents}
      changeGenericEvents={changeGenericEditEvents}
      registerChangeEventId={registerChangeEventId}
      readonly
    />
  );

  const groups = [
    {
      title: 'Objektet sine underobjekter',
      key: 'objectStructure',
      value: (
        <CollapseGroupBox
          heading={'Objektet sine underobjekter'}
          Body={
            <DisplayStructureComponent
              subObjects={museumObject.subObjects}
              parentMusNoSeq={1}
              changeMuseumObjectData={changeMuseumObject}
              appSession={appSession}
              subCollection={
                museumObject.attributes && museumObject.attributes.museumNo
                  ? museumObject.attributes.museumNo.subColl
                  : ''
              }
              collection={
                museumObject.attributes && museumObject.attributes.museumNo
                  ? museumObject.attributes.museumNo.collection
                  : ''
              }
            />
          }
          Head={objectMetaDataViewComp}
          showHead={true}
        />
      )
    },
    {
      title: words.metaData,
      key: 'metaData',
      value: (
        <CollapseGroupBox
          heading={words.metaData}
          Body={objectMetaDataComp}
          Head={objectMetaDataViewComp}
          showHead={true}
        />
      )
    },
    {
      title: words.taxon,
      key: 'taxon',
      value: (
        <CollapseGroupBox
          heading={words.taxon}
          Body={
            <TaxonTabComponent
              defaultDateFormat={'yyyy'}
              taxonEvents={taxonEvents}
              changeTaxonEvents={changeTaxonEvents}
              addEventUuid={addEventUuid}
              changeEventUuid={changeEventUuid}
              deleteEventUuid={deleteEventUuid}
            />
          }
          Head={taxonViewComp}
          showHead={true}
        />
      )
    },
    {
      title: words.collEventConnection,
      key: 'collEventConnection',
      value: (
        <CollapseGroupBox
          heading={words.collEventConnection}
          Body={
            <CollectingEventRelationComponent
              showCollectingEvent={displayCollEvent}
              anchor={'#collEventConnection'}
              deleteOldCollectingEvent={deleteOldCollectingEvent}
              incForceRefresh={incForceRefresh}
              swapCollectingEvent={swapCollectingEvents}
              updateIsChanged={updateIsChanged}
              museumObject={museumObject}
              relatedEnrichedMuseumObjects={relatedEnrichedMuseumObjects}
              //objectUuid={museumObject.museumObjectUuid}
              changeDisplayCollEvent={changeDisplayCollEvent}
              copyAndAddCollectingEvent={copyAndAddCollectingEvent}
              searchResult={searchResult}
              clearSearch={clearSearch}
              searchFunction={searchFunction}
              addCollectingEvent={addCollectingEvent}
              collectingEventData={collectingEventData}
              changeCollectingEvent={changeCollectingEvent}
              appSession={appSession}
              history={history}
              save={saveFunction}
            />
          }
          Head={
            <React.Fragment>
              {collectingEventData && (
                <ViewCollectingEventComponent {...collectingEventData} />
              )}
            </React.Fragment>
          }
          showHead={true}
        />
      )
    },
    {
      title: words.sexAndStages,
      key: 'sexAndStages',
      value: (
        <CollapseGroupBox
          heading={words.sexAndStages}
          Body={
            <SexAndStagesComponent
              sexAndStagesEvent={sexAndStagesEvent}
              changeSexAndStagesEvent={changeSexAndStagesEvent}
            />
          }
          Head={viewSexAndStages}
          showHead={true}
        />
      )
    },
    {
      title: words.typeInfo,
      key: 'typeinfo',
      value: (
        <CollapseGroupBox
          heading="Typeinfo"
          Body={typeInfoComponent}
          Head={typeInfoDisplay}
          showHead={true}
        />
      )
    },
    {
      title: 'Gjenstandsopplysninger',
      key: 'objectinfo',
      value: (
        <CollapseGroupBox
          heading={'Gjenstandsopplysninger'}
          Body={<ObjectProperties />}
          Head={
            <React.Fragment>
              Visning av gjenstandopplysninger er ikke laget ennå
            </React.Fragment>
          }
          showHead={true}
        />
      )
    },
    {
      title: words.otherData,
      key: 'otherData',
      value: (
        <CollapseGroupBox
          heading={words.otherData}
          Body={otherDataComp}
          Head={otherDataViewComp}
          showHead={true}
        />
      )
    },
    {
      title: words.magazine,
      key: 'magazine',
      value: (
        <CollapseGroupBox
          heading={words.magazine}
          Body={
            <React.Fragment>
              <a href="/#">Legg til plukkliste</a> (Må lagre først)
            </React.Fragment>
          }
          Head={<React.Fragment />}
          showHead={true}
        />
      )
    },
    {
      title: 'Sammendrag',
      key: 'description',
      value: (
        <CollapseGroupBox
          heading={'Sammendrag'}
          Body={<p>Vise sammendraget av funnet</p>}
          Head={<React.Fragment>Visning av teksten</React.Fragment>}
          showHead={true}
        />
      )
    },
    {
      title: words.eventHistory,
      key: 'eventHistory',
      value: (
        <CollapseGroupBox
          heading={words.eventHistory}
          Body={<>Visning av Hendelsehistorikk er ikke laget ennå</>}
          Head={<>Visning av Hendelsehistorikk er ikke laget ennå</>}
          showHead={true}
        />
      )
    },
    {
      title: words.admin,
      key: 'admin',
      value: (
        <CollapseGroupBox
          heading={words.admin}
          Body={adminComp}
          Head={adminViewComp}
          showHead={true}
        />
      )
    }
  ];

  const onClickNewSubObject = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (museumObject.museumObjectUuid && createSubObject) {
      const o = await createSubObject(museumObject);
      const url = config.magasin.urls.client.object.editObject(appSession, o);
      history.push(url);
    }
  };

  return (
    <div className="d-flex flex-nowrap">
      <div
        className="container panel panel-default"
        style={{ marginRight: '10px', paddingTop: '70px' }}
      >
        <div className="panel-heading">
          <h1>{words.object}</h1>
        </div>
        <div className="panel-body">
          <div className="row">
            <div className="col-md-12">
              <button
                type="button"
                onClick={onClickNewSubObject}
                className="btn btn-secondary"
              >
                {words.newSubObject}
              </button>
            </div>
          </div>
          {selectedCollection ? (
            groups
              .filter(c => selectedCollection.editList.includes(c.key))
              .map((comp, i) => (
                <React.Fragment key={`key-${i}`}>
                  <div id={comp.key} className="anchor" />
                  {comp.value}
                  <br />
                </React.Fragment>
              ))
          ) : (
            <div>{words.noCollection}</div>
          )}
        </div>
      </div>
      <div className="bd-toc objectContainer">
        {selectedCollection &&
          groups
            .filter(c => selectedCollection.editList.includes(c.key))
            .map((comp, i) => (
              <React.Fragment key={`key-${i}`}>
                <a href={'#' + comp.key}>{comp.title}</a>
                <br />
              </React.Fragment>
            ))}
        {buttonRow}
      </div>
    </div>
  );
};

type ButtonRowPropType = {
  onClickSave: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClickCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
  readOnly: boolean;
  disabled: boolean;
  formHaveChanged: boolean;
};

const saveAndEditButtonRow = (props: ButtonRowPropType) => (
  <button
    type="button"
    className="btn btn-success"
    style={{ marginTop: '20px' }}
    onClick={props.onClickSave}
    disabled={!props.formHaveChanged || props.disabled}
  >
    {words.save}
  </button>
);

export default ObjectComponent;
