// !!This is an auto generated file, please do not edit this file manually!
// To update this file, go to metadata.http and click on: GET {{host}}metadata/generateObjectFiles

import { Uuid } from '../event/event';

// -- Object attributes constants
export enum ObjectTypeId {
  StorageNode = -5,
  CompositeContainer = -4,
  MusitContainer = -3,
  MusitObject = -2,
  MuseumObject = -1,
  MarineEvertebrates = 1,
  CulturalArtifact = 3,
  ArcheologicalObject = 4,
  EthnographicObject = 5,
  NumismaticObject = 6,
  NumismaticType = 7
}

// -- Attribute interfaces
export interface StorageNodeAttributes {
  label?: string;
  mainObjectUuid?: Uuid;
}

export interface CompositeContainerAttributes {
  mainObjectUuid?: Uuid;
}

export interface MusitContainerAttributes {
  mainObjectUuid?: Uuid;
}

export interface MusitObjectAttributes {}

export interface MuseumObjectAttributes {
  partOf?: Uuid;
}

export interface MarineEvertebratesAttributes {
  admNote?: string;
  artsObsNo?: number;
  boxOrJar?: string;
  collectorsNote?: string;
  dataset?: string;
  legacyData?: string;
  note?: string;
  oldBarcode?: number;
  oldNumbers?: string[];
  partOf?: Uuid;
  projectName?: string;
  slideOrTube?: string;
  uuids?: Uuid[];
  voucher?: string;
}

export interface CulturalArtifactAttributes {
  partOf?: Uuid;
}

export interface ArcheologicalObjectAttributes {
  archeologyData?: string;
  partOf?: Uuid;
  photoId?: number;
}

export interface EthnographicObjectAttributes {
  partOf?: Uuid;
}
