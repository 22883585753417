/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { SimplePersonsComponent } from '../../person/SimplePersonsComponent';
import { OutActorAndRelation } from '../../types/event/event';
import { OutPlace } from '../../types/place/place';
import EventMetadata from '../../collectingEvent/EventMetadataComponent';
import PlaceComponent from '../../place/PlaceComponent';
import { MusitEvent, CollectingEvent, DateAttributes } from '../../types/event/event';
import {
  CollectingEventEventAttributes as CollectingEventAttributes,
  EventTypeId
} from '../../types/event/eventTypes_auto';
import config from '../../../../config';
import * as H from 'history';
import { AppSession } from 'src/types/appSession';
import { getTranslator } from '../../../../shared/language';
import { CollectingEventHeader } from '../../types/frontend/collectingEventForm';
import CollEventInlineContainer from './CollectingEventInlineContainer';
import { compareMusNo } from '../../utils/compareFunctions';
import { UpdateIsChangedFunc } from '../../collectingEvent/CollectingEventContainer';
import {
  MuseumObjectSearchResult,
  InputMuseumObject
} from '../../types/museumObject/museumObject old';
import { MuseumObjectUuid } from '../../types/museumObject/museumObject';
import './CollectingEventRelationComponent.css';

const words = getTranslator({
  en: {
    eventSelected: (eventId: string) =>
      `Collecting event: "${eventId}" is connected to the object`,
    admPlace: 'Administrative place',
    list: 'List collecting events',
    connectedObjects: 'Connected objects',
    name: 'Name',
    registerNew: 'Register new collecting event attached to this object',
    search: 'Search',
    searchCollectingEvents: 'Search for collecting event',
    searchForCollectingEventAnd:
      'Search for collecting event and attach this object to it',
    searchOrRegister: 'Search to find collecting event to attach',
    station: 'Station',
    view: 'View collecting event',
    unlock: 'Unlock for edit collecting event data',
    gotoCollectingEvent: 'Go to collecting event to edit or create new',
    selectToAdd: 'Select to add',
    noName: 'No name',
    openInNewTab: 'Open in new sheet'
  },
  no: {
    eventSelected: (eventId: string) =>
      `Innsamlingshendelse: "${eventId}" er koblet til objektet`,
    admPlace: 'Administrativt sted',
    list: 'Liste innsamlinshendelser',
    connectedObjects: 'Tilkoblede objekter',
    name: 'Navn',
    registerNew: 'Registrer ny innsamlingshendelse koblet til dette objektet',
    search: 'Søk',
    searchCollectingEvents: 'Søk i innsamlingshendelser',
    searchForCollectingEventAnd: 'Søk på navn og legg objektet inn i valgt hendelse',
    searchOrRegister: 'Søk for å koble til innsamlingshendelse',
    station: 'Stasjon',
    view: 'Se innsamlingshendelsen',
    unlock: 'Lås opp for å endre innsamlingshendelse',
    gotoCollectingEvent: 'Gå til innsamlingshendelse for å endre eller lage ny',
    selectToAdd: 'Velg for å legge til',
    noName: 'Anon.',
    openInNewTab: 'Åpne i ny fane'
  }
});

export const fromMuseumObjectUuidLocalStorageKey = 'fromMuseumObjectUuid';
export enum DisplayState {
  showSearch,
  showEdit,
  showList
}

export interface CollProps {
  mode: 'UNSHARED' | 'SHARED';
  collAttr?: CollectingEventAttributes;
  changeDisplayCollEvent: React.Dispatch<React.SetStateAction<DisplayState>>;
  showCollectingEvent: DisplayState;
  fromMuseumObjectUuid: MuseumObjectUuid;

  history: H.History;
  appSession: AppSession;
  date?: DateAttributes;
  headerData: CollectingEventHeader;
  relatedEnrichedMuseumObjects?: MuseumObjectSearchResult[];
  actorAndRelations?: OutActorAndRelation[];
  place?: OutPlace;

  onChangeMetaData: (e: CollectingEventAttributes) => void;
  onChangeDate: (e: DateAttributes) => void;
  onChangePlace: (place: OutPlace) => void;
  onChangeMethodId: (methodId: string) => void;
  onChangeActorAndRelations: (a: OutActorAndRelation[]) => void;
  updateIsChanged: UpdateIsChangedFunc;
  copyAndAddCollectingEvent?: () => Promise<void>;
}

export const CollEventInline = (props: CollProps) => (
  <div>
    {props.mode === 'SHARED' && (
      <div className="p-3">
        <div className="row">
          <div className="col-md-6">
            <button
              className="btn btn-primary"
              type="button"
              disabled={props.headerData.eventUuid !== undefined ? false : true}
              onClick={() => {
                if (props.headerData.eventUuid) {
                  localStorage.setItem(
                    fromMuseumObjectUuidLocalStorageKey,
                    props.fromMuseumObjectUuid
                  );
                  const url = config.magasin.urls.client.collectingEvent.editNew(
                    props.appSession,
                    props.headerData.eventUuid
                  );
                  props.history.push(url);
                }
              }}
            >
              {words.gotoCollectingEvent}
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <b>{words.connectedObjects}</b>
          </div>
          <div className="col-md-9">
            {props.relatedEnrichedMuseumObjects &&
              props.relatedEnrichedMuseumObjects
                .sort((m1, m2) =>
                  compareMusNo(
                    m1.mnr + '/' + m1.subNo || '1000000000',
                    m2.mnr + '/' + m2.subNo || '1000000000'
                  )
                )
                .map((e, i) => (
                  <div key={`objList-${i}`}>
                    {e.mnr + `${e.subNo ? '/' + e.subNo : ''}`}
                  </div>
                ))}
          </div>
        </div>
      </div>
    )}

    <EventMetadata
      dataState={props.collAttr && props.collAttr}
      dateState={(props.date && props.date) || {}}
      changeData={props.onChangeMetaData}
      changeDate={props.onChangeDate}
      disabled={props.mode === 'SHARED'}
      eventHeaderData={props.headerData}
      funcState={{ disabled: false, readOnly: false }}
    />
    <SimplePersonsComponent
      id="collectingEventRelPersons"
      eventMetadata={props.collAttr}
      changeEventMetadataState={props.onChangeMetaData}
      changePersonDataState={props.onChangeActorAndRelations}
      disabled={props.mode === 'SHARED'}
      personData={props.actorAndRelations || []}
    />
    <PlaceComponent
      place={props.place || {}}
      updatePlace={props.onChangePlace}
      disabled={props.mode === 'SHARED'}
      methodId={props.collAttr?.methodId} //Because one of the fields is part of event metadata
      updateMethodId={props.onChangeMethodId} //Because one of the fields is part of event metadata
    />
  </div>
);

const CollectingEventRelationComponent = ({
  showCollectingEvent,
  museumObject,
  searchResult,
  searchFunction,
  addCollectingEvent,
  collectingEventData,
  changeCollectingEvent,
  changeDisplayCollEvent,
  relatedEnrichedMuseumObjects,
  clearSearch,
  history,
  appSession,
  updateIsChanged,
  anchor,
  copyAndAddCollectingEvent,
  swapCollectingEvent,
  incForceRefresh,
  deleteOldCollectingEvent,
  save
}: {
  save?: (collectingEvent?: CollectingEvent) => Promise<void>;
  museumObject: InputMuseumObject;
  deleteOldCollectingEvent?: () => void;
  incForceRefresh?: () => void;
  showCollectingEvent: DisplayState;
  anchor: string;
  searchResult?: MusitEvent[];
  searchFunction: (searhTerm: string) => void;
  clearSearch: () => void;
  addCollectingEvent: (collectingEvent: CollectingEvent) => void;
  changeDisplayCollEvent: React.Dispatch<React.SetStateAction<DisplayState>>;
  relatedEnrichedMuseumObjects?: MuseumObjectSearchResult[];
  collectingEventData?: CollectingEvent;
  changeCollectingEvent: React.Dispatch<React.SetStateAction<CollectingEvent>>;
  history: H.History;
  appSession: AppSession;
  updateIsChanged: UpdateIsChangedFunc;
  copyAndAddCollectingEvent?: () => Promise<void>;
  swapCollectingEvent?: (
    museumObject: InputMuseumObject,
    newCollectingEvent: CollectingEvent,
    oldCollectingEvent?: CollectingEvent
  ) => Promise<Response | undefined>;
}) => {
  const [searchTerm, changeSearchTerm] = React.useState<string>('');
  const [selectedCollectingEvent, changeSelectedCollectingEvent] = React.useState<
    CollectingEvent | undefined
  >(undefined);

  //const hasEvent = true;

  const collEvent = (
    <CollEventInlineContainer
      copyAndAddCollectingEvent={copyAndAddCollectingEvent}
      updateIsChanged={updateIsChanged}
      mode={
        relatedEnrichedMuseumObjects && relatedEnrichedMuseumObjects.length > 1
          ? 'SHARED'
          : 'UNSHARED'
      }
      date={collectingEventData?.date}
      history={history}
      changeDisplayCollEvent={changeDisplayCollEvent}
      showCollectingEvent={showCollectingEvent}
      appSession={appSession}
      collAttr={collectingEventData?.attributes}
      place={collectingEventData?.place}
      actorAndRelations={collectingEventData?.relatedActors}
      changeCollectingEventData={changeCollectingEvent}
      relatedEnrichedMuseumObjects={relatedEnrichedMuseumObjects}
      objectUuid={museumObject.museumObjectUuid || 'x'}
      headerData={{
        collectionUuid: collectingEventData
          ? collectingEventData.collectionUuid
          : appSession.collectionId,
        museumId: collectingEventData
          ? collectingEventData.museumId
          : appSession.museumId,
        eventTypeId: collectingEventData
          ? collectingEventData.eventTypeId
          : EventTypeId.CollectingEvent,
        eventUuid: collectingEventData && collectingEventData.eventUuid
      }}
    />
  );
  const searchOrRegister = (
    <>
      <form>
        <div id="searchOrRegisterTop" className="row" />

        <label htmlFor="collEventSearch">{words.searchForCollectingEventAnd}</label>
        <div className="row pb-3">
          <div className="col-md-10">
            <input
              type="text"
              className="form-control"
              id="collEventSearch"
              placeholder={words.searchCollectingEvents}
              onChange={e => changeSearchTerm(e.target.value)}
              value={searchTerm}
            />
          </div>
          <div className="col-md-2">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={e => {
                e.preventDefault();
                changeSelectedCollectingEvent(undefined);
                searchFunction(searchTerm);
              }}
            >
              {words.search}
            </button>
          </div>
        </div>

        {searchResult ? (
          <div className="grid">
            <div className="row pt-3">
              <div className="col-md-3">{words.name}</div>
              <div className="col-md-4">{words.admPlace}</div>
              <div className="col-md-2">{words.station} </div>
            </div>
            <hr />
            {searchResult.map((s, i) => {
              return (
                <div className="row" key={`key-collresult - ${i}`}>
                  <div className="col-md-3">
                    {s.attributes && (s.attributes as CollectingEventAttributes).name}
                  </div>
                  <div className="col-md-4">
                    {s.place && s.place.admPlace && s.place.admPlace.name}
                  </div>
                  <div className="col-md-2">
                    {s.place && s.place.attributes && s.place.attributes.station}
                  </div>

                  <div className="col-md-2">
                    <a
                      href={anchor}
                      onClick={async e => {
                        try {
                          if (save) {
                            changeSelectedCollectingEvent(s as CollectingEvent);
                            clearSearch();
                            deleteOldCollectingEvent && deleteOldCollectingEvent();

                            await save(s as CollectingEvent);

                            if (museumObject.museumObjectUuid && incForceRefresh) {
                              incForceRefresh();
                              history.push(
                                config.magasin.urls.client.object.editObject(
                                  appSession,
                                  museumObject.museumObjectUuid
                                )
                              );
                            }
                          }
                        } catch (e) {
                          console.log('Error ---- Select to add:  ', e);
                        }
                      }}
                    >
                      {words.selectToAdd}
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        ) : selectedCollectingEvent ? (
          <div className="container">
            <div className="row">
              {' '}
              <div className="col alert alert-secondary">
                {words.eventSelected(selectedCollectingEvent.attributes?.name)}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="row">
          <button type="button" className="btn btn-secondary">
            + {words.registerNew}
          </button>
        </div>
      </form>
    </>
  );

  return (
    <CollectingEventRelationTabs
      searchOrRegister={searchOrRegister}
      collEventView={collEvent}
      displayState={showCollectingEvent}
      changeDisplayState={changeDisplayCollEvent}
    />
  );
};

const CollectingEventRelationTabs = ({
  searchOrRegister,
  collEventView,
  changeDisplayState,
  displayState
}: {
  searchOrRegister: JSX.Element;
  collEventView: JSX.Element;
  changeDisplayState: React.Dispatch<React.SetStateAction<DisplayState>>;
  displayState: DisplayState;
}) => {
  return (
    <>
      <ul className="nav nav-tabs" id="collectingEventRelationTabs" role="tablist">
        <li className="nav-item">
          {' '}
          <a
            className={`nav-link ${
              displayState === DisplayState.showEdit ? ' active' : ''
            }`}
            id="collEventView-tab"
            onClick={e => {
              e.preventDefault();
              changeDisplayState(DisplayState.showEdit);
            }}
            href="#collEventView"
            role="tab"
            aria-controls="collEventView"
            aria-selected="true"
          >
            {words.view}
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${
              displayState === DisplayState.showSearch ? ' active' : ''
            }`}
            onClick={e => {
              e.preventDefault();
              changeDisplayState(DisplayState.showSearch);
            }}
            id="searchOrRegister-tab"
            href="#searchOrRegister"
            role="tab"
            aria-controls="searchOrRegister"
            aria-selected="true"
          >
            {words.searchOrRegister}
          </a>
        </li>
      </ul>
      <div className="tab-content collectigEventTab">
        <div
          className={`tab-pane ${
            displayState === DisplayState.showEdit ? ' show active' : ' fade'
          }`}
          id="collEventView"
          role="tabpanel"
          aria-labelledby="collEventView-tab"
        >
          {collEventView}
        </div>
        <div
          className={`tab-pane ${
            displayState === DisplayState.showSearch ? ' show active' : ' fade'
          }`}
          id="searchOrRegister"
          role="tabpanel"
          aria-labelledby="searchOrRegister-tab"
        >
          {searchOrRegister}
        </div>
      </div>
    </>
  );
};

export default CollectingEventRelationComponent;
