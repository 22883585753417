// @flow
import * as React from 'react';
import { I18n } from 'react-i18nify';
import { toArray } from 'lodash';
import FontAwesome from 'react-fontawesome';
import { FormData } from './shared/formType';
import {
  getParentObjectId,
  getSortedObjectList,
  getSortedSampleList
} from './shared/getters';
import { AnalysisStoreState } from './analysisStore';
import { AppSession } from '../../types/appSession';
import config from '../../config';
import { getTranslator } from '../../shared/language';
import {
  AnalysisEvent,
  ExtraResultAttributeValues,
  Restriction,
  AnalysisType
} from '../../types/analysis';
import { History } from 'history';
import { Predefined } from '../../types/predefined';
import Loader from 'react-loader';
import { Maybe, TODO } from '../../types/common';
import { MouseEventHandler } from 'react';
import ViewResult from './components/ViewResult';
import { getFileAsBlob } from '../../models/analysis/analysisResult';
import { saveBlob } from '../../shared/download';
import { isErrorLoading } from '../../types/documentsCommon';

export type Props = {
  match: { params: { analysisId: string } };
  form: FormData;
  store: AnalysisStoreState;
  analysisPurpose: string;
  analysisTypeTerm: string;
  statusText: string;
  labPlaceText: string;
  objects: Array<AnalysisEvent>;
  clickEdit: MouseEventHandler<HTMLElement>;
  extraDescriptionAttributes: any;
  extraResultAttributes: Maybe<ExtraResultAttributeValues>;
  history: History;
  appSession: AppSession;
  cancelRestriction: () => void;
  updateRestriction: (restriction: Restriction) => void;
  updateAnalysis: Function;
  loadingAnalysis: boolean;
  hasRestrictions: Maybe<boolean>;
  toggleCancelDialog: () => void;
  isRestrictionValidForCancellation: boolean;
  predefined: Predefined;
  clearForm: Function;
  clearStore: Function;
  getAnalysis: Function;
  loadForm: Function;
  analysisTypeId: TODO;
};

type objectValue = string | object;

const getFormattedDate = (d: any) => {
  if (!d) return '';
  return (
    d.substring(8, 10) +
    '.' +
    d.substring(5, 7) +
    '.' +
    d.substring(0, 4) +
    ' ' +
    d.substring(11, 19)
  );
};

const words = getTranslator({
  en: {
    typeLabel: 'enLabel'
  },
  no: {
    typeLabel: 'noLabel'
  }
});

export const ViewExtraResultAttributesForObject = ({
  object,
  analysisEventType,
  affectedThing,
  language
}: {
  object: any;
  analysisEventType: AnalysisType;
  affectedThing: string;
  language: { isEn: boolean; isNo: boolean };
}) => {
  const keys =
    analysisEventType && analysisEventType.extraResultAttributes
      ? Object.keys(analysisEventType.extraResultAttributes).filter(t => t !== 'type')
      : [];

  const result = object.result;

  return (
    <>
      {keys.map((k: string, i: number) => {
        const label = I18n.t('musit.analysis.analysisExtraResultAttributes.' + k);
        let value: objectValue = '';
        if (k !== 'size' && result && result[k]) {
          if (typeof result[k] === 'string') {
            value = result[k];
          } else if (typeof result[k] === 'object') {
            value = `${result[k].value} ${result[k].unit}`;
          } else {
            value = '';
          }
        } else {
          value = '';
        }
        /*  old way of setting value changed as of MUSARK-2759
        const value =
          k !== 'size' && result[k]
            ? result[k]
            : result[k]
            ? result[k].value + ' ' + result[k].unit
            : ''; */

        return (
          <tr
            className={'collapse multi-collapse' + affectedThing}
            key={`rowNo-2-${i + '.' + i}`}
          >
            <td className="text-right" style={{ borderTop: 'none' }}>
              <b>{label}</b>
            </td>
            <td colSpan={4} style={{ borderTop: 'none' }}>
              {value}
            </td>
          </tr>
        );
        //return <div></div>;
      })}
    </>
  );
};

export const ViewRestricition = (props: {
  restriction: Restriction;
  clickCancelRestriction?: () => void;
  title: string;
}) => (
  <>
    <hr />
    <div className="row" style={{ marginTop: 20 }}>
      <div className="col">
        <h2>{props.title}</h2>
      </div>
    </div>
    <div className="row">
      <div className="col-md-3 text-right">
        <b>{I18n.t('musit.analysis.restrictions.restrictionsFor')}</b>
      </div>
      <div className="col-md-9">{props.restriction.requesterName}</div>
    </div>
    <div className="row">
      <div className="col-md-3 text-right">
        <b>{I18n.t('musit.analysis.restrictions.reasonForRestriction')}</b>
      </div>
      <div className="col-md-9">{props.restriction.reason}</div>
    </div>
    <div className="row">
      <div className="col-md-3 text-right">
        <b>{I18n.t('musit.analysis.restrictions.caseNumber')}</b>
      </div>
      <div className="col-md-9">
        {props.restriction.caseNumbers ? props.restriction.caseNumbers[0] : ''}
      </div>
    </div>
    <div className="row">
      <div className="col-md-3 text-right">
        <b>{I18n.t('musit.analysis.restrictions.endDate')}</b>
      </div>
      <div className="col-md-9">{getFormattedDate(props.restriction.expirationDate)}</div>
    </div>
    {props.restriction.cancelledReason && (
      <>
        <hr />
        <div className="row">
          <div className="col-md-3 text-right">
            <b>{I18n.t('musit.analysis.restrictions.cancelledBy')}</b>
          </div>
          <div className="col-md-9">{props.restriction.cancelledByName}</div>
        </div>
        <div className="row">
          <div className="col-md-3 text-right">
            <b>{I18n.t('musit.analysis.restrictions.reasonForCancelling')}</b>
          </div>
          <div className="col-md-9">{props.restriction.cancelledReason}</div>
        </div>
      </>
    )}
    {props.clickCancelRestriction && (
      <div className="row">
        <button
          className="btn btn-secondary"
          type="button"
          onClick={props.clickCancelRestriction}
          title={I18n.t('musit.analysis.restrictions.cancelRestriction')}
        >
          {I18n.t('musit.analysis.restrictions.cancelRestriction')}
        </button>
      </div>
    )}
  </>
);

const ViewExtraDescriptions = ({
  extraAttributes
}: {
  extraAttributes: {
    attributeKey: string;
    attributeType: string;
    attributeValue: any;
    allowedValues: any;
  }[];
}) => {
  return (
    <>
      {extraAttributes &&
        extraAttributes.map(
          (
            k: {
              attributeKey: string;
              attributeType: string;
              attributeValue: any;
              allowedValues: any;
            },
            i: number
          ) => {
            if (k.attributeType === 'String' || k.attributeType === 'Int') {
              return (
                <div key={i + '-ex'} className="row">
                  <div className="col-md-3 text-right">
                    <b>{I18n.t('musit.analysis.analysisAttributes.' + k.attributeKey)}</b>
                  </div>
                  <div className="col-md-9">{k.attributeValue}</div>
                </div>
              );
            }
            if (k.attributeType === 'Array[Int]') {
              return (
                <div key={i + '-ex'} className="row">
                  <div className="col-md-3 text-right">
                    <b>
                      {' '}
                      {I18n.t('musit.analysis.analysisAttributes.' + k.attributeKey)}
                    </b>
                  </div>
                  <div className="col-md-9">
                    {(k.attributeValue ? k.attributeValue : []).map(
                      (n: number, i: number) => (
                        <div className="form-row" key={`types-key-${i}`}>
                          <span>{k.allowedValues[n - 1][words.typeLabel]}</span>
                        </div>
                      )
                    )}
                  </div>
                </div>
              );
            }
            return '';
          }
        )}
    </>
  );
};

const MiniCollapse = (props: any) => {
  const [collapse, changeCollapse] = React.useState(false);
  return (
    <td
      data-toggle="collapse"
      data-target={'.multi-collapse' + props.affectedThing}
      aria-expanded="false"
      style={{ cursor: 'pointer' }}
      title="attachments"
      className="text-right"
      onClick={() => changeCollapse(!collapse)}
    >
      <span
        className={'fa ' + (collapse ? 'fa-chevron-down' : 'fa-chevron-right')}
        style={{ color: 'black' }}
      />
    </td>
  );
};

const ObjectViewer = (props: any) => {
  return (
    <>
      <div className="row" style={{ backgroundColor: '#f7f7f7', marginTop: 20 }}>
        <div className="col">
          <h2>{I18n.t('musit.analysis.objects')}</h2>
        </div>
      </div>
      <div className="row" style={{ backgroundColor: '#f7f7f7', paddingTop: 10 }}>
        <div className="col">
          <table className="table">
            <thead>
              <tr>
                <th style={{ cursor: 'text' }}>
                  {I18n.t('musit.objects.objectsView.musNo')}
                </th>
                <th style={{ cursor: 'text' }}>
                  {I18n.t('musit.objects.objectsView.subNo')}
                </th>
                <th style={{ cursor: 'text' }}>{I18n.t('musit.analysis.term')}</th>
                <th style={{ cursor: 'text' }} />
              </tr>
            </thead>
            {getSortedObjectList(
              props.objects.map((ob: any) => ({
                ...ob.objectData,
                result: ob.result,
                files: ob.files || [],
                analysisType: props.analysisEventTypes.find(
                  (a: AnalysisType) => a.id === ob.analysisTypeId
                )
              }))
            ).map((o: any, i: number) => {
              const url = config.magasin.urls.client.object.gotoObject(
                props.appSession,
                o.uuid
              );
              return (
                <tbody key={o.uuid + i}>
                  <tr key={`rowNo-0-${i}`}>
                    <td>{o.museumNo}</td>
                    <td>{o.subNo}</td>
                    <td>{o.term}</td>

                    <MiniCollapse affectedThing={o.uuid} />
                  </tr>
                  <tr className={'collapse multi-collapse' + o.uuid}>
                    <td colSpan={12}>
                      <b>
                        {props.appSession.language.isEn
                          ? o.analysisType.enName
                          : o.analysisType.noName}
                      </b>
                    </td>
                  </tr>
                  <ViewExtraResultAttributesForObject
                    object={o}
                    analysisEventType={o.analysisType}
                    affectedThing={o.uuid}
                    language={props.appSession.language}
                  />
                  <tr className={'collapse multi-collapse' + o.uuid} key={`rowNo-2-${i}`}>
                    <td className="text-right" style={{ borderTop: 'none' }}>
                      <b>{I18n.t('musit.analysis.externalSource')}</b>
                    </td>
                    <td colSpan={4} style={{ borderTop: 'none' }}>
                      {o.result && o.result.extRef}
                    </td>
                  </tr>
                  <tr className={'collapse multi-collapse' + o.uuid} key={`rowNo-3-${i}`}>
                    <td className="text-right" style={{ borderTop: 'none' }}>
                      <b>{I18n.t('musit.analysis.files')}</b>
                    </td>
                    <td colSpan={4} style={{ borderTop: 'none' }}>
                      {displayFiles(
                        o.files,
                        props.appSession.museumId,
                        props.appSession.accessToken
                      )}
                    </td>
                  </tr>
                  <tr className={'collapse multi-collapse' + o.uuid} key={`rowNo-4-${i}`}>
                    <td className="text-right" style={{ borderTop: 'none' }}>
                      <b>{I18n.t('musit.analysis.commentsToResult')}</b>
                    </td>
                    <td colSpan={4} style={{ borderTop: 'none' }}>
                      {o.result && o.result.comment}
                    </td>
                  </tr>
                  <tr
                    className={'collapse multi-collapse' + o.uuid + ' text-right'}
                    key={`rowNo-5-${i}`}
                  >
                    <td style={{ borderTop: 'none' }}>
                      <b>
                        <a href={url}>{I18n.t('musit.objects.goToParentObject')}</a>
                      </b>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
};

const SampleViewer = (props: any) => {
  return (
    <>
      <div className="row" style={{ backgroundColor: '#f7f7f7', marginTop: '20px' }}>
        <div className="col">
          <h2>{I18n.t('musit.analysis.samples')}</h2>
        </div>
      </div>
      <div className="row" style={{ backgroundColor: '#f7f7f7', paddingTop: '10px' }}>
        <div className="col">
          <table className="table">
            <thead>
              <tr>
                <th style={{ cursor: 'text' }}>
                  {I18n.t('musit.objects.objectsView.musNo')}
                </th>
                <th style={{ cursor: 'text' }}>
                  {I18n.t('musit.objects.objectsView.subNo')}
                </th>
                <th style={{ cursor: 'text' }}>{I18n.t('musit.analysis.term')}</th>
                <th style={{ cursor: 'text' }}>{I18n.t('musit.sample.sampleNumber')}</th>
                <th style={{ cursor: 'text' }}>{I18n.t('musit.sample.sampleType')}</th>
                <th style={{ cursor: 'text' }} />
              </tr>
            </thead>
            {getSortedSampleList(
              props.samples.map((s: any) => ({
                ...s.sampleData,
                objectData: s.objectData,
                affectedThing: s.affectedThing,
                files: s.files,
                result: s.result,
                analysisType: props.analysisEventTypes.find(
                  (a: AnalysisType) => a.id === s.analysisTypeId
                )
              }))
            ).map((o: any, i: number) => {
              const url = config.magasin.urls.client.object.gotoObject(
                props.appSession,
                o.objectData.uuid
              );
              return (
                <tbody key={o.affectedThing + i}>
                  <tr key={`rowNo-6-${i}`}>
                    <td>{o.objectData.museumNo}</td>
                    <td>{o.objectData.subNo}</td>
                    <td>{o.objectData.term}</td>
                    <td>{o.sampleNum}</td>
                    <td>{o.sampleType.enSampleType}</td>
                    <MiniCollapse affectedThing={o.affectedThing} />
                  </tr>
                  <ViewExtraResultAttributesForObject
                    object={o}
                    analysisEventType={o.analysisType}
                    affectedThing={o.affectedThing}
                    language={props.appSession.language}
                  />
                  <tr
                    className={'collapse multi-collapse' + o.affectedThing}
                    key={`rowNo-7-${i}`}
                  >
                    <td className="text-right" style={{ borderTop: 'none' }}>
                      <b>{I18n.t('musit.analysis.externalSource')}</b>
                    </td>
                    <td colSpan={4} style={{ borderTop: 'none' }}>
                      {o.result && o.result.extRef}
                    </td>
                  </tr>
                  <tr
                    className={'collapse multi-collapse' + o.affectedThing}
                    key={`rowNo-8-${i}`}
                  >
                    <td className="text-right" style={{ borderTop: 'none' }}>
                      <b>{I18n.t('musit.analysis.files')}</b>
                    </td>
                    <td colSpan={4} style={{ borderTop: 'none' }}>
                      {displayFiles(
                        o.files,
                        props.appSession.museumId,
                        props.appSession.accessToken
                      )}
                    </td>
                  </tr>
                  <tr
                    className={'collapse multi-collapse' + o.affectedThing}
                    key={`rowNo-9-${i}`}
                  >
                    <td className="text-right" style={{ borderTop: 'none' }}>
                      <b>{I18n.t('musit.analysis.commentsToResult')}</b>
                    </td>
                    <td colSpan={4} style={{ borderTop: 'none' }}>
                      {o.result && o.result.comment}
                    </td>
                  </tr>
                  <tr
                    className={
                      'collapse multi-collapse' + o.affectedThing + ' text-right'
                    }
                    key={`rowNo-10-${i}`}
                  >
                    <td style={{ borderTop: 'none' }}>
                      <b>
                        <a href={url}>{I18n.t('musit.objects.goToParentObject')}</a>
                      </b>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
};
const displayFiles = (files: any, museumId: number, accessToken: string) => (
  <div className="form-control-static">
    {Array.isArray(files) &&
      files.map((file: any) => {
        if (isErrorLoading(file)) {
          return null;
        }
        const fid = file.fid;
        const title = file.title;
        return (
          <p key={fid}>
            <button
              className="btn-link"
              onClick={e => {
                e.preventDefault();
                // tslint:disable-next-line: no-floating-promises
                getFileAsBlob(fid, museumId, accessToken)
                  .do(res => {
                    if (res instanceof Blob) {
                      saveBlob(res, title);
                    }
                  })
                  .toPromise();
              }}
            >
              {file.title}
            </button>
          </p>
        );
      })}
  </div>
);

export default (props: Props) => {
  /*   const analyseType = props.predefined && props.predefined.analysisTypes && props.predefined.analysisTypes.find((a: AnalysisType) => a.id = props.analysisTypeId);
    const extraAttributes = analyseType?.extraResultAttributes; */

  return !props.loadingAnalysis ? (
    <div className="container" style={{ paddingTop: 100 }}>
      <div className="page-header">
        <button
          type="button"
          className="btn btn-primary pull-right"
          onClick={e => {
            window.location.href = 'edit?analysisId=' + props.store.analysis!.id;
          }}
        >
          {I18n.t('musit.analysis.updateAnalysis')}
        </button>

        <h1>{I18n.t('musit.analysis.analysis')}</h1>
      </div>
      <div className="row" style={{ marginTop: 10 }}>
        <div className="col-md-3 text-right">
          <b>{I18n.t('musit.texts.registeredBy')}</b>
        </div>
        <div className="col-md-9">
          <FontAwesome name="user" /> {props.form.registeredByName.value}
          <FontAwesome name="clock-o" style={{ marginLeft: 20 }} />{' '}
          {getFormattedDate(props.form.registeredDate.value)}
        </div>
      </div>
      {props.form.updatedDate.value && (
        <div className="row">
          <div className="col-md-3 text-right">
            <b>{I18n.t('musit.texts.lastUpdateBy')}</b>
          </div>
          <div className="col-md-9">
            <FontAwesome name="user" /> {props.form.updatedByName.value}
            <FontAwesome name="clock-o" style={{ marginLeft: 20 }} />{' '}
            {getFormattedDate(props.form.updatedDate.value)}
          </div>
        </div>
      )}
      <hr />
      <div className="row">
        <div className="col-md-3 text-right">
          <b>{I18n.t('musit.analysis.analysisType')}</b>
        </div>
        <div className="col-md-9">{props.analysisTypeTerm}</div>
      </div>
      {props.extraDescriptionAttributes && (
        <ViewExtraDescriptions extraAttributes={props.extraDescriptionAttributes} />
      )}
      <div className="row">
        <div className="col-md-3 text-right">
          <b>{I18n.t('musit.analysis.reason')}</b>
        </div>
        <div className="col-md-9">{props.analysisPurpose}</div>
      </div>
      <div className="row">
        <div className="col-md-3 text-right">
          <b>{I18n.t('musit.analysis.status')}</b>
        </div>
        <div className="col-md-9">{props.statusText}</div>
      </div>
      <div className="row">
        <div className="col-md-3 text-right">
          <b>{I18n.t('musit.analysis.place')}</b>
        </div>
        <div className="col-md-9">{props.labPlaceText}</div>
      </div>
      <div className="row">
        <div className="col-md-3 text-right">
          <b>{I18n.t('musit.analysis.caseNumber')}</b>
        </div>
        <div className="col-md-9">
          {props.form.caseNumbers.value &&
            Array.isArray(props.form.caseNumbers.value) &&
            props.form.caseNumbers.value.join(', ')}
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-md-3 text-right">
          <b>{I18n.t('musit.analysis.note')}</b>
        </div>
        <div className="col-md-9">{props.form.note.value}</div>
      </div>
      <hr />
      <div className="row" style={{ marginTop: 20 }}>
        <div className="col">
          <h2>{I18n.t('musit.analysis.personTillAnalysis')}</h2>
        </div>
      </div>
      <table className="table" style={{ marginTop: 10 }}>
        <thead>
          <tr>
            <th scope="col">{I18n.t('musit.texts.name')}</th>
            <th scope="col">{I18n.t('musit.texts.role')}</th>
            <th scope="col">{I18n.t('musit.texts.date')}</th>
          </tr>
        </thead>
        <tbody>
          {props.form.persons.value!.map(p => {
            return (
              <tr key={p.uuid}>
                <td>{p.name}</td>
                <td>{I18n.t('musit.analysis.roles.' + p.role)}</td>
                <td>{getFormattedDate(p.date).split(' ')[0]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <hr />
      <ObjectViewer
        appSession={props.appSession}
        analysisEventTypes={props.predefined.analysisTypes}
        objects={props.objects.filter(o =>
          o.sampleData && o.sampleData.sampleTypeId ? false : true
        )}
      />
      <SampleViewer
        appSession={props.appSession}
        analysisEventTypes={props.predefined.analysisTypes}
        samples={props.objects.filter(o =>
          o.sampleData && o.sampleData.sampleTypeId ? true : false
        )}
      />
      {props.store.analysis && (
        <React.Fragment>
          <div className="row">
            <div className="col">
              <h2>{I18n.t('musit.analysis.resultMetaData')}</h2>
            </div>
          </div>
          <hr />
        </React.Fragment>
      )}
      {props.store.analysis && (
        <ViewResult
          files={props.store.analysis ? props.store.analysis.files : []}
          extraAttributes={props.extraResultAttributes}
          externalSource={toArray(props.form.externalSource.value).join(',')}
          comments={props.form.comments.value}
          appSession={props.appSession}
          history={props.history}
          parentObjectId={
            props.objects && props.objects.length === 1
              ? getParentObjectId(props.objects[0])
              : null
          }
        />
      )}

      {props.store.analysis && props.store.analysis.restriction && (
        <ViewRestricition
          restriction={props.store.analysis.restriction}
          title={
            props.store.analysis.restriction.cancelledStamp
              ? I18n.t('musit.analysis.restrictions.restrictionsCancelled')
              : I18n.t('musit.analysis.restrictions.restrictions')
          }
        />
      )}
    </div>
  ) : (
    <Loader loaded={false} />
  );
};
