import * as React from 'react';
import * as T from './types';
import { insertOrUpdateNumField, numFieldToNumSearch } from './Search';
import { fieldsForEventType } from './initData';
import { Field, NumSearch } from './types';
import { getTranslator } from '../../../shared/language';

const words = getTranslator({
  en: {
    eventType: 'Event type',
    selectEventType: 'Select event type',
    collectingEvent: 'Collecting event',
    taxon: 'Classification event',
    sexAndStages: 'Sex and stages',
    selectFieldToAdd: 'Select field to add',
    selectNumberFieldToAdd: 'Select number field to add',
    eventDateFrom: 'Date from',
    eventDateTo: 'Date to',
    altitude: 'Altitude',
    depth: 'Depth',
    precision: 'Precision',
    accuracy: 'Accuracy (GPS)'
  },
  no: {
    eventType: 'Hendelsestype',
    selectEventType: 'Velg hendelsestype',
    collectingEvent: 'Innsamlingshendelse',
    taxon: 'Bestemmelseshendelse',
    sexAndStages: 'Kjønn og stadier',
    selectFieldToAdd: 'Velg felt å legge til',
    selectNumberFieldToAdd: 'Velg nummerfelt å legge til søk',
    eventDateFrom: 'Data fra',
    eventDateTo: 'Dato til',
    altitude: 'Høyde',
    depth: 'Dybde',
    precision: 'Presisjon',
    accuracy: 'Nøyaktighet (GPS)'
  }
});

const NumberField = ({
  field,
  addToEventFilter,
  index,
  label
}: {
  field: Field;
  index: number;
  addToEventFilter: (numSearch: NumSearch) => void;
  label: string;
}) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const v = e.target.value;
    const s: NumSearch = numFieldToNumSearch(field.value, v, field.contentPrefix);
    addToEventFilter(s);
  };

  return (
    <div className="form-row" key={index + '-row'}>
      <label
        key={`label-${index}`}
        htmlFor={field.value}
        className="col-md-3 col-form-label"
      >
        {label}
      </label>
      <div key={`key-col-${index}`} className="col-md-4">
        <input
          id={field.value + index}
          key={'input' + field.value + index}
          type="text"
          onChange={onChange}
          className="form-control"
        />
      </div>
    </div>
  );
};

const SelectedFields = ({
  fields,
  addToEventFilter,
  fieldTranslate
}: {
  fields: Field[];
  addToEventFilter: (numSearch: NumSearch) => void;
  fieldTranslate: { [k: string]: string };
}) => (
  <div style={{ padding: '10px 0px' }}>
    {fields.map((f: Field, i: number) => (
      <NumberField
        key={`numfield-${i}`}
        index={i}
        field={f}
        addToEventFilter={addToEventFilter}
        label={fieldTranslate[`attributes§${f.contentPrefix}` || '']}
      />
    ))}
  </div>
);

const EventFieldSelect = ({
  fields,
  changeSelectedFields
}: {
  fields: T.Field[];
  changeSelectedFields: React.Dispatch<React.SetStateAction<T.Field[]>>;
}) => {
  const findFieldFromFieldName = (fieldName: string) =>
    fields.find(f => f.value === fieldName);
  const [selectedField, changeSelectedField] = React.useState<string>('');

  return (
    <div className="row">
      <label htmlFor="selectField" className="col-md-3 col-form-label">
        {words.selectNumberFieldToAdd}
      </label>
      <div className="col-md-4">
        <select
          value={selectedField}
          id="selectField"
          className="form-control"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            const v = e.target.value;
            changeSelectedField(v);
            changeSelectedFields(s => {
              const f = findFieldFromFieldName(v);
              if (f) return [...s, f];
              return s;
            });
          }}
        >
          <option key="field--1" value={''} label={words.selectFieldToAdd} />
          {fields.map((f: Field, i: number) => (
            <option key={`field-${i}`} value={f.value} label={words[f.value]} />
          ))}
        </select>
      </div>
    </div>
  );
};

type Props = {
  eventFilter: T.EventFilter | null;
  changeEventFilter: React.Dispatch<React.SetStateAction<T.EventFilter>>;
  fieldTranslate: { [k: string]: string };
};

const EventFilter = (p: Props) => {
  const [eventType, changeEventType] = React.useState<string>('');
  const [selectedFields, changeSelectedFields] = React.useState<T.Field[]>([]);

  const onChangeEventType = (eventType: string) => {
    changeEventType(eventType);
    p.changeEventFilter(e => ({
      ...e,
      eventTypeId: fieldsForEventType[eventType].eventTypeId
    }));
  };

  const onChangeDate = (field: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
    const v = ev.target.value;

    p.changeEventFilter((e: T.EventFilter) => {
      return { ...e, [field]: v };
    });
  };
  const addToEventFilter = (eventType: string) => (n: NumSearch) => {
    const numSearchWithEventType = { ...n, eventType };
    const oldNumFields =
      p.eventFilter && p.eventFilter.numFields ? p.eventFilter.numFields : [];
    const newNumFields = insertOrUpdateNumField(numSearchWithEventType, oldNumFields);
    p.changeEventFilter(e => ({ ...e, numFields: newNumFields }));
  };
  const dateFrom =
    p.eventFilter !== null && p.eventFilter.dateFrom ? p.eventFilter.dateFrom : '';
  const dateTo =
    p.eventFilter !== null && p.eventFilter.dateTo ? p.eventFilter.dateTo : '';

  return (
    <>
      <div className="form-group row">
        <div className="col-md-3">
          <label htmlFor="eventTypeField" className="col-md-2 col-form-label">
            {words.eventType}
          </label>
          <select
            style={{ marginLeft: '10px' }}
            value={eventType}
            id="eventTypeField"
            onChange={e => onChangeEventType(e.target.value)}
            className="form-control"
          >
            <option key="1" value={''}>
              {words.selectEventType}
            </option>
            <option key="2" value="collectingEvent">
              {words.collectingEvent}
            </option>
            <option key="3" value="taxon">
              {words.taxon}
            </option>
            <option key="4" value="sexAndStages">
              {words.sexAndStages}
            </option>
          </select>
        </div>
      </div>

      {eventType && (
        <div className="form-row">
          <div className="col-md-3">
            <label htmlFor="eventDateFrom">{words.eventDateFrom}</label>
            <input
              disabled={eventType === undefined}
              value={dateFrom}
              type="text"
              placeholder={words.eventDateFrom}
              id="eventDateFrom"
              onChange={onChangeDate('dateFrom')}
              className="form-control"
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="eventDateTo">{words.eventDateTo}</label>
            <input
              placeholder={words.eventDateTo}
              value={dateTo}
              type="text"
              disabled={eventType === undefined}
              id="eventDateTo"
              onChange={onChangeDate('dateTo')}
              className="form-control"
            />
          </div>
        </div>
      )}

      {eventType && (
        <EventFieldSelect
          fields={fieldsForEventType[eventType].fields}
          changeSelectedFields={changeSelectedFields}
        />
      )}

      <SelectedFields
        addToEventFilter={addToEventFilter(eventType)}
        fields={selectedFields}
        fieldTranslate={p.fieldTranslate}
      />
    </>
  );
};

export default EventFilter;
