import * as React from 'react';
import { getEvent } from './ObjectContainerFunctions';
import { MusitEvent } from '../types/event/event';
import { words } from './EditMetaDataComponent';
import { InputMuseumObject } from '../types/museumObject/museumObject';
import { EventTypeId } from '../types/event/eventTypes_auto';

const ViewObjectMetadata = ({
  genericEvents,
  subObjects
}: {
  genericEvents: MusitEvent[];
  subObjects: InputMuseumObject[];
}) => {
  const attributesEvent = getEvent(
    EventTypeId.MuseumObjectAttributeAssignment,
    genericEvents
  );
  const museumNoEvent = getEvent(EventTypeId.MuseumNoAssignment, genericEvents);
  const museumNo =
    museumNoEvent && museumNoEvent.attributes && museumNoEvent.attributes.museumNo
      ? museumNoEvent.attributes.museumNo
      : '';
  const oldNumbers =
    attributesEvent && attributesEvent.attributes && attributesEvent.attributes.oldNumbers
      ? attributesEvent.attributes.oldNumbers.join()
      : '';
  const oldBarcode =
    attributesEvent &&
    attributesEvent.attributes &&
    attributesEvent.attributes.oldBarcode;

  const uuid =
    attributesEvent &&
    attributesEvent.attributes &&
    attributesEvent.attributes.uuids &&
    attributesEvent.attributes.uuids[0];

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-2 h4">
          <b>{words.objectNumber}</b>
        </div>
        <div className="col-md-4 h4">{museumNo}</div>{' '}
        <div className="col-md-2 h4">
          <b>{words.subNumber}</b>
        </div>
        <div className="col-md-4 h4">{museumNo}</div>
      </div>
      <div className="row">
        <div className="col-md-2">
          <b>{words.oldNumbers}</b>{' '}
        </div>
        <div className="col-md-4">{oldNumbers}</div>
      </div>
      <div className="row">
        <div className="col-md-2">
          <b>{words.uuid}</b>
        </div>
        <div className="col-md-4">{uuid}</div>
        <div className="col-md-2">
          <b>{words.oldBarcode}</b>{' '}
        </div>
        <div className="col-md-4">{oldBarcode}</div>
      </div>
      <div className="row">
        <div className="col-md-2">
          <b>Sub objects: </b>
        </div>
        <div className="col-md-10">
          {subObjects &&
            subObjects.reduce(
              (p: string, m: InputMuseumObject) =>
                p +
                (p !== '' ? '; ' : '') +
                (m.attributes && m.attributes.museumNo && m.attributes.museumNo.museumNo),
              ''
            )}
        </div>
      </div>
    </div>
  );
};

export default ViewObjectMetadata;
