import * as React from 'react';
import ObjectComponent from './ObjectComponent';

import {
  MusitEvent,
  SexAndStagesEvent,
  CollectingEvent,
  SubCollectionMuseumAndCollection,
  NextMuseumNoAndSubNo
} from '../types/event/event';
import { AdmProps } from './AdminComponent';
import {
  EventTypeId,
  RoleTypeId,
  MuseumNoAssignmentEventAttributes,
  RegistrationStatusTable,
  DisseminationStatusTable
} from '../types/event/eventTypes_auto';
import { AppSessionContext } from '../../app/AppComponent';
import { TaxonEvent } from '../taxon/taxonLib';
import config from '../../../config';
import { emitSuccess, emitError } from '../../../shared/errors';
import * as H from 'history';
import { withRouter } from 'react-router-dom';
import { get, put, post } from '../../../../src/shared/ajaxFetch/ajaxFetch';
import { I18n } from 'react-i18nify';

import {
  IsAddedType,
  createObjectHeaderData,
  createJson,
  createJSONForSwap,
  IsChangedType,
  ChangeResult,
  IsDeletedType,
  createObjectEventHeaderData,
  createEmptySexAndStagesEvent,
  ObjectChangeStatus,
  getEvent,
  taxonEventToInputEvent
} from './ObjectContainerFunctions';
import {
  InputMuseumObject,
  MuseumObjectSearchResult
} from '../types/museumObject/museumObject';
import { InputEvent } from '../types/event/event';
import { AppSession } from '../../../../src/types/appSession';
import { callGetUrl, callGetActor } from './ObjectContainerCreate';
import { MuseumObjectAndRelation } from '../taxon/RevisonComponent';
import {
  IsChangedType as IsChangedCollectingEventType,
  initialChangeState as initialCollectingEventChangeState,
  hasAnyChanges,
  createJson as createCollectingEventJSON,
  createEventHeaderData,
  UpdateIsChangedFunc as UpdateIsChangedCollectingEventFunc
} from '../collectingEvent/CollectingEventContainer';
import { Uuid } from 'src/models/object/collectingEvent';
import { AdmStatuses } from '../eventHistory/EventHistoryContainer';
import { Person } from '../types/person/personName';
import uniq from 'lodash/uniq';
export const hasJustEmptyTaxon = (taxonEvents: TaxonEvent[]) => {
  return taxonEvents.length === 1 && taxonEvents[0].attributes.taxons.length === 0
    ? taxonEvents[0].eventUuid
    : undefined;
};

const ObjectContainerEdit = withRouter(({ match, history }) => {
  const getUrl = config.api.objects.getMuseumObjectUrl;

  const [isChangedCollectingEventData, updateIsChangedCollectingEventState]: [
    IsChangedCollectingEventType,
    UpdateIsChangedCollectingEventFunc
  ] = React.useState<IsChangedCollectingEventType>(initialCollectingEventChangeState);

  const appSession = React.useContext(AppSessionContext);
  const museumObjectUuid = match.params.id;
  const museumObjectHeader = createObjectHeaderData(appSession, museumObjectUuid);
  const [allEvents, changeAllEvents] = React.useState<MusitEvent[]>([]);

  const [taxonEvents, changeTaxonEvents] = React.useState<TaxonEvent[]>([]);
  const [museumObject, changeMuseumObject] = React.useState<InputMuseumObject>({
    ...museumObjectHeader,
    subObjects: [],
    events: []
  });

  //Current collecting event
  const [collectingEventData, changeCollectingEventData] = React.useState<
    CollectingEvent | undefined
  >(undefined);

  // New collecting event (if changes the collecting event)
  const [oldCollectingEvent, changeOldCollectingEvent] = React.useState<
    CollectingEvent | undefined
  >(undefined);

  const [sexAndStages, changeSexAndStages] = React.useState<SexAndStagesEvent>(
    createEmptySexAndStagesEvent(
      createObjectEventHeaderData(appSession, EventTypeId.SexAndStage)
    )
  );
  const [genericEditEvents, changeGenericEditEvents] = React.useState<MusitEvent[]>([]);
  const [admStatuses, setAdmStatuses] = React.useState<AdmStatuses>({
    regStatuses: [],
    pubStatuses: []
  });

  const [admProps, changeAdmProps] = React.useState<AdmProps>({
    regBy: '',
    regDate: '',
    lastChangeBy: '',
    lastChangedDate: '',
    registrationStatuses: [],
    publicationStatuses: [],
    genericEvents: [],
    appSession: appSession,
    changeStatus: { publicationStatusChanged: false, registrationStatusChanged: false }
  });

  const [actorsInUse, changeActorsInUse] = React.useState<Person[]>([]);

  const [currentRegistrationStatus] = React.useState(undefined);
  const [currentDissiminationStatus] = React.useState(undefined);
  const [isAddedData, updateIsAddedState] = React.useState<IsAddedType>({});
  const [isChangedData, updateIsChangedState] = React.useState<IsChangedType>({});
  const [isDeletedData, updateIsDeletedState] = React.useState<IsDeletedType>({});
  const [isLoading, updateIsLoading] = React.useState<boolean>(true);
  const [collectingEventSearchResult, changeCollectingEventSearchResult] = React.useState<
    MusitEvent[] | undefined
  >(undefined);

  const [objectChangeStatus, changeObjectChangeStatus] = React.useState<
    ObjectChangeStatus
  >(
    museumObject && museumObject.museumObjectUuid
      ? ObjectChangeStatus.added
      : ObjectChangeStatus.notAdded
  );

  const [legacyEvents, setLegacyEvents] = React.useState<MusitEvent[] | undefined>(
    undefined
  );

  const [forceRefresh, incForceRefresh] = React.useState<number>(0);
  const addEventUuid = (eventUuid: string) =>
    updateIsAddedState(e => ({ ...e, [eventUuid]: true }));

  const changeEventUuid = (eventUuid: string): ChangeResult => {
    if (isAddedData[eventUuid] === true) {
      return ChangeResult.alreadyAdded;
    }
    updateIsChangedState(e => ({ ...e, [eventUuid]: true }));
    return ChangeResult.changed;
  };
  const deleteEventUuid = (eventUuid: string, eventTypeId: number) => {
    if (!isAddedData[eventUuid]) {
      updateIsDeletedState({ ...isDeletedData, [eventUuid]: eventTypeId });
    }
    removeIsAddedState(eventUuid);
    removeIsChangedState(eventUuid);
  };

  const registerChangeEventId = (eventId: number) => {
    updateIsChangedState({ ...isChangedData, [eventId.toString()]: true });
  };
  const removeIsAddedState = (eventUuid: string) => {
    updateIsAddedState({ ...isAddedData, [eventUuid]: false });
  };

  const removeIsChangedState = (eventUuid: string) => {
    updateIsChangedState({ ...isChangedData, [eventUuid]: false });
  };

  const resetState = () => {
    const emptyTaxonUuid = hasJustEmptyTaxon(taxonEvents);
    updateIsAddedState(emptyTaxonUuid ? { [emptyTaxonUuid]: true } : {});
    updateIsChangedState({});
    updateIsDeletedState({});
    updateIsChangedCollectingEventState(initialCollectingEventChangeState);
    changeOldCollectingEvent(undefined);
  };

  const searchCollectingEventSearchFunction = async (searchTerm: string) => {
    const getURL = config.api.collectingEvent.collectingEventsSearch(
      appSession.museumId,
      10,
      searchTerm
    );
    const res = await callGetUrl(history, appSession, getURL);
    if (res) {
      changeCollectingEventSearchResult(res);
    }
  };

  const createSubObject = async (parentObject: InputMuseumObject) => {
    const getSubCollsURL = config.api.objects.getSubcollections(appSession);

    let resp = await get(getSubCollsURL, appSession.accessToken);

    let jsonStr = await resp.json();
    const subColls: SubCollectionMuseumAndCollection[] = jsonStr;

    const subColl = subColls.find(
      s => s.subCollectionId === parentObject.subCollectionId
    );
    const musNoSeqId = subColl?.museumNumberSequenceId;

    let subNoURL;
    let musNo: NextMuseumNoAndSubNo;
    let assignmentAttr: MuseumNoAssignmentEventAttributes;
    let musNoAssignment: InputEvent;
    if (musNoSeqId && parentObject.subCollectionId && parentObject.museumObjectUuid) {
      subNoURL = config.api.objects.getNextSubNo(
        musNoSeqId,
        parentObject.museumObjectUuid
      );
      resp = await get(subNoURL, appSession.accessToken);
      jsonStr = await resp.json();
      musNo = jsonStr;
      assignmentAttr = {
        museumNoSeq: musNo.museumNoSeq,
        subNo: musNo.subNo.toString(),
        museumNo: musNo.museumNo
      };

      const postUrl = config.api.objects.addSubObjectUrl(museumObjectUuid);

      musNoAssignment = {
        eventTypeId: EventTypeId.MuseumNoAssignment,
        museumId: parentObject.museumId,
        collectionUuid: parentObject.collectionUuid,
        attributes: assignmentAttr
      };
      const data: InputMuseumObject = {
        museumId: parentObject.museumId,
        museumObjectTypeId: parentObject.museumObjectTypeId,
        attributes: { uuids: [] },
        collectionUuid: parentObject.collectionUuid,
        subCollectionId: parentObject.subCollectionId,
        events: [musNoAssignment],
        subObjects: []
      };
      const res = await post(postUrl, JSON.stringify(data), appSession.accessToken);
      const json = await res.json();
      return json.museumObjectUuid;
    }
  };

  const onChangeSexAndStages = (sexAndStages: SexAndStagesEvent) => {
    changeSexAndStages(sexAndStages);
    updateIsChangedState(s => ({ ...s, [sexAndStages.eventTypeId]: true }));
  };

  const editCollectingEvent = async (collectingEvent: CollectingEvent) => {
    if (collectingEvent.eventUuid) {
      const url = config.api.collectingEvent.editEventNew(collectingEvent.eventUuid);
      changeEventUuid(collectingEvent.eventUuid);
      const json =
        museumObject.museumObjectUuid && collectingEvent
          ? createCollectingEventJSON(
              {
                ...createEventHeaderData(appSession),
                revisionOf: collectingEvent.eventUuid
              },
              collectingEvent.date || {},
              collectingEvent.attributes || { name: '' },
              collectingEvent.relatedActors || [],
              collectingEvent.place
                ? {
                    ...collectingEvent.place,
                    admPlaceUuid: collectingEvent.place.admPlace
                      ? collectingEvent.place.admPlace.admPlaceUuid
                      : undefined
                  }
                : {},
              isChangedCollectingEventData,
              undefined,
              undefined,
              collectingEvent.relatedMuseumObjects
            )
          : undefined;

      const JSONStr = JSON.stringify(json);

      changeObjectChangeStatus(ObjectChangeStatus.changed);

      //await callPostUrl(history, appSession, url, JSONStr, resetState);

      await post(url, JSONStr, appSession.accessToken);
    }
  };

  const createNewCollectingEvent = async (
    collectingEvent: undefined | CollectingEvent,
    appSession: AppSession
  ) => {
    const url = config.api.collectingEvent.addEventUrl;

    const headerData = {
      museumId: appSession.museumId,
      collectionUuid: appSession.collectionId,
      eventTypeId: EventTypeId.CollectingEvent
    };

    const respons = await post(
      url,
      JSON.stringify({ ...collectingEvent, ...headerData }),
      appSession.accessToken
    );
    if (respons.ok) {
      const { eventUuid } = await respons.json();
      const getURL = config.api.collectingEvent.getEvent(eventUuid);

      const response = await get(getURL, appSession.accessToken);
      if (response.ok) {
        return await response.json();
      }
      alert(response.statusText);
    }
    alert(respons.statusText);
  };

  const save = async (collectingEventToSwap?: CollectingEvent) => {
    try {
      if (!museumObject.museumObjectUuid) {
        return;
      }

      let newCollectingEventLocal;
      let oldCollectingEventLocal = oldCollectingEvent;
      let response;

      if (collectingEventToSwap === undefined) {
        if (collectingEventData && collectingEventData?.eventUuid === undefined) {
          newCollectingEventLocal = await createNewCollectingEvent(
            collectingEventData,
            appSession
          );
        } else if (collectingEventData && hasAnyChanges(isChangedCollectingEventData)) {
          response = await editCollectingEvent(collectingEventData);

          newCollectingEventLocal = undefined;
          oldCollectingEventLocal = undefined;
        }
      } else {
        response = await swapCollectingEvent(
          museumObject,
          collectingEventToSwap,
          collectingEventData
        );

        newCollectingEventLocal = undefined;
        oldCollectingEventLocal = undefined;
      }

      const putUrl = config.api.objects.editMuseumObjectUrl(
        museumObject.museumObjectUuid
      );
      const putJson: InputMuseumObject = createJson(
        objectChangeStatus,
        appSession,
        museumObject,
        genericEditEvents,
        taxonEventToInputEvent(taxonEvents),
        sexAndStages,
        isAddedData,
        isChangedData,
        isDeletedData,
        oldCollectingEventLocal,
        newCollectingEventLocal,
        isChangedCollectingEventData
      );

      //if response is undefined, it is ok. The previous services returns undefined if everything is ok
      if (
        (putJson.events.length > 0 ||
          (putJson.revisions && putJson.revisions.length > 0)) &&
        (!response || response.ok)
      ) {
        response = await put(putUrl, JSON.stringify(putJson), appSession.accessToken);
      }

      const saveEvent = {
        eventTypeId: 'saveSuccess',
        message: I18n.t('musit.texts.saveConfirmed')
      };

      if (!response || response.ok) {
        const url = config.magasin.urls.client.object.editObject(
          appSession,
          museumObject.museumObjectUuid
        );
        resetState();
        incForceRefresh(r => r + 1);
        history && history.replace(url);
        emitSuccess(saveEvent);
      } else {
        emitError({
          eventTypeId: 'errorOnSave',
          message: I18n.t('musit.errorMainMessages.saveError')
        });
      }
      changeObjectChangeStatus((s: any) => {
        switch (s) {
          case ObjectChangeStatus.notAdded:
            return ObjectChangeStatus.added;
          case ObjectChangeStatus.added:
            return ObjectChangeStatus.changed;
          default:
            return ObjectChangeStatus.changed;
        }
      });
    } catch (err) {
      emitError({
        eventTypeId: 'errorOnSave',
        message: I18n.t('musit.errorMainMessages.saveError: ' + err)
      });
    }
  };

  React.useEffect(() => {
    const fetch = async () => {
      const registrationStatusData: RegistrationStatusTable[] = await fetchRegistrationData(
        appSession
      );
      const publicationStatusData: DisseminationStatusTable[] = await fetchDisseminationStatus(
        appSession
      );
      const admStatusesData: AdmStatuses = {
        regStatuses: registrationStatusData,
        pubStatuses: publicationStatusData
      };
      setAdmStatuses(admStatusesData);
    };
    // tslint:disable-next-line: no-floating-promises
    fetch();
  }, [appSession]);

  React.useEffect(() => {
    const fetchData = async () => {
      const result: Response = await get(getUrl(match.params.id), appSession.accessToken);

      const data = await result.json();
      await processCollectingEvent(data.events, appSession, changeCollectingEventData);
      const relatedActors = uniq(
        (data.events as MusitEvent[]).reduce((p, e) => {
          const relactors = e.relatedActors
            ? e.relatedActors.reduce(
                (p, r) => (r.actorUuid ? [...p, r.actorUuid] : p),
                []
              )
            : [];
          if (e.createdBy) return [...p, e.createdBy, ...relactors];
          else return [...p, ...relactors];
        }, [])
      );

      await processRelatedActors(relatedActors, changeActorsInUse, appSession);

      changeObjectChangeStatus(ObjectChangeStatus.added);
      updateIsChangedCollectingEventState(initialCollectingEventChangeState);
      const museumObjectIn: InputMuseumObject = {
        subCollectionId: data.subCollectionId,
        collectionUuid: appSession.collectionId,
        museumId: data.museumId,
        museumObjectUuid: data.museumObjectUuid,
        museumObjectTypeId: data.museumObjectTypeId,
        attributes: data.attributes
          ? {
              ...data.attributes,
              eventUuid: data.attributes ? data.attributes.eventUuid : undefined
            }
          : undefined,
        events: [],
        subObjects: data.subObjects
      };
      changeMuseumObject(museumObjectIn);
      processTaxonEvents(data.events, changeTaxonEvents);
      changeAllEvents(data.events);

      const eventsForObjectType = [
        EventTypeId.MuseumNoAssignment,
        EventTypeId.MuseumObjectAttributeAssignment,
        EventTypeId.TypeInfo,
        EventTypeId.RegistrationStatus,
        EventTypeId.DisseminationStatus
      ];
      processGenericEvents(eventsForObjectType, data.events, changeGenericEditEvents);
      processLegacyEvents(data.events, setLegacyEvents);
      processSexAndStages(data.events, changeSexAndStages);
      processAdmData(
        data.createdBy,
        data.createdDate,
        data.events,
        admStatuses,
        appSession,
        actorsInUse,
        changeAdmProps
      );

      updateIsLoading(false);
    };
    // tslint:disable-next-line:no-floating-promises
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id, forceRefresh, admStatuses]);

  const addCollectingEvent = (collectingEvent: CollectingEvent) => {
    changeOldCollectingEvent(collectingEventData);
    changeCollectingEventData(collectingEvent);
  };

  const clearSearch = () => changeCollectingEventSearchResult(undefined);

  const callBack = (history: H.History, museumObjectUuid: Uuid) => () => {
    const url = config.magasin.urls.client.object.editObject(
      appSession,
      museumObjectUuid
    );
    history.replace(url);
  };

  const copyAndAddCollectingEvent = async (collectingEventData?: CollectingEvent) => {
    if (museumObject.museumObjectUuid && collectingEventData) {
      const museumObjectUuid: Uuid = museumObject.museumObjectUuid;
      const url = config.api.objects.editMuseumObjectUrl(museumObject.museumObjectUuid);
      const collectingEventCopy: CollectingEvent = {
        ...collectingEventData,
        relatedEnrichedMuseumObjects: undefined,
        relatedMuseumObjects: undefined,
        eventUuid: undefined
      };

      const newCollectingEvent = await createNewCollectingEvent(
        collectingEventCopy,
        appSession
      );
      addCollectingEvent({
        ...collectingEventCopy,
        eventUuid: newCollectingEvent.eventUuid
      });
      const json = createJSONForSwap(
        museumObject,
        collectingEventData,
        newCollectingEvent
      );

      await callPutUrl(
        history,
        appSession,
        url,
        JSON.stringify(json),
        () => {
          return null;
        },
        callBack(history, museumObjectUuid)
      );
    }
  };

  const swapCollectingEvent = async (
    museumObject: InputMuseumObject,
    newCollectingEvent: CollectingEvent,
    oldCollectingEvent?: CollectingEvent
  ) => {
    const revOfOld = oldCollectingEvent
      ? {
          revisionOf: oldCollectingEvent.eventUuid,
          eventTypeId: oldCollectingEvent.eventTypeId,
          relatedMuseumObjects: oldCollectingEvent.relatedMuseumObjects?.filter(
            f => f.museumObjectUuid !== museumObject.museumObjectUuid
          )
        }
      : undefined;

    const museumObjectUuid = museumObject.museumObjectUuid || match.params.id;

    const revOfNew = {
      revisionOf: newCollectingEvent.eventUuid,
      eventTypeId: newCollectingEvent.eventTypeId,
      relatedMuseumObjects: newCollectingEvent.relatedMuseumObjects
        ? [
            ...newCollectingEvent.relatedMuseumObjects,
            {
              museumObjectUuid: museumObjectUuid,
              roleId: RoleTypeId.museumObjectFor
            }
          ]
        : [
            {
              museumObjectUuid: museumObjectUuid,
              roleId: RoleTypeId.museumObjectFor
            }
          ]
    };
    const data: InputMuseumObject = {
      ...museumObject,
      revisions: (museumObject.revisions
        ? [...museumObject.revisions, revOfOld, revOfNew]
        : [revOfOld, revOfNew]
      ).filter(e => e !== undefined)
    };
    const url = museumObject.museumObjectUuid
      ? config.api.objects.editMuseumObjectUrl(museumObject.museumObjectUuid)
      : undefined;

    let response;
    if (url) {
      response = await put(url, JSON.stringify(data), appSession.accessToken);
    }
    return response;
  };

  return isLoading ? null : (
    <ObjectComponent
      appSession={appSession}
      createSubObject={createSubObject}
      clearSearch={clearSearch}
      admProps={admProps}
      admStatuses={admStatuses}
      registrationStatus={currentRegistrationStatus}
      dissiminationStatus={currentDissiminationStatus}
      museumObject={museumObject}
      changeMuseumObject={changeMuseumObject}
      taxonEvents={taxonEvents}
      changeTaxonEvents={changeTaxonEvents}
      legacyEvents={legacyEvents}
      allEvents={allEvents}
      incForceRefresh={() => incForceRefresh(e => e + 1)}
      genericEvents={genericEditEvents}
      changeGenericEditEvents={changeGenericEditEvents}
      swapCollectingEvents={swapCollectingEvent}
      registerChangeEventId={registerChangeEventId}
      sexAndStagesEvent={sexAndStages}
      changeSexAndStagesEvent={onChangeSexAndStages}
      addEventUuid={addEventUuid}
      changeEventUuid={changeEventUuid}
      deleteEventUuid={deleteEventUuid}
      saveFunction={save}
      searchResult={collectingEventSearchResult}
      searchFunction={searchCollectingEventSearchFunction}
      addCollectingEvent={addCollectingEvent}
      changeCollectingEvent={changeCollectingEventData}
      collectingEventData={collectingEventData}
      oldCollectingEvent={oldCollectingEvent}
      deleteOldCollectingEvent={() => changeOldCollectingEvent(undefined)}
      history={history}
      copyAndAddCollectingEvent={() => copyAndAddCollectingEvent(collectingEventData)}
      updateIsChanged={updateIsChangedCollectingEventState}
    />
  );
});

export const getActorName = async (
  actorUuid: string | undefined,
  appSession: AppSession
) => {
  if (actorUuid === undefined) return undefined;
  const url = config.api.persons.getUrl(actorUuid);
  const ret = callGetActor(appSession, url);
  if (ret) {
    return await ret;
  }
  return undefined;
};

const processAdmData = (
  createdBy: string,
  createdDate: string,
  events: MusitEvent[],
  admStatuses: AdmStatuses,
  appSession: AppSession,
  actorsInUse: Person[],
  changeAdmData: React.Dispatch<React.SetStateAction<AdmProps>>
) => {
  if (events === undefined || admStatuses === undefined) {
    return;
  }
  const upd = events.reduce((p: MusitEvent | undefined, c: MusitEvent) => {
    if (p && c.createdDate && p.createdDate) {
      return c.createdDate > p.createdDate ? c : c ? p : c;
    }
    return c;
  });
  const regBy = actorsInUse.find(a => a.actorUuid === createdBy);
  const updBy = actorsInUse.find(a => a.actorUuid === upd.createdBy);

  const eventsSync = events
    .filter(
      e =>
        e.eventTypeId === EventTypeId.DisseminationStatus ||
        e.eventTypeId === EventTypeId.RegistrationStatus
    )
    .map(e => {
      const p = actorsInUse.find(a => a.actorUuid === e.createdBy);
      return { ...e, actorName: p };
    });

  const admProps: AdmProps = {
    regBy: regBy ? regBy.name : createdBy || '',
    lastChangeBy: updBy ? updBy.name : upd.createdBy || '',
    regDate: createdDate || '',
    lastChangedDate: upd.createdDate || '',
    changeStatus: { publicationStatusChanged: false, registrationStatusChanged: false },
    publicationStatuses: admStatuses.pubStatuses,
    registrationStatuses: admStatuses.regStatuses,
    genericEvents: eventsSync,
    appSession: appSession,
    changeGenericEvents: () => '',
    registerChangeEventId: () => ''
  };
  changeAdmData(a => ({ ...a, ...admProps }));
};

const processLegacyEvents = (
  events: MusitEvent[],
  setLegacyEvents: (legacyEvents: MusitEvent[]) => void
) => {
  const l = events.filter(e => e.eventTypeId === EventTypeId.Legacy);
  setLegacyEvents(l);
};
const processTaxonEvents = (
  events: any[],
  changeTaxonEvents: (taxonEvents: TaxonEvent[]) => void
) => {
  const taxonEvent: TaxonEvent[] = events
    ? events.filter(e => e.eventTypeId === EventTypeId.Taxon)
    : [];
  changeTaxonEvents(taxonEvent);
};

const processSexAndStages = (
  events: MusitEvent[],
  changeSexAndStagesEvent: React.Dispatch<React.SetStateAction<SexAndStagesEvent>>
) => {
  if (events) {
    const sexAndStagesEvents = events.filter(
      (e: MusitEvent) => e.eventTypeId === EventTypeId.SexAndStage
    );
    const length = sexAndStagesEvents.length;
    if (length > 0) {
      const index = length - 1;
      changeSexAndStagesEvent(sexAndStagesEvents[index]);
    }
  }
};

const getObjectListFromRelatedObjects = async (
  relatedObjects: MuseumObjectAndRelation[],
  appSession: AppSession
) => {
  const url = config.api.objects.getPostObjectSearchResult;
  const json = JSON.stringify({
    museumObjectUuids: relatedObjects.map(r => r.museumObjectUuid)
  });

  const response = await post(url, json, appSession.accessToken);
  const result: MuseumObjectSearchResult[] = await response.json();
  return result;
};

const processCollectingEvent = async (
  events: MusitEvent[],
  appSession: AppSession,
  changeCollectingEventData: (e: CollectingEvent | undefined) => void
) => {
  if (events) {
    const collectingEvent: MusitEvent | undefined = events.find(
      e => e.eventTypeId === EventTypeId.CollectingEvent
    );

    let relobj: MuseumObjectSearchResult[];
    if (collectingEvent && collectingEvent.relatedMuseumObjects) {
      relobj = await getObjectListFromRelatedObjects(
        collectingEvent.relatedMuseumObjects,
        appSession
      );
      changeCollectingEventData({
        ...collectingEvent,
        relatedEnrichedMuseumObjects: relobj
      } as CollectingEvent);
    } else if (collectingEvent) {
      changeCollectingEventData({
        ...collectingEvent
      } as CollectingEvent);
    }
  }
};
function hasValue<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}
export const processGenericEvents = (
  eventTypeIdList: EventTypeId[],
  events: MusitEvent[],
  changeGenericEditEvents: React.Dispatch<React.SetStateAction<MusitEvent[]>>
) => {
  const genericEvents = eventTypeIdList.map(typeId => getEvent(typeId, events));
  changeGenericEditEvents(genericEvents.filter(hasValue));
};

const callPutUrl = async (
  history: H.History,
  appSession: AppSession,
  putUrl: string,
  putJson: string,
  resetState: () => void,
  callBack?: () => void
) => {
  try {
    const response = await put(putUrl, putJson, appSession.accessToken); //everything is ok
    const saveEvent = {
      eventTypeId: 'saveSuccess',
      message: I18n.t('musit.texts.saveConfirmed')
    };

    if (response.ok) {
      resetState();
      emitSuccess(saveEvent);
      if (callBack) callBack();
    } else {
      emitError({
        eventTypeId: 'errorOnSave',
        message: I18n.t('musit.errorMainMessages.saveError')
      });
    }
  } catch (e) {
    emitError({
      eventTypeId: 'errorOnSave',
      message: I18n.t('musit.errorMainMessages.saveError')
    });
  }
};
const fetchDisseminationStatus = async (appSession: any) => {
  const disseminationStatusUrl = config.api.objectAdministration.disseminationStatus;
  const dissResults: Response = await get(disseminationStatusUrl, appSession.accessToken);
  const dissData = await dissResults.json();
  return dissData;
};
const fetchRegistrationData = async (appSession: any) => {
  const registrationStatusUrl = config.api.objectAdministration.registrationStatus;
  const regResults: Response = await get(registrationStatusUrl, appSession.accessToken);
  const regData = await regResults.json();
  return regData;
};

const processRelatedActors = async (
  relatedActors: string[],
  changeActorsInUse: React.Dispatch<React.SetStateAction<Person[]>>,
  appSession: AppSession
) => {
  const r = await Promise.all(
    relatedActors.map(async a => await getActorName(a, appSession))
  );
  const ret = r.reduce((p, c) => {
    if (c) return [...p, c];
    else return p;
  }, []);
  changeActorsInUse(ret);
};

export default ObjectContainerEdit;
