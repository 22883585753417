import * as React from 'react';

type Props = {
  value: string;
  label: string;
  id: string;
  disabled?: boolean;
  updateField: (fieldName: string) => (value: string) => void;
};

type TextFieldProps = {
  value: string;
  id: string;
  label: string;
  rows?: number;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

const TextField = ({
  value,
  id,
  label,
  onChange,
  rows = 3,
  disabled
}: TextFieldProps) => (
  <React.Fragment>
    <div className="col-md-2" style={{ textAlign: 'right' }}>
      <b>{label}</b>
    </div>{' '}
    <div className="col-md-6">
      <textarea
        className="form-control"
        id={id}
        onChange={onChange}
        rows={rows}
        value={value}
        disabled={disabled}
      />
    </div>
  </React.Fragment>
);

const NoteField = (props: Props) => {
  return (
    <div className="form-row">
      <TextField
        value={props.value}
        id={props.id}
        onChange={e => props.updateField(props.id)(e.target.value)}
        label={props.label}
        disabled={props.disabled}
      />
    </div>
  );
};

export default NoteField;
