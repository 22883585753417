import * as React from 'react';
import * as T from './types';
import * as L from 'lodash';
import AttributesLookup from './AttributeLookup';
import { attributes as attributeFields } from './initData';
import { getTranslator } from '../../../shared/language';

const words = getTranslator({
  en: {
    freeTextPhraseSearch: 'Freetext-/Phrase search',
    selectAttributeToAdd: 'Select attribute to add to seach',
    selectAttribute: 'Select attribute'
  },
  no: {
    freeTextPhraseSearch: 'Fritekst-/frasesøk',
    selectAttributeToAdd: 'Velg attributt å legge til søk',
    selectAttribute: 'Velg attributt'
  }
});

type Props = {
  handleChangeAggregationSearchInput: (s: string) => Promise<void>;
  cleanupAggregationSearchField: () => void;
  attributesSearch: string;
  aggregationSearchInput: string;
  currentAttributeField: T.Attribute | null;
  changeCurrentAttributeField: React.Dispatch<React.SetStateAction<T.Attribute>>;
  changeAttributeSearch: React.Dispatch<React.SetStateAction<string>>;
  attributeFieldTranslator: { [k: string]: string };
  ctrlDown: boolean;
  toggleCtrlIsDown: React.Dispatch<React.SetStateAction<boolean>>;
  aggregations: T.AggregationItem[];
};

const AttributesSearch = (p: Props) => {
  const [attributesAdded, changeAttributesAdded] = React.useState<boolean>(false);
  const el = React.useRef(null);

  React.useEffect(() => {
    setPositionForTextField(
      (el.current as unknown) as HTMLTextAreaElement,
      p.attributesSearch.length - 2
    );
  }, [attributesAdded, p.attributesSearch.length]);

  const setPositionForTextField = (el: HTMLTextAreaElement | null, length: number) => {
    if (el !== null) {
      el.setSelectionRange(length, length);
      el.focus();
    }
  };

  const onChangeAttributes = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    p.changeAttributeSearch(e.target.value);
  };

  const addToAttribute = (v: string) => {
    p.changeAttributeSearch(t => `${t}${t ? '\n' + v : v}`);
  };

  const handleClickAttributeValue = (v: string, attribute: string) => {
    AddAttribute(attribute, v);
  };

  const AddAttribute = (attribute: string, value?: string) => {
    addToAttribute(`( ${attribute} ${value ? value : ''}  )`);
    changeAttributesAdded(b => !b);
  };
  return (
    <React.Fragment>
      <div className="form-row">
        <div className="col-md-8">
          <label htmlFor="freetextSearchField">{words.freeTextPhraseSearch}</label>
          <textarea
            ref={el}
            className="form-control"
            id="freetextSearchField"
            value={p.attributesSearch}
            onChange={onChangeAttributes}
            rows={3}
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="selectAttributeId">{words.selectAttribute}</label>
          <select
            value={''}
            id="selectAttributeId"
            className="form-control"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const v = e.target.value;
              const a = attributeFields.find(a => a.attribute === v);
              const attributeToAdd = a ? a.phrasePrefix + ' ' + a.fieldName : '';
              if (a) p.changeCurrentAttributeField(a);
              if (p.ctrlDown) {
                $('#attributeModalId').modal();
              } else {
                AddAttribute(attributeToAdd);
              }
            }}
          >
            <option
              key={`attr-opt-00`}
              value={''}
              onKeyDown={e => {
                if (e.key === 'Control') {
                  p.toggleCtrlIsDown(true);
                }
              }}
              onKeyUp={e => {
                if (e.key === 'Control') {
                  p.toggleCtrlIsDown(false);
                }
              }}
            >
              {words.selectAttributeToAdd}{' '}
            </option>
            {L.uniq(attributeFields.map(e => e.event)).map((ev, i) => {
              return (
                <optgroup key={`attr-opt-${i}`} label={ev}>
                  {attributeFields
                    .filter(e => e.event === ev)
                    .map((a, ii) => {
                      const attributeKey = `attributes§${a.attribute}`;
                      const tabIndex = 0;
                      return (
                        <option
                          key={`opt-${ii}-${i}`}
                          data-toggle="modal"
                          data-target="#attributeModalId"
                          id={'ID-opt-attr ' + ii.toString() + '-' + i.toString()}
                          tabIndex={tabIndex}
                          value={a.attribute}
                        >
                          {' '}
                          {p.attributeFieldTranslator[attributeKey]}
                        </option>
                      );
                    })}
                </optgroup>
              );
            })}
          </select>
          <div className="form-check">
            <input
              id="checkedCtrlDown"
              type="checkbox"
              onChange={e => {
                if (e.target.checked) p.toggleCtrlIsDown(true);
                else p.toggleCtrlIsDown(false);
              }}
              className="form-check-input"
              checked={p.ctrlDown}
            />
            <label className="form-check-label" htmlFor="checkedCtrlDown">
              {'Søk attributter ved klikk'}
            </label>
          </div>
        </div>
      </div>
      <AttributesLookup
        cleanUp={p.cleanupAggregationSearchField}
        aggregationSearchInput={p.aggregationSearchInput}
        aggregations={p.aggregations}
        handleClick={handleClickAttributeValue}
        handleChangeAggregationSearchInput={p.handleChangeAggregationSearchInput}
        fieldName={
          p.currentAttributeField
            ? p.currentAttributeField.event + ' ' + p.currentAttributeField.fieldName
            : ''
        }
        label={
          p.currentAttributeField
            ? p.attributeFieldTranslator[
                `attributes§${p.currentAttributeField.attribute}`
              ]
            : ''
        }
        size={10}
      />
    </React.Fragment>
  );
};

export default AttributesSearch;
