// @flow
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import FontAwesome from 'react-fontawesome';
import './index.css';
import Config from '../../config';
import Logos from '../../components/logos/Logos';
import { RxInjectLegacy as inject } from '../../shared/react-rxjs-patch';
import { AppSession } from '../../types/appSession';
import { TODO } from '../../types/common';

const reportURL = Config.magasin.urls.client.report.goToReport;
const magasinURL = Config.magasin.urls.client.magasin.goToMagasin;
const analysisURL = Config.magasin.urls.client.analysis.addAnalysis;
const aboutURL = '/about';
const notFoundURL = '/notfound';

type Props = {
  appSession: AppSession;
  goToNotFound: () => void;
  goToAbout: () => void;
  goTo: (url: string) => void;
};

const buttonAdd = (t: TODO, onClick: TODO) => (
  <button style={{ fontSize: '3.2em' }} type="button" className="btn" onClick={onClick}>
    {t} <FontAwesome name="chevron-right" />
  </button>
);

export const HomePage = (props: Props) => (
  <div className="container">
    <div className="row">
      <h1>{I18n.t('musit.texts.musitBase')}</h1>
    </div>
    <div className="row">
      <form>
        <div className="form-group">
          <div className="input-group">
            <input className="form-control" type="text" />
            <div className="input-group-append">
              <span className="input-group-text">
                {' '}
                <FontAwesome className="buttonIcon" name="search" />
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div className="row button-row">
      <div className="col-md-6">
        {buttonAdd(
          I18n.t('musit.texts.magazine'),
          props.goTo(magasinURL(props.appSession))
        )}
      </div>
      <div className="row">
        {buttonAdd(
          I18n.t('musit.analysis.analysis'),
          props.goTo(analysisURL(props.appSession))
        )}
      </div>
    </div>
    <div className="row button-row">
      <div className="col-md-6">
        {buttonAdd(
          I18n.t('musit.reports.reports'),
          props.goTo(reportURL(props.appSession))
        )}
      </div>
    </div>
    <div className="row">
      <div style={{ height: '300px' }} />
      <div style={{ textAlign: 'center', height: '80px' }}>
        {I18n.t('musit.texts.footerText')}
      </div>
      <div style={{ textAlign: 'center', height: '50px' }}>
        <div className="col-md-4 offset-md-4">
          <button className="btn btn-link" type="button" onClick={props.goToNotFound}>
            {I18n.t('musit.texts.aboutMusitSolutions')}
          </button>
        </div>
        <div className="col-md-4">
          <button className="btn btn-link" type="button" onClick={props.goToAbout}>
            {I18n.t('musit.texts.abuotMusit')}
          </button>
        </div>
      </div>
      <div style={{ textAlign: 'center', height: '100px' }}>
        <Logos />
      </div>
    </div>
  </div>
);

const data = {
  appSession$: { type: PropTypes.object.isRequired }
};

const props = (props: TODO) => ({
  ...props,
  goToNotFound: () => props.history.push(notFoundURL),
  goToAbout: () => props.history.push(aboutURL),
  goTo: (url: string) => () => props.history.push(url)
});

export default inject(data, {}, props)(HomePage);
