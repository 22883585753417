import * as React from 'react';
import * as T from './types';
import { getTranslator } from '../../../shared/language';

const words = getTranslator({
  en: {
    first: 'First',
    next: 'Next',
    previous: 'Previous',
    page: 'Page',
    of: 'av',
    last: 'Last'
  },
  no: {
    first: 'Første',
    next: 'Neste',
    previous: 'Forrige',
    page: 'Side',
    of: 'av',
    last: 'Siste'
  }
});

enum PageType {
  first = 0,
  prev = 1,
  pageNum = 2,
  next = 3,
  last = 4,
  label = 5
}
type PageItem = {
  pageType: PageType;
  pageNum?: number;
  label: string;
  disabled: boolean;
  visible: boolean;
  active?: boolean;
};

const Paging = ({
  size,
  gotoPage,
  currPage,
  changeCurrPage,
  results
}: {
  size: number;
  gotoPage: (page: number) => Promise<void>;
  changeCurrPage: React.Dispatch<React.SetStateAction<number>>;
  currPage: number;

  results: T.Result;
}) => {
  const onClick = (p: PageItem) => async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (p.pageNum) {
      await gotoPage(p.pageNum);
      changeCurrPage(p.pageNum);
    }
  };

  const count = results.objectList.count;

  const fullPages = Math.floor(count / size);
  const additionalPages = count % size > 0 ? 1 : 0;
  const numberOfPages = fullPages + additionalPages;
  React.useEffect(() => {
    if (results.objectList.count > 1 && currPage === 0) {
      changeCurrPage(1);
    }
  }, [results.objectList.count, size, currPage, changeCurrPage]);

  const pageArray: PageItem[] =
    count > 0
      ? [
          {
            pageType: PageType.prev,
            label: words.previous,
            pageNum: currPage - 1,
            disabled: currPage === 1 ? true : false,
            visible: true
          },
          {
            pageType: PageType.first,
            label: '1',
            pageNum: 1,
            disabled: currPage === 1 ? true : false,
            visible: currPage <= 3 ? false : true
          },

          {
            pageType: PageType.label,
            label: '...',
            disabled: true,
            visible: currPage > 3 ? true : false
          },
          {
            pageType: PageType.pageNum,
            label: (currPage - 2).toString(),
            pageNum: currPage - 2,
            disabled: currPage - 2 === 1 ? true : false,
            visible: currPage - 2 > 0 ? true : false
          },
          {
            pageType: PageType.pageNum,
            label: (currPage - 1).toString(),
            pageNum: currPage - 1,
            disabled: currPage === 1 ? true : false,
            visible: currPage - 1 > 0 ? true : false
          },
          {
            pageType: PageType.pageNum,
            label: currPage.toString(),
            pageNum: currPage,
            disabled: false,
            visible: true,
            active: true
          },
          {
            pageType: PageType.pageNum,
            label: (currPage + 1).toString(),
            pageNum: currPage + 1,
            disabled: currPage === numberOfPages ? true : false,
            visible: currPage + 1 <= numberOfPages ? true : false
          },
          {
            pageType: PageType.pageNum,
            label: (currPage + 2).toString(),
            pageNum: currPage + 2,
            disabled: currPage + 1 === numberOfPages ? true : false,
            visible: currPage + 2 <= numberOfPages ? true : false
          },
          {
            pageType: PageType.label,
            label: '...',
            disabled: true,
            visible: currPage + 2 < numberOfPages ? true : false
          },
          {
            pageType: PageType.last,
            label: numberOfPages.toString(),
            pageNum: numberOfPages,
            disabled: currPage === numberOfPages ? true : false,
            visible: currPage >= numberOfPages - 2 ? false : true
          },
          {
            pageType: PageType.next,
            label: words.next,
            pageNum: currPage + 1,
            disabled: currPage === numberOfPages ? true : false,
            visible: true
          }
        ]
      : [];

  return (
    <nav aria-label="Hei">
      <ul className="pagination justify-content-end">
        {pageArray
          .filter(p => p.visible)
          .map((p, i) => (
            <li
              aria-current={p.active ? 'page' : undefined}
              key={`paging-li-${i}`}
              className={`page-item${
                p.disabled ? ' disabled' : p.active ? ' active' : ''
              }`}
            >
              <a
                className="page-link"
                aria-disabled={p.disabled}
                tabIndex={p.disabled ? -1 : i}
                onClick={onClick(p)}
                href="/#"
              >
                {p.label}
              </a>
            </li>
          ))}
      </ul>
    </nav>
  );
};

export default Paging;
