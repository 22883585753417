import * as React from 'react';
import { I18n } from 'react-i18nify';
import { Synonym } from '../types/person/personName';
import { Uuid } from '../types/event/event old';

type NameStatus = 1 | 2 | 3;
export const NoneDisabled: NameStatus = 1;
export const NameDisabled: NameStatus = 2;
export const TitleLastFirstDisabled: NameStatus = 3;

type Props = {
  onChange: (
    displaySynonyms: Synonym[],
    addedOrChangedSynonyms: Synonym[],
    removedActorNames: Uuid[]
  ) => void;
  synonyms: Synonym[];
  deletedSynonyms: Uuid[];
  addedSynonyms: Synonym[];
};

const Synonyms = (props: Props) => {
  const [singleSynonym, updateSingleSynonym] = React.useState<Synonym | undefined>(
    undefined
  );

  const addNewSynonym = (synonym: Synonym) => () => {
    const _displayedSynonyms = [...props.synonyms, synonym];
    const _addedSynonyms = [...props.addedSynonyms, synonym];
    props.onChange(_displayedSynonyms, _addedSynonyms, props.deletedSynonyms);
    updateSingleSynonym(undefined);
  };

  const calculateNameStatus = (props: Synonym): NameStatus => {
    const nameHasValue = props.name !== '';
    if (!titleLastFirstHasValue(props) && !nameHasValue) {
      return NoneDisabled;
    } else if (titleLastFirstHasValue(props)) {
      return NameDisabled;
    } else if (nameHasValue) {
      return TitleLastFirstDisabled;
    } else {
      return NameDisabled;
    }
  };

  const createNameFromTitleFirstLast = (pn: {
    title?: string;
    firstName?: string;
    lastName?: string;
  }) => (fieldName: string, value: string) => {
    const tmp = { ...pn, [fieldName]: value };
    const retur =
      (tmp.lastName || '') +
      (tmp.title ? ', ' + tmp.title : '') +
      (tmp.firstName ? (tmp.title ? ' ' + tmp.firstName : ', ' + tmp.firstName) : '');
    return retur || '';
  };

  const fixInitials = (initials: string) => {
    const r = initials.replace(/\s*\.\s*/g, '.');
    return r;
  };

  const titleLastFirstHasValue = ({
    firstName,
    lastName,
    title
  }: {
    firstName?: string;
    lastName?: string;
    title?: string;
  }): boolean => {
    const titleHasValue = title && title !== '' ? true : false;
    const firstNameHasValue = firstName && firstName !== '' ? true : false;
    const lastNameHasValue = lastName && lastName !== '' ? true : false;
    return titleHasValue || firstNameHasValue || lastNameHasValue;
  };

  const deleteSynonym = (synonym: Synonym) => {
    const _deletedSynonyms = synonym.actorNameUuid
      ? [...props.deletedSynonyms, synonym.actorNameUuid]
      : props.deletedSynonyms;
    const addedIndex = props.addedSynonyms.findIndex(s => s === synonym);
    const _addedSynonyms =
      !synonym.actorNameUuid && addedIndex !== -1
        ? removeFromArray(props.addedSynonyms, addedIndex)
        : props.addedSynonyms;
    const index = props.synonyms.findIndex(s => s === synonym);
    const _displayedSynonyms = removeFromArray(props.synonyms, index);
    props.onChange(_displayedSynonyms, _addedSynonyms, _deletedSynonyms);
  };

  function removeFromArray<T>(array: T[], index: number): T[] {
    let newArray = [...array];
    newArray.splice(index, 1);
    return newArray;
  }

  const changeTextField = (fieldName: string) => (value: string) =>
    singleSynonym
      ? updateSingleSynonym({ ...singleSynonym, [fieldName]: value })
      : updateSingleSynonym({ [fieldName]: value, name: value });
  const changePersonStateWithName = (fieldName: string) => (
    value: string,
    nameValue: string
  ) => {
    updateSingleSynonym({ ...singleSynonym, [fieldName]: value, name: nameValue });
  };
  const nameStatus = singleSynonym ? calculateNameStatus(singleSynonym) : NoneDisabled;

  return (
    <div className="container-fluid">
      <div
        className="form-row"
        style={{
          fontWeight: 'bold',
          paddingBottom: '0px'
        }}
      >
        <div className="col-md-1">{I18n.t('musit.objectModule.person.title')}</div>
        <div className="col-md-3">{I18n.t('musit.objectModule.person.firstName')}</div>
        <div className="col-md-3">{I18n.t('musit.objectModule.person.lastName')}</div>
        <div className="col-md-3">{I18n.t('musit.objectModule.person.name')}</div>
        <div className="col-md-2">{I18n.t('musit.objectModule.person.action')}</div>
      </div>
      <hr />
      {props.synonyms.map((s: Synonym, i: number) => (
        <div
          className="form-row"
          key={`${s.title}_${i}`}
          style={{
            lineHeight: 1
          }}
        >
          <div className="col-md-1">{s.title}</div>
          <div className="col-md-3">{s.firstName}</div>
          <div className="col-md-3">{s.lastName}</div>
          <div className="col-md-3">{s.name}</div>
          <div className="col-md-2">
            <button
              type="button"
              className="btn btn-link"
              onClick={e => {
                e.preventDefault();
                deleteSynonym(s);
              }}
            >
              {I18n.t('musit.texts.delete')}
            </button>
          </div>
        </div>
      ))}

      <div className="form-row">
        <div className="col-md-1">
          <input
            id="title"
            className="form-control"
            type="text"
            disabled={nameStatus === TitleLastFirstDisabled}
            value={singleSynonym ? singleSynonym.title : ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const name = createNameFromTitleFirstLast({
                firstName: singleSynonym ? singleSynonym.firstName : undefined,
                title: singleSynonym ? singleSynonym.title : undefined,
                lastName: singleSynonym ? singleSynonym.lastName : undefined
              })('title', e.target.value);
              changePersonStateWithName('title')(e.target.value, name);
            }}
          />
        </div>
        <div className="col-md-3">
          <input
            id="firstName"
            type="text"
            disabled={nameStatus === TitleLastFirstDisabled}
            className="form-control"
            value={singleSynonym ? singleSynonym.firstName : ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const firstName = fixInitials(e.target.value);
              const name = createNameFromTitleFirstLast({
                firstName: firstName,
                title: singleSynonym ? singleSynonym.title : undefined,
                lastName: singleSynonym ? singleSynonym.lastName : undefined
              })('firstName', e.target.value);
              changePersonStateWithName('firstName')(firstName, name);
            }}
          />
        </div>
        <div className="col-md-3">
          <input
            id="lastName"
            type="text"
            disabled={nameStatus === TitleLastFirstDisabled}
            className="form-control"
            value={singleSynonym ? singleSynonym.lastName : ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const name = createNameFromTitleFirstLast({
                firstName: singleSynonym ? singleSynonym.firstName : undefined,
                title: singleSynonym ? singleSynonym.title : undefined,
                lastName: singleSynonym ? singleSynonym.lastName : undefined
              })('lastName', e.target.value);
              changePersonStateWithName('lastName')(e.target.value, name);
            }}
          />
        </div>
        <div className="col-md-3">
          <input
            id="name"
            type="text"
            disabled={nameStatus === NameDisabled}
            className="form-control"
            value={singleSynonym ? singleSynonym.name : ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const name = fixInitials(e.target.value);
              changeTextField('name')(name);
            }}
          />
        </div>
        <div className="col-md-2 align-self-end">
          <button
            id="btn-add"
            className="btn btn-secondary"
            disabled={singleSynonym ? false : true}
            type="button"
            onClick={() => {
              if (singleSynonym) addNewSynonym(singleSynonym)();
            }}
          >
            {I18n.t('musit.texts.add')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Synonyms;
