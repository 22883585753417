import * as React from 'react';
import ReactJson from 'react-json-view-ts';
import { get } from '../../../shared/ajaxFetch/ajaxFetch';
import { useParams } from 'react-router';
import { AppSessionContext } from '../../app/AppComponent';
import { getTranslator } from '../../../shared/language';
import config from '../../../config';
import { MusitEvent } from '../types/event/event';
import { LegacyEventAttributes } from '../types/event/eventTypes_auto';

const words = getTranslator({
  no: {
    legacyData: 'Legacydata',
    collapse: 'Kollaps',
    expand: 'Ekspander',
    default: 'Default'
  },
  en: {
    legacyData: 'Legacy data',
    collapse: 'Collapse',
    expand: 'Expand',
    default: 'Default'
  }
});
interface LegacyProps {
  note: string;
  json: Object | null;
  collapseLevel: number | boolean;
  incCollapseLevel: () => void;
  decCollapseLevel: () => void;
  setCollapsed: () => void;
  setExpanded: () => void;
  setDefault: () => void;
}

const LegacyComponent = (props: LegacyProps) => {
  return (
    <div>
      <h1>{words.legacyData}</h1>
      <div className="btn-group btn-group-sm" role="group" aria-label="Tools">
        <button hidden className="btn btn-default" onClick={props.decCollapseLevel}>
          "-"
        </button>
        <button
          disabled={props.collapseLevel === false}
          type="button"
          className="btn btn-secondary"
          onClick={props.setExpanded}
        >
          {words.expand}
        </button>
        <button type="button" className="btn btn-secondary" onClick={props.setDefault}>
          {words.default}
        </button>
        <button
          disabled={props.collapseLevel === true}
          type="button"
          className="btn btn-secondary"
          onClick={props.setCollapsed}
        >
          {words.collapse}
        </button>
        <button hidden className="btn btn-default" onClick={props.incCollapseLevel}>
          "+"
        </button>
      </div>

      <ReactJson
        style={{ padding: '30px' }}
        theme={'apathy:inverted'}
        displayDataTypes={false}
        collapsed={props.collapseLevel}
        name={words.legacyData}
        src={props.json || { noData: 'No data to show' }}
      />
    </div>
  );
};

const LegacyContainer = () => {
  const params = useParams();
  console.log('Params', params);
  const defaultDepth = 2;
  const id = params['id'];
  const url = config.api.collectingEvent.getEvent(id);
  const appSession = React.useContext(AppSessionContext);
  const [collapseLevel, changeCollapseLevel] = React.useState<number | boolean>(
    defaultDepth
  );
  const [json, setJson] = React.useState<Object | null>(null);
  React.useEffect(() => {
    const fetch = async () => {
      const json = await get(url, appSession.accessToken);
      let r: MusitEvent = await json.json();
      if (r.attributes && r.attributes.legacyData) {
        setJson(JSON.parse((r.attributes as LegacyEventAttributes).legacyData));
      }
    };
    // tslint:disable-next-line: no-floating-promises
    fetch();
  }, [url, appSession.accessToken]);

  return (
    <div className="container" style={{ paddingTop: '80px' }}>
      <LegacyComponent
        collapseLevel={collapseLevel}
        setExpanded={() => changeCollapseLevel(false)}
        setCollapsed={() => changeCollapseLevel(true)}
        setDefault={() => changeCollapseLevel(defaultDepth)}
        incCollapseLevel={() => changeCollapseLevel((l: number) => l - 1)}
        decCollapseLevel={() => changeCollapseLevel((l: number) => l + 1)}
        json={json}
        note={'test'}
      />
    </div>
  );
};

export default LegacyContainer;
