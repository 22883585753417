// @flow
import * as React from 'react';
import { Field } from '../form';
import { AppSession } from '../../types/appSession';
import { Star } from '../../types/common';

export type ValueExtractor = (
  o: { id: number; noStatus: string; enStatus: string },
  a?: AppSession
) => string;

export interface FieldDropDownProps {
  field: Field<string>;
  defaultOption?: string;
  valueFn?: ValueExtractor;
  displayFn?: ValueExtractor;
  controlWidth?: number;
  title: string;
  onChange: Function;
  selectItems: Array<Star>;
  inputProps?: { className?: string; style?: {} };
  appSession?: AppSession;
}

export default function FieldDropDown({
  field,
  title,
  defaultOption,
  valueFn,
  displayFn,
  onChange,
  inputProps,
  selectItems,
  appSession,
  controlWidth = 12
}: FieldDropDownProps) {
  return (
    <div className={`col-md-${controlWidth}`}>
      {title !== '' && (
        <label className="col-form-label" htmlFor={field.name}>
          <b>{title}</b>
        </label>
      )}
      <select
        {...inputProps}
        className={`form-control ${inputProps ? inputProps.className || '' : ''} ${
          field.status ? (field.status.valid ? '' : 'is-invalid') : ''
        }`}
        value={field.value || ''}
        id={field.name}
        onChange={e => onChange({ name: field.name, rawValue: e.target.value })}
      >
        {defaultOption && <option>{defaultOption}</option>}
        {selectItems.map((v: any, i) => (
          <option
            key={i}
            value={valueFn ? (appSession ? valueFn(v, appSession) : valueFn(v)) : v}
          >
            {displayFn ? (appSession ? displayFn(v, appSession) : displayFn(v)) : v}
          </option>
        ))}
      </select>
    </div>
  );
}
