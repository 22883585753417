import * as React from 'react';
import {
  Map as LeafletMap,
  TileLayer,
  Marker,
  Popup,
  Polyline,
  Rectangle,
  Polygon
} from 'react-leaflet';

type MapProps = {
  coord?: {
    lat?: number;
    lng?: number;
    lat2?: number;
    lng2?: number;
    polygon?: number[][];
    line?: number[][];
    rectangle?: [[number, number], [number, number]];
  };
  coordinateString?: string;
  style?: {
    height: string;
    width: string;
  };
};

export const LMap = (props: MapProps) => {
  const rectangle: [number, number][] | undefined =
    props.coord && props.coord.rectangle
      ? props.coord.rectangle.map(r => [r[1], r[0]])
      : undefined;
  const line =
    props.coord && props.coord.line
      ? props.coord.line.map((x: [number, number]) => ({ lng: x[0], lat: x[1] }))
      : undefined;

  const polygon =
    props.coord && props.coord.polygon
      ? props.coord.polygon.map((x: [number, number]) => ({ lng: x[0], lat: x[1] }))
      : undefined;

  const pos: [number, number] = [62.934, 10.8];
  const pos_inn: [number, number] =
    props.coord && props.coord.lat && props.coord.lng
      ? [props.coord.lat, props.coord.lng]
      : pos;
  const minLat = polygon
    ? polygon.reduce(
        (prev: number, x: { lat: number; lng: number }) => Math.min(x.lat, prev),
        100000000
      )
    : line
    ? line.reduce(
        (prev: number, x: { lat: number; lng: number }) => Math.min(x.lat, prev),
        100000000
      )
    : rectangle
    ? rectangle[0][0]
    : undefined;

  const maxLat = polygon
    ? polygon.reduce(
        (prev: number, x: { lat: number; lng: number }) => Math.max(x.lat, prev),
        -100000000
      )
    : line
    ? line.reduce(
        (prev: number, x: { lat: number; lng: number }) => Math.max(x.lat, prev),
        -100000000
      )
    : rectangle
    ? rectangle[1][0]
    : undefined;

  const minLng = polygon
    ? polygon.reduce(
        (prev: number, x: { lat: number; lng: number }) => Math.min(x.lng, prev),
        100000000
      )
    : line
    ? line.reduce(
        (prev: number, x: { lat: number; lng: number }) => Math.min(x.lng, prev),
        100000000
      )
    : rectangle
    ? rectangle[0][1]
    : undefined;
  const maxLng = polygon
    ? polygon.reduce(
        (prev: number, x: { lat: number; lng: number }) => Math.max(x.lng, prev),
        -100000000
      )
    : line
    ? line.reduce(
        (prev: number, x: { lat: number; lng: number }) => Math.max(x.lng, prev),
        -100000000
      )
    : rectangle
    ? rectangle[1][1]
    : undefined;

  const center =
    minLat && minLng && maxLat && maxLng
      ? {
          lng: minLng + (maxLng - minLng) / 2,
          lat: minLat + (maxLat - minLat) / 2
        }
      : undefined;

  const zoom = rectangle ? 14 : line ? 12 : polygon ? 11 : 8;

  return (
    <LeafletMap
      id="map"
      center={center || { lat: 0, lng: 0 }}
      zoom={zoom}
      style={props.style}
    >
      <TileLayer
        attribution={
          '&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors'
        }
        url={'https://{s}.tile.osm.org/{z}/{x}/{y}.png'}
      />
      {line ? (
        <Polyline positions={line} color="red" weight={3} opacity={0.3} />
      ) : polygon ? (
        <Polygon
          positions={polygon}
          color="red"
          weight={1}
          fillColor="grey"
          fillOpacity={0.3}
          opacity={0.3}
        />
      ) : rectangle ? (
        <Rectangle
          bounds={rectangle}
          color="red"
          weight={2}
          fillColor={'green'}
          fillOpacity={0.4}
        />
      ) : (
        <Marker position={pos_inn}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker>
      )}
    </LeafletMap>
  );
};
