import { ActorAndRelation } from '../types/event/event';
import { DateRangeWithVerbatim } from 'src/components/DatePicker';
import { MuseumObjectEventHeader } from '../types/museumObject/museumObject';

export type TaxonEvent = {
  attributes: {
    taxonTypeId: number;
    taxons: TaxonTypeSave[];
    note?: string;
  };
  uuid?: string;
  date?: DateRangeWithVerbatim;
  relatedActors?: ActorAndRelation[];
  detType?: string;
  orderNo: number;
} & MuseumObjectEventHeader;
export type TaxonTypeEdit = TaxonTypeMini & TaxonMetaData;

export type TaxonTypeSave = TaxonTypeMini & TaxonMetaData;

export type TaxonTypeFromArtDB = {
  scientificNameID: number;
  scientificName: string;
  scientificNameAuthorship?: string;
  taxonRank: string;
  acceptedNameUsage?: {
    scientificNameID: number;
    scientificName: string;
    scientificNameAuthorship?: string;
    taxonRank?: string;
  };
  higherClassification?: HigherClassification[];
};

export type TaxonTypeMini = {
  selectedName: {
    scientificNameId: number;
    scientificName: string;
    scientificNameAuthorship?: string;
  };
  taxonRank: string;
  acceptedNameUsage?: {
    scientificNameId: number;
    scientificName: string;
    scientificNameAuthorship?: string;
    taxonRank?: string;
  };
  higherClassification?: HigherClassification[];
};

type HigherClassification = {
  taxonRank: string;
  scientificName: string;
  scientificNameAuthorship?: string;
};
export interface TaxonMetaData {
  precisionCode?: PrecisionCode;
  precisionRank?: string;
  sensu?: string;
}

export type PrecisionCode = 'cf' | 'aff';

export enum InfraspesificRank {
  form = 'form',
  var = 'var',
  ssp = 'ssp'
}
export enum TaxonTypeId {
  Rev = 4,
  Conf = 2,
  ConfName = 3,
  Det = 1
}
export const convertTaxonData = (input: TaxonTypeFromArtDB[]): TaxonTypeMini[] =>
  input.map(data => convertOneTaxon(data));

export const convertOneTaxon = (data: TaxonTypeFromArtDB): TaxonTypeMini => ({
  selectedName: {
    scientificName: data.scientificName,
    scientificNameAuthorship: data.scientificNameAuthorship,
    scientificNameId: data.scientificNameID
  },
  taxonRank: data.taxonRank,
  acceptedNameUsage: !data.acceptedNameUsage
    ? undefined
    : {
        scientificName: data.acceptedNameUsage.scientificName,
        scientificNameAuthorship: data.acceptedNameUsage.scientificNameAuthorship,
        scientificNameId: data.acceptedNameUsage.scientificNameID
      },
  higherClassification: !data.higherClassification
    ? undefined
    : data.higherClassification.map(cl => ({
        taxonRank: cl.taxonRank,
        scientificName: cl.scientificName,
        scientificNameAuthorship: cl.scientificNameAuthorship
      }))
});

export const createDetString = (persons?: ActorAndRelation[]) =>
  persons
    ? persons.reduce(
        (p: string, a: ActorAndRelation) =>
          p !== '' ? p + '; ' + a.actorName : a.actorName,
        ''
      )
    : '';

export const toDetTypeStr = (taxonTypeId: TaxonTypeId) => {
  switch (taxonTypeId) {
    case TaxonTypeId.Rev:
      return 'rev';
    case TaxonTypeId.Conf:
      return 'conf';
    case TaxonTypeId.ConfName:
      return 'confName';
    case TaxonTypeId.Det:
      return 'det';
    default:
      return '';
  }
};

export const formatTaxonStrings = (
  taxon: TaxonTypeMini,
  precision?: PrecisionCode,
  precisionRank?: string,
  sensu?: string
) => {
  let firstPart;
  let precisionCode;
  if (
    taxon.taxonRank === 'species' ||
    taxon.taxonRank === 'subspecies' ||
    taxon.taxonRank === 'variety' ||
    taxon.taxonRank === 'form'
  ) {
    firstPart = createFirstPartOfTaxonString(
      taxon.higherClassification,
      precision,
      precisionRank
    );
    precisionCode = precisionIfRankIs(taxon.taxonRank, precisionRank, precision);
  } else {
    precisionCode = capitalize(
      precisionIfRankIs(taxon.taxonRank, precisionRank, precision)
    );
  }

  let epitet;
  if (taxon.taxonRank === 'subspecies') {
    epitet = 'ssp. ' + taxon.selectedName.scientificName.split(' ').reverse()[0];
  } else if (taxon.taxonRank === 'variety') {
    epitet = 'var. ' + taxon.selectedName.scientificName.split(' ').reverse()[0];
  } else epitet = taxon.selectedName.scientificName.split(' ').reverse()[0];

  const scientificName =
    (firstPart ? firstPart + ' ' : '') +
    (precisionCode ? precisionCode : '') +
    epitet +
    (sensu ? ' ' + sensu : '');
  return [
    scientificName,
    taxon.selectedName.scientificNameAuthorship
      ? taxon.selectedName.scientificNameAuthorship
      : ''
  ];
};

const capitalize = (s: string) => {
  if (s) {
    const strArray: string[] = s.split('');
    return [strArray[0].toUpperCase(), ...strArray.splice(1)].join('');
  }
  return '';
};

const precisionIfRankIs = (
  currentRank?: string,
  rank?: string,
  precision?: PrecisionCode
) => (currentRank === rank && precision ? precision + '. ' : '');

export const getPrecisionRanksFromTaxon = (taxonHit: TaxonTypeMini) => {
  const genusIndex = taxonHit.higherClassification
    ? taxonHit.higherClassification.findIndex(
        (t: HigherClassification) => t.taxonRank === 'genus'
      )
    : -1;

  if (genusIndex >= 0 && taxonHit.higherClassification) {
    return [
      ...taxonHit.higherClassification.map(r => r.taxonRank).slice(genusIndex),
      taxonHit.taxonRank
    ];
  } else {
    return [taxonHit.taxonRank];
  }
};

const createFirstPartOfTaxonString = (
  higherClassification?: HigherClassification[],
  precision?: PrecisionCode,
  precisionRank?: string
) => {
  if (higherClassification === undefined || higherClassification == null) {
    return '';
  }
  return higherClassification.reduce((p: string, c: HigherClassification) => {
    if (c.taxonRank === 'genus') {
      return (
        capitalize(precisionIfRankIs(precisionRank, 'genus', precision)) +
        c.scientificName
      );
    }
    if (c.taxonRank === 'species') {
      const a = c.scientificName.split(' ');
      return (
        capitalize(precisionIfRankIs(precisionRank, 'genus', precision)) +
        a[0] +
        ' ' +
        precisionIfRankIs(precisionRank, 'species', precision) +
        a[1]
      );
    }
    if (c.taxonRank === 'subspecies') {
      const namearray = c.scientificName.split(' ');
      return (
        capitalize(precisionIfRankIs(precisionRank, 'genus', precision)) +
        namearray[0] +
        ' ' +
        precisionIfRankIs(precisionRank, 'species', precision) +
        namearray[1] +
        ' ' +
        precisionIfRankIs(precisionRank, 'subspecies', precision) +
        ' ssp. ' +
        namearray[2]
      );
    }
    return p;
  }, '');
};

export const fullFormatTaxon = (
  taxon: TaxonTypeMini,
  precision?: PrecisionCode,
  precisionRank?: string,
  sensu?: string
) => {
  const a = formatTaxonStrings(taxon, precision, precisionRank, sensu);
  return a[0] + (a[1] ? ' ' + a[1] : '');
};
