import * as React from 'react';
import TaxonComponent from './TaxonComponent';
import RevisionComponent from './RevisonComponent';
import { EventTypeId } from '../types/event/eventTypes_auto';
import { TaxonEvent, TaxonTypeId } from './taxonLib';
import uuidv1 from 'uuid/v1';
import { AppSessionContext } from '../../app/AppComponent';
import {
  createObjectEventHeaderData,
  ChangeResult
} from '../object/ObjectContainerFunctions';
import { MuseumObjectEventHeader } from '../types/museumObject/museumObject';
import { getTranslator } from '../../../shared/language';
import './TaxonNavComponent.css';

const words = getTranslator({
  en: {
    revisions: 'Revisions',
    taxon: 'Taxon'
  },
  no: {
    revisions: 'Revisjoner',
    taxon: 'Takson'
  }
});
const detTypeWords = getTranslator({
  en: {
    det: 'Det',
    rev: 'Rev',
    confName: 'Conf with name change',
    conf: 'Conf'
  },
  no: {
    det: 'Bestemmelse',
    rev: 'Revisjon',
    confName: 'Konfirmering med navnebytte',
    conf: 'Konfirmering'
  }
});

type Props = {
  taxonEvents: TaxonEvent[];
  changeTaxonEvents: React.Dispatch<React.SetStateAction<TaxonEvent[]>>;
  defaultDateFormat: string;
  addEventUuid: (eventUuid: string) => void;
  changeEventUuid: (eventUuid: string) => ChangeResult;
  deleteEventUuid: (eventUuid: string, eventTypeId: number) => void;
};

enum TAXSTATE {
  TAXON = 1,
  REVISION = 2
}

const TaxonNavTab = (props: Props) => {
  const [visibleTab, changeVisibleTab] = React.useState<TAXSTATE>(TAXSTATE.TAXON);

  const appSession = React.useContext(AppSessionContext);
  const eventHeaderData = createObjectEventHeaderData(
    appSession,
    EventTypeId.Taxon,
    uuidv1()
  );

  const createNewEmptyFirstTaxonEvent = (
    eventHeaderData: MuseumObjectEventHeader,
    taxonTypeId: TaxonTypeId
  ) => {
    if (eventHeaderData.eventUuid) {
      props.addEventUuid(eventHeaderData.eventUuid);
    }
    const emptyTaxonEvent = {
      ...eventHeaderData,
      uuid: uuidv1(),
      eventTypeId: EventTypeId.Taxon,
      orderNo: 0,
      attributes: { taxonTypeId: taxonTypeId, taxons: [] }
    };
    props.changeTaxonEvents([emptyTaxonEvent]);
    return emptyTaxonEvent;
  };

  const taxonEvents: TaxonEvent[] =
    props.taxonEvents.length > 0
      ? props.taxonEvents
      : [createNewEmptyFirstTaxonEvent(eventHeaderData, TaxonTypeId.Det)];

  const registerChangeAndCheckIfAdded = (event: TaxonEvent): boolean => {
    if (event.eventUuid) {
      return props.changeEventUuid(event.eventUuid) === ChangeResult.alreadyAdded;
    } else if (event.revisionOf) {
      return props.changeEventUuid(event.revisionOf) === ChangeResult.alreadyAdded;
    } else {
      return false;
    }
  };

  // this is neccessary to signal to the backend that this event is an edit.
  // If an event is sent as a revision with eventuuid it signals that we want to delete it.
  const transformEventToRevision = (event: TaxonEvent): TaxonEvent => ({
    ...event,
    eventUuid: undefined,
    revisionOf: event.eventUuid ? event.eventUuid : event.revisionOf
  });

  const changeEvent = (index: number) => (event: TaxonEvent) => {
    const isAlreadyAdded = registerChangeAndCheckIfAdded(event);

    const ev: TaxonEvent = isAlreadyAdded ? event : transformEventToRevision(event);

    props.changeTaxonEvents((t: TaxonEvent[]) => {
      if (t.length > 0 && ev) {
        return [...t.slice(0, index), ev, ...t.slice(index + 1)];
      } else {
        if (t.length === 0 && ev) return [ev];
        else return [];
      }
    });
  };

  return (
    <div className="container">
      <form className="needs-validation" noValidate>
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <a
              className={`nav-link bg-whitesmoke ${
                visibleTab === TAXSTATE.TAXON ? ' active' : ' border-bottom'
              }`}
              id="taxon-tab"
              data-toggle="tab"
              href="#taxon-tab-content"
              role="tab"
              aria-controls="taxon"
              aria-selected={visibleTab === TAXSTATE.TAXON ? true : false}
              onClick={e => {
                e.preventDefault();
                changeVisibleTab(TAXSTATE.TAXON);
              }}
            >
              {words.taxon}
            </a>
          </li>
          <li className="nav-item">
            {' '}
            <a
              className={`nav-link bg-whitesmoke ${
                visibleTab === TAXSTATE.REVISION ? ' active' : ' border-bottom'
              }`}
              id="revision-tab"
              data-toggle="tab"
              href="#revision-tab-content"
              role="tab"
              aria-controls="revision"
              aria-selected={visibleTab === TAXSTATE.REVISION ? true : false}
              onClick={e => {
                e.preventDefault();
                changeVisibleTab(TAXSTATE.REVISION);
              }}
            >
              {words.revisions}
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div
            className={`tab-pane ${visibleTab === TAXSTATE.TAXON ? ' active' : ''}`}
            id="taxon-tab-content"
          >
            <TaxonComponent
              taxonEvent={taxonEvents[taxonEvents.length - 1]}
              appSession={appSession}
              changeTaxonEvent={changeEvent(taxonEvents.length - 1)}
              defaultDateFormat={props.defaultDateFormat}
              detTypeWords={detTypeWords}
            />
          </div>
          <div
            className={`tab-pane ${visibleTab === TAXSTATE.REVISION ? ' active' : ''}`}
            id="revision-tab-content"
          >
            <RevisionComponent
              revisionsIn={taxonEvents}
              changeEvent={changeEvent}
              addEventUuid={props.addEventUuid}
              deleteEventUuid={props.deleteEventUuid}
              updateRevisions={props.changeTaxonEvents}
              detTypeWords={detTypeWords}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default TaxonNavTab;
