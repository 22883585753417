import { Result } from '../../../src/modules/museumObject/objectSearchES/types';
import { CollectingEventSearchResult } from '../../modules/museumObject/collectingEvent/SearchCollectingEventContainer';
import { PersonNameSearchResult as PersonSearchHit } from '../../../src/modules/museumObject/types/person/personName';
import { SearchData } from '../../../src/modules/numismatic/searchPage/SearchContainer';

export const savePersonSearchResult = (persons: PersonSearchHit[]) => {
  return {
    type: 'SAVE_PERSON_SEARCH_RESULT',
    payload: persons
  };
};

export const saveObjectSearchResult = (objectList: Result) => {
  return {
    type: 'SAVE_OBJECT_SEARCH_RESULT',
    payload: objectList
  };
};

export const saveNumisObjectSearchResult = (objectList: SearchData[]) => {
  return {
    type: 'SAVE_NUMIS_OBJECT_SEARCH_RESULT',
    payload: objectList
  };
};

export const setLoadStatus = (loading: boolean) => {
  return {
    type: 'SET_LOADING_STATUS',
    payload: loading
  };
};

export const saveCurrentSearchPage = (currentPage: number) => {
  return {
    type: 'SAVE_CURRENT_PAGE',
    payload: currentPage
  };
};

export const saveCollectingEventSearch = (
  collectingEventSearch: CollectingEventSearchResult[]
) => {
  return {
    type: 'SAVE_COLLECTING_EVENT_SEARCH',
    payload: collectingEventSearch
  };
};
