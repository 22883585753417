//@flow
import * as React from 'react';
import { Maybe } from '../types/common';

type FieldDropDownProps = {
  id: string;
  onChange: Function;
  items: Array<string>;
  displayItems: Maybe<Array<string>>;
  title: string;
  disabled?: boolean;
};

export default function({
  id,
  onChange,
  items,
  displayItems,
  title,
  disabled
}: FieldDropDownProps) {
  return (
    <div id={id} className={`dropdown`}>
      <button
        className={`btn btn-light dropdown-toggle`}
        type="button"
        id={id + 'button'}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        disabled={disabled}
      >
        {title}
      </button>
      <div className="dropdown-menu" aria-labelledby={id + 'button'}>
        {items.map((v, ii) => (
          <div className="dropdown-item" key={ii} onClick={e => onChange(v)}>
            {(displayItems && displayItems[ii]) || v}
          </div>
        ))}
      </div>
    </div>
  );
}
