import * as React from 'react';
import {
  MuseumObjectSearchResult,
  SearchMuseumObject,
  MuseumObjectUuid
} from '../types/museumObject/museumObject';
import { AppSession } from '../../../types/appSession';
import { post } from '../../../shared/ajaxFetch/ajaxFetch';
import config from '../../../config';
import { MuseumObjectAndRelation } from '../types/event/event';
import { getTranslator } from '../../../shared/language';

const words = getTranslator({
  en: {
    searchObjectToConnect: 'Search object to connect to the collecting event',
    addSelectedObjects: 'Add selected objects to collecting event',
    musNoFrom: 'Museum number from',
    musNoTo: 'Museum number to',
    musNo: 'Museum number',
    registredBy: 'Registred by',
    registredDate: 'Registred date',
    taxonName: 'Taxon name',
    search: 'Search'
  },
  no: {
    searchObjectToConnect: 'Søk objekter for å legge til innsamlingen',
    addSelectedObjects: 'Legg valgte objekter til innsamlingshendelsen',
    musNoFrom: 'Museumsnummer fra',
    musNoTo: 'Museumsnummer til',
    musNo: 'Museumsnummer',
    registredBy: 'Registrert av',
    registredDate: 'Registrert dato',
    taxonName: 'Taksonnavn',
    search: 'Søk'
  }
});

interface SearchProps {
  appSession: AppSession;
  title?: string;
  relatedMuseumObjects: MuseumObjectAndRelation[] | undefined;
  addRelatedMuseumObjects: (objects: MuseumObjectUuid[]) => void;
  changeRelatedMuseumObjects: React.Dispatch<
    React.SetStateAction<MuseumObjectAndRelation[]>
  >;
}

const SearchForm = (p: SearchProps) => {
  const [search, changeSearch] = React.useState<SearchMuseumObject | undefined>(
    undefined
  );

  type IsSelected = { [key: string]: boolean } | undefined;

  const [selectedObjects, changeSelected] = React.useState<IsSelected>(undefined);

  const [searchResult, changeSearchResult] = React.useState<MuseumObjectSearchResult[]>(
    []
  );
  const [isSearchResultloaded, changeIsSearchResultloaded] = React.useState(false);

  const clearAll = () => {
    changeSearchResult([]);
    changeSelected(undefined);
  };

  const toggleSelected = (uuid: string) =>
    changeSelected((s: IsSelected) => {
      if (s) {
        return { ...s, [uuid]: s[uuid] ? false : true };
      } else {
        return { [uuid]: true };
      }
    });

  const getObjectListFromSearch = async (s: SearchMuseumObject) => {
    const url = config.api.objects.searchMuseumObject;
    const response: Response = await post(
      url,
      JSON.stringify(s),
      p.appSession.accessToken
    );
    if (response.ok) {
      const result = await response.json();
      const url = config.api.objects.getPostObjectSearchResult;
      const data = result;
      const response2 = await post(url, JSON.stringify(data), p.appSession.accessToken);
      if (response2.ok) {
        return await response2.json();
      }
      return undefined;
    }
    return undefined;
  };

  const searchHeader = {
    museumId: p.appSession.museumId,
    collectionUuid: p.appSession.collectionId
  };

  const onSearchTaxon = (fieldName: string) => (value: string | number | undefined) => {
    changeSearch(s => {
      const r = s
        ? {
            ...s,
            taxonSearch: s.taxonSearch
              ? {
                  ...s.taxonSearch,
                  [fieldName]: value
                }
              : { [fieldName]: value }
          }
        : {
            ...searchHeader,
            taxonSearch: { [fieldName]: value }
          };
      return r;
    });
  };

  const onSearchMusNo = (fieldName: string) => (value: string | number | undefined) => {
    changeSearch(s => {
      const r = s
        ? {
            ...s,
            museumObjectSearch: s.museumObjectSearch
              ? {
                  ...s.museumObjectSearch,
                  [fieldName]: value
                }
              : { [fieldName]: value }
          }
        : {
            ...searchHeader,
            museumObjectSearch: { [fieldName]: value }
          };
      return r;
    });
  };

  return (
    <form>
      <h4>{words.searchObjectToConnect}</h4>
      <div className="form-row">
        <div className="form-group col-md-2">
          <label htmlFor="musno">
            <b>{words.musNo}</b>
          </label>
          <input
            className="form-control"
            id="musno"
            type="text"
            value={
              search && search.museumObjectSearch ? search.museumObjectSearch.mnr : ''
            }
            onChange={e => {
              const value = e.target.value ? e.target.value : undefined;
              onSearchMusNo('mnr')(value);
            }}
          />
        </div>
        <div className="form-group col-md-2">
          <label htmlFor="musno-fra">
            <b>{words.musNoFrom}</b>
          </label>
          <input
            className="form-control"
            id="musno-fra"
            type="number"
            value={
              search && search.museumObjectSearch ? search.museumObjectSearch.mnrFrom : ''
            }
            onChange={e => {
              const value = e.target.value ? Number.parseInt(e.target.value) : undefined;
              onSearchMusNo('mnrFrom')(value);
            }}
          />
        </div>
        <div className="form-group col-md-2">
          <label htmlFor="musno-til">
            <b>{words.musNoTo}</b>
          </label>
          <input
            className="form-control"
            id="musno-til"
            type="number"
            value={
              search && search.museumObjectSearch ? search.museumObjectSearch.mnrTo : ''
            }
            onChange={e => {
              const value = e.target.value ? Number.parseInt(e.target.value) : undefined;
              onSearchMusNo('mnrTo')(value);
            }}
          />
        </div>
        <div className="form-group col-md-2">
          <label htmlFor="museumObjectRegisteredByName">
            <b>{words.registredBy}</b>
          </label>
          <input
            className="form-control"
            id="museumObjectRegisteredByName"
            value={
              search && search.museumObjectSearch
                ? search.museumObjectSearch.museumObjectRegisteredByName
                : ''
            }
            type="text"
            onChange={e => {
              const value = e.target.value ? e.target.value : undefined;
              onSearchMusNo('museumObjectRegisteredByName')(value);
            }}
          />
        </div>
        <div className="form-group col-md-2">
          <label htmlFor="museumObjectRegisteredDate">
            <b>{words.registredDate}</b>
          </label>
          <input
            className="form-control"
            id="museumObjectRegisteredDate"
            type="text"
            value={
              search && search.museumObjectSearch
                ? search.museumObjectSearch.museumObjectRegisteredDate
                : ''
            }
            onChange={e => {
              const value = e.target.value ? e.target.value : undefined;
              onSearchMusNo('museumObjectRegisteredDate')(value);
            }}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="taxon">
            <b>{words.taxonName}</b>
          </label>
          <input
            className="form-control"
            id="taxon"
            type="text"
            onChange={e => {
              const value = e.target.value ? e.target.value : undefined;
              onSearchTaxon('scientificName')(value);
            }}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-3">
          <button
            type="submit"
            className="btn btn-secondary btn-block"
            onClick={async e => {
              e.preventDefault();
              if (search) {
                changeIsSearchResultloaded(false);
                const r = await getObjectListFromSearch(search);
                changeSearchResult(r);
                changeIsSearchResultloaded(true);
              }
            }}
          >
            {words.search}
          </button>
        </div>
      </div>
      {isSearchResultloaded && (
        <div className="container p-3 my-2 border border-secondary">
          <h5>Resultat</h5>
          <div className="row">
            <div className="col-md-2">
              <b>Musno</b>
            </div>
            <div className="col-md-4">
              <b>Taxon</b>
            </div>
            <div className="col-md-3">
              <b>Det</b>
            </div>
            <div className="col-md-2">
              <b>Velg for å legge til</b>
            </div>
          </div>

          {(searchResult || []).map((r, i) => {
            return (
              <div className="row" key={`row-${i}`}>
                <div className="col-md-2">{r.mnr}</div>
                <div className="col-md-4">
                  {r.taxons && r.taxons.length > 0 ? r.taxons[0].scientificName : ''}
                </div>
                <div className="col-md-3">
                  {' '}
                  {r.taxons && r.taxons.length > 0 ? r.taxons[0].determinators : ''}
                </div>
                <div className="col-md-2">
                  <div className="form-check,">
                    {isAlreadyConnected(r) ? 'event: ' + r.collectingEventName : null}
                    {!isAlreadyConnected(r) && (
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={e => {
                          if (r.museumObjectUuid) toggleSelected(r.museumObjectUuid);
                        }}
                        checked={
                          r.museumObjectUuid && selectedObjects
                            ? selectedObjects[r.museumObjectUuid]
                            : false
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <br />
      <button
        type="button"
        className="btn btn-primary"
        onClick={e => {
          p.addRelatedMuseumObjects(
            searchResult
              ? searchResult
                  .filter(
                    e =>
                      e.museumObjectUuid &&
                      selectedObjects &&
                      selectedObjects[e.museumObjectUuid]
                  )
                  .reduce(
                    (p, c) => (c.museumObjectUuid ? [...p, c.museumObjectUuid] : p),
                    []
                  )
              : []
          );
          clearAll();
          e.preventDefault();
          changeIsSearchResultloaded(false);
        }}
      >
        {words.addSelectedObjects}
      </button>
    </form>
  );
};

const isAlreadyConnected = (r: MuseumObjectSearchResult) =>
  r.collectingEventName !== undefined ||
  r.collectingEventPersons !== undefined ||
  r.collectingEventRegisteredByName !== undefined ||
  r.collectingEventRegisteredDate !== undefined;

export default SearchForm;
