// @flow

import * as React from 'react';
import cx from 'classnames';

import { Paging, ChangePage } from '../searchStore';

export type Props = {
  paging: Paging;
  onChangePage: (page: ChangePage) => void;
};

const Pagination = (props: Props) => {
  const paging = props.paging;
  return (
    <ul className="pagination">
      <li
        className={cx({
          disabled: paging.currentPage === 1,
          'page-item': true
        })}
      >
        <a
          href="/#"
          className="page-link"
          onClick={e => {
            e.preventDefault();
            if (paging.currentPage !== 1) props.onChangePage('previous');
          }}
        >
          &laquo;
        </a>
      </li>

      {paging.showPages.map(pageNum => (
        <li
          key={pageNum}
          className={cx({
            active: paging.currentPage === pageNum,
            'page-item': true
          })}
        >
          {paging.currentPage === pageNum ? (
            <a href="/#" className="page-link">
              {pageNum}
            </a>
          ) : (
            <a
              className="page-link"
              href="/#"
              onClick={e => {
                e.preventDefault();
                props.onChangePage(pageNum);
              }}
            >
              {pageNum}
            </a>
          )}
        </li>
      ))}

      <li
        className={cx({
          disabled: paging.currentPage === paging.totalPages,
          'page-item': true
        })}
      >
        <a
          href="/#"
          className="page-link"
          onClick={e => {
            e.preventDefault();
            if (paging.currentPage !== paging.totalPages) props.onChangePage('next');
          }}
        >
          <span>&raquo;</span>
        </a>
      </li>
    </ul>
  );
};

export default Pagination;
