import * as React from 'react';
import { SelectType } from './types';
import { getTranslator } from '../../../shared/language';

const words = getTranslator({
  en: {
    selectResultFields: 'Select result fields',
    closeModal: 'Done',
    removeSelected: 'Remove selected fields'
  },
  no: {
    selectResultFields: 'Velg resultatfelter',
    closeModal: 'Ferdig',
    removeSelected: 'Fjern valgte felt'
  }
});

const SelectResultField = ({
  onClick,
  selectedResultFields,
  onDoubleClickField
}: {
  onClick: (i: number) => (e: React.MouseEvent<HTMLOptGroupElement>) => void;
  selectedResultFields: SelectType[];
  onDoubleClickField: (
    eventIndex: number,
    fieldIndex: number
  ) => (e: React.MouseEvent<HTMLOptionElement>) => void;
}) => {
  return (
    <select
      className="form-control"
      multiple
      onChange={e => console.log(e.target.value)}
      style={{ height: '400px', fontSize: 'smaller' }}
    >
      {selectedResultFields.map((e: SelectType, eventIndex: number) => (
        <optgroup
          key={`result-- ${eventIndex}`}
          label={e.eventName}
          onClick={onClick(eventIndex)}
        >
          {e.show &&
            e.fields.map(
              (
                f: { label: string; value: string; selected?: boolean },
                fieldIndex: number
              ) => (
                <option
                  style={
                    f.selected
                      ? { backgroundColor: '#cecece', color: '#3a4045' }
                      : undefined
                  }
                  onDoubleClick={onDoubleClickField(eventIndex, fieldIndex)}
                  key={`RESfield-${fieldIndex}`}
                  label={f.label}
                />
              )
            )}
        </optgroup>
      ))}
    </select>
  );
};

const ModalResultFieldSelector = ({
  allFields,
  clear,
  onClick,
  onDoubleClickField,
  onCloseModal
}: {
  allFields: SelectType[];
  clear: () => void;
  onClick: (i: number) => (e: React.MouseEvent<HTMLOptGroupElement, MouseEvent>) => void;
  onCloseModal: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  onDoubleClickField: (
    eventIndex: number,
    fieldIndex: number
  ) => (e: React.MouseEvent<HTMLOptionElement>) => void;
}) => {
  return (
    <React.Fragment>
      <button
        type="button"
        className="btn btn-outline-primary"
        data-toggle="modal"
        data-target=".bd-example-modal-lg"
      >
        {words.selectResultFields}
      </button>
      <div
        className="modal fade bd-example-modal-lg"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="mySmallModalLabel"
        aria-hidden={true}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {words.selectResultFields}
              </h5>
              <button className="btn btn-default" type="button" onClick={clear}>
                Fjern valgte
              </button>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onCloseModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <SelectResultField
                selectedResultFields={allFields}
                onDoubleClickField={onDoubleClickField}
                onClick={onClick}
              />
              <div className="row">
                <button
                  className="btn-default"
                  type="button"
                  data-dismiss="modal"
                  onClick={onCloseModal}
                >
                  {words.closeModal}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ModalResultFieldSelector;
