// @flow
import * as React from 'react';
import { Field } from '../form';
import { AppSession } from '../../types/appSession';
import { identity } from 'lodash';
import { Maybe } from '../../types/common';

type FieldComboDropDownProps<T> = {
  field: Field<string>;
  defaultOption?: string;
  extractValue: (v: T, a: Maybe<AppSession>) => number | string;
  displayValue: (v: T, a: Maybe<AppSession>) => string;
  title: string;
  onChange: Function;
  controlWidth?: number;
  selectItems: Array<T>;
  inputProps?: { className?: string; style?: {} };
  appSession?: AppSession;
};

type FieldComboDropDownStrProps = {
  field: Field<string>;
  defaultOption?: string;
  title: string;
  onChange: Function;
  selectItems: Array<string>;
  inputProps?: { className?: string; style?: {} };
  appSession?: AppSession;
};

export default function FieldComboDropDown<T>({
  field,
  defaultOption,
  extractValue,
  displayValue,
  title,
  onChange,
  controlWidth,
  selectItems,
  inputProps,
  appSession
}: FieldComboDropDownProps<T>) {
  return (
    <div className={`col-md-${controlWidth}`}>
      {title !== '' && (
        <label className="col-form-label" htmlFor={field.name}>
          <b>{title}</b>
        </label>
      )}
      <input
        {...inputProps}
        className={`form-control ${inputProps ? inputProps.className || '' : ''} ${
          field.status ? (field.status.valid ? '' : 'is-invalid') : ''
        }`}
        value={field.value || ''}
        id={field.name}
        onChange={e => onChange({ name: field.name, rawValue: e.target.value })}
        list={`${field.name}-list`}
      />
      <datalist id={`${field.name}-list`}>
        {selectItems.map((v: T, i: number) => (
          <option key={i} value={extractValue(v, appSession)}>
            {displayValue(v, appSession)}
          </option>
        ))}
      </datalist>
    </div>
  );
}

/**
 * Wrapper function around FieldComboDropDown that handles pure string items
 */
export const FieldComboDropDownStr = (props: FieldComboDropDownStrProps) => {
  const extProps: FieldComboDropDownProps<string> = {
    ...props,
    displayValue: identity,
    extractValue: identity
  };
  return FieldComboDropDown(extProps);
};
