import { SexAndStage } from '../types/event/event';
import * as React from 'react';
import SexAndStageComp from './SexAndStageRowComp';

const SexAndStagesEdit = ({
  onChangeSexAndStages,
  editingSexAndStage
}: {
  onChangeSexAndStages?: (sexAndStage: SexAndStage) => void;
  editingSexAndStage?: SexAndStage;
  addRow?: (newRow: SexAndStage) => void;
}) => {
  const [sexAndStage, changeSexAndStage] = React.useState<SexAndStage>(
    editingSexAndStage || {}
  );
  const onChangeSexAndStage = (field: string) => (
    value: string | number | boolean | undefined
  ) => {
    const newSexAndStage = { ...sexAndStage, [field]: value };
    if (onChangeSexAndStages) {
      onChangeSexAndStages(newSexAndStage);
    } else {
      changeSexAndStage(newSexAndStage);
    }
  };
  React.useEffect(() => {
    changeSexAndStage(editingSexAndStage || {});
  }, [editingSexAndStage]);
  return (
    <SexAndStageComp
      key="Edit"
      sexAndStage={sexAndStage}
      onChangeSexAndStage={onChangeSexAndStage}
    />
  );
};

export default SexAndStagesEdit;
