import * as React from 'react';
import './ShowComponent.css';

export const ShowComponent = ({
  width,
  label,
  id,
  data
}: {
  width: number;
  id: string;
  label: string | object;
  data?: string | number;
}) => {
  return (
    <React.Fragment>
      <div className={`form-group col-md-${width}`}>
        {' '}
        <label className="control-label narrow" htmlFor={id}>
          {label}
        </label>{' '}
        <input
          type="text"
          readOnly
          id={id}
          className="form-control-plaintext"
          value={data}
        />
      </div>
    </React.Fragment>
  );
};
