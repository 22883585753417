import * as React from 'react';
import * as T from './types';
import { getTranslator } from '../../../shared/language';

const words = getTranslator({
  en: {
    museumNumber: 'Museum number',
    subNumber: 'Sub number',
    placeHolder: 'from..to, or n1,n2,n3',
    admin: 'Administrators',
    collEventConnection: 'Collecting event attached to',
    eventHistory: 'Event history',
    magazine: 'Storage facility',
    metaData: 'Metadata',
    otherData: 'Other data',
    sexAndStages: 'Sex and stages',
    taxon: 'Taxon',
    typeInfo: 'Type information',
    object: 'Object',
    noCollection: 'Can not edit selected collection',
    save: 'Save'
  },
  no: {
    museumNumber: 'Museumsnummer',
    subNumber: 'Undernumber',
    placeHolder: 'fra..til, eller n1,n2,n3',
    admin: 'Administratorer',
    collEventConnection: 'Innsamlingshendelse koblet til',
    eventHistory: 'Hendelsehistorikk',
    magazine: 'Magasine',
    metaData: 'Metadata',
    otherData: 'Andre data',
    sexAndStages: 'Kjønn og stadier',
    taxon: 'Takson',
    typeInfo: 'Type informasjon',
    object: 'Objekt',
    noCollection: 'Kan ikke redigere objekt av samme type som valgt samling',
    save: 'Lagre'
  }
});

type Props = {
  changeNumSearch: React.Dispatch<React.SetStateAction<T.NumSearch[] | null>>;
  numSearch: T.NumSearch[] | null;
  eventType: string;
};

export const insertOrUpdateNumField = (
  toInsert: T.NumSearch,
  toInsertIn: T.NumSearch[] | null
) => {
  if (toInsertIn === null) return [toInsert];
  const index = toInsertIn.findIndex(
    (v: T.NumSearch) => v.fieldName === toInsert.fieldName
  );
  if (index === -1) {
    return [...toInsertIn, toInsert];
  }
  return [...toInsertIn.slice(0, index), toInsert, ...toInsertIn.slice(index + 1)];
};

export const numFieldToNumSearch: (
  fieldName: string,
  s: string,
  contentPrefix?: string
) => T.NumSearch = (fieldName: string, s: string, contentPrefix?: string) => {
  const singlePattern = /^\s*\d+\s*$/;
  const fromPattern = /^\s*\d+..\s*$/;
  const toPattern = /^\s*..\d+\s*$/;
  const fromToPattern = /^\s*\d+..\d+\s*$/;
  const sepNumPattern = /^\s*\d+(\s*,\d+)+\s*$/;

  const numPattern = /\d+/g;
  if (singlePattern.test(s)) {
    const nums = s.match(numPattern);
    const singleNum = nums && nums.length === 1 ? Number.parseInt(nums[0]) : undefined;

    return {
      numString: s,
      fieldName: fieldName,
      searchKind: T.SearchKind.Single,
      singleNum,
      contentPrefix
    };
  }
  if (fromPattern.test(s)) {
    const nums = s.match(numPattern);
    const from = nums && nums.length === 1 ? Number.parseInt(nums[0]) : undefined;

    return {
      numString: s,
      fieldName: fieldName,
      searchKind: T.SearchKind.GreaterThan,
      fromTo: { from },
      contentPrefix
    };
  }
  if (toPattern.test(s)) {
    const nums = s.match(numPattern);
    const to = nums && nums.length === 1 ? Number.parseInt(nums[0]) : undefined;

    return {
      numString: s,
      fieldName: fieldName,
      searchKind: T.SearchKind.LessThan,
      fromTo: { to },
      contentPrefix
    };
  }
  if (fromToPattern.test(s)) {
    const nums = s.match(numPattern);
    const from = nums && nums.length === 2 ? Number.parseInt(nums[0]) : undefined;
    const to = nums && nums.length === 2 ? Number.parseInt(nums[1]) : undefined;

    return {
      numString: s,
      fieldName: fieldName,
      searchKind: T.SearchKind.Sequence,
      fromTo: { from, to },
      contentPrefix
    };
  }
  if (sepNumPattern.test(s)) {
    const nums = s.match(numPattern);
    return {
      numString: s,
      fieldName: fieldName,
      searchKind: T.SearchKind.CommaSep,
      separatedValues: nums?.map(s => Number.parseInt(s)),
      contentPrefix
    };
  }
  return {
    numString: s,
    fieldName: fieldName,
    searchKind: T.SearchKind.NoKind,
    contentPrefix
  };
};
const Search = (p: Props) => {
  const onChangeNumField = (fieldName: string) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const n = numFieldToNumSearch(fieldName, e.target.value);
    p.changeNumSearch(fields => insertOrUpdateNumField(n, fields));
  };

  const showSubNoField = () => {
    const r = p.numSearch
      ? p.numSearch.find(n => n.fieldName === 'museumNoAsNumber')
      : undefined;
    return r ? r.searchKind === T.SearchKind.Single : false;
  };
  const getValue = (fieldName: string) => {
    const r = p.numSearch ? p.numSearch.find(f => f.fieldName === fieldName) : undefined;
    return r ? r.numString : '';
  };

  return (
    <div className="form-row">
      <div className="col-md-4">
        <label htmlFor="musNoInput">{words.museumNumber}</label>
        <input
          value={getValue('museumNoAsNumber')}
          placeholder={words.placeHolder}
          id="museNoInput"
          className="form-control"
          type="text"
          onChange={onChangeNumField('museumNoAsNumber')}
        />
      </div>
      {showSubNoField() && (
        <div className="col-md-4">
          <label htmlFor="subNoInput">{words.subNumber}</label>
          <input
            value={getValue('subNoAsNumber')}
            onChange={onChangeNumField('subNoAsNumber')}
            placeholder={words.placeHolder}
            className="form-control"
            type="text"
          />
        </div>
      )}
    </div>
  );
};

export default Search;
