import * as React from 'react';
import { getTranslator } from '../../../shared/language';

export type CoordinateFieldProps = {
  coordinate: string;
  validCoordinate: boolean;
  disabled?: boolean;
  updateCoordinate: (v: string) => void;
};

const words = getTranslator({
  en: {
    coordinate: 'Coordinate'
  },
  no: {
    coordinate: 'Koordinat'
  }
});

const CoordinateField = (p: CoordinateFieldProps) => {
  return (
    <>
      <div className={`col-md-2`} style={{ textAlign: 'right' }}>
        <b>{words.coordinate}</b>
      </div>
      <div className={`col-md-6${p.validCoordinate ? ' has-success' : ''}`}>
        <input
          type="text"
          className="form-control"
          id="CoordinateString"
          value={p.coordinate}
          disabled={p.disabled}
          onChange={e => {
            const v = e.target.value.toUpperCase();
            p.updateCoordinate(v);
          }}
        />
      </div>
    </>
  );
};

export default CoordinateField;
