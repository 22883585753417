import '../css/Compact.css';
import * as React from 'react';
import { MusitEvent } from '../types/event/event';
import {
  getEvent,
  createObjectEventHeaderData,
  setEventAttribute,
  updateEvent
} from './ObjectContainerFunctions';
import { EventTypeId, LegacyEventAttributes } from '../types/event/eventTypes_auto';
import { MarineEvertebratesAttributes } from '../types/museumObject/objectTypes_auto';
import { AppSession } from '../../../types/appSession';
import { getTranslator } from '../../../shared/language';
import '../css/Compact.css';
import config from '../../../config';
import { appSession } from 'src/testutils/sampleDataForTest';
type OtherDataProps = {
  genericEvents: MusitEvent[];
  changeGenericEvents: React.Dispatch<React.SetStateAction<MusitEvent[]>>;
  appSession: AppSession;
  registerChangeEventId: (eventId: number) => void;
  readonly?: boolean;
  legacyEvents?: MusitEvent[];
};

const words = getTranslator({
  en: {
    artobsNumber: 'Artobs number',
    projectName: 'Project name',
    showLegacyData: 'Legacy data',
    openInNewWindow: 'Open in new browser window'
  },
  no: {
    artobsNumber: 'Artobs-nummer',
    projectName: 'Projektnavn',
    showLegacyData: 'Legacydata',
    openInNewWindow: 'Åpne i nytt browservindu'
  }
});

const OtherDataComponent = (props: OtherDataProps) => {
  const attrEvent: MusitEvent | undefined = getEvent(
    EventTypeId.MuseumObjectAttributeAssignment,
    props.genericEvents
  );

  const legacyData = props.legacyEvents
    ? props.legacyEvents.map(e => ({
        id: e.eventUuid,
        note: (e.attributes as LegacyEventAttributes).note
      }))
    : [
        {
          id: 'xrreere',
          note:
            'Migrering fra oracle (høsten 2020 - > Gamle data fra musit_zoologi_entomologi)'
        },
        { id: '3mdmkm3m4', note: 'Migrering fra ZooTRON - Oracle' }
      ];

  const attributes: MarineEvertebratesAttributes | undefined = attrEvent
    ? attrEvent.attributes
    : undefined;
  const existingOrNewAttrEvent = attrEvent
    ? attrEvent
    : createObjectEventHeaderData(
        props.appSession,
        EventTypeId.MuseumObjectAttributeAssignment
      );

  const onChangeFieldValue = (fieldName: string) => (value: string) => {
    const updatedEvent = setEventAttribute(existingOrNewAttrEvent, fieldName, value);
    const updatedEvents = updateEvent(updatedEvent, props.genericEvents);
    props.changeGenericEvents(updatedEvents);
    props.registerChangeEventId(updatedEvent.eventTypeId);
  };

  const otherDataView = (attributes: MarineEvertebratesAttributes | undefined) => (
    <>
      <div className="container-fluid">
        <form>
          <div className="form-group">
            <div className="form-row">
              {displayData(
                'dataset',
                'Dataset',
                3,
                attributes && attributes.dataset ? attributes.dataset : ''
              )}
              {displayData(
                'projectname',
                words.projectName,
                3,
                attributes && attributes.projectName ? attributes.projectName : ''
              )}{' '}
              {displayData(
                'voucher',
                'Voucher',
                4,
                attributes && attributes.voucher ? attributes.voucher : ''
              )}{' '}
              {displayData(
                'artobs',
                words.artobsNumber,
                2,
                attributes && attributes.artsObsNo ? attributes.artsObsNo.toString() : ''
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );

  const otherDataEdit = (
    attributes: MarineEvertebratesAttributes | undefined,
    onChangeFieldValue: (f: string) => (v: string) => void
  ) => (
    <>
      <div className="container-fluid">
        <form className="form-horizontal">
          <div className="form-group">
            <div className="form-row">
              {inputData(
                'dataset',
                'Dataset', // dont translate
                2,
                3,
                attributes && attributes.dataset ? attributes.dataset : '',
                onChangeFieldValue('dataset')
              )}
              {inputData(
                'projectname',
                words.projectName,
                2,
                3,
                attributes && attributes.projectName ? attributes.projectName : '',
                onChangeFieldValue('projectName')
              )}{' '}
              {inputData(
                'voucher',
                'Voucher', // dont translate
                2,
                4,
                attributes && attributes.voucher ? attributes.voucher : '',
                onChangeFieldValue('voucher')
              )}{' '}
              {inputData(
                'artobs',
                words.artobsNumber,
                2,
                2,
                attributes && attributes.artsObsNo ? attributes.artsObsNo.toString() : '',
                onChangeFieldValue('artsObsNo')
              )}
            </div>
          </div>
          {legacyData && (
            <>
              <b>{words.showLegacyData}</b>
              {legacyData.map((l, i) => (
                <div key={`legacyRow-${i}`} className="form-row">
                  <div className="col-sm-8">{l.note}</div>
                  <div className="col-sm-4">
                    <a
                      href={config.magasin.urls.client.object.legacy(
                        appSession,
                        l.id || 'no-data'
                      )}
                      onClick={e => {
                        e.preventDefault();
                        window.open(
                          config.magasin.urls.client.object.legacy(
                            appSession,
                            l.id || 'no-data'
                          ),
                          words.showLegacyData,
                          'toolbar=no,scrollbars=yes,resizable=yes,top=500,left=500,width=800,height=800'
                        );
                      }}
                    >
                      {words.openInNewWindow}
                    </a>
                  </div>
                </div>
              ))}
            </>
          )}
        </form>
      </div>
    </>
  );
  if (props.readonly) return otherDataView(attributes);
  return otherDataEdit(attributes, onChangeFieldValue);
};

const displayData = (
  fieldId: string,
  description: string,
  width: number,
  data: string
) => (
  <>
    <div className={'col-md-' + width}>
      <label className={'control-label narrow'} htmlFor={fieldId}>
        {description}
      </label>
      <input
        type="text"
        readOnly
        className="form-control-plaintext"
        id={fieldId}
        placeholder={description}
        value={data}
      />
    </div>
  </>
);

const inputData = (
  fieldId: string,
  description: string,
  widthLabel: number,
  widthInput: number,
  data: string,
  onChange: (v: string) => void
) => (
  <>
    <div className={'col-md-' + widthInput}>
      <label className={'control-label narrow'} htmlFor={fieldId}>
        {description}
      </label>
      <input
        type="text"
        className="form-control"
        onChange={e => onChange(e.target.value)}
        id={fieldId}
        placeholder={description}
        value={data}
      />
    </div>
  </>
);

export default OtherDataComponent;
