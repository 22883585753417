import * as React from 'react';
import { withRouter } from 'react-router-dom';

import SimpleNumisDataViewer from './SimpleNumisViewerComponent';

const SimpleNumisViewerContainer = withRouter(({ history }) => {
  return (
    <div>
      <SimpleNumisDataViewer />
    </div>
  );
});

export default SimpleNumisViewerContainer;
