const geometries = [
  {
    geometry: 'point'
  },

  {
    geometry: 'polygon'
  },
  {
    geometry: 'line'
  }
];
export default geometries;
