import './analysis.css'; // needed?
import * as React from 'react';
import AnalysisEditor from './AnalysisEditor';
import { I18n } from 'react-i18nify';
import { postNewAnalysis } from './analysisAPI';

export default (props: any) => {
  // show loader if analysis object is not yet initialised from API
  const objectIds = new URLSearchParams(props.location.search).getAll('objectId');

  return (
    <>
      <div className="container" style={{ marginTop: 100 }}>
        <div className="row">
          <div className="col">
            <h1>{I18n.t('musit.objects.objectsView.newAnalysis')}</h1>
          </div>
        </div>
      </div>
      <AnalysisEditor
        submitButtonTitle={'newAnalysis'}
        formValues={{
          objects: objectIds
        }}
        handleSubmit={postNewAnalysis}
        history={props.history}
      />
    </>
  );
};
