import * as React from 'react';
import AdmPlaceSuggestion from './AdmplaceSuggestionNew';
import { AdmPlace } from '../types/place/place';
import { getTranslator } from '../../../shared/language';

const words = getTranslator({
  en: {
    admCenter: 'Administrative center',
    country: 'Country',
    municipality: 'Municipality',
    province: 'State/province'
  },
  no: {
    admCenter: 'Administrativt senter',
    country: 'Land',
    municipality: 'Kommune',
    province: 'Fylke'
  }
});

type Props = { admPlace?: AdmPlace } & {
  updateState: (p?: AdmPlace) => void;
  disabled?: boolean;
};

const getAdmPlaceData = (field: string) => (a?: AdmPlace) => {
  if (a) {
    const arrayPlaces = a.path ? a.path.split(':') : undefined;
    let placeString: string = '';

    if (field === 'Kommune') {
      placeString = arrayPlaces ? arrayPlaces[2] : '';
    } else if (field === 'Fylke') {
      placeString = arrayPlaces ? arrayPlaces[1] : '';
    } else if (field === 'Land') {
      placeString = arrayPlaces ? arrayPlaces[0] : '';
    }
    return placeString || '';
  } else {
    return '';
  }
};

const AdmPlaceComponent = (p: Props) => {
  return (
    <div>
      <div className="form-row">
        <div className="col-md-2" style={{ textAlign: 'right' }}>
          <b>{words.admCenter}</b>
        </div>
        <div className="col-md-6">
          <AdmPlaceSuggestion
            disabled={p.disabled}
            admPlace={p.admPlace}
            updateAdmplace={a => p.updateState(a)}
          />
        </div>
      </div>
      <div className="form-row" style={{ padding: '15px 0px' }}>
        <div className="col-md-2 offset-md-2">
          <input
            className="form-control"
            value={getAdmPlaceData('Kommune')(p.admPlace && p.admPlace)}
            disabled
            placeholder={words.municipality}
          />
        </div>
        <div className="col-md-2">
          <input
            className="form-control"
            value={getAdmPlaceData('Fylke')(p && p.admPlace)}
            disabled
            placeholder={words.province}
          />
        </div>
        <div className="col-md-2">
          <input
            className="form-control"
            value={getAdmPlaceData('Land')(p && p.admPlace)}
            disabled
            placeholder={words.country}
          />
        </div>
      </div>
    </div>
  );
};

export default AdmPlaceComponent;
