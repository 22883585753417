import * as React from 'react';
import { InputMuseumObject } from '../types/museumObject/museumObject';
import { inputData } from './EditMetaDataComponent';
import uuidv1 from 'uuid/v1';
import { AppSession } from 'src/types/appSession';
import { emitError } from '../../../shared/errors';
import { EventTypeId } from '../types/event/eventTypes_auto';

type PropTypes = {
  subObjects: InputMuseumObject[];
  collection: string | undefined;
  subCollection: string | undefined;
  parentMusNoSeq?: number;
  appSession: AppSession;
  changeMuseumObjectData: React.Dispatch<React.SetStateAction<InputMuseumObject>>;
};

type SubObjProps = {
  addToSubObjects: (subObj: InputMuseumObject) => void;
  appSession: AppSession;
  collection: string | undefined;
  parentMusNoSeq: number | undefined;
  editObject: InputMuseumObject;
  updateMuseumNo: (field: string, value: string) => void;
  updateTaxon: (field: string, value: string) => void;

  subColl: string | undefined;
};

export const checkSubObjExists = (
  obj: InputMuseumObject,
  subObjects: InputMuseumObject[]
) => {
  const r = subObjects.find(
    o =>
      obj.attributes &&
      o.attributes &&
      o.attributes.museumNo &&
      obj.attributes.museumNo &&
      o.attributes.museumNo.museumNoSeq === obj.attributes.museumNo.museumNoSeq &&
      o.attributes.museumNo.subNo === obj.attributes.museumNo.subNo &&
      o.attributes.museumNo.collection === obj.attributes.museumNo.collection &&
      o.attributes.museumNo.subColl === obj.attributes.museumNo.subColl
  );
  return r ? true : false;
};

const SimpleMuseumSubObject = (props: SubObjProps) => {
  return (
    <form>
      <fieldset className="form-group border p-2">
        <legend className="w-auto">Museum number</legend>
        <div className="row">
          {inputData(
            'museumNoSeq',
            'Number part',
            4,
            props.editObject.attributes && props.editObject.attributes.museumNo
              ? props.editObject.attributes.museumNo.museumNoSeq
              : undefined,
            props.updateMuseumNo
          )}
          {inputData(
            'subNo',
            'SubNo',
            4,
            props.editObject.attributes && props.editObject.attributes.museumNo
              ? props.editObject.attributes.museumNo.subNo
              : '',
            props.updateMuseumNo
          )}
        </div>
      </fieldset>

      <div className="row">
        {inputData(
          'museumNo',
          'Museum number',
          8,
          props.editObject.attributes && props.editObject.attributes.museumNo
            ? props.editObject.attributes.museumNo.museumNo
            : '',
          props.updateMuseumNo
        )}
      </div>
      <div className="row">
        {inputData(
          'collection',
          'Collection',
          4,
          props.editObject.attributes && props.editObject.attributes.museumNo
            ? props.editObject.attributes.museumNo.collection
            : '',
          () => null,
          undefined,
          true
        )}{' '}
        {inputData(
          'subColl',
          'Sub collection',
          4,
          props.editObject.attributes && props.editObject.attributes.museumNo
            ? props.editObject.attributes.museumNo.subColl
            : '',
          () => null,
          undefined,
          true
        )}
      </div>
    </form>
  );
};

export const createMusNoText = (o: InputMuseumObject, field: string, value: string) => {
  let museumNo = '';
  if (field === 'museumNoSeq') {
    museumNo =
      (o.attributes && o.attributes.museumNo && o.attributes.museumNo.collection
        ? o.attributes.museumNo.collection + '-'
        : '') +
      (o.attributes && o.attributes.museumNo && o.attributes.museumNo.subColl
        ? o.attributes.museumNo.subColl + '-'
        : '') +
      value +
      (o.attributes && o.attributes.museumNo && o.attributes.museumNo.subNo
        ? '/' + o.attributes.museumNo.subNo
        : '');
  }
  if (field === 'subNo') {
    museumNo =
      (o.attributes && o.attributes.museumNo && o.attributes.museumNo.collection
        ? o.attributes.museumNo.collection + '-'
        : '') +
      (o.attributes && o.attributes.museumNo && o.attributes.museumNo.subColl
        ? o.attributes.museumNo.subColl + '-'
        : '') +
      (o.attributes && o.attributes.museumNo ? o.attributes.museumNo.museumNoSeq : '') +
      (value !== '' ? '/' : '') +
      value;
  }

  return museumNo;
};

const DisplayStructureComponent = (props: PropTypes) => {
  const [subObjects, updateSubObjects] = React.useState<InputMuseumObject[]>(
    props.subObjects || []
  );
  const createNewObject: () => InputMuseumObject = () => ({
    museumId: props.appSession.museumId,
    museumObjectUuid: uuidv1(),
    collectionUuid: props.appSession.collectionId,
    subObjects: [],
    events: [
      {
        eventTypeId: EventTypeId.MuseumNoAssignment,
        attributes: {
          museumNo: {
            museumNo: '',
            subNo: '',
            museumNoSeq: props.parentMusNoSeq,
            subColl: props.subCollection,
            collection: props.collection
          }
        }
      }
    ]
  });

  const [editObj, updateEditObj] = React.useState<InputMuseumObject>(createNewObject());

  const updMuseumNo = (field: string, value: string) => {
    updateEditObj((p: InputMuseumObject) => {
      let attr;
      if (p.attributes) {
        const v = createMusNoText(p, field, value);

        attr = {
          ...p.attributes,
          museumNo: { ...p.attributes.museumNo, museumNo: v, [field]: value }
        };
      } else
        attr = {
          museumNo: { museumNo: '' }
        };
      return { ...p, attributes: attr };
    });
  };

  const addToSubObjects = (o: InputMuseumObject) => {
    const exists = checkSubObjExists(o, subObjects);
    if (exists) {
      emitError({
        message:
          'Objekt med museumsnr ' +
          (o && o.attributes && o.attributes.museumNo
            ? o.attributes.museumNo.museumNo
            : '') +
          ' finnes fra før'
      });
      return;
    }
    updateSubObjects((s: InputMuseumObject[]) => {
      const newSubObj = [...s, o];
      props.changeMuseumObjectData((p: InputMuseumObject) => ({
        ...p,
        subObjects: newSubObj
      }));

      return newSubObj;
    });
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="form-group">
          <ul>
            {subObjects.map((m: InputMuseumObject, i: number) => (
              <li key={`sub-${i}`}>
                {(m.attributes && m.attributes.museumNo
                  ? m.attributes.museumNo.museumNo
                  : m.museumObjectUuid) +
                  'må reimplenetere henting og formatering' /*(m.taxon ? ' ' + m.taxon.scientificNameWithAuthor : '')*/}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="row" style={{ marginBottom: 20 }}>
        <div className="col-md-12">
          <button
            type="button"
            className="btn-secondary btn"
            data-toggle="modal"
            data-target="#newSubObjModal"
          >
            + Registrer nytt underobjekt
          </button>{' '}
          (Må lagre før man kan registrere)
        </div>
      </div>
      <div
        className="modal fade"
        id="newSubObjModal"
        role="dialog"
        aria-labelledby="newSubObjLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Object data</h5>
            </div>
            <div className="modal-body container-fluid">
              <SimpleMuseumSubObject
                editObject={editObj}
                updateMuseumNo={updMuseumNo}
                appSession={props.appSession}
                addToSubObjects={addToSubObjects}
                collection={props.collection}
                subColl={props.subCollection}
                parentMusNoSeq={props.parentMusNoSeq}
                updateTaxon={() => {}}
              />
              <br />+ additional data (Sample, gender, stage, revisions, full taxon
              module)
            </div>

            <div className="modal-footer">
              {' '}
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  addToSubObjects(editObj);
                  updateEditObj(createNewObject());
                }}
              >
                {' '}
                Add
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => updateEditObj(createNewObject())}
              >
                {' '}
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DisplayStructureComponent;
