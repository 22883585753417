import * as React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../stores/actions/actions';
import { AppSession } from 'src/types/appSession';
import { withRouter } from 'react-router';

import { AppSessionContext } from '../../app/AppComponent';
import Config from '../../../config';
import { get } from '../../../shared/ajaxFetch/ajaxFetch';
import { Link } from 'react-router-dom';

// For testing loading functionality
const delay = async (ms: number) => {
  return new Promise(r => setTimeout(r, ms));
};

export type SearchData = { museumNo: string; subNo?: string; museumObjectUuid: string };
type SearchValues = { searchText: string };

const CallSearch = async (appSession: AppSession, musNo?: string, subNo?: string) => {
  const URL = Config.api.objects.search(appSession, musNo, subNo);
  const r = await get(URL, appSession.accessToken);
  const resp = await r.json();
  // await delay(2000); Testing loading-message
  return resp;
};

const Grid = ({ result }: { result: SearchData[] }) => {
  const appSession = React.useContext(AppSessionContext);
  console.log('RESULT => ', result);
  return result.length > 0 ? (
    <React.Fragment>
      <div className="row">
        <div className="col-md-2">
          <b>MuseumNo</b>
        </div>
        <div className="col-md-2">
          <b>SubNo</b>
        </div>
        <div className="col-md-6">
          <b>Uuid</b>
        </div>

        <div className="col-md-2">
          <b>Gå til objekt</b>
        </div>
      </div>
      {result.map((e: any, i: number) => (
        <div key={`${i}-searchResult`} className="row">
          <div className="col-md-2">{e.museumNo}</div>
          <div className="col-md-2">{e.subNo}</div>
          <div className="col-md-6">{e.museumObjectUuid}</div>
          <div className="col-md-2">
            {/* <button type="button" className="btn btn-link" onClick={() => {} }>
              {'->'}
            </button> */}
            <Link
              className="nav-link"
              to={{
                pathname: Config.magasin.urls.client.numismatic.gotoObjectView(
                  appSession,
                  e.museumObjectUuid
                )
              }}
            >
              {'->'}
            </Link>
          </div>
        </div>
      ))}
    </React.Fragment>
  ) : (
    <React.Fragment>
      <p style={{ paddingTop: '30px' }}>No data found</p>
    </React.Fragment>
  );
};

type SearchContainerProps = {
  x: string;
};

const SearchContainer = withRouter((props: any) => {
  const appSession = React.useContext(AppSessionContext);
  const [musNo, changeMusNo] = React.useState<string | undefined>(undefined);
  const [subNo, changeSubNo] = React.useState<string | undefined>(undefined);

  const doSearch = async (appSession: AppSession, museumNo?: string, subNo?: string) => {
    try {
      props.dispatch(actions.setLoadStatus(true));

      const resp = await CallSearch(appSession, museumNo || '*', subNo);

      const r = resp.map((e: any) => ({
        museumNo: e.museumNo,
        subNo: e.subNo,
        museumObjectUuid: e.museumObjectUuid
      }));
      //await delay(1500);

      props.dispatch(actions.setLoadStatus(false));
      props.dispatch(actions.saveNumisObjectSearchResult(r));
    } catch (err) {
      props.dispatch(actions.setLoadStatus(false));
      alert(err);
    }
  };
  // console.log('P', props);

  return (
    <div className="container" style={{ padding: '80px' }}>
      <h1>Søk i numismatikkdata</h1>
      <div className="row">
        <div className="col-md-2">
          <b>MuseumNo:</b>
        </div>
        <div className="col-md-3">
          <input
            onChange={e => {
              changeMusNo(e.target.value);
            }}
            className="form-control"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-2">
          <b>SubNo:</b>
        </div>
        <div className="col-md-3">
          <input
            onChange={e => {
              changeSubNo(e.target.value);
            }}
            className="form-control"
          />
        </div>
      </div>
      <div className="row">
        {' '}
        <button
          type="button"
          className="btn btn-primary"
          onClick={async () => {
            await doSearch(appSession, musNo, subNo);
          }}
        >
          Søk
        </button>
      </div>
      {props.loading ? (
        <React.Fragment>
          <p style={{ paddingTop: '30px' }}>Loading data.....</p>
        </React.Fragment>
      ) : (
        <Grid result={props.numisSearch} />
      )}
    </div>
  );
});

const mapStateToProps = (state: any) => state;
export default connect(mapStateToProps)(SearchContainer);
