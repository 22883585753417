import * as React from 'react';
import { I18n } from 'react-i18nify';
import * as H from 'history';
import FontAwesome from 'react-fontawesome';
import { CollectingEventSearchResult } from './SearchCollectingEventContainer';
import config from '../../../config';
import { AppSessionContext } from '../../../modules/app/AppComponent';
import { EventUuid } from '../types/event/event';
import NavigateSearch from '../../../search/NavigateSearch';
import { OutActorAndRelation } from '../types/event/event';

const onClickHeader = (history: H.History, url: string) => {
  history && history.push(url);
};

export type ParamProps = {
  id: string;
  value: string;
  onChange: (name: string, value: string) => void;
};
export type SearchParams = { [key: string]: string };

const CollectingEventResultHit = ({
  collEvent,
  onClickHeader,
  history,
  index
}: {
  collEvent: CollectingEventSearchResult;
  onClickHeader: (history: H.History, url: string) => void;
  history: H.History;
  index: number;
}) => {
  const appSession = React.useContext(AppSessionContext);

  if (!collEvent) return null;
  const createUrl = (eventUuid: EventUuid) =>
    config.magasin.urls.client.collectingEvent.editNew(appSession, eventUuid);
  return (
    <React.Fragment key={'collectingEventSeachFragment' + index}>
      <div className="media musit__media--search">
        <div className="media-left pr-4">
          <FontAwesome name="tag" style={{ fontSize: '1.3em', height: 25 }} />
        </div>
        <div className="media-body">
          <h4
            className="media-heading link"
            onClick={() => {
              onClickHeader(history, createUrl(collEvent.eventUuid));
            }}
          >
            {collEvent.attributes ? collEvent.attributes.name : ''}
            <a href="select">
              <span />
            </a>
          </h4>
          <div className="row">
            <div className="col-md-12">
              <div>
                <b> {I18n.t('musit.objectModule.collectingEvent.name')}:</b>{' '}
                {collEvent.attributes ? collEvent.attributes.name : ''}{' '}
              </div>
              <div>
                <b> {I18n.t('musit.objectModule.collectingEvent.eventDate')}:</b>{' '}
                {collEvent.date && collEvent.date.eventDateFrom
                  ? collEvent.date.eventDateFrom
                  : ''}{' '}
              </div>
              <div>
                <b> {I18n.t('musit.objectModule.collectingEvent.persons')}:</b>{' '}
                {collEvent.relatedActors
                  ? collEvent.relatedActors.reduce(
                      (s: string, c: OutActorAndRelation) =>
                        `${s === '' ? '' : '; '} ${c.currentActorName}`,
                      ''
                    )
                  : ''}
              </div>
              <div>
                <b> {I18n.t('musit.objectModule.place.admCenter')}:</b>{' '}
                {collEvent.place && collEvent.place.admPlace
                  ? collEvent.place.admPlace.name
                  : ''}{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const SearchParam = (props: ParamProps) => (
  <div className="row" style={{ marginTop: 10 }}>
    <div className="col-md-2 text-right col-form-label font-weight-bold">
      {I18n.t(`musit.eventsearch.${props.id}.label`)}
    </div>

    <div className="col-md-8">
      <input
        id={'search-' + props.id}
        className="form-control border-primary"
        type="text"
        value={props.value}
        placeholder={I18n.t(`musit.eventsearch.${props.id}.placeHolder`)}
        onChange={e => {
          localStorage.setItem(props.id, e.target.value || '');
          props.onChange(props.id, e.target.value);
        }}
      />
    </div>
  </div>
);

const CollectingEventSearchComponent = ({
  searchResultData,
  history,
  searchParams,
  updateSearchParams,
  onSearch,
  clearSearch
}: {
  searchResultData: CollectingEventSearchResult[];
  history: H.History;
  searchParams: SearchParams;
  updateSearchParams: React.Dispatch<React.SetStateAction<SearchParams>>;
  onSearch: () => void;
  clearSearch: () => void;
}) => {
  const onChange = (name: string, value: string) => {
    updateSearchParams((p: SearchParams) => ({ ...p, [name]: value }));
  };
  const appSession = React.useContext(AppSessionContext);
  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col">
            <NavigateSearch
              appSession={appSession}
              history={history.push}
              disableCollectingEvent={true}
              activeNav={'musit.objectModule.collectingEvent.collectingEvent'}
            />
          </div>
        </div>
        <h1>{I18n.t('musit.objectModule.collectingEvent.search')}</h1>
        <SearchParam id="name" value={searchParams['name'] || ''} onChange={onChange} />
        <SearchParam id="date" value={searchParams['date'] || ''} onChange={onChange} />
        <SearchParam
          id="person"
          value={searchParams['person'] || ''}
          onChange={onChange}
        />
        <SearchParam id="place" value={searchParams['place'] || ''} onChange={onChange} />

        <div className="row" style={{ marginTop: '10px' }}>
          <div className="offset-md-2 col-md-8">
            <button
              id="executeSearch"
              className="btn btn-primary float-right"
              type="submit"
              onClick={e => {
                e.preventDefault();
                onSearch();
              }}
            >
              {I18n.t('musit.texts.search')} <FontAwesome name="search" />
            </button>
            <button
              className="btn btn-outline-primary mr-2 float-right"
              onClick={e => {
                e.preventDefault();
                clearSearch();
              }}
            >
              {I18n.t('musit.texts.clearSearch')}
            </button>
          </div>
        </div>

        <div className="container" style={{ marginTop: 50 }}>
          {searchResultData.map((row, index) => (
            <CollectingEventResultHit
              key={'CollectingEventResultHit' + index}
              collEvent={row}
              onClickHeader={onClickHeader}
              history={history}
              index={index}
            />
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CollectingEventSearchComponent;
