import * as React from 'react';
import { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import { flatten, uniq, uniqBy, find, orderBy } from 'lodash';
import { I18n } from 'react-i18nify';
import { TODO, MUSTFIX } from '../../../types/common';
import { Group } from '../../../types/appSession';
import { Actor } from '../../../types/actor';
import './UserAccount.css';

interface MusitUserAccountProps {
  token: string;
  actor: Actor;
  groups: Group[];
  selectedMuseumId: number;
  selectedCollectionId?: string;
  handleLogout: Function;
  handleLanguage: Function;
  handleMuseumId: Function;
  handleCollectionId: Function;
}

/* Old: 
  static propTypes = {
    token: PropTypes.string.isRequired,
    actor: PropTypes.object.isRequired,
    groups: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedMuseumId: PropTypes.number,
    selectedCollectionId: PropTypes.string,
    handleLogout: PropTypes.func.isRequired,
    handleLanguage: PropTypes.func.isRequired,
    handleMuseumId: PropTypes.func.isRequired,
    handleCollectionId: PropTypes.func.isRequired
  };

*/

export default class MusitUserAccount extends Component<MusitUserAccountProps> {
  getCollections(mid: number, groups: Group[]) {
    const collections = uniqBy(
      flatten(groups.filter(g => g.museumId === mid).map(g => g.collections)),
      c => c.uuid
    );
    return orderBy(collections, ['name'], ['asc']);
  }

  adminLink() {
    const { token } = this.props;
    return (
      <button
        className="dropdown-item"
        onClick={() => (document.getElementById('userGroupAdmin') as MUSTFIX).submit()}
      >
        <form
          id={'userGroupAdmin'}
          method={'POST'}
          encType={'application/x-www-form-urlencoded'}
          action={'/service_auth/web'}
          target="blank"
        >
          <input hidden={true} name="_at" readOnly={true} value={token} />
        </form>
        <i className="fa fa-cogs" /> Admin
      </button>
    );
  }

  render() {
    const currentLanguage = localStorage.getItem('language');
    const checked = (language: TODO) =>
      currentLanguage === language && <FontAwesome name="check" />;

    const menuText = (t1: TODO, t2: TODO) => (
      <div className="row">
        <div className="col-md-1 col-sm-1 col-xs-1">{t1}</div>
        <div className="col-md-1 col-sm-1 col-xs-1">{t2}</div>
      </div>
    );
    const groups = this.props.groups;
    const selectedMuseumId = this.props.selectedMuseumId;
    const collectionId = this.props.selectedCollectionId;
    const museumDropDown = groups.length > 1 && groups[0].museumName;
    const collectionDropdown = groups.length > 0 && groups[0].collections.length > 0;
    const collections = collectionDropdown
      ? this.getCollections(selectedMuseumId, groups)
      : [];
    const hasAdmin = !!find(groups, g => {
      return g.permission >= 40;
    });

    return (
      <div className="dropdown" data-toogle="tooltip" title="Brukerdata/userdata">
        <button
          className="btn btn-outline-secondary userDataButton border-0 dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <FontAwesome name="user" size="lg" />{' '}
        </button>
        <div
          className="dropdown-menu dropdown-menu-right scrollableDropdownMenu pr-4"
          aria-labelledby="dropdownMenuButton"
        >
          {' '}
          {museumDropDown && (
            <h6 className="dropdown-header">{I18n.t('musit.userProfile.museum')}</h6>
          )}
          {museumDropDown &&
            uniq(groups.map(g => g.museumId)).map((museumId, i) => {
              const defaultCollectionId = this.getCollections(museumId, groups)[0].uuid;
              return (
                <button
                  className="dropdown-item"
                  type="button"
                  key={'museum-' + i}
                  onClick={() => this.props.handleMuseumId(museumId, defaultCollectionId)}
                >
                  {menuText(
                    selectedMuseumId === museumId ? <FontAwesome name="check" /> : '',
                    I18n.t(`musit.userProfile.museums.${museumId}`)
                  )}
                </button>
              );
            })}
          {museumDropDown && <div className="dropdown-divider" />}
          {collectionDropdown && (
            <h6 className="dropdown-header">{I18n.t('musit.userProfile.collection')}</h6>
          )}
          {collectionDropdown && collections ? (
            collections.map((cc, i: number) => (
              <button
                className="dropdown-item"
                type="button"
                key={'collection' + i}
                onClick={() => this.props.handleCollectionId(cc.uuid)}
              >
                {menuText(
                  collectionId === cc.uuid ? <FontAwesome name="check" /> : '',
                  I18n.t(`musit.userProfile.collections.${cc.uuid}`)
                )}
              </button>
            ))
          ) : (
            <div />
          )}
          {collectionDropdown && <div className="dropdown-divider" />}
          <h6 className="dropdown-header">{I18n.t('musit.language')}</h6>
          <button
            className="dropdown-item"
            onClick={() => this.props.handleLanguage('no')}
          >
            {menuText(checked('no'), 'NO')}
          </button>
          <button
            className="dropdown-item"
            onClick={() => this.props.handleLanguage('en')}
          >
            {menuText(checked('en'), 'EN')}
          </button>{' '}
          {hasAdmin && <div className="dropdown-divider" />}
          {hasAdmin && this.adminLink()}
          <div className="dropdown-divider" />
          <button
            className="dropdown-item"
            type="button"
            onClick={this.props.handleLogout as TODO}
          >
            {I18n.t('musit.userProfile.logout')}
          </button>
        </div>
      </div>
    );
  }
}
