import * as React from 'react';
import { get } from '../../../shared/ajaxFetch/ajaxFetch';
import { AppSessionContext } from '../../app/AppComponent';
import config from '../../../config';
import { PlaceProps } from './PlaceComponent';

export const escapeRegExp = (str = '') => str.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

const Highlight = ({ search = '', children = '' }) => {
  const patt = new RegExp(`(${escapeRegExp(search)})`, 'i');
  const parts = String(children).split(patt);

  if (search) {
    return (
      <React.Fragment>
        {parts.map((part, index) =>
          patt.test(part) ? <mark key={index}>{part}</mark> : part
        )}
      </React.Fragment>
    );
  } else {
    return <React.Fragment>{children}</React.Fragment>;
  }
};

const PlaceSuggestion = (p: PlaceProps) => {
  const appSession = React.useContext(AppSessionContext);
  const [inputState, changeInputState] = React.useState<string>('');
  const [suggestions, setSuggestions] = React.useState<any[]>([]);
  const [dropDownOpen, setDropDownOpen] = React.useState(false);

  const dropdown = React.useRef<HTMLUListElement>(null);

  // listen to all clicks on the page (use for closing dropdown)
  React.useEffect(() => {
    const handleClick = (e: any) => {
      const isDropDownClicked: boolean =
        (dropdown && dropdown.current && dropdown.current.contains(e.target)) === true;
      setDropDownOpen(isDropDownClicked); // if clicked outside of the person dropdown, close dropdown
    };
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  // call this function when "add person" field is typed in
  const handleAddChange = async (query: string) => {
    // query API
    changeInputState(query);
    const suggestionsRequest = await get(
      config.api.places.searchPlacesURL(query),
      appSession.accessToken
    );
    let data = await suggestionsRequest.json();
    // get the resultset and then make the dropdown
    if (data.error) data = []; // if error response, just return emtpy array
    if (Array.isArray(data)) {
      setSuggestions(data);
    } else {
      setSuggestions([]);
    }
    setDropDownOpen(true);
  };

  // call this function when a person is clicked on
  const handleAddClick = async (element: placesSearchResult) => {
    // get the resultset and then make the dropdown
    const placeResult = await get(
      config.api.places.getPlace(element.place_uuid),
      appSession.accessToken
    );
    let data = await placeResult.json();
    // get the resultset and then make the dropdown
    if (data.error) data = []; // if error response, just return emtpy array
    p.updatePlace(data);
    changeInputState('');
    setSuggestions([]);
  };
  type placesSearchResult = {
    concatplace: string;
    place_uuid: string;
  };
  const replaceRegex = new RegExp(escapeRegExp(inputState), 'g');
  return (
    <React.Fragment>
      <input
        id="placeSearch"
        autoComplete="off"
        className="form-control"
        value={inputState}
        onChange={e => handleAddChange(e.target.value)}
      />
      <ul
        className="dropdown-menu"
        ref={dropdown}
        style={{
          display: suggestions.length && dropDownOpen ? 'block' : 'none',
          marginLeft: 16
        }}
      >
        {suggestions.map((element: placesSearchResult, i: number) => (
          <li onClick={e => handleAddClick(element)} key={'sugg-' + i}>
            <a role="menuitem" href="#/">
              <Highlight search={inputState}>
                {element.concatplace.replace(replaceRegex, inputState)}
              </Highlight>
            </a>
          </li>
        ))}
      </ul>
    </React.Fragment>
  );
};
export default PlaceSuggestion;
