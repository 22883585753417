/*
 *  MUSIT is a museum database to archive natural and cultural history data.
 *  Copyright (C) 2016  MUSIT Norway, part of www.uio.no (University of Oslo)
 *
 *  This program is free software; you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation; either version 2 of the License,
 *  or any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License along
 *  with this program; if not, write to the Free Software Foundation, Inc.,
 *  51 Franklin Street, Fifth Floor, Boston, MA 02110-1301 USA.
 */
import * as React from 'react';
import { Component } from 'react';
import { MusitTextArea } from '../../../components/formfields';

interface ObservationDoubleTextAreaComponentProps {
  leftLabel: string;
  leftValue: string;
  leftTooltip: string;
  leftPlaceHolder: string;
  leftWidth: number;
  onChangeLeft: Function;
  rightLabel: string;
  rightValue: string;
  rightTooltip: string;
  rightPlaceHolder: string;
  rightWidth: number;
  onChangeRight: Function;
  disabled?: boolean;
}
/*#OLD
static propTypes = {
    leftLabel: PropTypes.string.isRequired,
    leftValue: PropTypes.string.isRequired,
    leftTooltip: PropTypes.string.isRequired,
    leftPlaceHolder: PropTypes.string.isRequired,
    leftWidth: PropTypes.number.isRequired,
    onChangeLeft: PropTypes.func.isRequired,
    rightLabel: PropTypes.string.isRequired,
    rightValue: PropTypes.string.isRequired,
    rightTooltip: PropTypes.string.isRequired,
    rightPlaceHolder: PropTypes.string.isRequired,
    rightWidth: PropTypes.number.isRequired,
    onChangeRight: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  };

*/

export default class ObservationDoubleTextAreaComponent extends Component<
  ObservationDoubleTextAreaComponentProps
> {
  static defaultProps: Partial<ObservationDoubleTextAreaComponentProps> = {
    leftValue: '',
    rightValue: ''
  };

  render() {
    return (
      <div className="row">
        <div className={`col-xs-12 col-sm-${this.props.leftWidth}`}>
          <label>
            <b>
              {this.props.leftLabel}
              {!this.props.disabled ? <span style={{ color: 'red' }}>*</span> : ''}
            </b>
          </label>
          <MusitTextArea
            value={this.props.leftValue}
            tooltip={this.props.leftTooltip}
            placeHolder={!this.props.disabled ? this.props.leftPlaceHolder : ''}
            onChange={this.props.onChangeLeft}
            disabled={this.props.disabled}
            validate={'text'}
            maximumLength={100}
            numberOfRows={5}
          />
        </div>
        <div className={`col-xs-12 col-sm-${this.props.rightWidth}`}>
          <label>
            <b>{this.props.rightLabel}</b>
          </label>
          <MusitTextArea
            value={this.props.rightValue}
            tooltip={this.props.rightTooltip}
            placeHolder={!this.props.disabled ? this.props.rightPlaceHolder : ''}
            onChange={this.props.onChangeRight}
            disabled={this.props.disabled}
            validate={'text'}
            maximumLength={250}
            numberOfRows={5}
          />
        </div>
      </div>
    );
  }
}
