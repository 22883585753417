export const stadiumListX = [
  {
    code: '',
    term: '--select--'
  },
  {
    code: 'e',
    term: 'egg'
  },
  {
    code: 'l',
    term: 'larvae'
  },
  {
    code: 'p',
    term: 'pupae'
  },
  {
    code: 'x',
    term: 'pupae exuviae'
  },
  {
    code: 'i',
    term: 'imago'
  },
  {
    code: 's',
    term: 'subimago'
  },
  {
    code: 'j',
    term: 'juvenile'
  },
  {
    code: 'n',
    term: 'nymph'
  },
  {
    code: 'a',
    term: 'adult'
  },
  {
    code: 'u',
    term: 'unknown'
  }
];

export const sexListX = [
  {
    code: '',
    term: '--select--'
  },
  {
    code: 'm',
    term: 'male'
  },
  {
    code: 'f',
    term: 'female'
  },
  {
    code: 'h',
    term: 'hermaphrodite'
  },
  {
    code: 'd',
    term: 'doubtful gender'
  },
  {
    code: 'u',
    term: 'unknown'
  }
];

export const sexList = [
  {
    sexId: 0,
    sexNo: '--velg--',
    sexEn: '--select--'
  },
  {
    sexId: 1,
    sexNo: 'han',
    sexEn: 'male'
  },
  {
    sexId: 2,
    sexNo: 'hun',
    sexEn: 'female'
  },
  {
    sexId: 3,
    sexNo: 'hermafroditt',
    sexEn: 'hermaphrodite'
  },
  {
    sexId: 4,
    sexNo: 'tvilsomt kjønn',
    sexEn: 'doubtful gender'
  },
  {
    sexId: 5,
    sexNo: 'ukjent',
    sexEn: 'unknown'
  }
];

export const stadiumList = [
  {
    stageId: 0,
    stageNo: '--velg--',
    stageEn: '--select--'
  },
  {
    stageId: 1,
    stageNo: 'egg',
    stageEn: 'egg'
  },
  {
    stageId: 2,
    stageNo: 'larve',
    stageEn: 'larvae'
  },
  {
    stageId: 3,
    stageNo: 'puppe',
    stageEn: 'pupae'
  },
  {
    stageId: 4,
    stageNo: 'pupae exuviae',
    stageEn: 'pupae exuviae'
  },
  {
    stageId: 5,
    stageNo: 'imago',
    stageEn: 'imago'
  },
  {
    stageId: 6,
    stageNo: 'subimago',
    stageEn: 'subimago'
  },
  {
    stageId: 7,
    stageNo: 'juvenile',
    stageEn: 'juvenile'
  },
  {
    stageId: 8,
    stageNo: 'nymph',
    stageEn: 'nymph'
  },
  {
    stageId: 9,
    stageNo: 'voksen',
    stageEn: 'adult'
  },
  {
    stageId: 10,
    stageNo: 'ukjent',
    stageEn: 'unknown'
  }
];
