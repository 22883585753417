import * as React from 'react';
import { I18n } from 'react-i18nify';
import { Synonym } from '../types/person/personName';
import { ActorUuid } from '../types/person/personName';
type Props = {
  onChangeText: (fieldName: string) => (value: string) => void;
  onChangePersonStateWithName: (
    fieldName: string
  ) => (value: string, nameValue: string) => void;
  firstName?: string;
  lastName?: string;
  title?: string;
  name: string;
  displayNameOnly: boolean;
  addSynonym?: () => void;
  uuid: ActorUuid;
  id: string;
};

type NameStatus = 1 | 2 | 3;
export const NoneDisabled: NameStatus = 1;
export const NameDisabled: NameStatus = 2;
export const TitleLastFirstDisabled: NameStatus = 3;

export const createNameFromTitleFirstLast = (pn: {
  title?: string;
  firstName?: string;
  lastName?: string;
}) => (fieldName: string, value: string) => {
  const tmp = { ...pn, [fieldName]: value };
  const retur =
    (tmp.lastName || '') +
    (tmp.title ? ', ' + tmp.title : '') +
    (tmp.firstName ? (tmp.title ? ' ' + tmp.firstName : ', ' + tmp.firstName) : '');
  return retur || '';
};

export const fixInitials = (initials: string) => {
  const r = initials.replace(/\s*\.\s*/g, '.');
  return r;
};

export const titleLastFirstHasValue = ({
  firstName,
  lastName,
  title
}: {
  firstName?: string;
  lastName?: string;
  title?: string;
}): boolean => {
  const titleHasValue = title && title !== '' ? true : false;
  const firstNameHasValue = firstName && firstName !== '' ? true : false;
  const lastNameHasValue = lastName && lastName !== '' ? true : false;
  return titleHasValue || firstNameHasValue || lastNameHasValue;
};

export const calculateNameStatus = (props: Synonym): NameStatus => {
  const nameHasValue = props.name !== '';
  if (!titleLastFirstHasValue(props) && !nameHasValue) {
    return NoneDisabled;
  } else if (titleLastFirstHasValue(props)) {
    return NameDisabled;
  } else if (nameHasValue) {
    return TitleLastFirstDisabled;
  } else {
    return NameDisabled;
  }
};

export const PersonName = (props: Props) => {
  const nameStatus = calculateNameStatus(props);
  return (
    <React.Fragment>
      {props.displayNameOnly || (
        <React.Fragment>
          <div className="col-md-1">
            <label htmlFor="title">{I18n.t('musit.objectModule.person.title')}</label>
            <input
              id={`title${props.id}`}
              className="form-control"
              type="text"
              disabled={nameStatus === TitleLastFirstDisabled}
              value={props.title || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const name = createNameFromTitleFirstLast({
                  firstName: props.firstName,
                  title: props.title,
                  lastName: props.lastName
                })('title', e.target.value);
                props.onChangePersonStateWithName('title')(e.target.value, name);
              }}
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="firsName">
              {I18n.t('musit.objectModule.person.firstName')}
            </label>
            <input
              id={`firstName${props.id}`}
              type="text"
              disabled={nameStatus === TitleLastFirstDisabled}
              className="form-control"
              value={props.firstName || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const firstName = fixInitials(e.target.value);
                const name = createNameFromTitleFirstLast({
                  firstName: firstName,
                  title: props.title,
                  lastName: props.lastName
                })('firstName', e.target.value);
                props.onChangePersonStateWithName('firstName')(firstName, name);
              }}
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="lastName">
              {I18n.t('musit.objectModule.person.lastName')}
            </label>
            <input
              id={`lastName${props.id}`}
              type="text"
              disabled={nameStatus === TitleLastFirstDisabled}
              className="form-control"
              value={props.lastName || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const name = createNameFromTitleFirstLast({
                  firstName: props.firstName,
                  title: props.title,
                  lastName: props.lastName
                })('lastName', e.target.value);
                props.onChangePersonStateWithName('lastName')(e.target.value, name);
              }}
            />
          </div>
        </React.Fragment>
      )}

      <div className="col-md">
        <label htmlFor="name">{I18n.t('musit.objectModule.person.name')}</label>
        <input
          id={`name${props.id}`}
          type="text"
          disabled={nameStatus === NameDisabled}
          className="form-control"
          value={props.name || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const name = fixInitials(e.target.value);
            props.onChangeText('name')(name);
          }}
        />
      </div>
    </React.Fragment>
  );
};
export default PersonName;
