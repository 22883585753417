import * as React from 'react';
import { I18n } from 'react-i18nify';
import Methods from './mockdata/methods';
import { OutPlace as Place } from '../types/place/place';
import { capitalize } from 'lodash';
import { InputCoordinateAttribute as CoordinateAttribute } from '../types/place/place';
import '../css/Compact.css';
import { ShowComponent } from '../components/showComponent/ShowComponent';

export const PlaceViewComponent = (props: {
  place: Place;
  methodId: number | undefined;
}) => {
  const pathArray =
    props.place.admPlace && props.place.admPlace.path
      ? props.place.admPlace.path.split(':')
      : undefined;
  const admPlaceName = pathArray ? pathArray[pathArray.length - 1] : '';
  const country = pathArray ? pathArray[3] : undefined;
  const fylke = pathArray ? pathArray[4] : undefined;
  const kommune = pathArray ? pathArray[5] : undefined;
  const admPlaceString = `${country || ''}${fylke ? ': ' + fylke : ''} ${
    kommune ? ': ' + kommune : ''
  } ${admPlaceName && admPlaceName !== kommune ? ': (' + admPlaceName + ')' : ''}`;

  const altitude = props.place.coordinateAttributes
    ? generateAltitudeString(
        props.place.coordinateAttributes,
        I18n.t('musit.objectModule.shared.from'),
        I18n.t('musit.objectModule.shared.to')
      )
    : '';
  let depth = props.place.coordinateAttributes
    ? generateDepthString(
        props.place.coordinateAttributes,
        I18n.t('musit.objectModule.shared.from'),
        I18n.t('musit.objectModule.shared.to')
      )
    : '';

  let coordinateString = props.place.coordinate
    ? props.place.coordinate.coordinateString
    : '';
  coordinateString =
    props.place.coordinateAttributes && props.place.coordinateAttributes.coordinateCa
      ? ' Ca. ' + coordinateString
      : coordinateString;
  coordinateString =
    props.place.coordinateAttributes && props.place.coordinateAttributes.addedLater
      ? '[ ' + coordinateString + ' ]'
      : coordinateString;
  if (
    props.place.coordinate &&
    (props.place.coordinate.coordinateType === 'MGRS' ||
      props.place.coordinate.coordinateType === 'UTM')
  ) {
    coordinateString =
      props.place.coordinate.zone && props.place.coordinate.band
        ? props.place.coordinate.zone + props.place.coordinate.band + coordinateString
        : coordinateString;
  }

  if (props.place.coordinate && props.place.coordinate.coordinateType === 'LAT/LONG') {
    coordinateString =
      props.place.coordinate.zone && props.place.coordinate.band
        ? props.place.coordinate.zone + props.place.coordinate.band + coordinateString
        : coordinateString;
  }

  const datumStr = props.place.coordinate ? props.place.coordinate.datum : '';
  const coordType = props.place.coordinate ? props.place.coordinate.coordinateType : '';

  const methodString: string = props.methodId
    ? findAndCapitalizeMethod(props.methodId)
    : '';

  const coordStrLabel = coordType ? (
    <span>
      {coordType}
      <sub>{datumStr}</sub>
    </span>
  ) : (
    ''
  );

  return (
    <div className="container-fluid">
      <form className="form">
        <div className="form-row narrow">
          <ShowComponent
            label={I18n.t('musit.objectModule.place.admCenter')}
            data={admPlaceString}
            width={3}
            id={'idAdmPlace'}
          />
          <ShowComponent
            label={I18n.t('musit.objectModule.place.locality')}
            data={props.place.attributes ? props.place.attributes.locality : ''}
            width={4}
            id={'idLocality'}
          />
          <ShowComponent
            label={I18n.t('musit.objectModule.place.ecology')}
            data={props.place.attributes ? props.place.attributes.ecology : ''}
            width={4}
            id={'idEcology'}
          />
        </div>
        <div className="form-row narrow">
          <ShowComponent
            label={I18n.t('musit.objectModule.place.station')}
            data={props.place.attributes ? props.place.attributes.station : ''}
            width={1}
            id={'idStation'}
          />
          <ShowComponent
            label={I18n.t('musit.objectModule.place.sample')}
            data={props.place.attributes ? props.place.attributes.sample : ''}
            width={1}
            id={'idSample'}
          />
          <ShowComponent
            label={I18n.t('musit.objectModule.place.ship')}
            data={props.place.attributes ? props.place.attributes.ship : ''}
            width={1}
            id={'idShip'}
          />
          <ShowComponent
            label={I18n.t('musit.objectModule.collectingEvent.method')}
            data={methodString}
            width={2}
            id={'idMethod'}
          />
          <ShowComponent
            label={I18n.t('musit.objectModule.place.coordinateSource')}
            data={
              props.place.coordinateAttributes
                ? props.place.coordinateAttributes.coordinateSource
                : ''
            }
            width={2}
            id={'idCoordSource'}
          />
          {props.place.coordinate &&
            props.place.coordinate.coordinateType === 'LAT/LONG' && (
              <ShowComponent
                label={I18n.t('musit.objectModule.place.geometry')}
                data={
                  props.place.coordinate ? props.place.coordinate.coordinateGeometry : ''
                }
                width={2}
                id={'idGeometry'}
              />
            )}
          <ShowComponent
            label={coordStrLabel}
            data={coordinateString}
            width={4}
            id={'idCoordStr'}
          />
        </div>
        <div className="form-row narrow">
          <ShowComponent
            label={I18n.t('musit.objectModule.place.precision')}
            data={
              props.place.coordinateAttributes
                ? props.place.coordinateAttributes.precision
                : ''
            }
            width={1}
            id={'idPrecision'}
          />
          <ShowComponent
            label={I18n.t('musit.objectModule.place.accuracy')}
            data={
              props.place.coordinateAttributes
                ? props.place.coordinateAttributes.gpsAccuracy
                : ''
            }
            width={2}
            id={'idAccuracy'}
          />
          <ShowComponent
            label={I18n.t('musit.objectModule.place.altitude')}
            data={altitude}
            width={4}
            id={'idAltitude'}
          />
          <ShowComponent
            label={I18n.t('musit.objectModule.place.depth')}
            data={depth}
            width={4}
            id={'idDepth'}
          />
        </div>
      </form>
    </div>
  );
};

export const generateAltitudeString = (
  coordinateAttributes: CoordinateAttribute,
  fromLabel: string,
  toLabel: string
): string => {
  let altitude: string =
    coordinateAttributes.altitudeFrom !== undefined &&
    !isNaN(coordinateAttributes.altitudeFrom)
      ? coordinateAttributes.altitudeFrom.toString()
      : '';
  altitude =
    coordinateAttributes.altitudeTo !== undefined &&
    !isNaN(coordinateAttributes.altitudeTo)
      ? fromLabel +
        ' : ' +
        altitude +
        '    ' +
        toLabel +
        ' : ' +
        coordinateAttributes.altitudeTo.toString()
      : altitude;
  altitude =
    altitude && coordinateAttributes.altitudeUnit !== undefined
      ? altitude + ' ' + coordinateAttributes.altitudeUnit
      : altitude;
  altitude = altitude && coordinateAttributes.altitudeCa ? ' Ca. ' + altitude : altitude;
  return altitude;
};

export const generateDepthString = (
  coordinateAttributes: CoordinateAttribute,
  fromLabel: string,
  toLabel: string
): string => {
  let depth: string =
    coordinateAttributes.depthFrom !== undefined && !isNaN(coordinateAttributes.depthFrom)
      ? coordinateAttributes.depthFrom.toString()
      : '';
  depth =
    coordinateAttributes.depthTo !== undefined && !isNaN(coordinateAttributes.depthTo)
      ? fromLabel +
        ' : ' +
        depth +
        '    ' +
        toLabel +
        ' : ' +
        coordinateAttributes.depthTo.toString()
      : depth;
  depth =
    depth && coordinateAttributes.depthUnit !== undefined
      ? depth + ' ' + coordinateAttributes.depthUnit
      : depth;
  depth = depth && coordinateAttributes.depthCa ? ' Ca. ' + depth : depth;
  return depth;
};

const findAndCapitalizeMethod = (findMethodId: number) =>
  capitalize(
    Methods.filter(({ methodId }: { methodId: number }) => methodId === findMethodId)
      .map(({ method }: { method: string }) => method)
      .join()
  );

export default PlaceViewComponent;
