/* eslint-disable jsx-a11y/anchor-is-valid */
// @flow
import * as React from 'react';
import { I18n } from 'react-i18nify';
import {
  Samples,
  SampleData,
  SampleStatus,
  SampleStatusData
} from '../../../types/samples';
import { SampleTypesObject } from '../../../types/sample';
import FontAwesome from 'react-fontawesome';
import { AppSession } from '../../../types/appSession';
import { ObjectData } from '../../../types/object';
import { flattenSample } from '../shared/types';
import { omit } from 'lodash';
import { getSampleType, getSampleSubType } from '../shared/types';
import { Maybe, TODO } from '../../../types/common';
import { PicklistData } from '../../../types/picklist';
import { Table, Tr, Td } from 'reactable';

type Props = {
  samples: Samples;
  onClick: Function;
  pickObject: Function;
  isItemAdded: Function;
  pickList: PicklistData;
  appSession: AppSession;
  objectData: ObjectData;
  sampleTypes: SampleTypesObject;
  sampleStatus: SampleStatus;
};

const pickObjectParams = (
  sampleData: SampleData,
  appSession: AppSession,
  objectData: ObjectData,
  sampleTypes: SampleTypesObject
) => {
  return {
    object: flattenSample(
      appSession,
      sampleTypes.sampleTypes,
      omit(objectData, 'sampleData') as TODO,
      sampleData
    )
  };
};
const getSampleStatus = (
  sampleStatus: SampleStatus,
  statusId: number,
  appSession: AppSession
) => {
  if (sampleStatus && statusId) {
    const sampleStatusFound: Maybe<SampleStatusData> = sampleStatus.find(
      f => f.id === statusId
    );
    if (sampleStatusFound) {
      return appSession.language.isEn
        ? sampleStatusFound.enStatus
        : sampleStatusFound.noStatus;
    }
  }
  return '';
};

const SampleTableComponent = ({
  samples,
  onClick,
  pickObject,
  isItemAdded,
  pickList,
  appSession,
  objectData,
  sampleTypes,
  sampleStatus
}: Props) => {
  return (
    <div>
      {/*       <table className="table table-hover table-inverse table-responsive">
        <thead>
          <th key="sampleNum">
            {I18n.t('musit.objects.objectsView.samples.sampleNumber')}
          </th>
          <th key="registeredDate">{I18n.t('musit.objects.objectsView.samples.date')}</th>
          <th key="sampleType">
            {I18n.t('musit.objects.objectsView.samples.sampleType')}
          </th>
          <th key="sampsampleSubTypeleNum">
            {I18n.t('musit.objects.objectsView.samples.sampleSubType')}
          </th>
          <th key="status">{I18n.t('musit.objects.objectsView.samples.status')}</th>
          <th key="hasAnalyse">{I18n.t('musit.objects.objectsView.samples.analysis')}</th>
          <th key="storageMedium">
            {I18n.t('musit.objects.objectsView.samples.storageMedia')}
          </th>
          <th key="add">
            <a
              href=""
              onClick={e => {
                e.preventDefault();
                samples.forEach(obj =>
                  pickObject(pickObjectParams(obj, appSession, objectData, sampleTypes))
                );
              }}
              title={I18n.t('musit.objectsearch.addAllToPickList')}
            >
              <FontAwesome style={{ fontSize: '1.3em' }} name="shopping-cart" />
            </a>
          </th>
        </thead>
        <tbody>
          {samples &&
            samples.map((s, i) => (
              <tr key={i} onClick={() => onClick(s)}>
                <td>{s.sampleNum || ''}</td>
                <td>{s.registeredDate || ''}</td>
                <td>{getSampleType(sampleTypes, s.sampleTypeId, appSession) || ''}</td>
                <td>{getSampleSubType(sampleTypes, s.sampleTypeId, appSession) || ''}</td>
                <td>{getSampleStatus(sampleStatus, s.status, appSession) || ''}</td>
                <td>{s.hasAnalyse || ''}</td>
                <td>{s.storageMedium || ''}</td>
                <td>
                  <a
                    onClick={e => {
                      pickObject(
                        pickObjectParams(s, appSession, objectData, sampleTypes)
                      );
                      e.stopPropagation();
                    }}
                    title={I18n.t('musit.objectsearch.addToPickList')}
                  >
                    {isItemAdded({ ...s, id: s.objectId }, pickList.objects) ? (
                      <FontAwesome
                        style={{ fontSize: '1.3em', color: 'Gray' }}
                        name="shopping-cart"
                      />
                    ) : (
                      <FontAwesome style={{ fontSize: '1.3em' }} name="shopping-cart" />
                    )}
                  </a>
                </td>
              </tr>
            ))}
        </tbody>
      </table> */}

      <Table
        className="table table-hover table-inverse table-responsive"
        columns={[
          {
            key: 'sampleNum',
            label: I18n.t('musit.objects.objectsView.samples.sampleNumber')
          },
          {
            key: 'registeredDate',
            label: I18n.t('musit.objects.objectsView.samples.date')
          },
          {
            key: 'sampleType',
            label: I18n.t('musit.objects.objectsView.samples.sampleType')
          },
          {
            key: 'sampleSubType',
            label: I18n.t('musit.objects.objectsView.samples.sampleSubType')
          },
          { key: 'status', label: I18n.t('musit.objects.objectsView.samples.status') },
          {
            key: 'hasAnalyse',
            label: I18n.t('musit.objects.objectsView.samples.analysis')
          },
          {
            key: 'storageMedium',
            label: I18n.t('musit.objects.objectsView.samples.storageMedia')
          },
          {
            key: 'add',
            label: (
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  samples.forEach(obj =>
                    pickObject(pickObjectParams(obj, appSession, objectData, sampleTypes))
                  );
                }}
                title={I18n.t('musit.objectsearch.addAllToPickList')}
              >
                <FontAwesome style={{ fontSize: '1.3em' }} name="shopping-cart" />
              </a>
            )
          }
        ]}
        sortable={['id', 'date']}
        noDataText={I18n.t('musit.samples.noSamplesForObject')}
      >
        {samples &&
          samples.map((s, i) => (
            <Tr key={i} onClick={() => onClick(s)}>
              <Td column="sampleNum">{s.sampleNum || ''}</Td>
              <Td column="registeredDate">{s.registeredDate || ''}</Td>
              <Td column="sampleType">
                {getSampleType(sampleTypes, s.sampleTypeId, appSession) || ''}
              </Td>
              <Td column="sampleSubType">
                {getSampleSubType(sampleTypes, s.sampleTypeId, appSession) || ''}
              </Td>
              <Td column="status">
                {getSampleStatus(sampleStatus, s.status, appSession) || ''}
              </Td>
              <Td column="hasAnalyse">{s.hasAnalyse || ''}</Td>
              <Td column="storageMedium">{s.storageMedium || ''}</Td>
              <Td column="add">
                <a
                  href="#"
                  onClick={e => {
                    pickObject(pickObjectParams(s, appSession, objectData, sampleTypes));
                    e.stopPropagation();
                  }}
                  title={I18n.t('musit.objectsearch.addToPickList')}
                >
                  {isItemAdded({ ...s, id: s.objectId }, pickList.objects) ? (
                    <FontAwesome
                      style={{ fontSize: '1.3em', color: 'Gray' }}
                      name="shopping-cart"
                    />
                  ) : (
                    <FontAwesome style={{ fontSize: '1.3em' }} name="shopping-cart" />
                  )}
                </a>
              </Td>
            </Tr>
          ))}
      </Table>
    </div>
  );
};

export default SampleTableComponent;
