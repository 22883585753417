import * as React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { DATE_FORMAT_DISPLAY } from '../shared/util';
import { formatISOString } from '../shared/util';
import { getTranslator } from '../shared/language';
import 'react-datepicker/dist/react-datepicker.css';

const words = getTranslator({
  en: {
    dateFormat: 'Date format',
    dateFrom: 'Date from',
    dateTo: 'Date to',
    verbatim: 'Verbatim',
    date: 'Date',
    year: 'Year',
    yearMonth: 'Year, month'
  },
  no: {
    dateFormat: 'Datoformat',
    dateFrom: 'Dato fra',
    dateTo: 'Dato til',
    verbatim: 'Verbatim',
    date: 'Dato',
    year: 'År',
    yearMonth: 'År, måned'
  }
});

export const getNow = () => {
  return formatISOString(new Date());
};

interface MusitDatePickerProps {
  dateFormat?: string; //PropTypes.string,
  value?: string | null; //PropTypes.string,
  onChange: Function; // PropTypes.func.isRequired,
  onClear?: Function; //PropTypes.func.isRequired,
  disabled?: boolean; //PropTypes.bool
  defaultValue?: string;
  className?: string;
  placeHolder?: string;
  id?: string;
}
export enum DateFormat {
  YEAR = 4,
  YEAR_MONTH = 7,
  DATE = 10
}
export const DateFormatString = {
  [DateFormat.YEAR]: 'yyyy',
  [DateFormat.YEAR_MONTH]: 'yyyy-MM',
  [DateFormat.DATE]: 'yyyy-MM-dd'
};
export const DateFormatLabel = {
  [DateFormat.YEAR]: 'year',
  [DateFormat.YEAR_MONTH]: 'yearMonth',
  [DateFormat.DATE]: 'date'
};

export interface DateRangeWithVerbatim {
  eventDateFrom?: string | null;
  eventDateTo?: string | null;
  lengthOfFormat: DateFormat;
  eventDateVerbatim?: string;
}
interface MusitCombinedDatePickerProps {
  dateRange: DateRangeWithVerbatim;
  onChange: (dateRange: DateRangeWithVerbatim) => void; // PropTypes.func.isRequired,
  disabled?: boolean;
  className?: string;
  placeHolder?: string;
  id: string;
  renderFunction?: (
    picker: JSX.Element,
    format: JSX.Element,
    verbatim: JSX.Element
  ) => JSX.Element;
}

export const MusitCombinedDatePicker: React.SFC<MusitCombinedDatePickerProps> = props => {
  const dateFrom = props.dateRange.eventDateFrom
    ? new Date(props.dateRange.eventDateFrom)
    : undefined;
  const dateTo = props.dateRange.eventDateTo
    ? new Date(props.dateRange.eventDateTo)
    : undefined;
  const formatOptions = [
    { value: DateFormat.YEAR, label: words[DateFormatLabel[DateFormat.YEAR]] },
    {
      value: DateFormat.YEAR_MONTH,
      label: words[DateFormatLabel[DateFormat.YEAR_MONTH]]
    },
    { value: DateFormat.DATE, label: words[DateFormatLabel[DateFormat.DATE]] }
  ];
  const format: string = DateFormatString[props.dateRange.lengthOfFormat];
  const isNumberRegex = /^\d+$/;
  const datePicker = (
    <React.Fragment>
      <div className="col-md-3 form-group">
        <label htmlFor={props.id}>{words.dateFrom}</label>{' '}
        {/* + ' (' + format.toUpperCase()})</label> */}
        <DatePicker
          id={props.id + 'fromDate'}
          placeholderText={format.toUpperCase()}
          onChange={(date: any | null) => {
            const dateString = date ? moment(date).format(format.toUpperCase()) : '';
            props.onChange({ ...props.dateRange, eventDateFrom: dateString });
          }}
          className={props.className}
          selected={dateFrom}
          isClearable
          disabled={props.disabled}
          dateFormat={format}
          showMonthYearPicker={props.dateRange.lengthOfFormat === DateFormat.YEAR_MONTH}
        />
      </div>
      <div className="col-md-3 form-group">
        <label htmlFor={props.id + 'to'}>
          {words.dateTo} {/* + ' (' + format.toUpperCase()}) */}
        </label>
        <DatePicker
          id={props.id + 'toDate'}
          placeholderText={format.toUpperCase()}
          onChange={(date: any | null) => {
            const dateString = date ? moment(date).format(format.toUpperCase()) : '';
            props.onChange({ ...props.dateRange, eventDateTo: dateString });
          }}
          className={props.className}
          selected={dateTo}
          disabled={props.disabled}
          dateFormat={format}
          isClearable
          showMonthYearPicker={props.dateRange.lengthOfFormat === DateFormat.YEAR_MONTH}
        />
      </div>
    </React.Fragment>
  );

  const yearPicker = (
    <React.Fragment>
      <div className="col-md-3 form-group">
        <label htmlFor={props.id}>
          {words.dateFrom} {/* {words.dateFrom + ' (' + format.toUpperCase() + ')'} */}
        </label>
        <input
          id={props.id + 'fromYear'}
          disabled={props.disabled}
          placeholder={format.toUpperCase()}
          onChange={e => {
            if (isNumberRegex.test(e.target.value) || e.target.value === '') {
              props.onChange({
                ...props.dateRange,
                eventDateFrom: e.target.value
              });
            }
          }}
          value={props.dateRange.eventDateFrom ? props.dateRange.eventDateFrom : ''}
          className={props.className}
        />
      </div>
      <div className="col-md-3 form-group">
        <label htmlFor={props.id + 'to'}>
          {words.dateTo} {/* {words.dateTo + ' (' + format.toUpperCase() + ')'} */}
        </label>
        <input
          id={props.id + 'toYear'}
          disabled={props.disabled}
          placeholder={format.toLocaleUpperCase()}
          onChange={e => {
            if (isNumberRegex.test(e.target.value) || e.target.value === '') {
              props.onChange({
                ...props.dateRange,
                eventDateTo: e.target.value
              });
            }
          }}
          value={props.dateRange.eventDateTo ? props.dateRange.eventDateTo : ''}
          className={props.className}
        />
      </div>
    </React.Fragment>
  );

  const picker =
    props.dateRange.lengthOfFormat === DateFormat.YEAR ? yearPicker : datePicker;

  const dateFormatSelect = (
    <Select
      id={props.id + 'format'}
      className="border border-primary rounded"
      options={formatOptions}
      onChange={(format: any) => {
        const formatString: string = DateFormatString[format.value];
        const strFrom = moment(dateFrom).format(formatString.toUpperCase());
        const strTo = moment(dateTo).format(formatString.toUpperCase());
        const from = dateFrom
          ? { eventDateFrom: strFrom.substr(0, format.value) }
          : { eventDateFrom: undefined };
        const to = dateTo ? { eventDateTo: strTo } : { eventDateTo: undefined };
        props.onChange({
          ...props.dateRange,
          ...from,
          ...to,
          lengthOfFormat: format.value
        });
      }}
      clearable={false}
      value={props.dateRange.lengthOfFormat}
    />
  );
  const verbatimInput = (
    <input
      type="text"
      className={'form-control ' + props.className}
      disabled={props.disabled}
      id={props.id + 'verbatim'}
      onChange={event => {
        props.onChange({
          ...props.dateRange,
          eventDateVerbatim: event.target.value
        });
      }}
      value={props.dateRange.eventDateVerbatim}
      placeholder={words.date}
      style={{ width: '100%', display: 'block' }}
    />
  );

  if (props.renderFunction) {
    return props.renderFunction(picker, dateFormatSelect, verbatimInput);
  } else {
    return (
      <React.Fragment>
        <div className="row">
          {picker}
          <div className="col-md-2 form-group">
            <label htmlFor={props.id + 'format'}>{words.dateFormat}</label>
            {dateFormatSelect}
          </div>
          <div className="col-md-4 form-group">
            <label htmlFor={props.id + 'verbatim'}>{words.verbatim}</label>
            {verbatimInput}
          </div>
        </div>
      </React.Fragment>
    );
  }
};

export const MusitDatePicker: React.SFC<MusitDatePickerProps> = props => {
  const value = props.value ? new Date(props.value) : undefined;
  const dateFormat =
    props.dateFormat === 'YYYY-MM-DD'
      ? 'yyyy-MM-dd'
      : props.dateFormat === 'DD.MM.YYYY'
      ? 'dd.MM.yyyy'
      : props.dateFormat;

  return (
    <DatePicker
      id={props.id}
      dateFormat={dateFormat}
      onChange={(date: any | null) => {
        try {
          if (date === null) {
            props.onClear && props.onClear();
          } else {
            const dd = new Date(date);
            props.onChange(dd);
          }
        } catch (e) {
          console.log('Musit Date Picker:  Error in converting' + e.toString());
        }
      }}
      className={props.className}
      selected={value}
      isClearable
    />
  );
};

export default MusitDatePicker;

MusitDatePicker.defaultProps = {
  dateFormat: DATE_FORMAT_DISPLAY,
  disabled: false
};
