import * as React from 'react';
import { Component } from 'react';
import * as PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import MusitUserAccount from './components/UserAccount';
import './AppComponent.css';
import * as Logo from './musitLogo.png';
import LoginComponent from '../login/LoginComponent';
import { emitError } from '../../shared/errors';
import Loader from 'react-loader';
import {
  loadAppSession$,
  setCollectionId$,
  setMuseumId$,
  setRolesForModules$,
  MusitMatch
} from '../../stores/appSession';
import { RxInjectLegacy as inject } from '../../shared/react-rxjs-patch';
import {
  clearNodes$ as clearNodePicklist$,
  clearObjects$ as clearObjectPicklist$
} from '../../stores/pickList';
import Config from '../../config';
import { backendVersion, frontendVersion, VersionInfo } from '../../build';
import featureToggles, { FeatureToggles } from '../../featureToggles';
import classnames from 'classnames';
import env from '../../shared/environment';
import { clear$ as clearSearchStore$ } from '../../search/searchStore';
import { AppSession } from '../../types/appSession';
import { PicklistData } from '../../types/picklist';
import { TODO } from '../../types/common';
import { appSession } from '../../../src/testutils/sampleDataForTest';
import { Link } from 'react-router-dom';
import { getTranslator } from '../../shared/language';

const about = '/about';

interface AppComponentProps {
  children: object;
  appSession: AppSession;
  setMuseumId: Function;
  setCollectionId: Function;
  loadAppSession: Function;
  setRolesForModules: Function;
  pickList: PicklistData;
  goTo: Function;
  clearObjectPicklist: Function;
  clearNodePicklist: Function;
  featureToggles: FeatureToggles;
  clearSearchStore: Function;
  match: MusitMatch;

  rootNode?: TODO;
}

const words = getTranslator({
  en: {
    createNew: 'New collecting event',
    magazine: 'Storage facility',
    newObject: 'New object',
    pickList: 'Pick lists',
    reports: 'Reports',
    objects: 'Objects',
    numismatic: 'Numismatics'
  },
  no: {
    createNew: 'Ny innsamlingshendelse',
    magazine: 'Magasin',
    newObject: 'Nytt objekt',
    pickList: 'Plukklister',
    reports: 'Rapporter',
    objects: 'Objekter',
    numismatic: 'Numismatikk'
  }
});

export const AppSessionContext = React.createContext(appSession);

export class AppComponent extends Component<AppComponentProps> {
  constructor(props: AppComponentProps, context: TODO) {
    super(props, context);
    this.handleLanguage = this.handleLanguage.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleMuseumId = this.handleMuseumId.bind(this);
    this.handleCollectionId = this.handleCollectionId.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.loadAppSession();
  }

  handleLogout() {
    fetch('/api/auth/rest/logout', {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + this.props.appSession.accessToken
      })
    })
      .then(response => {
        if (response.ok) {
          localStorage.removeItem('accessToken');
          window.location.replace('https://auth.dataporten.no/logout');
        }
      })
      .catch(error => emitError({ type: 'network', error }));
  }

  handleLanguage(l: TODO) {
    localStorage.setItem('language', l);
    window.location.reload(true);
  }

  setMuseumCollectionId(museumId: TODO, collectionId: TODO) {
    localStorage.setItem('museumId', museumId);
    localStorage.setItem('collectionId', collectionId);
  }

  handleMuseumId(museumId: TODO, collectionId: TODO) {
    this.props.setMuseumId(museumId);
    this.props.setCollectionId(collectionId);
    this.props.setRolesForModules({
      email: this.props.appSession.actor.dataportenUser,
      museumId: museumId,
      collectionId: collectionId,
      isGod: this.props.appSession.isGod
    });
    this.props.clearObjectPicklist();
    this.props.clearNodePicklist();
    this.props.clearSearchStore();
    this.setMuseumCollectionId(museumId, collectionId);
    this.props.goTo(about);
  }

  handleCollectionId(collectionId: TODO) {
    this.props.setCollectionId(collectionId);
    this.props.setRolesForModules({
      email: this.props.appSession.actor.dataportenUser,
      museumId: this.props.appSession.museumId,
      collectionId: collectionId,
      isGod: this.props.appSession.isGod
    });
    this.props.clearObjectPicklist();
    this.props.clearSearchStore();
    this.setMuseumCollectionId(this.props.appSession.museumId, collectionId);
    this.props.goTo(about);
  }

  getFooterClass() {
    return classnames('footer', 'fixed-bottom', {
      backgroundUTV: env === 'utv',
      backgroundTEST: env === 'test',
      'version well': env === 'prod'
    });
  }

  isSessionLoaded() {
    return !!this.props.appSession.buildInfo;
  }

  buildVersionLink(versionInfo: VersionInfo) {
    if (versionInfo.url) {
      return (
        <a target="_blank" rel="noopener noreferrer" href={versionInfo.url}>
          {versionInfo.sha}
        </a>
      );
    }
    return versionInfo.sha;
  }

  render() {
    if (!this.props.appSession.accessToken) {
      return <LoginComponent />;
    }

    if (!this.isSessionLoaded()) {
      return <Loader loaded={false} />;
    }

    return (
      <AppSessionContext.Provider value={this.props.appSession}>
        <nav className="navbar navbar-expand navbar-light bg-light fixed-top">
          <div className="container" style={{ paddingLeft: 15, paddingRight: 15 }}>
            <a className="navbar-brand" style={{ paddingLeft: 0 }} href={about}>
              <img height="40" alt="logo" src={Logo} /> MUSIT
            </a>
            <ul className="navbar-nav mr-auto">
              {this.props.appSession.rolesForModules.storageFacilityRead && (
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to={Config.magasin.urls.client.magasin.goToMagasin(
                      this.props.appSession
                    )}
                  >
                    {words.magazine}
                  </Link>
                </li>
              )}

              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={Config.magasin.urls.client.report.goToReport(this.props.appSession)}
                >
                  {words.reports}
                </Link>
              </li>
            </ul>

            <ul className="navbar-nav content-end">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={Config.magasin.urls.client.picklist.goToPicklistObjects(
                    this.props.appSession
                  )}
                >
                  <span className="icon icon-musitpicklistobject" />
                  {this.props.pickList.objects.length}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={Config.magasin.urls.client.picklist.goToPicklistNodes(
                    this.props.appSession
                  )}
                >
                  <span className="icon icon-musitpicklistnode" />
                  {this.props.pickList.nodes.length}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={Config.magasin.urls.client.searchObjects.goToSearchObjects(
                    this.props.appSession
                  )}
                >
                  <FontAwesome name="search" style={{ fontSize: '1.3em', height: 25 }} />
                </Link>
              </li>
              <li className="nav-item">
                <MusitUserAccount
                  actor={this.props.appSession.actor}
                  groups={this.props.appSession.groups}
                  token={this.props.appSession.accessToken}
                  selectedMuseumId={this.props.appSession.museumId}
                  selectedCollectionId={this.props.appSession.collectionId}
                  handleLogout={this.handleLogout}
                  handleLanguage={this.handleLanguage}
                  handleMuseumId={this.handleMuseumId}
                  handleCollectionId={this.handleCollectionId}
                  //?rootNode={this.props.rootNode}
                />
              </li>
            </ul>
          </div>
        </nav>
        <div className="appContent">{this.props.children}</div>
        <footer className={this.getFooterClass()}>
          {'Backend build: '}
          {this.buildVersionLink(backendVersion(this.props.appSession.buildInfo))}
          {' - Client build: '}
          {this.buildVersionLink(frontendVersion())}
          <br />
          {env.toLowerCase() === 'utv' && <a href="/api">API DOCUMENTATION</a>}
        </footer>
      </AppSessionContext.Provider>
    );
  }
}

const props = (props: AppComponentProps) => ({
  ...props,
  featureToggles: featureToggles
});

const data = {
  appSession$: { type: PropTypes.object.isRequired },
  pickList$: { type: PropTypes.object.isRequired }
};

const commands = {
  loadAppSession$,
  setMuseumId$,
  setCollectionId$,
  clearObjectPicklist$,
  clearNodePicklist$,
  clearSearchStore$,
  setRolesForModules$
};

export default inject(data, commands, props)(AppComponent);
