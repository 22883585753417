import * as React from 'react';
import { MuseumAndCollection } from '../types/common';
import FieldMultiSelect from '../components/FieldMultiSelect';
import { I18n } from 'react-i18nify';
import { museumAndCollections, collections, museum } from './data';

type Props = {
  onChange: (m: MuseumAndCollection[]) => void;
  collections: MuseumAndCollection[];
};

export const MuseumAndCollections = (p: Props) => (
  <FieldMultiSelect
    id="collectionsForPerson"
    title={I18n.t('musit.objectModule.person.collectionsForPerson')}
    labelAbove={true}
    singleSelect={false}
    matchProp={'label'}
    removeSelected={false}
    closeOnSelect={true}
    options={museumAndCollections.map(m => {
      const museumRecord = museum.find(e => e.museumId === m.museumId);
      const collectionRecord = collections.find(
        e => e.collectionUUID === m.collectionUuid
      );
      const labelValue = `${museumRecord ? museumRecord.abbreviation : ''}-${
        collectionRecord ? collectionRecord.collectionName : ''
      }`;
      return {
        label: labelValue,
        value: JSON.stringify(m)
      };
    })}
    values={p.collections && p.collections.map(m => JSON.stringify(m))}
    onChange={(event: any) => {
      const v = event.map((v: any) => JSON.parse(v.value));
      p.onChange(v);
    }}
  />
);

export default MuseumAndCollections;
