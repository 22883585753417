import * as React from 'react';
import {
  Result,
  SelectType,
  SelectAbleField,
  AggregationQuery,
  SortableField
} from './types';
import { sortSelectable } from './Main';
import Paging from './Pagination';
import { getTranslator } from '../../../shared/language';
import FontAwesome from 'react-fontawesome';

const words = getTranslator({
  en: {
    search: 'Search',
    numPerPage: 'Hits per page',
    gotoObject: 'Go to object'
  },
  no: {
    search: 'Søk',
    numPerPage: 'Antall treff per side',
    gotoObject: 'Gå til objekt'
  }
});

const selectedResultFields: (resultFields: SelectType[]) => SelectAbleField[] = (
  resultFields: SelectType[]
) => {
  const r = resultFields.reduce(
    (p, c: SelectType) => [...p, ...c.fields.filter(f => f.selected)],
    []
  );
  return r;
};

const ResultGrid = ({
  gotoObject,
  pageSize,
  onChangePageSize,
  currPage,
  changeCurrPage,
  result,
  changeResult,
  resultFields,
  clear,
  onClickRemove,
  gotoPage,
  doSearch,
  sortField,
  onChangeSortOrder
}: {
  pageSize: number;
  sortField: SortableField | null;
  currPage: number;
  onChangeSortOrder: () => void;
  changeCurrPage: React.Dispatch<React.SetStateAction<number>>;
  onChangePageSize: (newPageSize: number) => Promise<void>;
  changeResult: React.Dispatch<React.SetStateAction<Result>>;
  gotoObject: (objectUuid: string) => void;
  gotoPage: (pageNo: number) => Promise<void>;
  result: Result;
  resultFields: SelectType[];
  clear: () => void;
  onClickRemove: (s: SelectAbleField) => void;
  doSearch: (
    pageNo: number,
    pageSize: number,
    resFields: SelectType[],
    withAggregations?: AggregationQuery
  ) => Promise<void>;
}) => {
  const headerFields = selectedResultFields(resultFields);
  const removeFieldFromResult = (i: number) => {
    const newResult = result.objectList
      ? result.objectList.grid.map(r => [...r.slice(0, i), ...r.slice(i + 1)])
      : [];
    const newObjectList = result.objectList
      ? { ...result.objectList, grid: newResult }
      : { pos: 0, count: 0, grid: [] };
    changeResult((r: Result) => ({ ...r, objectList: newObjectList }));
  };

  /*   const changePS = async (ps: string) => {
    const pageSizeAsNumber = Number.parseInt(ps);
    await onChangePageSize(pageSizeAsNumber);
  }; */

  return (
    <div style={{ backgroundColor: '#f2f2f2', paddingTop: '20px' }} className="form-row">
      <div className="col-md-10"></div>
      <div className="col-md-2">
        <button
          type="button"
          className="btn btn-primary search-button"
          onClick={async () => doSearch(1, pageSize, resultFields)}
        >
          {words.search}
        </button>
      </div>

      <br></br>

      <div className="col" style={{ paddingTop: '20px' }}>
        <Paging
          changeCurrPage={changeCurrPage}
          currPage={currPage}
          size={pageSize}
          results={result}
          gotoPage={gotoPage}
        />
      </div>

      <table className="table table-sm">
        <colgroup>
          <col span={1} style={{ width: '10px' }} />
        </colgroup>
        <thead style={{ fontSize: 'smaller' }}>
          <tr key="R-0">
            <th />
            {sortSelectable(headerFields).map((f, ind) => (
              <th key={`hf-${ind}`}>
                {f.label}
                <button
                  type="button"
                  className="btn btn-link btn-sm"
                  onClick={e => {
                    e.preventDefault();
                    onClickRemove(f);
                    removeFieldFromResult(ind);
                  }}
                  aria-label="Close"
                >
                  &times;
                </button>
                {f.value === sortField?.attribute ? (
                  <button
                    type="button"
                    onClick={onChangeSortOrder}
                    className="btn btn-link btn-sm"
                  >
                    {' '}
                    {sortField.sortType === 'desc' ? (
                      <FontAwesome size="2x" name="caret-down" />
                    ) : sortField.sortType === 'asc' ? (
                      <FontAwesome size="2x" name="caret-up" />
                    ) : null}
                  </button>
                ) : null}
              </th>
            ))}
          </tr>
        </thead>
        <tbody style={{ fontSize: 'smaller' }}>
          {result.objectList &&
            result.objectList.grid.length > 1 &&
            result.objectList.grid.slice(1).map((row: string[], i: number) => {
              return (
                <tr key={`R-${i}`}>
                  <td>
                    <button
                      type="button"
                      className="btn btn-link btn-sm"
                      data-toggle="tooltip"
                      title={words['gotoObject']}
                      onClick={e => {
                        e.preventDefault();
                        gotoObject(row[0]);
                      }}
                    >
                      <FontAwesome name="arrow-right" />
                    </button>
                  </td>
                  {row.slice(1).map((data: string, cnum: number) => (
                    <td key={`C-${i}${cnum}`}>{data}</td>
                  ))}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ResultGrid;
