import * as React from 'react';
import { I18n } from 'react-i18nify';
import { ConditionAssessmentProps, ConditionCodeType } from '../../../types/conservation';
import SubEventComponentNote from '../components/subEventComponentNote';
import { AppSession } from '../../../types/appSession';

export default function ConditionAssessment(props: ConditionAssessmentProps) {
  const suffix = ':';
  const getDisplayNameForConditionCode = (
    v: number,
    conditionCodes: Array<ConditionCodeType>,
    appSession: AppSession
  ) => {
    const conditionCodeObj = conditionCodes.find(c => c.conditionCode === v);
    return appSession.language.isEn && conditionCodeObj
      ? conditionCodeObj.enCondition
      : conditionCodeObj
      ? conditionCodeObj.noCondition
      : '';
  };

  const extraAttributes = (
    <div className="form-group row">
      <label className="col-sm-2 h4" htmlFor={`conditionCode_${props.index}`}>
        {I18n.t('musit.conservation.events.conditionAssessment.conditionCode') + suffix}
      </label>
      <div className="col-md-8">
        {props.viewMode ? (
          props.conditionAssessment.conditionCode === null || undefined ? (
            ''
          ) : (
            <div style={{ paddingTop: '8px' }}>
              {getDisplayNameForConditionCode(
                props.conditionAssessment.conditionCode,
                props.conditionCodes,
                props.appSession
              )}
            </div>
          )
        ) : (
          <div className="dropdown" id={`conditionCodeDropwdown-${props.index}`}>
            <button
              type="button"
              className="btn btn-secondary dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {props.conditionAssessment.conditionCode === null || undefined
                ? I18n.t(
                    'musit.conservation.events.conditionAssessment.chooseConditionAssessment'
                  )
                : getDisplayNameForConditionCode(
                    props.conditionAssessment.conditionCode,
                    props.conditionCodes,
                    props.appSession
                  )}
            </button>
            <div className="dropdown-menu">
              {props.conditionCodes &&
                props.conditionCodes.map((c, i) => (
                  <div
                    className="dropdown-item"
                    key={'cond-code-item-' + i}
                    onClick={() => {
                      props.onChange('conditionCode')(c.conditionCode);
                    }}
                  >
                    {props.appSession.language.isEn ? c.enCondition : c.noCondition}
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <SubEventComponentNote
      {...props}
      subEvent={props.conditionAssessment}
      eventName={I18n.t(
        'musit.conservation.events.conditionAssessment.conditionAssessment'
      )}
      noteLabel={I18n.t('musit.conservation.events.conditionAssessment.note')}
      extraAttributes={extraAttributes}
    />
  );
}
