import * as React from 'react';
import { SexAndStagesEvent, SexAndStage } from '../types/event/event';

const ViewGenderAndStages = (props: { sexAndStages: SexAndStagesEvent }) => {
  return (
    <div className="container">
      <table className="table table-sm">
        <thead>
          <tr>
            <th>Gender</th>
            <th>Stage</th>
            <th>Count</th>
            <th>Estimated count</th>
          </tr>
        </thead>
        <tbody>
          {props.sexAndStages.attributes &&
            props.sexAndStages.attributes.sexAndStage &&
            props.sexAndStages.attributes.sexAndStage.map((p: SexAndStage) => (
              <tr>
                <td>{p.sexId}</td>
                <td>{p.stageId}</td>
                <td>{p.count}</td>
                <td>{p.estimated ? 'Yes' : 'No'}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewGenderAndStages;
