const coordinateDatums = [
  {
    datum: 'EUREF89'
  },
  {
    datum: 'WGS84'
  },
  {
    datum: 'ED50'
  }
];
export default coordinateDatums;
