import * as React from 'react';
import AddPersonContainer from './AddPersonContainer';

type Props = {
  actorUuid?: string;
  helpText: string;
  goBackText: string;
  id: string;
  closeModal?: () => void;
};

const ModalComponent = (props: Props) => {
  const AddPerson = AddPersonContainer({
    fromModal: true,
    id: props.id,
    closeModal: props.closeModal
  });
  return (
    <div className="personmodal-background">
      <div className="personmodal-content container">
        <div className="modal-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={props.closeModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="personmodal-body">
          <AddPerson />
        </div>
        <div className="modal-footer">
          <button onClick={props.closeModal} type="button" className="btn btn-primary">
            {props.goBackText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalComponent;
