import * as React from 'react';
import { I18n } from 'react-i18nify';
import { DerivedCoordinate } from '../types/place/place';
import { getTranslator } from '../../../shared/language';

type Props = {
  coordinate?: string;
  datum?: string;
  geometryType: string;
  coordinateType: string;
  validCoordinate: boolean;
  children: any;
  datums: { datum: string }[];
  coordinateTypes: { type_text: string }[];
  geometryTypes: { geometry: string }[];
  updateCoordinate: (fieldName: string) => (value?: string | DerivedCoordinate) => void;
  updateShowMap: () => void;
  onToggleCoordinateFormats: () => void;
  showValidCoordinate: boolean;
  coordinateSideText: JSX.Element;
  disabled?: boolean;
};

const words = getTranslator({
  en: {
    point: 'Point',
    line: 'Line',
    polygon: 'Polygon'
  },
  no: {
    point: 'Punkt',
    line: 'Linje',
    polygon: 'Polygon'
  }
});

const LatLongComponent = (p: Props) => {
  return (
    <div>
      <div className="form-row">
        <div className="col-md-2 offset-md-2">
          <b>{I18n.t('musit.objectModule.place.coordinateType')}</b>
        </div>
        <div className="col-md-2">
          <b>{I18n.t('musit.objectModule.place.coordinateDatum')}</b>
        </div>

        <div className="col-md-2">
          <b>{I18n.t('musit.objectModule.place.geometry')}</b>
        </div>
      </div>
      <div className="form-row" style={{ paddingBottom: '15px' }}>
        <div className="col-md-2" style={{ textAlign: 'right' }}>
          <b>{I18n.t('musit.objectModule.place.coordinateMetadata')}</b>
        </div>
        <div className="col-md-2">
          {' '}
          <select
            className="form-control"
            id="coordinateType"
            value={p.coordinateType}
            disabled={p.disabled}
            onChange={e => {
              p.updateCoordinate('coordinateType')(e.target.value);
            }}
          >
            {p.coordinateTypes ? (
              p.coordinateTypes.map(({ type_text }: { type_text: string }, i: number) => (
                <option key={`optionRow_${i}`} value={type_text}>
                  {type_text}
                </option>
              ))
            ) : (
              <option key={`optionRow_${1}`}>{'No data'}</option>
            )}
          </select>
        </div>
        <div className="col-md-2">
          <select
            className="form-control"
            value={p.datum}
            disabled={p.disabled}
            id="datum"
            placeholder={I18n.t('musit.objectModule.place.coordinateDatum')}
            onChange={e => {
              p.updateCoordinate('datum')(e.target.value);
            }}
          >
            {p.datums ? (
              p.datums.map(({ datum }: { datum: string }, i: number) => (
                <option value={datum} key={`optionRow_${i}`}>
                  {datum}
                </option>
              ))
            ) : (
              <option key={`optionRow_${1}`}>{'No datum'}</option>
            )}
          </select>
        </div>
        <div className="col-md-2">
          {' '}
          <select
            className="form-control"
            id="coordinateGeomertry"
            disabled={p.disabled}
            onChange={e => {
              p.updateCoordinate('coordinateGeometry')(e.target.value);
            }}
            value={p.geometryType}
          >
            {p.geometryTypes ? (
              p.geometryTypes.map(({ geometry }: { geometry: string }, i: number) => (
                <option value={geometry} key={`optionRow_${i}`}>
                  {words[geometry]}
                </option>
              ))
            ) : (
              <option key={`optionRow_${1}`}>{'No data'}</option>
            )}
          </select>
        </div>
      </div>
      <div className="form-row">
        {p.children}
        {p.coordinateSideText}
      </div>
    </div>
  );
};

export default LatLongComponent;
