import * as React from 'react';
import AdmPlaceComponent from './AdmPlaceComponent';
import CoordinatAttributesComponent from './CoordinateAttributes';
import CoordinateComponent from './CoordinateComponent';
import { LMap } from './MapComponent/MapComponent';
import MarineAttributes from './MarineAttributes';
import NoteField from './NoteField';
import coordToDerived from './CoordinateLogic/coordinateToDerived';
import { DerivedCoordinate, InputCoordinate } from '../types/place/place';
import { OutPlace as Place, AdmPlace } from '../types/place/place';
import { getTranslator } from '../../../shared/language';
import PlaceSuggestion from './PlaceSuggestion';
import { get } from '../../../shared/ajaxFetch/ajaxFetch';
import config from '../../../config';
import { AppSessionContext } from '../../../modules/app/AppComponent';

export type PlaceProps = { place: Place; methodId?: number } & {
  updatePlace: (p: Place) => void;
  updateMethodId: (methodId: string) => void;
  disabled?: boolean;
};

export type ShowMapState = {
  showMap: boolean;
};

const words = getTranslator({
  en: {
    ecology: 'Ecology',
    locality: 'Locality',
    search: 'Search any place'
  },
  no: {
    ecology: 'Økologi',
    locality: 'Lokalitet',
    search: 'Søk alle steder'
  }
});

const CoordinateFormats = (props: { coordinateType: string; geometryType: string }) => (
  <div className="container-fluid" style={{ fontSize: 'small' }}>
    <p style={{ fontSize: 'medium' }}>
      {`Lovlige formater for ${props.coordinateType}`}:
    </p>

    {props.coordinateType === 'MGRS' ? (
      <div>
        <pre>NM 2345,3221 (antall siffer i Øst/Vest og Nord/Sør er fra 1 - 5).</pre>
        <pre>Sone og belte (Zone and Band) må være angitt.</pre>
        <pre>Øst/Vest og Nord/Sør skilles med komma.</pre>
      </div>
    ) : props.coordinateType === 'UTM' ? (
      <div>
        <pre>
          "555774.345 6651831.234", der første tall er Øst/Vest, og Nord/Sør er andre
          tall. <br />
          Band må oppgis i 'N' eller 'S'
        </pre>
        <pre>Tallene kan skilles med komma eller mellomrom</pre>
      </div>
    ) : props.coordinateType === 'LAT/LONG' && props.geometryType === 'point' ? (
      <div>
        <pre>
          Enten grader (min (og sekunder)) eller desimalgrader Grader minutter og
          sekunder:
        </pre>
        <pre>
          60 45 23N 10 34 12E 53
          <br />
          23N 10 12 34W{' '}
        </pre>
        <pre>
          Desimalgrader: 60.2345 32.322 <br />
          -10.34 34
        </pre>
        <pre>Negative tall er S for første tall og W for andre tall.</pre>
        <pre>Nord/Sør og Øst/Vest skilles med mellomrom</pre>
      </div>
    ) : props.coordinateType === 'LAT/LONG' && props.geometryType === 'line' ? (
      <div>
        <p style={{ fontWeight: 'bold', fontSize: 'small' }}>
          {`Lovlige formater for line`}:
        </p>

        <pre>
          x<sub>1</sub> y<sub>1</sub>, x<sub>2</sub> y<sub>2</sub>, x<sub>3</sub> y
          <sub>3</sub>,....,x<sub>n</sub> y<sub>n</sub>
          <br />
          <i>60.1 23.34,60.23 23.8, 61.23 25.123, 62.10 26.12, 63.12 23</i>
        </pre>
      </div>
    ) : props.coordinateType === 'LAT/LONG' && props.geometryType === 'polygon' ? (
      <div>
        <p style={{ fontSize: 'small', fontWeight: 'bold' }}>
          {`Lovlige formater for polygon`}:
        </p>

        <pre>
          x<sub>1</sub> y<sub>1</sub>, x<sub>2</sub> y<sub>2</sub>, x<sub>3</sub> y
          <sub>3</sub>,....,x<sub>n</sub> y<sub>n</sub>
          <br />
          <i>60.1 23.34,60.23 23.8, 61.23 25.123, 62.10 26.12, 63.12 23, 60.1 23.34</i>
        </pre>
      </div>
    ) : (
      <div />
    )}
  </div>
);

export const PlaceComponent = (p: PlaceProps) => {
  const updateAdmPlace = (a?: AdmPlace) =>
    p.updatePlace({ ...p.place, admPlaceUuid: a && a.admPlaceUuid, admPlace: a });

  const [showCoordinateFormats, onToggleShow] = React.useState(false);
  const onToggleCoordinateFormats = () =>
    onToggleShow((p: boolean) => (p ? false : true));

  const [showMapState, updateShowMap] = React.useState<ShowMapState>({
    showMap: false
  });
  let derivedCoordinate: DerivedCoordinate | undefined;

  const updShowMap = () =>
    updateShowMap(p => ({
      ...p,
      showMap: p.showMap ? false : true
    }));

  const updateCoordinate = (fieldName: string) => (
    value?: string | number | DerivedCoordinate
  ) => {
    const coordinate: InputCoordinate = p.place.coordinate
      ? p.place.coordinate
      : {
          datum: 'WGS84',
          coordinateType: 'MGRS',
          coordinateString: '',
          coordinateGeometry: 'point'
        };
    const coordinateType =
      fieldName === 'coordinateType' && typeof value === 'string'
        ? value
        : coordinate.coordinateType;
    const coordinateString: string =
      fieldName.toLowerCase() === 'coordinatestring' && value && typeof value === 'string'
        ? value
        : coordinate.coordinateString;

    const zone =
      fieldName.toLowerCase() === 'zone' &&
      value &&
      (typeof value === 'string' || value === undefined)
        ? Number.parseInt(value)
        : coordinate.zone;
    const band =
      fieldName.toLowerCase() === 'band' &&
      value &&
      (typeof value === 'string' || value === undefined)
        ? value
        : coordinate.band;

    const coordToSend = {
      ...coordinate,
      zone,
      band,
      coordinateString,
      coordinateType,
      [fieldName]: value
    };
    const derivedCoordinate = coordToDerived(coordToSend);
    const pl: Place = {
      ...p.place,
      coordinate: {
        ...coordToSend,
        [fieldName]: value,
        derivedCoordinate: derivedCoordinate
      }
    };
    p.updatePlace(pl);
  };

  const updateCoordinateAttributes = (fieldName: string) => (
    value: string | number | boolean
  ) => {
    const pl: Place = {
      ...p.place,
      coordinateAttributes:
        p.place && p.place.coordinateAttributes
          ? { ...p.place.coordinateAttributes, [fieldName]: value }
          : {
              addedLater: false,
              altitudeCa: false,
              depthCa: false,
              coordinateCa: false,
              altitudeUnit: 'Meters',
              depthUnit: 'Meters',
              [fieldName]: value
            }
    };
    p.updatePlace(pl);
  };

  const updateAttributeData = (fieldName: string) => (
    value: string | number | boolean
  ) => {
    p.updatePlace({
      ...p.place,
      attributes: p.place.attributes
        ? { ...p.place.attributes, [fieldName]: value }
        : { [fieldName]: value }
    });
  };
  const appSession = React.useContext(AppSessionContext);
  derivedCoordinate =
    p.place && p.place.coordinate
      ? p.place.coordinate.derivedCoordinate || coordToDerived(p.place.coordinate)
      : undefined;

  const [colletingMethods, setColletingMethods] = React.useState([]);
  const getCollectingEventUrl = config.api.collectingEvent.getCollectingEventMethods;
  React.useEffect(() => {
    const fetchData = async () => {
      const result: Response = await get(getCollectingEventUrl, appSession.accessToken);
      const data = await result.json();
      setColletingMethods(data);
    };
    // tslint:disable-next-line:no-floating-promises
    fetchData();
  }, [appSession.accessToken, getCollectingEventUrl]);
  return (
    <React.Fragment>
      <div className="form-row" style={{ padding: '10px 0px' }}>
        <label
          className="col-form-label d-flex justify-content-end col-md-2"
          htmlFor="placeSearch"
        >
          {words.search}
        </label>
        <div className="col-md-8">
          <PlaceSuggestion
            place={p.place}
            updatePlace={p.updatePlace}
            updateMethodId={p.updateMethodId}
          />
        </div>
      </div>
      <AdmPlaceComponent
        admPlace={p.place.admPlace}
        updateState={updateAdmPlace}
        disabled={p.disabled}
      />
      <NoteField
        value={
          p.place.attributes && p.place.attributes.locality
            ? p.place.attributes.locality
            : ''
        }
        label={words.locality}
        id="locality"
        updateField={updateAttributeData}
        disabled={p.disabled}
      />
      <NoteField
        value={
          p.place.attributes && p.place.attributes.ecology
            ? p.place.attributes.ecology
            : ''
        }
        label={words.ecology}
        id="ecology"
        updateField={updateAttributeData}
        disabled={p.disabled}
      />
      <MarineAttributes
        collectingEventMethods={colletingMethods}
        language={appSession.language}
        disabled={p.disabled}
        methodId={p.methodId}
        station={p.place.attributes && p.place.attributes.station}
        sample={p.place.attributes && p.place.attributes.sample}
        ship={p.place.attributes && p.place.attributes.ship}
        onChange={updateAttributeData}
        onChangeMethod={p.updateMethodId}
      />
      <CoordinateComponent
        coordinateString={p.place.coordinate ? p.place.coordinate.coordinateString : ''}
        updateShowMap={updShowMap}
        validCoordinate={
          derivedCoordinate &&
          ((derivedCoordinate.lat && derivedCoordinate.lng) ||
            derivedCoordinate.polygon ||
            derivedCoordinate.line ||
            derivedCoordinate.rectangle)
            ? true
            : false
        }
        showMap={showMapState.showMap}
        coordinateType={p.place.coordinate ? p.place.coordinate.coordinateType : 'MGRS'}
        zone={p.place.coordinate ? p.place.coordinate.zone : undefined}
        band={p.place.coordinate ? p.place.coordinate.band : undefined}
        datum={(p.place.coordinate && p.place.coordinate.datum) || 'WGS84'}
        coordinateGeometry={
          p.place.coordinate ? p.place.coordinate.coordinateGeometry : 'point'
        }
        updateCoordinate={updateCoordinate}
        onToggleCoordinateFormats={onToggleCoordinateFormats}
        showValidCoordinate={showCoordinateFormats}
      />

      {derivedCoordinate &&
      (derivedCoordinate.polygon ||
        derivedCoordinate.line ||
        derivedCoordinate.rectangle ||
        (derivedCoordinate.lat && derivedCoordinate.lng)) &&
      showMapState.showMap ? (
        <div className="form-row">
          <div className="col-md-10 offset-md-2">
            <LMap
              coord={{
                lat: derivedCoordinate.lat,
                lng: derivedCoordinate.lng,
                lat2: derivedCoordinate.lat2,
                lng2: derivedCoordinate.lng2,
                polygon: derivedCoordinate.polygon,
                rectangle: derivedCoordinate.rectangle,
                line: derivedCoordinate.line
              }}
              style={{
                height: '40vh',
                width: '60%'
              }}
            />
          </div>
        </div>
      ) : derivedCoordinate === undefined && showCoordinateFormats ? (
        <div className="form-row">
          <div className="col-md-10 offset-md-2">
            {' '}
            <CoordinateFormats
              coordinateType={
                p.place.coordinate ? p.place.coordinate.coordinateType : 'MGRS'
              }
              geometryType={
                p.place.coordinate && p.place.coordinate.coordinateGeometry
                  ? p.place.coordinate.coordinateGeometry
                  : 'point'
              }
            />
          </div>
        </div>
      ) : null}

      <CoordinatAttributesComponent
        disabled={p.disabled}
        updateAttributes={updateCoordinateAttributes}
        attributes={p.place.coordinateAttributes}
      />
    </React.Fragment>
  );
};

export default PlaceComponent;
