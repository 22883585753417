import * as React from 'react';
import { ActorAndRelation } from '../types/event/event';

import { TaxonEvent, fullFormatTaxon, createDetString } from './taxonLib';
import '../css/Compact.css';

type PropTypes = { taxonEvents: TaxonEvent[] };

const Taxon = (taxEvent: TaxonEvent) => (
  <React.Fragment>
    <div className="form-row narrow">
      <div className="col-md-6">
        <div className="form-group">
          <label className="narrow" htmlFor="taxonNameId">
            Taxon
          </label>
          <input
            type="text"
            id="taxonNameId"
            className="form-control-plaintext"
            value={
              taxEvent.attributes.taxons[0] &&
              fullFormatTaxon(
                taxEvent.attributes.taxons[0],
                taxEvent.attributes.taxons[0].precisionCode,
                taxEvent.attributes.taxons[0].precisionRank,
                taxEvent.attributes.taxons[0].sensu
              )
            }
          />
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group">
          <label className="narrow" htmlFor="detId">
            Det
          </label>
          <input
            type="text"
            id="detId"
            className="form-control-plaintext"
            value={createDetString(taxEvent.relatedActors)}
          />
        </div>
      </div>
      <div className="col-md-2">
        <div className="form-group">
          <label className="narrow" htmlFor="detId">
            Date
          </label>
          <input
            type="text"
            id="dateId"
            className="form-control-plaintext"
            value={(taxEvent.date && taxEvent.date.eventDateFrom) || ''}
          />
        </div>
      </div>
    </div>
  </React.Fragment>
);

const TaxonRev = (taxEvent: TaxonEvent) => (
  <tr>
    <td>{taxEvent.detType}</td>
    <td>
      {taxEvent.attributes.taxons[0] &&
        fullFormatTaxon(
          taxEvent.attributes.taxons[0],
          taxEvent.attributes.taxons[0].precisionCode,
          taxEvent.attributes.taxons[0].precisionRank,
          taxEvent.attributes.taxons[0].sensu
        )}
    </td>{' '}
    <td>{taxEvent.date && taxEvent.date.eventDateFrom} </td>
    <td>
      {taxEvent.relatedActors
        ? taxEvent.relatedActors.reduce((p: string, a: ActorAndRelation) => {
            const r = p !== '' ? p + '; ' : a.actorName;
            return r;
          }, '')
        : ''}
    </td>
  </tr>
);

const ShowRevisions = (props: { rev: TaxonEvent[] }) => (
  <table className="table table-sm">
    <thead>
      <tr>
        <th>Det type</th>
        <th>Taxon</th> <th>Date </th>
        <th>Det</th>
      </tr>
    </thead>
    <tbody>
      {props.rev.map(r => (
        <TaxonRev {...r} />
      ))}
    </tbody>
  </table>
);

const ViewTaxonComponent = (props: PropTypes) => (
  <div className="container p-1">
    <form>
      <Taxon {...props.taxonEvents[props.taxonEvents.length - 1]} />
      <h6>
        {' '}
        <b>{'Revisions'}</b>
      </h6>
      <ShowRevisions rev={props.taxonEvents} />
    </form>
  </div>
);
export default ViewTaxonComponent;
