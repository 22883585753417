import * as React from 'react';
import config from '../../../config';
import { AppSession } from '../../../types/appSession';
import {
  SubCollectionMuseumAndCollection,
  NextMuseumNoAndSubNo
} from '../types/event/event';
import { get } from '../../../shared/ajaxFetch/ajaxFetch';
import Select from 'react-select';
import { createMuseumObject } from '../object/ObjectContainerCreate';
import { getTranslator } from '../../../shared/language';
import * as H from 'history';

enum NumberingType {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL'
}

type Props = {
  appSession: AppSession;
  history: H.History;
};

type DropDownValue = {
  label: string;
  value: number;
};

export const words = getTranslator({
  en: {
    createObject: 'Create new object',
    AUTO: 'Generate number',
    MANUAL: 'Register arrears',
    adviceNumber: 'When choosing arrears, the regnr/museumnr must be lower than: '
  },
  no: {
    createObject: 'Opprett nytt objekt',
    AUTO: 'Autonummerer',
    MANUAL: 'Registrer restanse',
    adviceNumber: 'Ved valg av restanse må regnr/museumsnr være lavere enn: '
  }
});

const CreateNewObject = ({ appSession, history }: Props) => {
  const [restanseNr, changeRestanseNr] = React.useState<string>('');
  const [subcollections, changeSubcollections] = React.useState<
    SubCollectionMuseumAndCollection[]
  >([]);
  const [selectedSubcollection, changeSelectedSubcollection] = React.useState<
    SubCollectionMuseumAndCollection
  >({
    collectionUuid: '',
    museumId: 0,
    collectionNameNo: '',
    collectionNameEn: '',
    prefix: undefined,
    startNo: undefined,
    museumNumberSequenceId: 0,
    subCollectionId: 0
  });
  const [dropdownValue, changeDropdownValue] = React.useState<DropDownValue>({
    label: '',
    value: 0
  });
  const [restanseNrBool, changerestanseNrBool] = React.useState<boolean>(false);

  React.useEffect(() => {
    const fetchData = async () => {
      const result: Response = await get(
        config.api.objects.getSubcollections(appSession),
        appSession.accessToken
      );
      const data = await result.json();

      changeSubcollections(data);
      let tempLastSelectedSubCollection =
        data.find(
          (sc: SubCollectionMuseumAndCollection) =>
            sc.museumNumberSequenceId === getLastSelectedSubCollection()
        ) || data[0];
      if (tempLastSelectedSubCollection) {
        changeDropdownValue({
          label: formatLabel(tempLastSelectedSubCollection),
          value: tempLastSelectedSubCollection.museumNumberSequenceId
        });
        changeSelectedSubcollection(tempLastSelectedSubCollection);
      }
    };
    // tslint:disable-next-line:no-floating-promises
    fetchData();
  }, [appSession]);

  React.useEffect(() => {
    if (dropdownValue.value) {
      localStorage.setItem('museumNumberSequenceId', dropdownValue.value.toString());
      let tempSelectedSubCollection = subcollections.find(
        (sc: SubCollectionMuseumAndCollection) =>
          sc.museumNumberSequenceId === dropdownValue.value
      );
      if (tempSelectedSubCollection) {
        changeSelectedSubcollection(tempSelectedSubCollection);
      }
      changeMuseumNumberSequenceId(dropdownValue.value);
    }
  }, [dropdownValue, subcollections]);

  const getLastSelectedSubCollection = (): number | undefined => {
    const museumNumberSequenceIdStr = localStorage.getItem('museumNumberSequenceId');
    return museumNumberSequenceIdStr
      ? Number.parseInt(museumNumberSequenceIdStr)
      : undefined;
  };
  const [museumNumberSequenceId, changeMuseumNumberSequenceId] = React.useState<
    number | undefined
  >(getLastSelectedSubCollection());
  const [numberingType, changeNumberingType] = React.useState<NumberingType>(
    NumberingType.AUTO
  );
  const getNextNumber = async (museumNumberSequenceId: number) => {
    const url = config.api.objects.getNextMuseumNo(museumNumberSequenceId);
    const json = await get(url, appSession.accessToken);
    const res: NextMuseumNoAndSubNo = await json.json();
    return res;
  };

  const formatLabel = (subCollection: SubCollectionMuseumAndCollection) => {
    const word = getTranslator({
      en: { subCollectionName: subCollection.collectionNameEn },
      no: { subCollectionName: subCollection.collectionNameNo }
    });
    return word.subCollectionName + ' (' + subCollection.prefix + ')';
  };

  const MAX_NUMBER_VALUE =
    (selectedSubcollection?.startNo && selectedSubcollection?.startNo - 1) || 1999999;
  const MIN_NUMBER_VALUE = 0;

  const parseRestanseNrInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(parseInt(e.currentTarget.value))) {
      changeRestanseNr(Math.abs(parseInt(e.currentTarget.value)).toString());
    } else {
      changeRestanseNr('');
    }

    if (
      parseInt(e.currentTarget.value) > MAX_NUMBER_VALUE ||
      parseInt(e.currentTarget.value) === MIN_NUMBER_VALUE ||
      e.currentTarget.value === ''
    ) {
      changerestanseNrBool(true);
      e.preventDefault();
      return;
    } else if (
      selectedSubcollection.startNo &&
      parseInt(e.currentTarget.value) >= selectedSubcollection.startNo
    ) {
      changerestanseNrBool(true);
      e.preventDefault();
      return;
    } else {
      changerestanseNrBool(false);
    }
  };

  const createObjectAndRedirect = async () => {
    if (museumNumberSequenceId) {
      const res: NextMuseumNoAndSubNo = await getNextNumber(museumNumberSequenceId);
      if (
        selectedSubcollection &&
        selectedSubcollection.startNo &&
        restanseNr &&
        numberingType === NumberingType.MANUAL
      ) {
        if (parseInt(restanseNr) > selectedSubcollection.startNo) {
          return;
        }
        res.museumNo = selectedSubcollection.prefix + restanseNr.toString();
        res.museumNoSeq = parseInt(restanseNr);
      }
      delete res['museumNumberSequenceId'];
      const selectedSubCollection = subcollections.find(
        sc => sc.museumNumberSequenceId === museumNumberSequenceId
      );
      await createMuseumObject(
        appSession,
        history,
        res,
        selectedSubCollection ? selectedSubCollection.subCollectionId : undefined
      );
    }
  };
  return subcollections.length > 0 ? (
    <div className="container">
      <div className="form-group row">
        <div className="col-md-4">
          <Select
            placeholder="Select..."
            clearable={false}
            value={dropdownValue}
            multi={false}
            onChange={changeDropdownValue}
            options={subcollections.map(coll => ({
              label: formatLabel(coll),
              value: coll.museumNumberSequenceId
            }))}
          />
        </div>

        <div className="form-check col-md-5 mt-2">
          <input
            type="checkbox"
            id="numberAuto"
            name="number"
            value={NumberingType.AUTO}
            checked={numberingType === NumberingType.AUTO}
            onChange={e => {
              if (e.target.checked) changeNumberingType(NumberingType.AUTO);
            }}
            onClick={e => changeRestanseNr('')}
          />{' '}
          <label htmlFor="numberAuto" style={{ marginRight: '15px' }}>
            {words.AUTO}
          </label>
          <input
            type="checkbox"
            id="numberManual"
            name="number"
            value={NumberingType.MANUAL}
            checked={numberingType === NumberingType.MANUAL}
            onChange={e => {
              if (e.target.checked) changeNumberingType(NumberingType.MANUAL);
            }}
            onClick={e => changerestanseNrBool(true)}
          />{' '}
          <label htmlFor="numberManual">{words.MANUAL}</label>
        </div>

        <div
          hidden={numberingType === NumberingType.MANUAL ? false : true}
          className="col-5"
          style={{ marginTop: '20px' }}
        >
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="restanse-pre">
                {selectedSubcollection ? selectedSubcollection.prefix : ''}
              </span>
            </div>
            <input
              type="number"
              className="form-control"
              placeholder=""
              value={restanseNr}
              onChange={e => parseRestanseNrInput(e)}
              id="restanse-value"
              aria-describedby="restanse-pre"
            />
          </div>
          <p>{words.adviceNumber + selectedSubcollection?.startNo}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-10"></div>
        <div className="col-md-2">
          <button
            type="button"
            onClick={createObjectAndRedirect}
            disabled={
              !museumNumberSequenceId ||
              numberingType === undefined ||
              (numberingType === NumberingType.MANUAL && restanseNrBool)
            }
            className="btn btn-primary btn-sm"
          >
            {words.createObject}
          </button>
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment>
      <div className="row col-md-4" style={{ marginBottom: '20px' }}>
        <button
          type="button"
          onClick={createObjectAndRedirect}
          className="btn btn-secondary btn-sm"
        >
          {words.createObject}
        </button>
      </div>
    </React.Fragment>
  );
};

export default CreateNewObject;
